var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app",
        [
          _c(
            "div",
            {
              staticClass: "visually-hidden",
              attrs: {
                role: "status",
                "aria-live": "polite",
                id: "live-region",
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("timesheetUI.TimesheetsForLabel")) +
                  " " +
                  _vm._s(_vm.selectedDate.startOfWeekLong) +
                  " " +
                  _vm._s(_vm.selectedDate.year) +
                  " to " +
                  _vm._s(_vm.selectedDate.endOfWeekLong) +
                  " " +
                  _vm._s(_vm.selectedDate.year) +
                  " "
              ),
            ]
          ),
          _c(
            "a",
            { staticClass: "skip-link", attrs: { href: "#addJobButton" } },
            [_vm._v(_vm._s(_vm.$t("timesheetUI.TimesheetsSkipBar")))]
          ),
          _c("TimeEntryDrawer"),
          _c(
            "v-main",
            { style: `padding-right: ${_vm.VMainRightOffset}` },
            [
              _c(
                "v-container",
                {
                  staticClass: "pa-5 pb-0",
                  attrs: { fluid: "", "fill-height": "" },
                },
                [
                  _c("timesheet-entry-dialog"),
                  _c("VueAnnouncer"),
                  _vm.loading ? _c("timesheet-loader") : _vm._e(),
                  !_vm.initialLoad
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "flex-grow-1 height-100 d-flex flex-column",
                        },
                        [
                          _c("AlertOnBehalfOf"),
                          _c(
                            "div",
                            { staticClass: "flex-grow-1" },
                            [
                              _c("TheHeader", { staticClass: "mb-4" }),
                              _c("VDivider", {
                                attrs: { role: "presentation", dark: "" },
                              }),
                              _c("AssignedJobsBanner"),
                              _c("NavigationHeader", {
                                staticClass: "mb-8 mt-4",
                              }),
                              _c("CalendarHeader", { staticClass: "mb-4" }),
                              _c(
                                "div",
                                {
                                  staticClass: "sr-only",
                                  attrs: {
                                    "aria-live": "polite",
                                    "aria-atomic": "true",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatDateToAnnounceCurrentYearAndMonth(
                                          _vm.calendar[0].date
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _vm.absenceBookingAllowed && _vm.absenceModule
                                ? _c("div", [_c("absence-rows")], 1)
                                : _vm._e(),
                              _c("div", { staticClass: "ts-main-container" }, [
                                _vm.timesheetJobs.length > 0
                                  ? _c(
                                      "div",
                                      [
                                        _vm.doesAnyJobRequireLocation &&
                                        _vm.isLocationActiveForThisWeek
                                          ? _c("TimesheetManagePreference")
                                          : _vm._e(),
                                        _c("TimesheetJobGroups", {
                                          attrs: {
                                            openActions: {
                                              openTimesheetDialog:
                                                _vm.openTimesheetDialog,
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : !_vm.hasSomeContract
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "v-alert",
                                          {
                                            staticClass:
                                              "alert alert-danger mt-9",
                                            staticStyle: {
                                              top: "15px",
                                              position: "relative",
                                            },
                                            attrs: {
                                              outlined: "",
                                              role: "alert",
                                              "asp-validation-summary": "All",
                                              type: "error",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "alert alert-danger",
                                                attrs: {
                                                  role: "alert",
                                                  "asp-validation-summary":
                                                    "All",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "ErrorMessages.5011"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : !_vm.timesheetJobs.length
                                  ? _c(
                                      "div",
                                      [
                                        _vm.searchTimesheetJobs ||
                                        _vm.globalSearchSelectedFilters.length >
                                          0
                                          ? _c("timesheet-no-results-found")
                                          : _c("timesheet-empty"),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ],
                            1
                          ),
                          _vm.calendar.length > 0 && _vm.hasSomeContract
                            ? _c(
                                "div",
                                [
                                  _c("timesheet-footer", {
                                    staticClass: "mt-16",
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("timesheet-dialog-delete-error"),
                  _c("timesheet-dialog-submit-confirmation"),
                  _c("timesheet-dialog-hide-job-error"),
                  _c("timesheet-dialog-edit-hidden-job-error"),
                  _vm.absenceBookingAllowed && _vm.absenceModule
                    ? _c("div", [
                        !_vm.loadingAbsenceData
                          ? _c("div", [_c("absence-form-modify")], 1)
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _c("dialog-alert"),
                  _c("dialog-alert-confirm-action"),
                  _c("manage-preference-dialog"),
                ],
                1
              ),
            ],
            1
          ),
          _c("AlertToast", { ref: "alertToast" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }