var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loadingFeatures
    ? _c(
        "div",
        [
          _c(
            "v-expansion-panels",
            {
              attrs: {
                flat: "",
                accordion: "",
                disabled: _vm.isTimesheetBeingCorrected,
              },
            },
            _vm._l(_vm.timesheetExists, function (item, index) {
              return _c(
                "v-expansion-panel",
                {
                  key: item.id,
                  on: { change: () => _vm.handleChange(item, index) },
                },
                [
                  _c("v-expansion-panel-header", {
                    ref: "expansion_header",
                    refInFor: true,
                    attrs: { "hide-actions": "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ open }) {
                            return [
                              _c(
                                "div",
                                {
                                  ref: "header",
                                  refInFor: true,
                                  attrs: { "data-expanded": open },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-space-between mb-1 align-center",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "font-weight--500" },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              style: {
                                                transform: open
                                                  ? "rotate(90deg)"
                                                  : "rotate(0)",
                                              },
                                              attrs: {
                                                size: 18,
                                                color: "primary",
                                              },
                                            },
                                            [_vm._v("mdi-chevron-right")]
                                          ),
                                          item.isPercentageEntry
                                            ? [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "font-weight--600",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.beforeEdit[index]
                                                            .durationPercentageValue ??
                                                            item.durationPercentageValue
                                                        ) +
                                                        "% "
                                                    ),
                                                  ]
                                                ),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "timesheetUI.TableColumns.Duration"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            : [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "font-weight--600",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.beforeEdit[index]
                                                            .durationValue ??
                                                            item.durationValue
                                                        ) +
                                                        "h "
                                                    ),
                                                  ]
                                                ),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "timesheetUI.TableColumns.Duration"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ],
                                        ],
                                        2
                                      ),
                                      !_vm.isTemporaryExternalEmployee
                                        ? _c(
                                            "div",
                                            {
                                              class: `status-pill status-pill--${item.timesheetStatus?.toLowerCase()}`,
                                            },
                                            [
                                              item.timesheetStatus ===
                                              "Unsubmitted"
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "timesheetUI.Status.Unsubmitted"
                                                        )
                                                      )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              item.timesheetStatus ===
                                              "Submitted"
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "timesheetUI.Status.Submitted"
                                                        )
                                                      )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              item.timesheetStatus ===
                                              "Approved"
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "timesheetUI.Status.Approved"
                                                        )
                                                      )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              item.timesheetStatus ===
                                              "Rejected"
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "timesheetUI.Status.Rejected"
                                                        )
                                                      )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  !open
                                    ? _c("div", [
                                        !_vm.agencyData.hideTimesheetWorkcode
                                          ? _c(
                                              "div",
                                              { staticClass: "mb-2" },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      size: 12,
                                                      color: "primary",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "mdi-clipboard-text-outline"
                                                    ),
                                                  ]
                                                ),
                                                _vm.timesheetDetailFeatures
                                                  .activity.required
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "font-size--12",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.activity
                                                            ) +
                                                            " - " +
                                                            _vm._s(
                                                              item.activityName
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : item.workCode
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "font-size--12",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.jobTitleForBilling
                                                                ? `${item.jobTitleForBilling} - ${item.jobTitleForBillingName}`
                                                                : `${item.workCode} - ${item.workCodeName}`
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-space-between",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "font-size--10 accent--text font-weight--600",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item.billableIndicator
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            item.location
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "font-weight--600 d-flex align-center",
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          size: 15,
                                                          color: "primary",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-map-marker-outline"
                                                        ),
                                                      ]
                                                    ),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(item.location) +
                                                          " " +
                                                          _vm._s(
                                                            item.wfh
                                                              ? "- WFH"
                                                              : ""
                                                          )
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          },
                        },
                        {
                          key: "actions",
                          fn: function ({ open }) {
                            return [
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm.$t("timesheetUI.Footer.actions")) +
                                    ": " +
                                    _vm._s(open)
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                  _c("v-expansion-panel-content", [
                    _c(
                      "div",
                      { staticClass: "px-4 py-3" },
                      [
                        _vm.timesheetDetail
                          ? _c("timesheet-entry-detail")
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            }),
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "px-6 py-3 info rounded-lg final-count mt-4 font-size--16",
            },
            [
              _c("span", { staticClass: "font-weight--600" }, [
                _vm._v(
                  _vm._s(_vm.duration) +
                    _vm._s(this.agencyData.isPercentageAgency ? "%" : "h")
                ),
              ]),
              _vm._v(
                " " +
                  _vm._s(_vm.$t("timesheetUI.Footer.FooterTotalLabel")) +
                  " "
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }