var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VCard",
    { staticClass: "pa-12 mt-4 light-border", attrs: { outlined: "" } },
    [
      _c("h2", { staticClass: "text-h2" }, [
        _vm._v(" " + _vm._s(_vm.$t("timesheetUI.Empty.Title")) + " "),
      ]),
      _c("div", { staticClass: "mb-7 font-size--16" }, [
        _vm._v(" " + _vm._s(_vm.$t("timesheetUI.Empty.SubTitle")) + " "),
      ]),
      _c("SearchJob"),
      _c("CopyJobsFromPreviousWeek", {
        staticClass: "ml-5",
        attrs: { color: "primary" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }