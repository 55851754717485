<template>
  <div class="d-flex">
    <v-btn
      outlined
      rounded
      class="font-weight--600 timesheet-entry__actions--new-entry"
      v-if="(!showForm || timesheetDetail?.id) && !isTimesheetBeingCorrected"
      :disabled="loading || savingTimesheet || loadingFeatures"
      @click="addNew"
      key="newEntry"
      >{{ $t('General.Btns.NewEntry') }}</v-btn
    >

    <template v-if="agencyData.enableSelfCorrection">
      <template
        v-if="
          !isTimesheetBeingCorrected &&
          isTimesheetApproved &&
          timesheetDetail?.id
        "
      >
        <v-btn
          rounded
          depressed
          color="primary"
          class="font-weight--600 timesheet-entry__actions--save px-5 ml-auto"
          @click="handleTimesheetCorrection"
          key="correctTimesheet"
          :disabled="
            loading ||
            savingTimesheet ||
            loadingFeatures ||
            !agencyData.enableSelfCorrection ||
            !isCorrectionValid ||
            timesheetDetail.isSelfCorrected
          "
        >
          {{ $t('General.Btns.CorrectTimesheet') }}
        </v-btn>
      </template>

      <template
        v-if="
          timesheetDetail?.id &&
          isTimesheetBeingCorrected &&
          !isTimesheetBeingReviewed
        "
      >
        <v-btn
          outlined
          rounded
          class="font-weight--600 timesheet-entry__actions--new-entry"
          @click="handleCancel"
          key="cancel"
        >
          {{ $t('General.Btns.Cancel') }}
        </v-btn>
        <v-btn
          rounded
          depressed
          color="primary"
          class="font-weight--600 timesheet-entry__actions--save px-5 ml-auto"
          @click="() => setIsTimesheetBeingReviewed(true)"
          v-if="validForEntry"
          key="reviewCorrection"
          :disabled="invalid || !hasTimeEntryChanged || shouldReviewBtnDisable"
        >
          {{ $t('General.Btns.ReviewCorrection') }}
        </v-btn>
      </template>

      <template
        v-if="
          timesheetDetail &&
          timesheetDetail.timesheetStatus === 'Rejected' &&
          agencyData.enableSelfCorrection &&
          timesheetDetail.selfCorrectedHours < 0
        "
      >
        <v-btn
          rounded
          depressed
          color="primary"
          class="font-weight--600 timesheet-entry__actions--save px-5 ml-auto"
          @click="() => saveTimesheet()"
          key="removeCorrection"
          :disabled="
            loadingFeatures ||
            timesheetTotals >
              (timesheetDetail && timesheetDetail.isPercentageEntry ? 100 : 24)
          "
        >
          {{ $t('General.Btns.RemoveCorrection') }}
        </v-btn>
      </template>

      <template v-if="isTimesheetBeingReviewed">
        <v-btn
          outlined
          rounded
          class="font-weight--600 timesheet-entry__actions--new-entry"
          @click="() => setIsTimesheetBeingReviewed(false)"
          key="back"
        >
          {{ $t('General.Btns.Back') }}
        </v-btn>
        <v-btn
          rounded
          depressed
          color="primary"
          :loading="savingTimesheet"
          class="font-weight--600 timesheet-entry__actions--save px-5 ml-auto"
          @click="handleConfirm"
          key="confirm"
          :disabled="!timesheetDetail?.id"
        >
          {{ $t('General.Btns.Confirm') }}
        </v-btn>
      </template>
    </template>

    <v-btn
      rounded
      depressed
      color="primary"
      class="font-weight--600 timesheet-entry__actions--save px-5 ml-auto"
      type="submit"
      key="save"
      :loading="savingTimesheet"
      v-if="
        validForEntry &&
        !isTimesheetApproved &&
        !!timesheetDetail &&
        !(
          timesheetDetail &&
          timesheetDetail.timesheetStatus === 'Rejected' &&
          agencyData.enableSelfCorrection &&
          timesheetDetail.selfCorrectedHours < 0
        )
      "
      :disabled="
        loading ||
        savingTimesheet ||
        invalid ||
        loadingFeatures ||
        (showForm && isTimesheetApproved) ||
        !hasTimeEntryChanged ||
        !isEmpty(preparingForAutoSave)
      "
      >{{ $t('General.Btns.Save') }}</v-btn
    >
  </div>
</template>
<script>
import { cloneDeep, isEmpty } from 'lodash'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'TimeEntryDrawerFooter.vue',
  props: ['saveValidate'],
  computed: {
    ...mapState('storeTimesheets', [
      'timesheetDetail',
      'agencyData',
      'loading',
      'savingTimesheet',
      'loadingFeatures',
      'timesheetExists',
      'hasTimeEntryChanged',
      'isTimesheetBeingCorrected',
      'observer',
      'timesheetDetailOld',
      'isTimesheetBeingReviewed',
      'preparingForAutoSave',
      'isTimeEntryDrawerOpen',
    ]),
    ...mapGetters('storeTimesheets', [
      'validForEntry',
      'isTimesheetApproved',
      'showForm',
      'isCorrectionValid',
      'hoursTotalPerDay',
    ]),
    invalid() {
      return this.observer?.flags.invalid || false
    },
    shouldReviewBtnDisable() {
      const propertiesToExclude = ['billableIndicator', 'disablePPMSelect']

      const customObjectComparison = (obj1, obj2) =>
        JSON.stringify(
          Object.fromEntries(
            Object.entries(obj1).filter(
              ([key]) => !propertiesToExclude.includes(key)
            )
          )
        ) ===
        JSON.stringify(
          Object.fromEntries(
            Object.entries(obj2).filter(
              ([key]) => !propertiesToExclude.includes(key)
            )
          )
        )

      const timesheetDetailLocal = cloneDeep(this.timesheetDetail)
      const key = timesheetDetailLocal.isPercentageEntry
        ? 'durationPercentageValue'
        : 'durationValue'
      timesheetDetailLocal[key] = parseFloat(timesheetDetailLocal[key])

      if (
        customObjectComparison(timesheetDetailLocal, this.timesheetDetailOld)
      ) {
        return true
      }
      return false
    },

    timesheetTotals() {
      const totalTimeAsDayBasis = Math.abs(
        parseFloat(
          this.timesheetDetail.isPercentageEntry
            ? this.timesheetDetail.durationPercentageValue < 0
              ? this.timesheetDetail.durationPercentageValue
              : 0
            : (this.timesheetDetail.durationValue < 0
                ? this.timesheetDetail.durationValue
                : 0) || 0
        ).toFixed(2)
      )
      return (
        parseFloat(totalTimeAsDayBasis) +
        parseFloat(
          this.timesheetDetail.isPercentageEntry
            ? this.hoursTotalPerDay[this.timesheetDetail.dayIndex].percent
            : this.hoursTotalPerDay[this.timesheetDetail.dayIndex].hours
        )
      )
    },
  },
  methods: {
    ...mapMutations('storeTimesheets', [
      'resetTableToSave',
      'revertTimesheet',
      'setTimesheetEntryFieldsForReview',
      'setIsTimesheetBeingCorrected',
      'setIsTimesheetBeingReviewed',
      'setTimesheetDetail',
      'setTimesheetReviewedValue',
      'setTimesheetExists',
      'hideTimeEntryDrawer',
    ]),
    isEmpty,
    ...mapActions('storeTimesheets', ['addNewTimesheet', 'saveTimesheet']),
    addNew() {
      this.resetTableToSave()
      this.revertTimesheet(this.beforeEdit)
      this.addNewTimesheet({ showDrawer: true })
    },
    handleTimesheetCorrection() {
      this.setTimesheetEntryFieldsForReview()
      this.setIsTimesheetBeingCorrected(true)
    },
    handleCancel() {
      this.setIsTimesheetBeingCorrected(false)
      this.setTimesheetDetail({
        data: this.timesheetDetailOld,
        openDrawer: true,
      })
    },
    async handleConfirm() {
      const localTimesheetDetail = cloneDeep(this.timesheetDetail)
      localTimesheetDetail.id = null
      const isSecondAPI = localTimesheetDetail.isPercentageEntry
        ? localTimesheetDetail.durationPercentageValue
        : localTimesheetDetail.durationValue

      const isCorrectedValueMoreThanZero = isSecondAPI > 0

      await this.saveValidate({
        preventTimesheetRefresh: isCorrectedValueMoreThanZero,
      })
      this.setTimesheetDetail({
        data: localTimesheetDetail,
        openDrawer: this.isTimeEntryDrawerOpen,
      })
      this.setTimesheetReviewedValue()

      if (isSecondAPI != 0) {
        this.setTimesheetExists(false)
        await this.saveTimesheet()
      }
      this.hideTimeEntryDrawer()
    },
  },
}
</script>
<style lang="scss" scoped>
.timesheet-entry__actions--new-entry {
  border-color: $border-color;
}
</style>
