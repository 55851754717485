var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-alert",
    {
      staticClass: "py-2",
      attrs: {
        value: _vm.showSnackbar,
        color: "accent-light",
        transition: "slide-y-transition",
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "justify-space-between d-flex font-size--14 align-center",
        },
        [
          _c(
            "span",
            { staticClass: "d-flex black--text" },
            [
              _c("v-icon", {
                staticClass: "mdi mdi-information-outline",
                attrs: { color: "black" },
              }),
              _c("span", { staticClass: "ml-3" }, [
                _c("span", { staticClass: "font-weight--500" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "timesheetUI.HeaderOnBehalfOf.selectedOnBehalfOfText"
                      )
                    )
                  ),
                ]),
                _c("span", { staticClass: "font-weight--600" }, [
                  _vm._v(" " + _vm._s(_vm.OBOUserName) + ". "),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "font-weight--600 font-size--14",
              attrs: {
                color: "primary",
                rounded: "",
                depressed: "",
                disabled: _vm.loading,
              },
              on: { click: _vm.gotomytimesheet },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("timesheetUI.HeaderOnBehalfOf.gotomytimesheet")
                  ) +
                  " "
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }