<template>
  <VBtn
    depressed
    rounded
    :color="color || 'button-background'"
    :height="height || 40"
    @click="copyJobsFromPreviousWeek"
  >
    <span class="font-weight--600">{{
      $t('timesheetUI.Empty.CopyJobFromPreviousWeek')
    }}</span>
  </VBtn>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'CopyJobsFromPreviousWeek',
  props: {
    color: {
      type: String,
    },
    height: {
      type: String,
    },
  },
  methods: {
    ...mapActions('storeTimesheets', ['copyJobsFromPreviousWeek']),
  },
}
</script>
