var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: 680, scrollable: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      class: [
                        "font-weight--600 text-decoration-underline px-0",
                        _vm.screen === "location-dialog"
                          ? "font-size--14"
                          : "font-size--12",
                      ],
                      attrs: { color: "accent", text: "", "x-small": "" },
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("timesheetUI.LearnMore.btnText")) + " "
                  ),
                ]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "px-0 py-0 flex-column" },
            [
              _c("div", { staticClass: "px-6 py-5 width--100" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-space-between align-center width--100",
                  },
                  [
                    _c("div", [
                      _c(
                        "div",
                        { staticClass: "font-weight--600 font-size--24" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("timesheetUI.LearnMore.Heading")) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "", "aria-label": "Close Dialog" },
                        on: { click: _vm.handleClose },
                      },
                      [_c("v-icon", [_vm._v("mdi-close")])],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("v-divider", {
                staticClass: "width--100",
                attrs: { role: "presentation" },
              }),
            ],
            1
          ),
          _c("v-divider", {
            staticClass: "width--100",
            attrs: { role: "presentation" },
          }),
          _c(
            "div",
            {
              staticClass:
                "font-size--14 font-weight--500 primary--text py-4 px-6 overflow-y-auto inner-container",
            },
            [
              _c("div", { staticClass: "font-weight--600" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("timesheetUI.LearnMore.qustion1.qustion")) +
                    " "
                ),
              ]),
              _c("div", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("timesheetUI.LearnMore.qustion1.answer.answer")
                    ) +
                    " "
                ),
              ]),
              _c("ol", { attrs: { type: "1" } }, [
                _c("li", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("timesheetUI.LearnMore.qustion1.answer.list1")
                      ) +
                      " "
                  ),
                ]),
                _c("li", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("timesheetUI.LearnMore.qustion1.answer.list2")
                      ) +
                      " "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "font-weight--600" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("timesheetUI.LearnMore.qustion2.qustion")) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "mb-2" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("timesheetUI.LearnMore.qustion2.answer.answer")
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "font-weight--600" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("timesheetUI.LearnMore.qustion3.qustion")) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "mb-2" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("timesheetUI.LearnMore.qustion3.answer.answer")
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "font-weight--600" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("timesheetUI.LearnMore.qustion4.qustion")) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "mb-2" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("timesheetUI.LearnMore.qustion4.answer.answer")
                    ) +
                    " "
                ),
              ]),
              _c("ul", { staticClass: "mb-2" }, [
                _c("li", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("timesheetUI.LearnMore.qustion4.answer.list1")
                      ) +
                      " "
                  ),
                ]),
                _c("li", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("timesheetUI.LearnMore.qustion4.answer.list2")
                      ) +
                      " "
                  ),
                ]),
                _c("li", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("timesheetUI.LearnMore.qustion4.answer.list3")
                      ) +
                      " "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "font-weight--600" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("timesheetUI.LearnMore.qustion5.qustion")) +
                    " "
                ),
              ]),
              _c("div", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("timesheetUI.LearnMore.qustion5.answer.answer")
                    ) +
                    " "
                ),
              ]),
            ]
          ),
          _c("v-divider", {
            staticClass: "width--100",
            attrs: { role: "presentation" },
          }),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight--600 preference-actions--close my-4",
                  attrs: { outlined: "", rounded: "" },
                  on: { click: _vm.handleClose },
                },
                [_vm._v(_vm._s(_vm.$t("General.Btns.Close")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }