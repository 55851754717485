<template>
  <v-tooltip
    v-model="show"
    :top="top"
    :left="left"
    :right="right"
    :bottom="bottom"
    :nudge-left="nudgeLeft"
    :nudge-right="nudgeRight"
    :nudge-top="nudgeTop"
    :nudge-bottom="nudgeBottom"
    :absolute="absolute"
    :offset-x="offsetX"
    :offset-y="offsetY"
    origin="top center"
    :open-on-hover="openHover"
    open-on-click
    :disabled="disabled"
    :content-class="`timesheet-tooltip__v-tooltip ${tooltipTriangleClass}`"
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        v-on="on"
        v-click-outside="hideToolTip"
        v-bind="attrs"
        class="tooltip-trigger"
        @click="show = !show"
      >
        <slot name="trigger" :show="show" :manualHandler="manualHandler">
        </slot>
        <span role="status" ref="liveRegion" class="sr-only"></span>
      </div>
    </template>
    <v-card class="timesheet-tooltip__body" rounded="xl" ref="tooltipBody">
      <v-card-text class="px-3 py-2">
        <slot name="body"></slot>
      </v-card-text>
    </v-card>
  </v-tooltip>
</template>

<script>
export default {
  name: 'TimesheetTooltipV2',
  data() {
    return {
      show: false,
    }
  },
  props: {
    top: {
      default: false,
      type: Boolean,
    },
    bottom: {
      default: false,
      type: Boolean,
    },
    left: {
      default: false,
      type: Boolean,
    },
    right: {
      default: false,
      type: Boolean,
    },
    nudgeLeft: {
      default: 0,
      type: [String, Number],
    },
    nudgeRight: {
      default: 0,
      type: [String, Number],
    },
    nudgeTop: {
      default: 0,
      type: [String, Number],
    },
    nudgeBottom: {
      default: 0,
      type: [String, Number],
    },
    absolute: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    liveRegionText: {
      type: String,
      default: '',
    },
    openHover: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    offsetX() {
      return this.left || this.right
    },
    offsetY() {
      return this.top || this.bottom
    },
    tooltipTriangleClass() {
      if (this.top) {
        return 'tooltip-top'
      }
      if (this.left) {
        return 'tooltip-left'
      }
      if (this.right) {
        return 'tooltip-right'
      }
      if (this.bottom) {
        return 'tooltip-bottom'
      }
      return ''
    },
  },
  updated() {
    this.updateAccessibilityAttributes()
  },
  methods: {
    hideToolTip() {
      this.show = false
    },
    updateAccessibilityAttributes() {
      const parentElement = this.$refs.tooltipBody?.$el?.parentElement

      if (
        parentElement &&
        parentElement.classList.contains('v-tooltip__content')
      ) {
        parentElement.setAttribute('role', 'tooltip')
        // parentElement.setAttribute('aria-hidden', !this.show)
        if (this.show) {
          this.$refs.liveRegion.innerHTML = ''
          setTimeout(() => {
            this.$refs.liveRegion.innerHTML =
              '<span>' + this.liveRegionText + '</span>'
          }, 100)
        }
      }
    },
    manualHandler(arg) {
      this.show = arg || false
    },
  },
}
</script>

<style lang="scss" scoped>
.v-tooltip__content {
  background-color: $primary !important;
  border-radius: 8px;
  max-width: 225px;
}
.v-tooltip__content.menuable__content__active {
  opacity: 1 !important;
}
.timesheet-tooltip__v-tooltip {
  background-color: transparent;
  padding: 0;
  .timesheet-tooltip__body {
    box-shadow: 0 6px 13px -5px rgba(0, 0, 0, 0.5);
    background-color: $primary;
    .v-card__text {
      font-size: 14px;
      line-height: 1.5;
      color: #fff;
    }

    i {
      font-size: 17px;
      padding: 3px;
      display: inline-block;
      width: 24px;
    }
  }
}

.tooltip-top::before {
  border-right: solid 8px transparent;
  border-left: solid 8px transparent;
  transform: translateX(-50%);
  position: absolute;
  z-index: -21;
  content: '';
  top: 100%;
  left: 50%;
  height: 0;
  width: 0;
}

.tooltip-top::before {
  border-top: solid 8px $primary !important;
}

.tooltip-bottom::before {
  border-right: solid 8px transparent;
  border-left: solid 8px transparent;
  transform: translateX(-50%);
  position: absolute;
  z-index: -21;
  content: '';
  bottom: 100%;
  left: 50%;
  height: 0;
  width: 0;
}

.tooltip-bottom::before {
  border-bottom: solid 8px $primary !important;
}

.tooltip-right::before {
  content: ' ';
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -8px;
  border-width: 8px;
  border-style: solid;
  border-top: solid 8px transparent;
  border-bottom: solid 8px transparent;
  border-left: solid 8px transparent;
}

.tooltip-right::before {
  border-right: solid 8px $primary !important;
}

.tooltip-left::before {
  content: ' ';
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -8px;
  border-width: 8px;
  border-style: solid;
  border-top: solid 8px transparent;
  border-bottom: solid 8px transparent;
  border-right: solid 8px transparent;
}

.tooltip-left::before {
  border-left: solid 8px $primary !important;
}
</style>
