import { orderBy } from 'lodash'

export function sortByFavJob(array) {
  array?.sort((a, b) => {
    if (a.isFavJob_V2 && !b.isFavJob_V2) {
      return -1
    } else if (!a.isFavJob_V2 && b.isFavJob_V2) {
      return 1
    } else {
      return 0
    }
  })
}

/**
 *
 * @param {Object} grouped          grouped jobs by jobGroupName
 * @param {Object[]} job            list of all timesheet jobs
 * @param {String} jobsSortOrder    job list sort order
 * @returns {Object} {sortedJobs, groupedJobs}
 * @description returns the sorted jobs according to the jobSortOrder and the grouped jobs
 */
export const sortJobs = (grouped, jobs, jobsSortOrder) => {
  const billableKey = 'Billable'
  let sortedJobs = jobs
  let billableSort = ''

  if (jobsSortOrder) {
    switch (jobsSortOrder) {
      case 'BI - asc':
        sortedJobs = jobs
        billableSort = 'asc'
        break
      case 'BI - desc':
        sortedJobs = jobs
        billableSort = 'desc'
        break
      case 'Name - asc':
        sortedJobs = orderBy(jobs, (item) => item.jobName, 'asc')
        break
      case 'Name - desc':
        sortedJobs = orderBy(jobs, (item) => item.jobName, 'desc')
        break

      default:
        sortedJobs = jobs
        break
    }
  }

  let keys = Object.keys(grouped)
  const groupedJobs = {}
  if (
    jobsSortOrder &&
    billableSort &&
    Object.keys(grouped).includes(billableKey)
  ) {
    keys = keys.filter((key) => key !== billableKey)
    if (billableSort === 'asc') {
      keys.unshift(billableKey)
    }
    if (billableSort === 'desc') {
      keys.push(billableKey)
    }
  }
  keys.forEach((key) => {
    groupedJobs[key] = grouped[key]
  })

  sortByFavJob(sortedJobs)
  return { sortedJobs, groupedJobs }
}

export const DEFAULT_PANELS = [0, 1, 2, 3, 4, 5]
