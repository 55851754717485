var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "time-entry-cell height-100 d-flex",
      class: `${_vm.multiple ? "multiple-entries" : ""}`,
      on: {
        dblclick: function ($event) {
          return _vm.$emit("dblclick", $event.target.value)
        },
      },
    },
    [
      _c("VTextField", {
        ref: "timeEntryInput",
        class: `time-entry--cell glow ${_vm.shouldGlow ? "active" : ""} ${
          _vm.activeCell === _vm.id ? "blue-glow" : ""
        }`,
        attrs: {
          value: _vm.value,
          dense: "",
          outlined: "",
          readonly: _vm.multiple || _vm.readonly,
          error: !!_vm.error,
          disabled: _vm.disabled,
          loading: _vm.loading,
          id: _vm.id,
          messages: _vm.error,
          "data-timsheet-cell-job": _vm.disabled ? null : _vm.jobId,
          "aria-label": `${_vm.jobName || ""} - ${
            _vm.humanFriendlyDate
          }, ${_vm.$t("timesheetUI.Tooltips.InlineCellInfo")}`,
        },
        on: {
          focus: function ($event) {
            $event.preventDefault()
            return _vm.selectCellText.apply(null, arguments)
          },
          blur: function ($event) {
            return _vm.$emit("blur", $event)
          },
          input: function ($event) {
            return _vm.$emit("input", $event)
          },
          keydown: _vm.handleKeyDown,
        },
        scopedSlots: _vm._u(
          [
            _vm.error
              ? {
                  key: "prepend-inner",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "d-flex flex-column" },
                        [
                          _c("Tooltip", {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "trigger",
                                  fn: function ({ manualHandler }) {
                                    return [
                                      _c(
                                        "VBtn",
                                        {
                                          attrs: {
                                            "aria-label": "warning message",
                                            "aria-describedby": `error-message-${_vm.id}`,
                                            "x-small": "",
                                            icon: "",
                                            color: "error",
                                          },
                                          on: {
                                            focus: () => manualHandler(true),
                                            blur: () => manualHandler(false),
                                          },
                                        },
                                        [
                                          _c("VIcon", [
                                            _vm._v("mdi-information-outline"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "body",
                                  fn: function () {
                                    return [
                                      _c("span", {
                                        attrs: {
                                          id: `error-message-${_vm.id}`,
                                        },
                                        domProps: {
                                          innerHTML: _vm._s(_vm.error),
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              3490076937
                            ),
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
            {
              key: "message",
              fn: function ({}) {
                return [
                  _vm.error
                    ? _c(
                        "div",
                        { staticClass: "text-center message-container" },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "font-size--10 error--text",
                              attrs: { tabindex: "0" },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit(
                                    "dblclick",
                                    $event.target.value
                                  )
                                },
                                keypress: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.$emit(
                                    "dblclick",
                                    $event.target.value
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("timesheetUI.TableColumns.Details")
                                )
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }