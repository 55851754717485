<template>
  <div>
    <GridRow>
      <template #col1>
        <GridCell id="sortDropdownDontainer">
          <JobsSortDropdown />
        </GridCell>
      </template>
      <template v-for="day in calendar" v-slot:[day.dayName.toLowerCase()]>
        <GridCell
          :key="day.dayName"
          :class="`text-h6 text-center ${
            day.isHoliday && !agencyData.enableAbsenceModule ? 'info' : ''
          }`"
        >
          <div class="d-flex justify-center">
            <div
              :class="{
                'today-header-cell': isToday(day),
              }"
            >
              {{ day.localeDayName }} {{ day.day }}
              <div
                v-if="day.isHoliday && !agencyData.enableAbsenceModule"
                class="font-size--10 font-weight-regular mt-1"
              >
                {{ $t('timesheetUI.HeaderCalendar.LabelHoliday') }}
              </div>
            </div>
          </div>
        </GridCell>
      </template>
      <template v-slot:total>
        <GridCell class="text-h6 text-center">
          <span>{{ $t('timesheetUI.HeaderCalendar.LabelTotal') }} </span>
          <span v-if="agencyData.isPercentageAgency">
            <span v-if="$vuetify.breakpoint.smAndDown">%</span>
            <span v-else>{{
              $t('timesheetUI.HeaderCalendar.LabelPercentage')
            }}</span>
          </span>
          <span v-else>{{ $t('timesheetUI.HeaderCalendar.LabelHours') }}</span>
        </GridCell>
      </template>
    </GridRow>
  </div>
</template>

<script>
import GridRow from '../grid/GridRow.vue'
import GridCell from '../grid/GridCell.vue'
import JobsSortDropdown from '../jobs-sort-dropdown/index.vue'
import moment from 'moment'
import { mapState } from 'vuex'

export default {
  components: {
    GridRow,
    GridCell,
    JobsSortDropdown,
  },
  data() {
    return {
      currentButtonAriaLabel: '',
      isMenuVisible: false,
    }
  },
  computed: {
    ...mapState('storeTimesheets', ['agencyData', 'calendar']),
  },
  methods: {
    updateAriaLabel(holidayName) {
      this.currentButtonAriaLabel = `Public Holiday: ${holidayName}`
      this.isMenuVisible = true
    },

    isToday: function (day) {
      return moment().isSame(day.date, 'day')
    },
  },
  mounted() {
    if (this.day && this.day.holidayName) {
      this.currentButtonAriaLabel = `Holiday: ${this.day.holidayName}`
    }
  },
}
</script>

<style scoped lang="scss">
.today-header-cell {
  padding: 2px 8px;
  background-color: $accent;
  border-radius: 4px;
  color: $white;
}
</style>
