<template>
  <v-btn
    :class="[`v-btn--daily v-btn--${dayStatus.status}`, { disabled: false }]"
    small
    depressed
    rounded
    color="primary"
    :disabled="submittingTimesheets || statusNotEligibleToBeSubmitted"
    @click="$emit('click')"
  >
    <template
      v-if="dayStatus.status === 'unsubmitted' || dayStatus.status === 'none'"
    >
      {{ $t('timesheetUI.Btns.BtnSubmit') }}
    </template>
    <template v-else>
      {{ $t('timesheetUI.Btns.BtnSubmitted') }}
    </template>
  </v-btn>
</template>

<script>
export default {
  name: 'DailySubmitButton',
  props: ['dayStatus', 'submittingTimesheets'],
  computed: {
    statusNotEligibleToBeSubmitted() {
      return this.dayStatus.state === 'disabled'
    },
  },
}
</script>
