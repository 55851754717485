<template>
  <div class="my-4 d-flex align-center" v-if="showCopyCheckbox">
    <div class="d-flex align-center flex-grow-1">
      <v-switch
        :value="copyRestOfWeekSelected"
        @change="setCopyRestOfWeekSelected"
        @keyup.space="setCopyRestOfWeekSelected(!copyRestOfWeekSelected)"
        hide-details
        :aria-checked="copyRestOfWeekSelected ? 'true' : 'false'"
        aria-labelledby="copyRestOfWeekLabel"
        role="checkbox"
        id="checkBoxInputCopyRestOfWeek"
        tabindex="0"
        class="pa-0 ma-0 custom-switch--color"
        color="accent"
        inset
      ></v-switch>
      <label id="copyRestOfWeekLabel" aria-label="Copy to Rest of Week">
        Copy to rest of week
      </label>
    </div>

    <CopyRWLearnMore />
  </div>
</template>
<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import moment from 'moment'
import CopyRWLearnMore from '../learn-more/CopyRWLearnMore.vue'

export default {
  name: 'CopyToRestOfWeek',
  data() {
    return {
      somethingSaved: false,
      timesheetExistsClone: {},
      loading: true,
      showCopyInfoMessage: false,
      // alertConfirmDialogOpen: false,
    }
  },
  components: { CopyRWLearnMore },
  computed: {
    ...mapState('storeTimesheets', [
      'timesheetDetail',
      'calendar',
      'allowCopyRestOfWeek',
      'agencyData',
      'copyRestOfWeekSelected',
    ]),
    ...mapGetters('storeAbsenceTracker', ['getAbsenceTotalByDay']),

    showCopyCheckbox() {
      if (
        this.timesheetDetail?.id ||
        this.timesheetDetail?.timesheetStatus === 'Approved' ||
        this.timesheetDetail?.timesheetStatus === 'Rejected'
      ) {
        return false
      }

      const calendarDay = this.calendar[this.timesheetDetail.dayIndex]

      // const isFridayOrLater = this.timesheetDetail.dayIndex >= 4
      const isFridayOrLater =
        moment(this.timesheetDetail.reportedDate.split('T')[0]).isoWeekday() -
          1 >=
        4

      const checkAbsenceModule = () => {
        if (this.agencyData.enableAbsenceModule) {
          const foundHoliday = this.getAbsenceTotalByDay.some((a) =>
            moment(new Date(calendarDay.date.split('T')[0])).isSame(
              a.date.split('T')[0],
              'day'
            )
          )

          return foundHoliday
        }

        if (!this.agencyData.enableAbsenceModule) {
          return calendarDay.isHoliday
        }
      }

      if (
        !this.allowCopyRestOfWeek ||
        calendarDay.isWeekend ||
        checkAbsenceModule() ||
        isFridayOrLater
      ) {
        return false
      }

      const dayIndex = this.timesheetDetail?.dayIndex

      const durationValueRules = () => {
        if (this.timesheetDetail?.id) {
          return this.timesheetDetail?.isPercentageEntry
            ? parseFloat(this.timesheetDetail?.durationPercentageValue) !== 0
            : parseFloat(this.timesheetDetail?.durationValue) !== 0
        }

        return true
      }

      return (
        !moment(new Date(this.calendar[dayIndex].date)).isSame(
          new Date(),
          'day'
        ) &&
        durationValueRules() &&
        (!this.calendar[dayIndex].isHoliday ||
          !this.calendar[dayIndex].isWeekend)
      )
    },
  },
  methods: {
    ...mapMutations('storeTimesheets', ['setCopyRestOfWeekSelected']),
  },
}
</script>
