<template>
  <div class="d-flex justify-end align-center mt-4">
    <span
      class="font-size--16 pr-2 location-label"
      v-if="this.getPreferenceLocationLabel"
    >
      {{ $t('timesheetUI.ManagePreference.SelectedLocation') }}:
      <b class="font-weight--600">{{
        this.getPreferenceLocationLabel
      }}</b></span
    >
    <v-btn
      color="accent"
      text
      x-small
      class="font-size--16"
      @click="showPreferenceDialog"
      :disabled="isMidWeekAgencyChange"
    >
      <template>
        {{ $t('timesheetUI.ManagePreference.ManageLocationPreferenceText') }}
      </template>
      <template v-if="agencyData.isWFHActive">
        {{ $t('timesheetUI.ManagePreference.ManageWFHPreferenceText') }}
      </template>
    </v-btn>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'TimesheetManagePreference',
  methods: {
    ...mapMutations('storeTimesheets', [
      'showPreferenceDialog',
      'closePreferenceDialog',
    ]),
  },
  computed: {
    ...mapGetters('storeTimesheets', [
      'getPreferenceLocationLabel',
      'isMidWeekAgencyChange',
    ]),
    ...mapState('storeTimesheets', ['agencyData']),
  },
}
</script>

<style lang="scss" scoped>
.location-label {
  border-right: 1px solid $primary;
  line-height: 15px;
  border-width: 2px;
}
</style>
