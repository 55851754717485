var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return Object.keys(_vm.absencesWithinRange).length
    ? _c(
        "div",
        {
          class: {
            "loading-rows": _vm.loadingAbsenceData,
          },
        },
        [
          _c(
            "v-expansion-panels",
            {
              attrs: { flat: "" },
              model: {
                value: _vm.absencePanel,
                callback: function ($$v) {
                  _vm.absencePanel = $$v
                },
                expression: "absencePanel",
              },
            },
            [
              _c(
                "v-expansion-panel",
                { staticClass: "absence-group--panel" },
                [
                  _c("v-expansion-panel-header", {
                    staticClass: "h3 font-weight--600",
                    attrs: { "hide-actions": "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ open }) {
                            return [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-2",
                                  style: {
                                    transform: open
                                      ? "rotate(90deg)"
                                      : "rotate(0)",
                                  },
                                  attrs: { size: 30, color: "primary" },
                                },
                                [_vm._v("mdi-chevron-right")]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "font-size--18 font-weight--600 width--100 mb-0",
                                },
                                [_vm._v(" Absence ")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      398696816
                    ),
                  }),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "AbsenceRowGroup",
                        [
                          _vm._l(
                            _vm.createdAbsences,
                            function (absences, entitlementId) {
                              return [
                                _vm.absencesWithinRange[entitlementId]
                                  ? _c("GridRow", {
                                      key: entitlementId,
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "col1",
                                            fn: function () {
                                              return [
                                                _c("GridCell", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex justify-space-between align-center py-0",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-size--12",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              absences[0]
                                                                .leaveType
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-menu",
                                                        {
                                                          attrs: {
                                                            "close-on-content-click": true,
                                                            "offset-x": true,
                                                            "aria-label":
                                                              "`Absence Row action menu",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function ({
                                                                  on,
                                                                  attrs,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "VBtn",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            staticClass:
                                                                              "pa-0 rounded-lg",
                                                                            attrs:
                                                                              {
                                                                                "min-width":
                                                                                  "28",
                                                                                height:
                                                                                  "28",
                                                                                "x-small":
                                                                                  "",
                                                                                text: "",
                                                                                color:
                                                                                  "primary",
                                                                              },
                                                                          },
                                                                          "VBtn",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "VIcon",
                                                                          [
                                                                            _vm._v(
                                                                              "mdi-dots-horizontal"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c(
                                                            "v-list",
                                                            {
                                                              attrs: {
                                                                dense: "",
                                                                width: "200",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item",
                                                                {
                                                                  staticClass:
                                                                    "cursor-pointer primary--text rounded-lg overflow-hidden",
                                                                  attrs: {
                                                                    "aria-label": `Modify ${absences[0].leaveType}`,
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.showAbsenceModifyFormDialog(
                                                                          absences
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-icon",
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              size: "20",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " mdi-pencil-outline"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-list-item-content",
                                                                    [
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "timesheetUI.Absence.ModifyAbsence"
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                          _vm._l(
                                            _vm.calendar,
                                            function (day, i2) {
                                              return {
                                                key: day.dayName.toLowerCase(),
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "GridCell",
                                                      {
                                                        key: `${i2}-day`,
                                                        class: {
                                                          background:
                                                            (day.isWeekend &&
                                                              !_vm
                                                                .firstAvailableContract
                                                                .weekendEntryEnabled) ||
                                                            (day.isHoliday &&
                                                              !_vm.agencyData
                                                                .enableAbsenceModule) ||
                                                            _vm.checkWeekend(
                                                              day.date
                                                            ),
                                                        },
                                                        attrs: {
                                                          disabled:
                                                            day.isWeekend &&
                                                            !day.weekendEntryEnabled,
                                                        },
                                                      },
                                                      [
                                                        (
                                                          day.isWeekend
                                                            ? day.weekendEntryEnabled
                                                            : true
                                                        )
                                                          ? _c(
                                                              "TimeEntryCell",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    _vm.getAbsenceTotal(
                                                                      absences,
                                                                      day.date
                                                                    ),
                                                                  day: day,
                                                                  readonly: "",
                                                                },
                                                              }
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              }
                                            }
                                          ),
                                          {
                                            key: "total",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "GridCell",
                                                  {
                                                    staticClass:
                                                      "job-group--week-total",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex justify-center",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.getAbsenceTotalByAbsence(
                                                                absences
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  : _vm._e(),
                              ]
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }