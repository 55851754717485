var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dialogShow
    ? _c(
        "v-dialog",
        {
          attrs: { width: "630", persistent: "" },
          model: {
            value: _vm.dialogShow,
            callback: function ($$v) {
              _vm.dialogShow = $$v
            },
            expression: "dialogShow",
          },
        },
        [
          _c(
            "FocusLock",
            { attrs: { returnFocus: "" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "dialog-alert--confirm-action",
                  attrs: {
                    role: "alert",
                    "aria-live": "polite",
                    tabindex: "0",
                    "aria-label": "Alert: There has been an error",
                  },
                },
                [
                  _c(
                    "v-card-title",
                    {
                      class: [
                        "d-flex justify-space-between flex-wrap-inherit align-start pa-4",
                        `headline--${_vm.dialogAlertType}`,
                      ],
                    },
                    [
                      _c("div", { staticClass: "h1 mb-1 font-weight--600" }, [
                        _c("span", [_vm._v(_vm._s(_vm.dialogAlertTitle))]),
                        _vm.dialogAlertType && !_vm.dialogAlertTitle
                          ? _c("span", [
                              _vm.dialogAlertType.toLowerCase() === "error"
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "timesheetUI.ErrorDialog.ErrorTitle"
                                        )
                                      )
                                    ),
                                  ])
                                : _vm.dialogAlertType.toLowerCase() ===
                                  "success"
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "timesheetUI.ErrorDialog.SuccessTitle"
                                        )
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "pa-4" },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { md: "12" } }, [
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(_vm.dialogAlertMessage),
                              },
                            }),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    { staticClass: "justify-end pa-4" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-3 pa-4",
                          attrs: {
                            outlined: "",
                            rounded: "",
                            large: "",
                            color: "primary",
                            width: "100",
                          },
                          on: {
                            click: function ($event) {
                              _vm.dialogShow = false
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.dialogCancelLabel))]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-3 pa-4",
                          attrs: {
                            color: "primary",
                            depressed: "",
                            rounded: "",
                            large: "",
                            width: "100",
                          },
                          on: { click: _vm.callback },
                        },
                        [_vm._v(_vm._s(_vm.dialogConfirmLabel))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }