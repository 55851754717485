<template>
  <div
    :class="[
      'grid-cell d-flex justify-center height-100 py-2',
      { 'px-4': !noGutters },
      disabled ? 'disabled-cell' : '',
    ]"
  >
    <div class="flex-grow-1">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GridCell',
  props: {
    disabled: {
      type: Boolean,
    },
    noGutters: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped lang="scss">
.grid-cell {
  position: relative;
  border-radius: inherit;
}

.grid-cell:not(:has(.time-entry-cell)) {
  align-items: center;
}

.disabled-cell {
  background-image: repeating-linear-gradient(
    45deg,
    $border-color 1px,
    $background 1px 20px,
    $border-color 20px 21px
  );
}
</style>
