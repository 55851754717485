var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "grid-cell d-flex justify-center height-100 py-2",
        { "px-4": !_vm.noGutters },
        _vm.disabled ? "disabled-cell" : "",
      ],
    },
    [_c("div", { staticClass: "flex-grow-1" }, [_vm._t("default")], 2)]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }