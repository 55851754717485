<template>
  <div
    class="d-flex justify-md-start justify-space-around align-center flex-wrap"
  >
    <v-btn
      outlined
      rounded
      color="primary"
      data-cy="v-btn__header--prevWeek"
      tabindex="0"
      @focus="currentDayAnnouncement = ''"
      @click="navigateTimesheetByWeek('lastweek')"
      class="pl-2 light-border"
      :aria-label="`${$t('timesheetUI.Btns.BtnPrevWeek')}`"
      :disabled="
        isWeekStartDateBeforeMinDateForTimesheetNavigation ||
        !isEmpty(preparingForAutoSave)
      "
    >
      <v-icon size="30">mdi-chevron-left</v-icon>

      <span
        :class="`${
          isWeekStartDateBeforeMinDateForTimesheetNavigation
            ? ''
            : 'font-color--light'
        }font-size--14 font-weight--600`"
      >
        {{ $t('timesheetUI.Btns.BtnPrevWeek') }}
      </span>
    </v-btn>

    <v-menu
      ref="menu"
      @input="focusOnDatePicker"
      :disabled="!isEmpty(preparingForAutoSave)"
      v-if="$vuetify.breakpoint.mdAndUp"
      v-model="currentTimesheetDatePicker"
      transition="slide-x-transition"
      offset-y
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          outlined
          v-on="on"
          rounded
          color="action"
          tabindex="0"
          :aria-label="`Calendar button: current Selected Week ${selectedWeekRangeText}`"
          ref="calendarButton"
          class="mx-3 btn-datepicker light-border rounded px-1"
          min-width="190"
        >
          <v-icon color="secondary" class="font-color--light mr-2"
            >mdi-calendar</v-icon
          >
          <span class="secondary--text font-size--14 font-weight--400">{{
            selectedWeekRangeText
          }}</span>
          <v-icon color="secondary" class="font-color--light ml-2"
            >mdi-chevron-down</v-icon
          >
        </v-btn>
      </template>
      <div>
        <FocusLock returnFocus>
          <v-date-picker
            color="accent"
            :range="range"
            :value="
              range
                ? [selectedDate.startOfWeek, selectedDate.endOfWeek]
                : this.selectedDate.startOfWeek
            "
            @click:date="dateSelectedHandler($event)"
            scrollable
            first-day-of-week="1"
            :locale-first-day-of-year="7"
            no-title
            :max="maxDate"
            :weekday-format="getDay"
            :min="minDateForTimesheetNavigation"
            @update:picker-date="setAriaLabels"
          >
            <div class="px-1 col py-0">
              <v-btn
                @click="navigateTimesheetByWeek($event)"
                outlined
                rounded
                class="font-size--14 light-border col"
              >
                {{ $t('timesheetUI.HeaderDatePicker.BtnCurrentWeek') }}
              </v-btn>
            </div>
          </v-date-picker>
        </FocusLock>
      </div>
    </v-menu>

    <v-btn
      outlined
      rounded
      color="primary"
      data-cy="v-btn__header--nextWeek"
      :disabled="!isEmpty(preparingForAutoSave)"
      @click="navigateTimesheetByWeek('nextweek')"
      @focus="announceCurrentDay"
      @blur="currentDayAnnouncement = ''"
      tabindex="0"
      class="pr-2 light-border"
      :aria-label="`${$t('timesheetUI.Btns.BtnNextWeek')}`"
    >
      <span class="font-size--14 font-weight--600">
        {{ $t('timesheetUI.Btns.BtnNextWeek') }}
      </span>

      <v-icon size="30">mdi-chevron-right</v-icon>
    </v-btn>
    <span
      :aria-label="`${currentDayAnnouncement}`"
      aria-live="assertive"
      class="sr-only"
    ></span>
    <!-- sm- date picker -->
    <div
      v-if="$vuetify.breakpoint.smAndDown"
      class="d-flex"
      style="max-width: 255px; cursor: pointer"
      @click="currentTimesheetDatePicker = true"
    >
      <v-icon class="ml-3 mr-2">mdi-calendar-month</v-icon>
      <v-text-field
        :value="selectedWeekRangeText"
        readonly
        outlined
        dense
        hide-details
        style="width: 250px"
      ></v-text-field>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import FocusLock from 'vue-focus-lock'
import { isEmpty } from 'lodash'

export default {
  name: 'WeekNavigation',
  components: {
    FocusLock,
  },
  data: () => ({
    currentTimesheetDatePicker: false,
    range: true,
    maxDate: '',
    currentDayAnnouncement: '',
    weekDays: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
  }),
  computed: {
    ...mapState('storeTimesheets', [
      'calendar',
      'agencyData',
      'currentTime',
      'minDateForTimesheetNavigation',
      'preparingForAutoSave',
      'locale',
    ]),
    ...mapGetters('storeTimesheets', [
      'selectedDate',
      'selectedWeekRangeText',
      'isWeekStartDateBeforeMinDateForTimesheetNavigation',
    ]),
  },
  methods: {
    ...mapMutations('storeTimesheets', ['setSelectedDate']),
    ...mapActions('storeTimesheets', ['loadData']),
    isEmpty,
    getDay(date) {
      return moment.utc(new Date(date)).locale(this.locale).format('ddd')
    },
    focusOnDatePicker(value) {
      if (value) {
        this.$nextTick(() => {
          const datePicker = document.querySelector('.v-date-picker-header')
          const firstFocusable = datePicker.querySelector(
            'button:not([disabled])'
          )

          if (firstFocusable) {
            setTimeout(() => {
              firstFocusable.focus()
            }, 300)
          }
        })
      }
    },
    dateSelectedHandler(date) {
      this.setSelectedDate(date)
      this.currentTimesheetDatePicker = false

      this.loadData('navigation').then(() => {
        setTimeout(() => {
          this.$refs.calendarButton.$el.focus()
        }, 500)
      })
    },
    navigateTimesheetByWeek(e) {
      this.range = false

      switch (e) {
        case 'lastweek':
          this.setSelectedDate(this.currentTime.subtract(1, 'weeks'))
          break
        case 'nextweek':
          this.setSelectedDate(this.currentTime.add(1, 'weeks'))
          break
        default:
          this.setSelectedDate(moment())
          break
      }

      this.searchBox = false
      this.search = ''

      this.loadData('navigation')

      setTimeout(() => {
        this.range = true
      }, 150)
    },
    announceCurrentDay() {
      this.currentDayAnnouncement =
        'current day is ' + moment().format('dddd Do')
    },
    isToday: function (day) {
      return moment().isSame(day.date, 'day')
    },
    setAriaLabels($e) {
      setTimeout(() => {
        const days = document.querySelectorAll(
          '.v-date-picker-table--date .v-btn'
        )
        days.forEach((day) => {
          const dayLabel = day
            .querySelector('.v-btn__content')
            .textContent.trim()
          const ariaLabel = moment(new Date(`${$e}-${dayLabel}`)).format('LL')
          day.setAttribute('aria-label', ariaLabel)
        })
      }, 250)
    },
  },

  mounted() {
    setInterval(() => {
      this.currentDayAnnouncement = ''
    }, 2000)
  },
}
</script>
