<template>
  <div class="d-flex align-center">
    <div
      :aria-label="
        IsPercentageByDay[index].some((entry) => entry === true)
          ? `${hoursTotalPerDay[index].percent} Percent`
          : `${hoursTotalPerDay[index].hours} Hours`
      "
    >
      <span
        :class="{
          'asterisk-indicator': isThresholdError(
            IsPercentageByDay[index].some((entry) => entry === true),
            hoursTotalPerDay[index],
            index
          ),
        }"
      >
        <span v-if="mixedTimeEntry">{{
          IsPercentageByDay[index].some((entry) => entry === true)
            ? hoursTotalPerDay[index].percent
            : hoursTotalPerDay[index].hours
        }}</span>
        <span v-if="!mixedTimeEntry">
          {{
            agencyData.isPercentageAgency
              ? hoursTotalPerDay[index].percent
              : hoursTotalPerDay[index].hours
          }}
        </span>
        <span v-if="mixedTimeEntry">{{
          IsPercentageByDay[index].some((entry) => entry === true) ? '%' : 'h'
        }}</span>
      </span>
    </div>
    <timesheet-tooltip-v2
      top
      v-if="
        day.isRequiredByUserContract &&
        isThresholdError(
          IsPercentageByDay[index].some((entry) => entry === true),
          hoursTotalPerDay[index],
          index
        )
      "
    >
      <template v-slot:trigger>
        <v-btn
          role="button"
          icon
          x-small
          color="error"
          :aria-describedby="`dailyHoursMessage${index}`"
          aria-label="warning message"
        >
          <v-icon>mdi-information-outline</v-icon>
          <span
            role="tooltip"
            aria-hidden="true"
            :id="`dailyHoursMessage${index}`"
            v-show="false"
            v-html="getTooltipMessage()"
          ></span>
        </v-btn>
      </template>

      <template v-slot:body>
        <span :id="`tooltipContentForDay${index}`">
          <span v-if="agencyData.isPercentageAgency && mixedTimeEntry">
            <span
              v-if="IsPercentageByDay[index].some((entry) => entry === true)"
              v-html="
                $t('timesheetUI.Tooltips.Threshold.alertPercent', {
                  hours: getRemainingHours(
                    true,
                    hoursTotalPerDay[index].percent
                  ),
                })
              "
            ></span>

            <span
              v-else
              v-html="
                $t('timesheetUI.Tooltips.Threshold.alertHours', {
                  hours: getRemainingHours(
                    false,
                    this.timesheetHoursPerDayComputed.hoursPerDay[index] -
                      hoursTotalPerDay[index].hours
                  ),
                })
              "
            ></span>
          </span>
          <span v-else>
            <span
              v-if="agencyData.isPercentageAgency"
              v-html="
                $t('timesheetUI.Tooltips.Threshold.alertPercent', {
                  hours: getRemainingHours(
                    true,
                    hoursTotalPerDay[index].percent
                  ),
                })
              "
            ></span>

            <span
              v-else-if="firstAvailableContract.isTaskAgency"
              v-html="
                $t('timesheetUI.Tooltips.Threshold.alertOvertime', {
                  hours: getRemainingHours(
                    false,
                    agencyData.timesheetHoursPerDay +
                      1 -
                      (hoursTotalPerDay[index].hours -
                        hoursTotalPerDay[index].overtime)
                  ),
                })
              "
            ></span>

            <span
              v-else
              v-html="
                $t('timesheetUI.Tooltips.Threshold.alertHours', {
                  hours: getRemainingHours(
                    false,
                    timesheetHoursPerDayComputed.hoursPerDay[index] -
                      hoursTotalPerDay[index].hours
                  ),
                })
              "
            >
            </span>
          </span>
          <span>
            {{
              firstAvailableContract.dwIndicator === '1'
                ? $t('timesheetUI.Tooltips.Threshold.alertDW1')
                : (part2 = $t('timesheetUI.Tooltips.Threshold.alertDW2'))
            }}
          </span>
        </span>
      </template>
    </timesheet-tooltip-v2>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TimesheetTooltipV2 from '../../TimesheetTooltipV2'

export default {
  name: 'DailyHoursGrid',
  props: [
    'day',
    'firstAvailableContract',
    'timesheetHoursPerDayComputed',
    'hoursTotalPerDay',
    'getRemainingHours',
    'mixedTimeEntry',
    'IsPercentageByDay',
    'isThresholdError',
    'tooltipsActive',
    'hideTooltip',
    'index',
    'setTooltip',
  ],
  components: {
    TimesheetTooltipV2,
  },

  computed: {
    ...mapState('storeTimesheets', ['agencyData']),
  },

  methods: {
    getTooltipMessage() {
      let percentMessage = ''
      if (this.agencyData.isPercentageAgency && this.mixedTimeEntry) {
        if (
          this.IsPercentageByDay[this.index].some((entry) => entry === true)
        ) {
          percentMessage = this.$t(
            'timesheetUI.Tooltips.Threshold.alertPercent',
            {
              hours: this.getRemainingHours(
                true,
                this.hoursTotalPerDay[this.index].percent
              ),
            }
          )
        } else {
          percentMessage = this.$t(
            'timesheetUI.Tooltips.Threshold.alertHours',
            {
              hours: this.getRemainingHours(
                false,
                this.timesheetHoursPerDayComputed.hoursPerDay[this.index] -
                  this.hoursTotalPerDay[this.index].hours
              ),
            }
          )
        }
      } else {
        if (this.agencyData.isPercentageAgency) {
          percentMessage = this.$t(
            'timesheetUI.Tooltips.Threshold.alertPercent',
            {
              hours: this.getRemainingHours(
                true,
                this.hoursTotalPerDay[this.index].percent
              ),
            }
          )
        } else if (this.firstAvailableContract.isTaskAgency) {
          percentMessage = this.$t(
            'timesheetUI.Tooltips.Threshold.alertOvertime',
            {
              hours: this.getRemainingHours(
                false,
                this.agencyData.timesheetHoursPerDay +
                  1 -
                  (this.hoursTotalPerDay[this.index].hours -
                    this.hoursTotalPerDay[this.index].overtime)
              ),
            }
          )
        } else {
          percentMessage = this.$t(
            'timesheetUI.Tooltips.Threshold.alertHours',
            {
              hours: this.getRemainingHours(
                false,
                this.timesheetHoursPerDayComputed.hoursPerDay[this.index] -
                  this.hoursTotalPerDay[this.index].hours
              ),
            }
          )
        }
      }

      percentMessage +=
        this.firstAvailableContract.dwIndicator === '1'
          ? this.$t('timesheetUI.Tooltips.Threshold.alertDW1')
          : (this.part2 = this.$t('timesheetUI.Tooltips.Threshold.alertDW2'))
      return percentMessage
    },
  },
}
</script>
