import Vue from 'vue'
import Vuex from 'vuex'

import storeAbsenceTracker from './modules/storeAbsenceTracker'
import storeTimesheets from './modules/timesheets'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    storeAbsenceTracker,
    storeTimesheets,
  },
})
