<template>
  <VSelect
    :value="jobsSortOrder"
    :items="dropdownJobItem"
    item-text="text"
    placeholder="Sort jobs"
    dense
    outlined
    :menu-props="{
      positionY: 60,
      absolute: true,
      minWidth: '100%',
    }"
    attach="#sortDropdownDontainer"
    hide-details
    append-icon="mdi-chevron-down"
    class="font-size--14"
    @change="handleSortOptionChange"
    @keydown="arrowKeyHandler"
    :aria-label="`Sort Jobs Options. Selected: ${
      dropdownJobItem.find((a) => a.value === jobsSortOrder)?.text
    }`"
  >
    <template #prepend>
      <VIcon color="primary">mdi-sort-variant</VIcon>
    </template>

    <template #selection="{ item }">
      <div class="secondary--text">
        <span class="font-weight-medium">{{ item.sortName }}</span>
        &nbsp;
        <span>{{ item.sortDescription }}</span>
      </div>
    </template>

    <template #item="{ attrs, on, item }">
      <VListItem v-bind="attrs" v-on="on" class="rounded-lg">
        <VListItemContent>
          <VListItemTitle class="primary--text">
            <span>{{ item.sortName }}</span>
            &nbsp;
            <span class="font-weight-regular">{{ item.sortDescription }}</span>
          </VListItemTitle>
        </VListItemContent>
      </VListItem>
    </template>
  </VSelect>
</template>

<script>
import { arrowKeyHandler } from '@Shared/filters'
import { mapMutations, mapState } from 'vuex'
import i18n from '@Shared/i18n'

export default {
  name: 'JobsSortDropdown',
  data() {
    return {
      dropdownJobItem: [
        {
          text: 'BI Billable first to Non-billable last',
          sortName: i18n.t('timesheetUI.Sort.BIAscName', window.CULTURE),
          sortDescription: i18n.t('timesheetUI.Sort.BIAsc', window.CULTURE),
          value: 'BI - asc',
          descending: false,
        },
        {
          text: 'BI Non-billable first to Billable last',
          sortName: i18n.t('timesheetUI.Sort.BIDescName', window.CULTURE),
          sortDescription: i18n.t('timesheetUI.Sort.BIDesc', window.CULTURE),
          value: 'BI - desc',
          descending: true,
        },
        {
          text: 'Task Name A-Z',
          sortName: i18n.t('timesheetUI.Sort.NameAscName', window.CULTURE),
          sortDescription: i18n.t('timesheetUI.Sort.NameAsc', window.CULTURE),
          value: 'Name - asc',
          descending: true,
        },
        {
          text: 'Task Name Z-A',
          sortName: i18n.t('timesheetUI.Sort.NameAscName', window.CULTURE),
          sortDescription: i18n.t('timesheetUI.Sort.NameDesc', window.CULTURE),
          value: 'Name - desc',
          descending: false,
        },
      ],
    }
  },
  computed: {
    ...mapState('storeTimesheets', ['jobsSortOrder']),
  },
  methods: {
    ...mapMutations('storeTimesheets', ['setJobsSortOrder']),
    handleSortOptionChange(value) {
      this.setJobsSortOrder(value)
    },
    arrowKeyHandler($e) {
      return arrowKeyHandler($e)
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .v-input__slot {
    padding: 0 !important;
  }

  .v-input__append-inner .v-icon {
    color: $primary;
    font-size: 30px;
  }

  .v-select__slot {
    input {
      height: 0;
      padding: 0;
    }
  }

  fieldset {
    color: #fff !important;
  }
}
</style>
