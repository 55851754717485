var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.unFilteredshelvedAssignedTasksCount ||
    _vm.unFilteredInactiveAssignedProjectsCount
    ? _c(
        "VBanner",
        {
          staticClass: "pa-0 font-size--12",
          attrs: { "single-line": "", color: "background" },
          scopedSlots: _vm._u(
            [
              {
                key: "actions",
                fn: function ({ dismiss }) {
                  return [
                    _c(
                      "VBtn",
                      {
                        staticClass: "pa-0 rounded-lg",
                        attrs: {
                          "min-width": "20",
                          height: "20",
                          "x-small": "",
                          text: "",
                          "aria-label": "Close Assigned Job",
                          color: "primary",
                        },
                        on: { click: dismiss },
                      },
                      [
                        _c("VIcon", { staticClass: "font-size--14" }, [
                          _vm._v("mdi-window-close"),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ],
            null,
            false,
            381447673
          ),
          model: {
            value: _vm.showBanner,
            callback: function ($$v) {
              _vm.showBanner = $$v
            },
            expression: "showBanner",
          },
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "font-weight-medium font-size--12 cursor-pointer",
              attrs: { text: "" },
              on: {
                click: function ($event) {
                  return _vm.setShowJobListPopupFromBannerLink(true)
                },
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("timesheetUI.AssignedJobsBanner.StartText")) +
                  " "
              ),
              _vm.unFilteredshelvedAssignedTasksCount
                ? _c(
                    "span",
                    { staticClass: "px-1 font-weight--600 accent-dark--text" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.unFilteredshelvedAssignedTasksCount) +
                          " " +
                          _vm._s(
                            _vm.$t(
                              "timesheetUI.AssignedJobsBanner.AssignTaskLabel"
                            )
                          ) +
                          _vm._s(
                            _vm.unFilteredshelvedAssignedTasksCount > 1
                              ? "s"
                              : ""
                          ) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.unFilteredshelvedAssignedTasksCount &&
              _vm.unFilteredInactiveAssignedProjectsCount
                ? _c("span", { staticClass: "px-1" }, [
                    _vm._v(
                      _vm._s(_vm.$t("timesheetUI.AssignedJobsBanner.And"))
                    ),
                  ])
                : _vm._e(),
              _vm.unFilteredInactiveAssignedProjectsCount
                ? _c(
                    "span",
                    { staticClass: "px-1 font-weight--600 accent-dark--text" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.unFilteredInactiveAssignedProjectsCount) +
                          " " +
                          _vm._s(
                            _vm.$t(
                              "timesheetUI.AssignedJobsBanner.AssignProjectLabel"
                            )
                          ) +
                          _vm._s(
                            _vm.unFilteredInactiveAssignedProjectsCount > 1
                              ? "s"
                              : ""
                          ) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "timesheetUI.AssignedJobsBanner.AssignedJobsBannerInYourList"
                    )
                  ) +
                  " "
              ),
              _c(
                "span",
                {
                  staticClass:
                    "px-1 font-weight-medium text-decoration-underline",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("timesheetUI.AssignedJobsBanner.TheJobList")
                      ) +
                      " "
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }