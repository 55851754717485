import Vue from 'vue'
import App from './Timesheets.vue'
import './assets/fonts/volte-font.css'
import { createVue } from './plugins/vuetify'
import store from './store/store'
import {
  formatDateFrontend,
  dateFormatForCalendar,
  formatDateMonthAsWord,
} from '@Shared/filters'
import mixinTrackingEventHandler from './mixins/trackingEventHandler'
import VueAnnouncer from '@vue-a11y/announcer'

import { Toast, options } from './plugins/notification-toast'

Vue.filter('formatDateFrontend', formatDateFrontend)
Vue.filter('dateFormatForCalendar', dateFormatForCalendar)
Vue.filter('formatDateMonthAsWord', formatDateMonthAsWord)

Vue.config.productionTip = false

Vue.mixin({
  methods: {
    mixinTrackingEventHandler,
  },
})

Vue.use(Toast, options)

Vue.use(VueAnnouncer)

import(`./sass/themes/${process.env.VUE_APP_THEME_NAME}`).then((styles) =>
  createVue({
    Component: App,
    selector: '#app',
    theme: styles.default,
    useVuex: store,
  })
)
