var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "800" },
      on: { "click:outside": _vm.closeSearchJobDialog },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-badge",
                {
                  attrs: {
                    color:
                      _vm.unFilteredinActiveAssignedItemsCount > 0
                        ? "error"
                        : "",
                    content: _vm.unFilteredinActiveAssignedItemsCount,
                    overlap: "",
                    "offset-x": "20",
                  },
                },
                [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "font-weight--600",
                        attrs: {
                          id: "addJobButton",
                          color: "button-background",
                          depressed: "",
                          rounded: "",
                          height: "40",
                          "aria-live": "polite",
                          "aria-label":
                            "search for a job or add assigned tasks and projects. " +
                            (_vm.unFilteredinActiveAssignedItemsCount > 0
                              ? `You have ${_vm.unFilteredinActiveAssignedItemsCount} assigned jobs`
                              : ""),
                        },
                      },
                      on
                    ),
                    [
                      _c(
                        "v-icon",
                        { attrs: { size: "20", color: "primary" } },
                        [_vm._v("mdi-format-list-bulleted")]
                      ),
                      _c("span", { staticClass: "ml-2" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("timesheetUI.Btns.BtnJobList")) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.showSearchJobDialog,
        callback: function ($$v) {
          _vm.showSearchJobDialog = $$v
        },
        expression: "showSearchJobDialog",
      },
    },
    [
      _c(
        "FocusLock",
        { attrs: { returnFocus: "" } },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "justify-space-between px-6 py-5" },
                [
                  _c("h1", { staticClass: "text-h2 primary--text" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("timesheetUI.SearchJobs.SearchJobsPanelTitle")
                        ) +
                        " "
                    ),
                  ]),
                  _c(
                    "div",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            icon: "",
                            color: "primary",
                            "aria-label": "Close Timesheet Search",
                          },
                          on: { click: _vm.closeSearchJobDialog },
                        },
                        [_c("v-icon", [_vm._v("mdi-window-close")])],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c("v-divider", { attrs: { role: "presentation" } }),
              _c(
                "v-card-text",
                { staticClass: "px-6 py-5" },
                [
                  _c("v-text-field", {
                    attrs: {
                      placeholder: _vm.$t(
                        "timesheetUI.SearchJobs.SearchBoxPlaceholder"
                      ),
                      "full-width": "",
                      outlined: "",
                      dense: "",
                      id: "searchInputBox",
                      "append-icon": "mdi-magnify",
                      "hide-details": "",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.searchDebounce($event)
                      },
                    },
                    model: {
                      value: _vm.searchQuery,
                      callback: function ($$v) {
                        _vm.searchQuery = $$v
                      },
                      expression: "searchQuery",
                    },
                  }),
                  _c(
                    "div",
                    [
                      [
                        _c(
                          "v-tabs",
                          {
                            staticClass: "pt-3 mb-4",
                            attrs: { height: "auto" },
                            model: {
                              value: _vm.activeTab,
                              callback: function ($$v) {
                                _vm.activeTab = $$v
                              },
                              expression: "activeTab",
                            },
                          },
                          [
                            _vm._l(_vm.filters, function (filter) {
                              return _c(
                                "v-tab",
                                {
                                  key: filter.key,
                                  on: {
                                    change: function ($event) {
                                      _vm.agencyMode = filter.value
                                    },
                                  },
                                },
                                [
                                  _c("span", [_vm._v(_vm._s(filter.title))]),
                                  filter.key === "assignedJobs" &&
                                  _vm.shelvedJobsCount
                                    ? _c("v-badge", {
                                        attrs: {
                                          color:
                                            "button-background text--primary font-weight--600",
                                          light: "",
                                          content: _vm.shelvedJobsCount,
                                          inline: "",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            }),
                          ],
                          2
                        ),
                      ],
                      _vm.totalResultCount > 0 &&
                      _vm.jobListAssignedJobsSearchKeyword
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-space-between mb-4 text-caption font-weight-medium",
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.isShowAllTab
                                        ? _vm.shelvedJobsCount +
                                            _vm.totalResultCount
                                        : _vm.totalResultCount
                                    ) +
                                    " " +
                                    _vm._s(
                                      _vm.$t(
                                        "timesheetUI.SearchJobs.ResultsFoundLabel"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm
                                        .$t(
                                          "timesheetUI.SearchJobs.ShowingResultsLabel"
                                        )
                                        .replace(
                                          "${pagNumber}",
                                          _vm.isShowAllTab
                                            ? _vm.shelvedJobsCount +
                                                _vm.searchResults.length
                                            : _vm.searchResults.length
                                        )
                                        .replace(
                                          "${totalResults}",
                                          _vm.isShowAllTab
                                            ? _vm.shelvedJobsCount +
                                                _vm.totalResultCount
                                            : _vm.totalResultCount
                                        )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm.showLoading && !_vm.searchResults.length
                    ? _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _c("v-progress-circular", {
                            attrs: {
                              indeterminate: "",
                              color: "primary",
                              size: 50,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("transition", { attrs: { name: "fade" } }, [
                    _c(
                      "div",
                      {
                        ref: "dialogContainer",
                        staticClass: "timesheet-search-job--items",
                      },
                      [
                        _vm.activeTab === _vm.filterTabs.assignedJobs
                          ? _c(
                              "div",
                              _vm._l(_vm.shelvedJobs, function (job, i) {
                                return _c("AssignedJobCard", {
                                  key: `${job.job_ID}-${i}-a`,
                                  attrs: {
                                    job: job,
                                    closeSearchJobDialog:
                                      _vm.closeSearchJobDialog,
                                    showSearchJobDialog:
                                      _vm.showSearchJobDialog,
                                  },
                                })
                              }),
                              1
                            )
                          : _vm._e(),
                        _vm.activeTab !== _vm.filterTabs.assignedJobs &&
                        (_vm.totalResultCount > 0 || _vm.shelvedJobs.length > 0)
                          ? _c(
                              "div",
                              [
                                _vm.activeTab === _vm.filterTabs.showAll
                                  ? _c(
                                      "div",
                                      _vm._l(
                                        _vm.shelvedJobs,
                                        function (job, i) {
                                          return _c("AssignedJobCard", {
                                            key: `${job.job_ID}-${i}-a`,
                                            attrs: {
                                              job: job,
                                              closeSearchJobDialog:
                                                _vm.closeSearchJobDialog,
                                              showSearchJobDialog:
                                                _vm.showSearchJobDialog,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    )
                                  : _vm._e(),
                                _vm._l(_vm.searchResults, function (job, i) {
                                  return _c(
                                    "v-card",
                                    {
                                      key: `${job.job_ID}-${i}`,
                                      staticClass: "mb-3",
                                      class: {
                                        "border-color--error":
                                          _vm.duplicateJobsList.includes(
                                            job.job_ID
                                          ),
                                      },
                                      attrs: { outlined: "" },
                                    },
                                    [
                                      _c(
                                        "v-card-text",
                                        { staticClass: "px-5 py-3" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-center gap-6",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "flex-grow-1 font-size--10",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      class: [
                                                        _vm.duplicateJobsList.includes(
                                                          job.job_ID
                                                        )
                                                          ? "error--text"
                                                          : "font-color--primary",
                                                        "font-weight--600 mb-2 font-size--12",
                                                      ],
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(job.job_Name)
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-wrap secondary--text",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "mr-1" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              job.client_Name
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" • "),
                                                      _c(
                                                        "span",
                                                        { staticClass: "mx-1" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              job.agency_Name
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" • "),
                                                      _c(
                                                        "span",
                                                        { staticClass: "ml-1" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(job.job_ID)
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  job.crossCompanyRuleTypeName
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "accent--text my-1",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "mr-1",
                                                            },
                                                            [_vm._v("•")]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "font-size--10",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  job.crossCompanyRuleTypeName
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "cross-company-rule__label",
                                                              attrs: {
                                                                color: "accent",
                                                                size: 10,
                                                              },
                                                            },
                                                            [_vm._v("mdi-star")]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  job.selfAssignmentWithManageTasking
                                                    ? [
                                                        _c("PPMTaskDropdown", {
                                                          attrs: {
                                                            job: job,
                                                            error:
                                                              _vm.ppmValid[
                                                                job.job_ID
                                                              ],
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.setSelfAssignementSelectedTasks,
                                                          },
                                                        }),
                                                      ]
                                                    : _vm._e(),
                                                  _vm.ppmValid[job.job_ID]
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "job-validation-message error--text mt-2",
                                                          attrs: {
                                                            id: "ppmTaskError",
                                                            role: "alert",
                                                            "aria-live":
                                                              "assertive",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "timesheetUI.SearchJobs.toAddAJob"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  job.isTaskAgency
                                                    ? [
                                                        _c(
                                                          "AltairTaskDropdown",
                                                          {
                                                            attrs: {
                                                              job: job,
                                                              error:
                                                                _vm
                                                                  .altairTaskValid[
                                                                  job.job_ID
                                                                ],
                                                            },
                                                            on: {
                                                              change:
                                                                _vm.setSelfAssignementSelectedTasks,
                                                            },
                                                          }
                                                        ),
                                                      ]
                                                    : _vm._e(),
                                                  _vm.altairTaskValid[
                                                    job.job_ID
                                                  ]
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "job-validation-message error--text mt-2",
                                                          attrs: {
                                                            id: "altairTaskError",
                                                            role: "alert",
                                                            "aria-live":
                                                              "assertive",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "timesheetUI.SearchJobs.toAddAJob"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm.duplicateJobsList.includes(
                                                    job.job_ID
                                                  )
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "font-size--10 duplicate-job__warning-msg mt-2",
                                                        },
                                                        [
                                                          !_vm.isJobSelfAssignment
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "timesheetUI.SearchJobs.DuplicateAssigned"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ])
                                                            : _c("span", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "timesheetUI.SearchJobs.DuplicateSelfAssigned"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                2
                                              ),
                                              _vm.duplicateJobsList.includes(
                                                job.job_ID
                                              )
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        depressed: "",
                                                        text: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.cancelDuplicateJobWarning(
                                                            job.job_ID
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      (job.selfAssignmentWithManageTasking &&
                                                        _vm.isJobSelfAssignment) ||
                                                      (!job.selfAssignmentWithManageTasking &&
                                                        job.isTaskAgency)
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-column align-center",
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "duplicate-job-cancel-btn-icon",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-close"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "font-size--10",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "timesheetUI.SearchJobs.CantBeAddToTimesheet"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-column align-center",
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "duplicate-job-cancel-btn-icon",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-close"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "font-size--10",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "timesheetUI.Btns.BtnCancel"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              !_vm.duplicateJobsList.includes(
                                                job.job_ID
                                              )
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "pl-1 pr-2 font-weight--600",
                                                      attrs: {
                                                        depressed: "",
                                                        text: "",
                                                        small: "",
                                                        disabled: _vm.loading,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.addJobToTimesheet(
                                                            job
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "d-flex align-center",
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                                size: 18,
                                                              },
                                                            },
                                                            [_vm._v("mdi-plus")]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              attrs: {
                                                                "aria-label": `${
                                                                  job.job_Name
                                                                }, ${_vm.$t(
                                                                  "timesheetUI.SearchJobs.BtnAddToTimesheet"
                                                                )} job`,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "timesheetUI.SearchJobs.BtnAddToTimesheet"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : !_vm.isJobSelfAssignment &&
                                                  !(
                                                    !job.selfAssignmentWithManageTasking &&
                                                    job.isTaskAgency
                                                  )
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        depressed: "",
                                                        text: "",
                                                        disabled: _vm.loading,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.addJobToTimesheet(
                                                            job,
                                                            true
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-center",
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                              },
                                                            },
                                                            [_vm._v("mdi-plus")]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "font-size--10",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "timesheetUI.SearchJobs.ConfirmNewRow"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                _vm.searchResults.length !==
                                _vm.totalResultCount
                                  ? _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "intersect",
                                            rawName: "v-intersect.quiet",
                                            value: {
                                              handler:
                                                _vm.timesheetSearchInfiniteLoad,
                                            },
                                            expression:
                                              "{\n                  handler: timesheetSearchInfiniteLoad,\n                }",
                                            modifiers: { quiet: true },
                                          },
                                        ],
                                        staticClass: "pa-6",
                                      },
                                      [
                                        _vm.showLoadingSearchResult
                                          ? _c(
                                              "div",
                                              { staticClass: "text-center" },
                                              [
                                                _c("v-progress-circular", {
                                                  attrs: {
                                                    indeterminate: "",
                                                    color: "primary",
                                                    size: 50,
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _vm._e(),
                        !_vm.showLoading &&
                        ((_vm.activeTab === _vm.filterTabs.assignedJobs &&
                          !_vm.shelvedJobs.length) ||
                          (_vm.activeTab === _vm.filterTabs.showAll &&
                            !_vm.totalResultCount &&
                            !_vm.shelvedJobs.length) ||
                          (_vm.activeTab !== _vm.filterTabs.assignedJobs &&
                            _vm.activeTab !== _vm.filterTabs.showAll &&
                            !_vm.totalResultCount))
                          ? _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "font-weight--600 mt-5 mb-6 primary--text",
                                  attrs: { tabindex: "0" },
                                  on: {
                                    focus: function ($event) {
                                      return _vm.addFocus($event)
                                    },
                                    blur: function ($event) {
                                      return _vm.removeFocus($event)
                                    },
                                  },
                                },
                                [
                                  _vm.searchQuery.length > 0
                                    ? _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm
                                              .$t(
                                                "timesheetUI.SearchJobs.NoResultsTitle"
                                              )
                                              .replace(
                                                "${searchQuery}",
                                                _vm.searchQuery
                                              )
                                          ),
                                        },
                                      })
                                    : _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$t(
                                              "timesheetUI.SearchJobs.NoJobsFound"
                                            )
                                          ),
                                        },
                                      }),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "primary--text lh-20",
                                  attrs: { tabindex: "0" },
                                  on: {
                                    focus: function ($event) {
                                      return _vm.addFocus($event)
                                    },
                                    blur: function ($event) {
                                      return _vm.removeFocus($event)
                                    },
                                  },
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t(
                                          "timesheetUI.SearchJobs.NoResultsTips"
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }