<template>
  <div class="d-flex gap-2 flex-wrap">
    <div class="old-values pa-3 rounded-lg flex-grow-1">
      <div class="mb-2 font-weight--600 font-size--16">
        {{ $t('General.OldValues') }}
      </div>
      <div v-for="(value, key) in timesheetDetailOldCopy" :key="key">
        <div v-if="checkFieldForComparison(key)">
          <v-label>{{ $t(`timesheetUI.ComparisonLabels.${key}`) }}</v-label>
          <p class="default" v-if="value || key === 'wfh'">
            {{ valuehandler(value, key) }}
          </p>
          <p v-else>-</p>
        </div>
      </div>
    </div>
    <div class="new-values pa-3 rounded-lg flex-grow-1">
      <div class="mb-2 font-weight--600 font-size--16">
        {{ $t('General.Newvalues') }}
      </div>
      <div v-for="(value, key) in timesheetDetailCopy" :key="key">
        <div v-if="checkFieldForComparison(key)">
          <v-label>{{ $t(`timesheetUI.ComparisonLabels.${key}`) }}</v-label>
          <v-icon
            size="13"
            color="primary"
            class="ml-1"
            v-if="hasValueChanged(value, timesheetDetailOldCopy[key], key)"
            >mdi-autorenew</v-icon
          >
          <p
            :class="{
              default: hasValueChanged(value, timesheetDetailOldCopy[key], key),
            }"
          >
            <span
              v-if="hasValueChanged(value, timesheetDetailOldCopy[key], key)"
            >
              {{ valuehandler(value, key) }}
            </span>
            <span v-else>{{ $t('timesheetUI.InfoPanel.NoChanges') }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import i18n from '@Shared/i18n'

export default {
  computed: {
    ...mapState('storeTimesheets', [
      'timesheetDetail',
      'timesheetDetailOld',
      'fieldsForComparison',
      'fieldsForComparisonMandatory',
      'timesheetDetailFeatures',
      'agencyData',
    ]),
    ...mapGetters('storeTimesheets', ['isSelfAssignmentv1_4']),
    timesheetDetailCopy() {
      return Object.keys(this.timesheetDetail)
        .sort()
        .reduce((obj, key) => {
          obj[key] = this.timesheetDetail[key]
          return obj
        }, {})
    },
    timesheetDetailOldCopy() {
      return Object.keys(this.timesheetDetailOld)
        .sort()
        .reduce((obj, key) => {
          obj[key] = this.timesheetDetailOld[key]
          return obj
        }, {})
    },
  },
  methods: {
    checkFieldForComparison(key) {
      if (key === 'durationPercentageValue') {
        return this.agencyData.isPercentageAgency
      }
      if (key === 'durationValue') {
        return !this.agencyData.isPercentageAgency
      }
      if (key === 'location' && !this.agencyData.isLocationActive) {
        return false
      }
      if (
        key === 'wfh' &&
        this.agencyData.isLocationActive &&
        this.agencyData.isWFHActive != undefined
      ) {
        return true
      }
      if (this.fieldsForComparisonMandatory.includes(key)) {
        return true
      }
      if (this.isSelfAssignmentv1_4 || key === 'clarizenCustomTask') {
        return false
      }
      if (
        this.timesheetDetailFeatures['activity']?.required &&
        (key === 'productReference' || key === 'productQuantity')
      ) {
        const productLables =
          !this.agencyData.productReference || !this.agencyData.productQuantity
        return productLables
      }
      return this.timesheetDetailFeatures[key]?.required
    },
    valuehandler(value, key) {
      if (key === 'wfh') {
        return value == true
          ? i18n.t(
              'timesheetUI.EntryFormLabels.TimesheetEntryWfhYes',
              window.CULTURE
            )
          : i18n.t(
              'timesheetUI.EntryFormLabels.TimesheetEntryWfhNo',
              window.CULTURE
            )
      }
      if (typeof value === 'object') {
        return value?.label || value?.clarizenTaskName
      }
      return value
    },
    hasValueChanged(newValue, oldValue) {
      return JSON.stringify(newValue) !== JSON.stringify(oldValue)
    },
  },
}
</script>
<style lang="scss" scoped>
.old-values {
  background-color: #efefef;
  label {
    font-size: 12px;
  }
  p {
    color: #757575;
    margin-bottom: 8px;
  }
}
.new-values {
  color: $primary;
  background-color: $accentLight;
  label {
    font-size: 12px;
  }
  p {
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 8px;
  }
  .default {
    color: inherit;
  }
}

label {
  color: $secondary !important;
}
</style>
