<template>
  <div v-if="showTimesheetDialog">
    <v-dialog
      v-model="showTimesheetDialog"
      :max-width="dialogWidth"
      :transition="false"
      overlay-opacity="0.09"
    >
      <FocusLock returnFocus>
        <v-card>
          <validation-observer ref="observer" v-slot="{ invalid }">
            <v-form
              @submit.prevent="saveValidate"
              lazy-validation
              :disabled="
                (showForm &&
                  isTimesheetApproved &&
                  !isTimesheetBeingCorrected) ||
                (timesheetDetail && timesheetDetail.selfCorrectedHours < 0)
              "
            >
              <v-card-title
                class="d-flex justify-space-between align-start pa-4"
              >
                <div class="timesheet-entry__header">
                  <h1 class="h1 font-weight--900 mb-1">
                    {{ timesheetExistsDate }}
                  </h1>
                  <div class="h5 d-flex flex-wrap">
                    {{ timesheetExistsSubtitle }}

                    <span
                      class="d-flex ml-1"
                      v-if="
                        timesheetSelectedJob.job.selfAssignmentWithManageTasking
                      "
                    >
                      •
                      <span class="d-inline-flex ml-1 flex-wrap">
                        <span class="font-color--primary">{{
                          $t(
                            'timesheetUI.TimesheetJobs.SelfAssignmentProjectLabel'
                          )
                        }}</span>
                      </span>
                    </span>

                    <span
                      v-else-if="
                        timesheetSelectedJob.job.isClarizenAssignedProject &&
                        timesheetSelectedJob.job.isClarizenTask
                      "
                      class="d-inline-flex flex-wrap font-color--primary ml-1"
                    >
                      •
                      <span class="ml-1 d-flex"
                        ><span
                          >{{
                            $t(
                              'timesheetUI.TimesheetJobs.ClarizenAssignProjectLabel'
                            )
                          }} </span
                        ><v-icon
                          color="primary"
                          small
                          class="clarizen-task__label"
                          >mdi-check</v-icon
                        ></span
                      >
                    </span>

                    <span
                      class="d-flex ml-1"
                      v-else-if="
                        timesheetSelectedJob.job.isClarizenTask ||
                        timesheetSelectedJob.job.selfAssignmentWithManageTasking
                      "
                    >
                      • {{ timesheetSelectedJob.job.clarizenTaskName }} •
                      <span class="d-inline-flex ml-1 flex-wrap">
                        <span class="font-color--primary">{{
                          $t(
                            'timesheetUI.TimesheetJobs.ClarizenAssignTaskLabel'
                          )
                        }}</span>
                      </span>
                    </span>

                    <span
                      v-if="timesheetSelectedJob.job.crossCompanyRuleTypeName"
                      class="font-color--primary job-dialog__meta"
                    >
                      <span class="font-color--body"> • </span>
                      <span class="d-inline-flex ml-1 flex-wrap"
                        >{{ timesheetSelectedJob.job.crossCompanyRuleTypeName }}
                      </span>
                      <span class="d-inline-flex">
                        <v-icon class="cross-company-rule__label"
                          >mdi-star</v-icon
                        ></span
                      >
                    </span>
                  </div>
                </div>
                <v-btn
                  icon
                  @click="timesheetEntryDialogClose"
                  :disabled="loading || savingTimesheet || loadingFeatures"
                  aria-label="Close modal"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-divider role="presentation"></v-divider>
              <v-progress-linear
                indeterminate
                v-if="loading || savingTimesheet || loadingFeatures"
                color="primary"
              ></v-progress-linear>
              <v-card-text class="pa-0">
                <timesheet-entry-table></timesheet-entry-table>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="justify-end pa-4">
                <v-btn
                  color="primary"
                  depressed
                  min-width="160px"
                  @click="addNew"
                  class="mr-auto"
                  outlined
                  v-if="!showForm"
                  :disabled="loading || savingTimesheet || loadingFeatures"
                >
                  {{ $t('General.Btns.NewEntry') }}
                </v-btn>

                <template
                  v-if="
                    showForm &&
                    !isTimesheetBeingCorrected &&
                    isTimesheetApproved
                  "
                >
                  <v-btn
                    color="primary"
                    depressed
                    min-width="160px"
                    class="mr-auto"
                    outlined
                    @click="timesheetEntryDialogClose"
                    :disabled="loading || savingTimesheet || loadingFeatures"
                  >
                    {{ $t('General.Btns.Close') }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    depressed
                    min-width="160px"
                    @click="handleTimesheetCorrection"
                    :disabled="
                      loading ||
                      savingTimesheet ||
                      loadingFeatures ||
                      !agencyData.enableSelfCorrection ||
                      !isCorrectionValid ||
                      timesheetDetail.isSelfCorrected
                    "
                  >
                    {{ $t('General.Btns.CorrectTimesheet') }}
                  </v-btn>
                </template>

                <template
                  v-if="
                    showForm &&
                    isTimesheetBeingCorrected &&
                    !isTimesheetBeingReviewed
                  "
                >
                  <v-btn
                    color="primary"
                    depressed
                    min-width="160px"
                    @click="handleCancel"
                    class="mr-auto"
                    outlined
                  >
                    {{ $t('General.Btns.Cancel') }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    depressed
                    min-width="160px"
                    @click="() => setIsTimesheetBeingReviewed(true)"
                    v-if="validForEntry"
                    :disabled="
                      invalid || !hasTimeEntryChanged || shouldReviewBtnDisable
                    "
                  >
                    {{ $t('General.Btns.ReviewCorrection') }}
                  </v-btn>
                </template>
                <template v-if="showForm && isTimesheetBeingReviewed">
                  <v-btn
                    color="primary"
                    depressed
                    min-width="160px"
                    @click="() => setIsTimesheetBeingReviewed(false)"
                    class="mr-auto"
                    outlined
                  >
                    {{ $t('General.Btns.Back') }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    depressed
                    min-width="160px"
                    @click="handleConfirm"
                  >
                    {{ $t('General.Btns.Confirm') }}
                  </v-btn>
                </template>
                <v-btn
                  color="primary"
                  depressed
                  @click="() => saveTimesheet()"
                  min-width="160px"
                  :disabled="
                    loadingFeatures ||
                    timesheetTotals >
                      (timesheetDetail && timesheetDetail.isPercentageEntry
                        ? 100
                        : 24)
                  "
                  v-if="
                    timesheetDetail &&
                    timesheetDetail.timesheetStatus === 'Rejected' &&
                    agencyData.enableSelfCorrection &&
                    timesheetDetail.selfCorrectedHours < 0
                  "
                >
                  {{ $t('General.Btns.RemoveCorrection') }}
                </v-btn>
                <v-btn
                  color="primary"
                  depressed
                  type="submit"
                  min-width="160px"
                  v-if="
                    validForEntry &&
                    !isTimesheetApproved &&
                    !(
                      timesheetDetail &&
                      timesheetDetail.timesheetStatus === 'Rejected' &&
                      agencyData.enableSelfCorrection &&
                      timesheetDetail.selfCorrectedHours < 0
                    )
                  "
                  :disabled="
                    loading ||
                    savingTimesheet ||
                    loadingFeatures ||
                    invalid ||
                    (showForm && isTimesheetApproved) ||
                    !hasTimeEntryChanged
                  "
                >
                  {{ $t('General.Btns.Save') }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </validation-observer>
        </v-card>
      </FocusLock>
    </v-dialog>
  </div>
</template>

<script>
// import TimesheetEntryDetail from './TimesheetEntryDetail.vue'
import TimesheetEntryTable from './TimesheetEntryTable.vue'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'

import { ValidationObserver } from 'vee-validate'
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import moment from 'moment'
import FocusLock from 'vue-focus-lock'

export default {
  name: 'TimesheetEntryDialog',
  components: {
    // TimesheetEntryDetail,
    TimesheetEntryTable,
    ValidationObserver,
    FocusLock,
  },
  data() {
    return {
      showTimesheetDialog: false,
      somethingSaved: false,
      timesheetExistsClone: {},
    }
  },
  watch: {
    timesheetDialogVisibility() {
      this.setHasTimeEntryChanged({ e: false, data: null })
      // this.showTimesheetDialog = this.timesheetDialogVisibility
    },
    showTimesheetDialog() {
      if (this.showTimesheetDialog) {
        this.setBeforeEdit(JSON.parse(JSON.stringify(this.timesheetExists)))
        this.timesheetExistsClone = cloneDeep(this.timesheetExists)
      } else {
        this.timesheetEntryDialogClose()
      }
    },
    timesheetExists: {
      deep: true,
      handler(oldVal, newVal) {
        if (newVal) {
          const _newVal = cloneDeep(newVal)
          _newVal.forEach((obj) => delete obj.toSave)
          const isValueSame = isEqual(this.timesheetExistsClone, _newVal)
          isValueSame
            ? this.setHasTimeEntryChanged({ e: false, data: null })
            : this.setHasTimeEntryChanged({ e: true, data: null })
        }
      },
    },
    timesheetDetail(oldVal, newVal) {
      this.setHasTimeEntryChanged({ e: oldVal && newVal, data: null })
    },
  },
  computed: {
    ...mapState('storeTimesheets', [
      'timesheetExists',
      'timesheetDetail',
      'timesheetSelectedJob',
      'timesheetDialogVisibility',
      'loading',
      'savingTimesheet',
      'loadingFeatures',
      'beforeEdit',
      'hasTimeEntryChanged',
      'dialogWidth',
      'isTimesheetBeingReviewed',
      'timesheetDetailOld',
      'agencyData',
      'isTimesheetBeingCorrected',
      'timesheetDetailFeatures',
    ]),
    ...mapGetters('storeTimesheets', [
      'validForEntry',
      'isEnabledSelfCorrection',
      'isCorrectionValid',
      'isSelfAssignmentv1_4',
      'hoursTotalPerDay',
    ]),
    timesheetExistsDate() {
      const locale = window.CULTURE === 'fr-FR' ? 'fr' : 'en'
      return moment(this.timesheetSelectedJob.date.split('T')[0])
        .locale(locale)
        .format('dddd DD MMM')
    },
    timesheetExistsSubtitle() {
      return `${
        this.timesheetSelectedJob.job.isClarizenTask ||
        this.timesheetSelectedJob.job.selfAssignmentWithManageTasking
          ? this.timesheetSelectedJob.job.clarizenProjectName
          : this.timesheetSelectedJob.job.jobName
      } • ${this.timesheetSelectedJob.job.job_ID}`
    },
    showForm() {
      const showform = !!this.timesheetDetail || !this.timesheetExists
      return showform
    },
    isTimesheetApproved() {
      if (this.timesheetDetail && this.timesheetDetail.timesheetStatus) {
        return this.timesheetDetail.timesheetStatus.toLowerCase() === 'approved'
      }
      return false
    },

    shouldReviewBtnDisable() {
      const propertiesToExclude = ['billableIndicator', 'disablePPMSelect']

      const customObjectComparison = (obj1, obj2) =>
        JSON.stringify(
          Object.fromEntries(
            Object.entries(obj1).filter(
              ([key]) => !propertiesToExclude.includes(key)
            )
          )
        ) ===
        JSON.stringify(
          Object.fromEntries(
            Object.entries(obj2).filter(
              ([key]) => !propertiesToExclude.includes(key)
            )
          )
        )

      const timesheetDetailLocal = cloneDeep(this.timesheetDetail)
      timesheetDetailLocal.durationValue = parseFloat(
        timesheetDetailLocal.durationValue
      )

      if (
        customObjectComparison(timesheetDetailLocal, this.timesheetDetailOld)
      ) {
        return true
      }
      return false
    },
    timesheetTotals() {
      const totalTimeAsDayBasis = Math.abs(
        this.timesheetExists
          .map((a) =>
            parseFloat(
              a.isPercentageEntry
                ? a.durationPercentageValue < 0
                  ? a.durationPercentageValue
                  : 0
                : (a.durationValue < 0 ? a.durationValue : 0) || 0
            )
          )
          .reduce((a, b) => a + b, 0)
          .toFixed(2)
      )
      return (
        parseFloat(totalTimeAsDayBasis) +
        parseFloat(
          this.timesheetDetail.isPercentageEntry
            ? this.hoursTotalPerDay[this.timesheetDetail.dayIndex].percent
            : this.hoursTotalPerDay[this.timesheetDetail.dayIndex].hours
        )
      )
    },
  },
  methods: {
    ...mapMutations('storeTimesheets', [
      'setTimesheetExists',
      'setTimesheetDetail',
      'revertTimesheet',
      'setBeforeEdit',
      'resetTableToSave',
      'setObserver',
      'setHasTimeEntryChanged',
      'setIsTimesheetBeingReviewed',
      'setTimesheetEntryFieldsForReview',
      'setTimesheetReviewedValue',
      'setTimesheetDetailOld',
      'setIsTimesheetBeingCorrected',
    ]),
    ...mapActions('storeTimesheets', [
      'saveTimesheet',
      'addNewTimesheet',
      'loadBillableIndicators',
    ]),
    saveValidate() {
      return this.$refs.observer.validate().then((valid) => {
        if (valid) {
          this.somethingSaved = true
          return this.saveTimesheet().then(() => {
            this.somethingSaved = false
            // create a timesheetExists clone to figure out a modification in the form to enable/disable the save button
            this.timesheetExistsClone = cloneDeep(this.timesheetExists)

            if (this.timesheetExistsClone.length > 0) {
              this.timesheetExistsClone.forEach((obj) => delete obj.toSave)
            }
          })
        }
      })
    },
    timesheetEntryDialogClose() {
      this.resetTableToSave()
      if (!this.somethingSaved) {
        this.revertTimesheet(this.beforeEdit)
      }

      this.somethingSaved = false
      this.$refs.observer.reset()
      this.showTimesheetDialog = false
      this.setTimesheetDetail({ data: null, dialog: false })
      this.setTimesheetExists(false)
      this.setIsTimesheetBeingCorrected(false)
      this.setIsTimesheetBeingReviewed(false)
      this.setTimesheetDetailOld(null)
    },
    addNew() {
      this.resetTableToSave()
      this.revertTimesheet(this.beforeEdit)
      this.addNewTimesheet()
    },
    handleCancel() {
      this.setIsTimesheetBeingCorrected(false)
      this.setTimesheetDetail({
        data: this.timesheetDetailOld,
        dialog: true,
      })
    },
    handleTimesheetCorrection() {
      this.setTimesheetEntryFieldsForReview()
      this.setIsTimesheetBeingCorrected(true)
    },
    async handleConfirm() {
      const localTimesheetDetail = cloneDeep(this.timesheetDetail)
      localTimesheetDetail.id = null
      await this.saveValidate()
      this.setTimesheetDetail({ data: localTimesheetDetail })
      this.setTimesheetReviewedValue()
      const isSecondAPI = localTimesheetDetail.isPercentageEntry
        ? localTimesheetDetail.durationPercentageValue
        : localTimesheetDetail.durationValue

      if (isSecondAPI != 0) {
        await this.saveTimesheet()
      }
      this.showTimesheetDialog = false
    },
  },
  updated() {
    this.setObserver(this.$refs.observer)
  },
}
</script>

<style lang="scss" scoped>
.v-dialog__content--active {
  z-index: 999 !important;
}
.timesheet-entry__header {
  flex: 1 0 0;
}
</style>
