import '../sass/core-modifications.scss'
import i18n from '@Shared/i18n'

import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { Touch } from 'vuetify/lib/directives'
import moment from 'moment'
import 'moment/locale/fr'

import fr from 'vuetify/es5/locale/fr'
import en from 'vuetify/es5/locale/en'

moment.locale('en', {
  week: {
    dow: 1,
    doy: 7,
  },
})

Vue.use(Vuetify, {
  directives: {
    Touch,
  },
})

export function createVue({
  Component,
  selector,
  theme = {},
  options = '',
  useVuex = false,
}) {
  const opts = {
    options,
    theme: {
      themes: {
        light: {
          primary: theme.primary,
          secondary: theme.secondary,
          accent: theme.accent,
          'accent-light': theme.accentLight,
          'accent-dark': theme.accentDark,
          action: theme.action,
          success: theme.success,
          info: theme.info,
          warning: theme.warning,
          error: theme.error,
          chip1: theme.chip1,
          textColor: theme.textColor,
          background: theme.background,
          'button-background': theme.buttonBackground,
          danger: theme.danger,
          'warning-dark': theme.warningDark,
        },
      },
    },
    breakpoint: {
      thresholds: {
        xs: 340,
        sm: 540,
        md: 800,
        lg: 1600,
      },
    },
    lang: {
      locales: { en, fr },
      current: window.CULTURE && window.CULTURE.includes('fr') ? 'fr' : 'en',
    },
  }

  const vuetify = new Vuetify(opts)

  new Vue({
    store: useVuex,
    i18n,
    vuetify,
    render: (h) => h(Component),
  }).$mount(selector)
}
