import moment from 'moment'

export const calculateAbsencePercentage = (hoursEntered, workingDayHours) => {
  hoursEntered = parseFloat(hoursEntered)
  workingDayHours = parseFloat(workingDayHours)
  let percentage = ((hoursEntered / workingDayHours) * 100).toFixed(0)
  return percentage
}

export const countWeekendDays = (startDate, endDate) => {
  let start = moment(startDate)
  let end = moment(endDate)
  let weekendDays = 0

  for (; start <= end; start.add(1, 'days')) {
    if (start.day() === 0 || start.day() === 6) weekendDays++
  }

  return weekendDays
}
