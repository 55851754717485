var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showCopyCheckbox
    ? _c(
        "div",
        { staticClass: "my-4 d-flex align-center" },
        [
          _c(
            "div",
            { staticClass: "d-flex align-center flex-grow-1" },
            [
              _c("v-switch", {
                staticClass: "pa-0 ma-0 custom-switch--color",
                attrs: {
                  value: _vm.copyRestOfWeekSelected,
                  "hide-details": "",
                  "aria-checked": _vm.copyRestOfWeekSelected ? "true" : "false",
                  "aria-labelledby": "copyRestOfWeekLabel",
                  role: "checkbox",
                  id: "checkBoxInputCopyRestOfWeek",
                  tabindex: "0",
                  color: "accent",
                  inset: "",
                },
                on: {
                  change: _vm.setCopyRestOfWeekSelected,
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "space", 32, $event.key, [
                        " ",
                        "Spacebar",
                      ])
                    )
                      return null
                    return _vm.setCopyRestOfWeekSelected(
                      !_vm.copyRestOfWeekSelected
                    )
                  },
                },
              }),
              _c(
                "label",
                {
                  attrs: {
                    id: "copyRestOfWeekLabel",
                    "aria-label": "Copy to Rest of Week",
                  },
                },
                [_vm._v(" Copy to rest of week ")]
              ),
            ],
            1
          ),
          _c("CopyRWLearnMore"),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }