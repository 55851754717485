var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expansion-panels",
    {
      attrs: { flat: "", multiple: "" },
      model: {
        value: _vm.panels,
        callback: function ($$v) {
          _vm.panels = $$v
        },
        expression: "panels",
      },
    },
    [
      _vm._l(_vm.panelHeader, function (header) {
        return _c(
          "v-expansion-panel",
          { key: `row-${header}`, staticClass: "job-group--panel" },
          [
            _c("v-expansion-panel-header", {
              class: `h3 font-weight--600`,
              attrs: { "hide-actions": "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ open }) {
                      return [
                        _c(
                          "v-icon",
                          {
                            staticClass: "mr-2",
                            style: {
                              transform: open ? "rotate(90deg)" : "rotate(0)",
                            },
                            attrs: { size: 30, color: "primary" },
                          },
                          [_vm._v("mdi-chevron-right")]
                        ),
                        _c(
                          "p",
                          {
                            staticClass:
                              "font-size--18 font-weight--600 width--100 mb-0",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("timesheetUI.JobGroupPrefix")) +
                                " " +
                                _vm._s(header) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            }),
            _c(
              "v-expansion-panel-content",
              [
                _c(
                  "JobRowGroup",
                  [
                    _vm._l(_vm.jobList, function (timesheetJob, i) {
                      return [
                        timesheetJob.jobGroupName === header
                          ? _c("JobRow", {
                              key: `row-${header}-${timesheetJob.id}`,
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "col1",
                                    fn: function () {
                                      return [
                                        _c("GridCell", [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex justify-space-between align-center",
                                            },
                                            [
                                              _c("div", [
                                                _vm.showFocusIcon(timesheetJob)
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "drawer-indicator",
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              color: "accent",
                                                              size: "10",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " mdi-triangle mdi-rotate-90 "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "div",
                                                  { staticClass: "break-word" },
                                                  [
                                                    (timesheetJob.isClarizenTask &&
                                                      _vm.businessRole ===
                                                        "ROAR PROJECT MANAGER" &&
                                                      !timesheetJob.selfAssignmentWithManageTasking) ||
                                                    (timesheetJob.isClarizenAssignedProject &&
                                                      timesheetJob.isClarizenTask &&
                                                      !timesheetJob.selfAssignmentWithManageTasking)
                                                      ? _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "pa-0 clarizen-custom-task-button mb-1",
                                                            attrs: {
                                                              text: "",
                                                              plain: "",
                                                              color: "primary",
                                                              height: "auto",
                                                              ripple: false,
                                                              tabindex: "0",
                                                              "aria-label": `Open: ${timesheetJob.clarizenProjectName}`,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.gotoClarizenProject(
                                                                  timesheetJob.clarizenProjectId
                                                                )
                                                              },
                                                              keypress:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "enter",
                                                                      13,
                                                                      $event.key,
                                                                      "Enter"
                                                                    )
                                                                  )
                                                                    return null
                                                                  return _vm.gotoClarizenProject(
                                                                    timesheetJob.clarizenProjectId
                                                                  )
                                                                },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "font-size--14 font-weight--600",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    timesheetJob.clarizenProjectName
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "primary--text font-size--14 font-weight--600",
                                                            attrs: {
                                                              tabindex: "0",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                timesheetJob.jobName
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              timesheetJob.isFavJob_V2,
                                                            expression:
                                                              "timesheetJob.isFavJob_V2",
                                                          },
                                                        ],
                                                        staticClass: "ml-1",
                                                        attrs: {
                                                          size: "14",
                                                          color: "warning",
                                                          tabindex: "0",
                                                          "aria-label":
                                                            "Job is favourited",
                                                          "aria-hidden":
                                                            "false",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-star-outline"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                timesheetJob.clarizenTaskName ||
                                                timesheetJob.taskName
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "break-word",
                                                      },
                                                      [
                                                        timesheetJob.clarizenTaskName
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "font-size--10 font-weight--600 accent-light rounded-lg text-uppercase px-2",
                                                                staticStyle: {
                                                                  "padding-top":
                                                                    "2px",
                                                                  "padding-bottom":
                                                                    "2px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      timesheetJob.clarizenTaskName
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        timesheetJob.taskName
                                                          ? _c(
                                                              "span",
                                                              {
                                                                class: [
                                                                  "font-size--10 font-weight--600 accent-light rounded-lg text-uppercase px-2",
                                                                  {
                                                                    "ml-1":
                                                                      timesheetJob.clarizenTaskName,
                                                                  },
                                                                ],
                                                                staticStyle: {
                                                                  "padding-top":
                                                                    "2px",
                                                                  "padding-bottom":
                                                                    "2px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      timesheetJob.taskName
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "font-size--12",
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          timesheetJob.job_ID
                                                        )
                                                      ),
                                                    ]),
                                                    timesheetJob.crossCompanyRuleTypeName
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "font-color--primary",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "mx-1",
                                                              },
                                                              [_vm._v("•")]
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "accent--text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    timesheetJob.crossCompanyRuleTypeName
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  size: "12",
                                                                  color:
                                                                    "accent",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " mdi-star-outline "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                timesheetJob.allocationStartDate
                                                  ? _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "mb-0 font-size--10 warning-dark--text",
                                                      },
                                                      [
                                                        _vm.allocationYear(
                                                          timesheetJob.allocationStartDate
                                                        ) !== 9999
                                                          ? _c("span", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "timesheetUI.HeaderCalendar.AllocationPeriod"
                                                                    )
                                                                  ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "formatDateFrontend"
                                                                    )(
                                                                      timesheetJob.allocationStartDate.split(
                                                                        "T"
                                                                      )[0]
                                                                    )
                                                                  ) +
                                                                  " - " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "formatDateFrontend"
                                                                    )(
                                                                      timesheetJob.allocationEndDate.split(
                                                                        "T"
                                                                      )[0]
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ])
                                                          : _c("span", [
                                                              _vm._v(
                                                                "Allocation Period: N/A"
                                                              ),
                                                            ]),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]),
                                              _c(
                                                "v-menu",
                                                {
                                                  attrs: {
                                                    "close-on-content-click": true,
                                                    "offset-x": true,
                                                    "aria-label": `Job Row action menu: ${timesheetJob.jobName} - ${timesheetJob.job_ID}`,
                                                    "content-class":
                                                      "job-group__row_menu_container",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({
                                                          on,
                                                          attrs,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "VBtn",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    staticClass:
                                                                      "pa-0 rounded-lg",
                                                                    attrs: {
                                                                      id: `job-${timesheetJob.id}`,
                                                                      "min-width":
                                                                        "28",
                                                                      height:
                                                                        "28",
                                                                      "x-small":
                                                                        "",
                                                                      text: "",
                                                                      color:
                                                                        "primary",
                                                                    },
                                                                  },
                                                                  "VBtn",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _c("VIcon", [
                                                                  _vm._v(
                                                                    "mdi-dots-horizontal"
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _c(
                                                    "v-list",
                                                    {
                                                      attrs: {
                                                        dense: "",
                                                        width: "200",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.getJobRowActions(
                                                        timesheetJob
                                                      ),
                                                      function (item) {
                                                        return _c(
                                                          "v-list-item",
                                                          {
                                                            key: item.value,
                                                            staticClass:
                                                              "cursor-pointer primary--text rounded-lg overflow-hidden",
                                                            attrs: {
                                                              "aria-label": `${item.label}:  ${timesheetJob.jobName} - ${timesheetJob.job_ID}`,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleJobRowAction(
                                                                  item.value,
                                                                  timesheetJob,
                                                                  i
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-item-icon",
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        item.icon
                                                                      )
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-list-item-content",
                                                              [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.label
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                  _vm._l(
                                    _vm.calendar,
                                    function (day, dayIndex) {
                                      return {
                                        key: day.dayName.toLowerCase(),
                                        fn: function () {
                                          return [
                                            _c("TimesheetCell", {
                                              key: day.dayName,
                                              attrs: {
                                                day: day,
                                                timesheetJob: timesheetJob,
                                                "job-index": i,
                                                "day-index": dayIndex,
                                                openActions: _vm.openActions,
                                              },
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      }
                                    }
                                  ),
                                  {
                                    key: "total",
                                    fn: function () {
                                      return [
                                        _c(
                                          "GridCell",
                                          {
                                            staticClass:
                                              "job-group--week-total",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex justify-center align-center",
                                                attrs: {
                                                  "aria-label": `${_vm.$t(
                                                    "General.for"
                                                  )} ${
                                                    timesheetJob.jobName
                                                  } - ${_vm.$t(
                                                    "timesheetUI.HeaderCalendar.LabelTotal"
                                                  )} ${_vm.$t(
                                                    "timesheetUI.HeaderCalendar.LabelProject"
                                                  )} ${
                                                    _vm.agencyData
                                                      .isPercentageAgency
                                                      ? _vm.$t(
                                                          "timesheetUI.HeaderCalendar.LabelPercentage"
                                                        )
                                                      : _vm.$t(
                                                          "timesheetUI.HeaderCalendar.LabelHours"
                                                        )
                                                  } ${_vm.$t("General.is")} ${
                                                    _vm.agencyData
                                                      .isPercentageAgency
                                                      ? _vm.hoursTotalPerJob[i]
                                                          .percent
                                                      : _vm.hoursTotalPerJob[i]
                                                          .hours
                                                  }`,
                                                  md: "3",
                                                },
                                              },
                                              [
                                                _c("div", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.agencyData
                                                          .isPercentageAgency
                                                          ? _vm
                                                              .hoursTotalPerJob[
                                                              i
                                                            ].percent
                                                          : _vm
                                                              .hoursTotalPerJob[
                                                              i
                                                            ].hours
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c("div", {
                                          staticClass: "offscreen",
                                          attrs: {
                                            "aria-label": `${_vm.$t(
                                              "General.for"
                                            )} ${
                                              timesheetJob.jobName
                                            } - ${_vm.$t(
                                              "timesheetUI.HeaderCalendar.LabelTotal"
                                            )} ${_vm.$t(
                                              "timesheetUI.HeaderCalendar.LabelProject"
                                            )} ${
                                              _vm.agencyData.isPercentageAgency
                                                ? _vm.$t(
                                                    "timesheetUI.HeaderCalendar.LabelPercentage"
                                                  )
                                                : _vm.$t(
                                                    "timesheetUI.HeaderCalendar.LabelHours"
                                                  )
                                            } ${_vm.$t("General.is")} ${
                                              _vm.agencyData.isPercentageAgency
                                                ? _vm.hoursTotalPerJob[i]
                                                    .percent
                                                : _vm.hoursTotalPerJob[i].hours
                                            }`,
                                          },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            })
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }