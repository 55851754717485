<template>
  <v-chip-group>
    <div class="justify-center v-slide-group__content">
      <v-chip
        small
        v-for="tag in selectedFilters"
        :key="tag.label"
        class="ellipsis"
        close
        :style="{ maxWidth: '120px' }"
        close-icon="mdi-close"
        @click:close="() => handleClose(tag)"
        :ripple="false"
      >
        <v-icon color="primary" class="mr-1" size="18">{{ tag.icon }}</v-icon>
        <span class="ellipsis">{{ tag.label }}</span>
      </v-chip>
      <v-chip
        v-if="selectedFilters.length > 1"
        class="ellipsis"
        close
        small
        outlined
        :style="{ maxWidth: '120px', background: 'white' }"
        close-icon="mdi-close"
        @click:close="handleClearAll"
        :ripple="false"
      >
        <span class="ellipsis">{{
          $t('timesheetUI.HeaderSearchTimesheet.ClearFilters')
        }}</span>
      </v-chip>
    </div>
  </v-chip-group>
</template>
<script>
import { mapMutations, mapState } from 'vuex'
export default {
  name: 'SearchFilterChips',
  computed: {
    ...mapState('storeTimesheets', [
      'globalSearchSelectedFilters',
      'globalFilterOptions',
    ]),
    selectedFilters: {
      get() {
        return this.globalSearchSelectedFilters
      },
    },
  },
  methods: {
    ...mapMutations('storeTimesheets', ['setGlobalSearchSelectedFilters']),
    handleClose(tag) {
      this.setGlobalSearchSelectedFilters(
        this.selectedFilters.filter((f) => f.label !== tag.label)
      )
    },
    handleClearAll() {
      this.setGlobalSearchSelectedFilters([])
    },
  },
}
</script>
<style lang="scss" scoped>
.v-chip {
  background-color: $easyGrey3 !important;
}
</style>
