<template>
  <div class="d-flex flex-column align-center mt-13">
    <div class="font-size--24 font-weight--600 mb-2">
      {{ $t('timesheetUI.HeaderSearchTimesheet.noResultsLabel') }}
    </div>
    <div class="h2 font-weight--600 subtext mb-9">
      {{ $t('timesheetUI.HeaderSearchTimesheet.noResultsSubText') }}
    </div>
    <div>
      <img src="@/assets/icons/noresults.svg" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TimesheetNoResultsFound',
}
</script>

<style scoped>
.subtext {
  color: #808080;
}
</style>
