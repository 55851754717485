<template>
  <GridRowWithPageOffset>
    <template #col1>
      <GridCell>
        <div class="font-size--12 font-weight--600 text-right">
          {{ $t('timesheetUI.Footer.FooterTotalLabel') }}
          {{
            $t(
              agencyData.isPercentageAgency
                ? 'timesheetUI.HeaderCalendar.LabelPercentage'
                : ''
            )
          }}
        </div>
      </GridCell>
    </template>
    <template v-for="(day, i) in calendar" v-slot:[day.dayName.toLowerCase()]>
      <GridCell :key="day.dayName">
        <div class="d-flex justify-center">
          <DailyHoursGrid
            v-if="day.isRequiredByUserContract"
            :day="day"
            :firstAvailableContract="firstAvailableContract"
            :timesheetHoursPerDayComputed="timesheetHoursPerDayComputed"
            :hoursTotalPerDay="hoursTotalPerDay"
            :getRemainingHours="getRemainingHours"
            :mixedTimeEntry="mixedTimeEntry"
            :IsPercentageByDay="IsPercentageByDay"
            :isThresholdError="isThresholdError"
            :tooltipsActive="tooltipsActive"
            :setTooltip="setTooltip"
            :hideTooltip="hideTooltip"
            :index="i"
          />
          <span v-else>-</span>
        </div>
      </GridCell>
    </template>
    <template #total>
      <GridCell>
        <div class="d-flex justify-center">
          <DailyHoursTotal
            :disableTooltip="isPartTimeOrContractorEmployee()"
            :timesheetCanBeSubmitted="timesheetCanBeSubmitted"
            :hoursTotal="hoursTotal"
            :mixedTimeEntry="mixedTimeEntry"
            :timesheetHoursPerDayComputed="timesheetHoursPerDayComputed"
            :timeSchduleComputed="timeSchduleComputed"
          />
        </div>
      </GridCell>
    </template>
  </GridRowWithPageOffset>
</template>

<script>
import GridRowWithPageOffset from '@Components/grid/GridRowWithoutPageOffset.vue'
import GridCell from '@Components/grid/GridCell.vue'
import DailyHoursGrid from './components/DailyHoursGrid.vue'
import DailyHoursTotal from './components/DailyHoursTotal.vue'

import {
  percisionRound,
  getTimesheetsDuplicate,
  getTimesheets,
} from '@Shared/filters'

import { EventBus } from '@Shared/EventBus'
import i18n from '@Shared/i18n'

import { uniqBy } from 'lodash'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import moment from 'moment'

let tabIndexTotal = 5

export default {
  name: 'FooterHoursRow',
  components: {
    GridRowWithPageOffset,
    GridCell,
    DailyHoursGrid,
    DailyHoursTotal,
  },
  props: [
    'tooltipsActive',
    'submittingTimesheets',
    'IsPercentageByDay',
    'updateSubmittingTimesheets',
  ],
  computed: {
    ...mapState('storeTimesheets', [
      'userData',
      'agencyData',
      'timesheets',
      'firstAvailableContract',
      'altairCode',
      'calendar',
      'timesheetDialogVisibility',
    ]),
    ...mapGetters('storeTimesheets', [
      'selectedDate',
      'hoursTotalPerDay',
      'hasSomeContract',
      'timesheetJobs',
      'isHiddenJobsScreen',
      'currentDayJobFeatures',
    ]),
    ...mapState('storeAbsenceTracker', [
      'absenceData',
      'loadingAbsenceData',
      'absenceBookingAllowed',
    ]),

    hasContract() {
      return true
    },
    timesheetHoursPerDayComputed() {
      let total = 0
      let totalHoursFTPT = 0

      const contractHoursPerDay = this.calendar.map((day) => {
        const hours = day.requiredTimesheetHours
        if (day.isRequiredByUserContract && day.isWeekend === false) {
          total += hours
        }
        if (
          day.isRequiredByUserContract &&
          day.isWeekend === false &&
          !day.isPartTimeEmployee &&
          !day.externalEmployeeIndicator &&
          day.careerStageLevel !== 'Intern' &&
          day.employeeType !== 'H'
        ) {
          totalHoursFTPT += hours
        }

        return hours
      })

      const uniqDayArray = contractHoursPerDay
        .map((day, i) => {
          return {
            day: this.calendar[i].dayName,
            dayPrev: this.calendar[i > 0 ? i - 1 : 6].dayName,
            requiredHours: day,
          }
        })
        .slice(0, 5)

      let obj = {
        totalHours: total,
        totalHoursFTPT: totalHoursFTPT,
        hoursPerDay: contractHoursPerDay,
        allValueSame: contractHoursPerDay.filter((value) => value !== 0)[0],
        calendarType: 'normalTimesheet',
        uniqDays: uniqBy(uniqDayArray, 'requiredHours'),
      }

      if (this.agencyData.isAlternateCalendarAvailable) {
        if (contractHoursPerDay[4] === 5.5) {
          if (contractHoursPerDay.some((day) => day === 7)) {
            obj.calendarType = 'spanishLeavingSummerTimesheet'
          } else {
            obj.calendarType = 'spanishNormalTimesheet'
          }
        } else if (contractHoursPerDay.slice(0, 5).every((day) => day === 7)) {
          obj.calendarType = 'spanishSummerTimesheet'
        } else if (
          contractHoursPerDay[4] === 7 &&
          contractHoursPerDay.some((day) => day === 8 || day === 5.5)
        ) {
          obj.calendarType = 'spanishEnteringSummerTimesheet'
        }
      }

      return obj
    },
    timeSchduleComputed() {
      if (
        this.timesheetHoursPerDayComputed &&
        (this.timesheetHoursPerDayComputed.calendarType ===
          'spanishNormalTimesheet' ||
          this.timesheetHoursPerDayComputed.calendarType === 'normalTimesheet')
      ) {
        return ''
      }

      const uniqDays = this.timesheetHoursPerDayComputed.uniqDays

      if (uniqDays.length > 2) {
        if (uniqDays[0].day === uniqDays[1].dayPrev) {
          return `${uniqDays[0].requiredHours} hours on ${uniqDays[0].day}, ${uniqDays[1].requiredHours} hours on ${uniqDays[1].day} - ${uniqDays[2].dayPrev} and ${uniqDays[2].requiredHours} hours on ${uniqDays[2].day}`
        } else {
          return `${uniqDays[0].requiredHours} hours per day ${uniqDays[0].day} - ${uniqDays[1].dayPrev}, ${uniqDays[1].requiredHours} hours on ${uniqDays[1].day} - ${uniqDays[2].dayPrev} and ${uniqDays[2].requiredHours} hours on ${uniqDays[2].day}`
        }
      } else if (uniqDays.length === 2) {
        if (uniqDays[0].day === uniqDays[1].dayPrev) {
          return `${uniqDays[0].requiredHours} hours on ${uniqDays[0].day} and ${uniqDays[1].requiredHours} hours from ${uniqDays[1].day}`
        } else {
          return `${uniqDays[0].requiredHours} hours per day ${uniqDays[0].day} - ${uniqDays[1].dayPrev} and ${uniqDays[1].requiredHours} hours from ${uniqDays[1].day}`
        }
      } else {
        return ''
      }
    },
    mixedTimeEntry() {
      if (this.agencyData.isPercentageAgency) {
        let arrMixed = []

        for (let key in this.IsPercentageByDay) {
          /* eslint-disable-next-line no-prototype-builtins */
          if (this.IsPercentageByDay.hasOwnProperty(key)) {
            let day = this.IsPercentageByDay[key]

            if (day.some((item) => item === false)) {
              arrMixed.push(true)
            }
          }
        }

        return arrMixed.length > 0
      }

      return false
    },
    dailyDailyAvailbleDays() {
      let today = moment()
      let availableDaysToSubmit = this.calendar.filter((weekday) =>
        moment(weekday.Date.split('T')[0]).utc().isSameOrBefore(today)
      )
      return this.UsersActiveDaysInThisWeek < availableDaysToSubmit.length
        ? this.UsersActiveDaysInThisWeek
        : availableDaysToSubmit.length
    },
    timesheetIdArrays() {
      let arrayObjs

      if (this.agencyData.dwIndicator === '1') {
        arrayObjs = {
          TimesheetIdList: {
            0: [],
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
          },
          JobIdList: {
            0: [],
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
          },
          UserJobs: {
            0: [],
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
          },
        }

        const daysToSubmitValues = []
        const daysHoursRequired = []
        this.calendar.forEach((day, i) => {
          const dayReported = day.date.split('T')[0]
          if (
            day.isRequiredByUserContract &&
            day.isWeekend === false &&
            !this.special(dayReported)
          ) {
            if (this.agencyData.isPercentageAgency) {
              daysToSubmitValues[i] = this.hoursTotalPerDay[i].percent
              daysHoursRequired[i] = 100
            } else {
              daysHoursRequired[i] =
                this.timesheetHoursPerDayComputed.hoursPerDay[i]
              daysToSubmitValues[i] = this.hoursTotalPerDay[i].hours
            }
          }
        })

        this.timesheets.forEach((ts) => {
          const i = moment(ts.reportedDate.split('T')[0]).isoWeekday() - 1
          let duration = daysToSubmitValues[i] >= daysHoursRequired[i]

          if (i > 4 && ts.durationPercentageValue > 0) {
            duration = true
          }

          if (
            !['Approved', 'Submitted', 'None', 'Rejected'].includes(
              ts.timesheetStatus
            ) &&
            (ts.durationValue !== 0 || ts.durationPercentageValue !== 0) &&
            duration
          ) {
            arrayObjs.TimesheetIdList[i].push(ts.id)
            arrayObjs.JobIdList[i].push(ts.job_ID)
            arrayObjs.UserJobs[i].push(ts.userJobId)
          }
        })
      } else {
        arrayObjs = {
          TimesheetIdList: [],
          JobIdList: [],
          UserJobs: [],
        }

        this.timesheets.forEach((ts) => {
          if (
            ts.timesheetStatus === 'Unsubmitted' &&
            (ts.durationValue !== 0 || ts.durationPercentageValue !== 0)
          ) {
            !arrayObjs.TimesheetIdList.includes(ts.Id) &&
              arrayObjs.TimesheetIdList.push(ts.id)
            !arrayObjs.JobIdList.includes(ts.Job_ID) &&
              arrayObjs.JobIdList.push(ts.job_ID)
            !arrayObjs.UserJobs.includes(ts.UserJobId) &&
              arrayObjs.UserJobs.push(ts.userJobId)
          }
        })
      }

      return arrayObjs
    },
    hoursTotal() {
      const nonConsiderableDays = this.agencyData.isWeekendConsidered ? 0 : 2
      let invalidDaysOnWeekDaysCount = 0

      this.calendar.forEach((day) => {
        if (!day.isRequiredByUserContract && !day.isWeekend) {
          invalidDaysOnWeekDaysCount++
        }
      })

      const tooltipHoursTotalPerDay = this.hoursTotalPerDay.slice(
        0,
        this.hoursTotalPerDay.length - nonConsiderableDays
      )
      let hoursTotal = this.hoursTotalPerDay
        .map((item) => item.hours)
        .reduce((a, b) => a + b, 0)

      const requiredDaysCount = 5 - invalidDaysOnWeekDaysCount
      let percentTotal =
        this.hoursTotalPerDay
          .map((item) => item.percent)
          .reduce((a, b) => a + b, 0) / requiredDaysCount

      let tooltipHours = tooltipHoursTotalPerDay
        .map((item) => item.hours)
        .reduce((a, b) => a + b, 0)

      return {
        hours: percisionRound(hoursTotal, 0.01),
        percent: percisionRound(percentTotal, 0.01),
        tooltipHours: percisionRound(tooltipHours, 0.01),
      }
    },

    timesheetCanBeSubmitted() {
      const daysToSubmitValues = []
      const daysHoursRequired = []
      const internEmployee = []
      const hourlyEmployee = []
      const partTimeHours = []
      const fullTimeHours = []
      const contractorTimeHours = []
      let totalPartTimeHours = 0
      let totalcontractorTimeHours = 0
      let totalfullTimeHours = 0
      let totalhourlyEmployeeHour = 0
      let totalInternEmployeeHour = 0

      let dailyStatusArray = {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
      }

      this.calendar.forEach((day, i) => {
        const dayReported = day.date.split('T')[0]
        const index = moment(dayReported).isoWeekday() - 1
        const timesheets = getTimesheets(this.timesheets, false, day.date)

        timesheets.forEach((item) => {
          let durationValue = this.agencyData.isPercentageAgency
            ? item.durationPercentageValue
            : item.DurationValue
          if (durationValue !== 0) {
            dailyStatusArray[index].push(item.timesheetStatus)
          }
        })
        if (
          day.isRequiredByUserContract &&
          (day.isWeekend === false || this.agencyData.isWeekendConsidered) &&
          !this.special(dayReported)
        ) {
          if (this.agencyData.isPercentageAgency) {
            daysToSubmitValues.push(this.hoursTotalPerDay[i].percent)
          } else {
            daysHoursRequired.push(
              this.timesheetHoursPerDayComputed.hoursPerDay[i]
            )
            daysToSubmitValues.push(this.hoursTotalPerDay[i].hours)
          }
          if (day.employeeType == 'H') {
            hourlyEmployee.push(this.hoursTotalPerDay[i].hours)
          }
          if (day.isPartTimeEmployee) {
            partTimeHours.push(this.hoursTotalPerDay[i].hours)
          }
          if (day.externalEmployeeIndicator) {
            contractorTimeHours.push(this.hoursTotalPerDay[i].hours)
          }
          if (day.careerStageLevel == 'Intern') {
            internEmployee.push(this.hoursTotalPerDay[i].hours)
          }
          if (
            !day.externalEmployeeIndicator &&
            !day.isPartTimeEmployee &&
            day.careerStageLevel !== 'Intern' &&
            !this.agencyData.isPercentageAgency
          ) {
            fullTimeHours.push(this.hoursTotalPerDay[i].hours)
          } else if (
            !day.externalEmployeeIndicator &&
            !day.isPartTimeEmployee &&
            day.careerStageLevel !== 'Intern' &&
            this.agencyData.isPercentageAgency
          ) {
            fullTimeHours.push(this.hoursTotalPerDay[i].percent)
          }
        }
      })

      const isWholeWeekHourlyEmployee = this.calendar.every(
        (day) => day.employeeType == 'H'
      )
      const isWholeWeekInternEmployee = this.calendar.every(
        (day) => day.careerStageLevel == 'Intern'
      )
      const isWholeWeekPartTimeEmployee = this.calendar.every(
        (day) => day.isPartTimeEmployee
      )
      const isWholeWeekContractorTimeEmployee = this.calendar.every(
        (day) => day.externalEmployeeIndicator
      )
      const isWholeWeekFullTimeEmployee = this.calendar.every(
        (day) =>
          !day.isPartTimeEmployee &&
          !day.externalEmployeeIndicator &&
          day.careerStageLevel !== 'Intern' &&
          day.employeeType !== 'H'
      )

      totalInternEmployeeHour = internEmployee.reduce((a, b) => a + b, 0)
      totalhourlyEmployeeHour = hourlyEmployee.reduce((a, b) => a + b, 0)
      totalPartTimeHours = partTimeHours.reduce((a, b) => a + b, 0)
      totalcontractorTimeHours = contractorTimeHours.reduce((a, b) => a + b, 0)
      totalfullTimeHours = fullTimeHours.reduce((a, b) => a + b, 0)
      const fullTimeHoursExcludeWeekend =
        fullTimeHours.length > 5
          ? fullTimeHours.slice(0, 5)
          : fullTimeHours.slice(0, fullTimeHours.length - 1)
      const daysToSubmitValuesExcludeWeekend = daysToSubmitValues.slice(0, 5)

      if (this.agencyData.isPercentageAgency) {
        if (this.agencyData.allowAllEmployeesSubmissionWithoutRestriction) {
          if (
            totalInternEmployeeHour > 0 ||
            totalPartTimeHours > 0 ||
            totalcontractorTimeHours > 0 ||
            totalfullTimeHours > 0 ||
            totalhourlyEmployeeHour > 0
          ) {
            return true
          }
        } else if (
          this.agencyData.allowPartTimeContractorSubmissionWithoutRestriction
        ) {
          if (
            isWholeWeekInternEmployee ||
            isWholeWeekPartTimeEmployee ||
            isWholeWeekContractorTimeEmployee ||
            isWholeWeekFullTimeEmployee ||
            isWholeWeekHourlyEmployee
          ) {
            if (isWholeWeekFullTimeEmployee) {
              return fullTimeHoursExcludeWeekend.every(
                (percent) => percent === 100
              )
            } else {
              return (
                contractorTimeHours.some((percent) => percent > 0) ||
                partTimeHours.some((percent) => percent > 0) ||
                hourlyEmployee.some((percent) => percent > 0) ||
                internEmployee.some((percent) => percent > 0)
              )
            }
          } else {
            return (
              fullTimeHoursExcludeWeekend.every((percent) => percent === 100) &&
              (contractorTimeHours.some((percent) => percent > 0) ||
                partTimeHours.some((percent) => percent > 0) ||
                hourlyEmployee.some((percent) => percent > 0) ||
                internEmployee.some((percent) => percent > 0))
            )
          }
        } else {
          return daysToSubmitValuesExcludeWeekend.every(
            (percent) => percent === 100
          )
        }
      } else {
        if (this.agencyData.dwIndicator === '1') {
          if (this.agencyData.allowAllEmployeesSubmissionWithoutRestriction) {
            if (
              totalInternEmployeeHour > 0 ||
              totalPartTimeHours > 0 ||
              totalcontractorTimeHours > 0 ||
              totalfullTimeHours > 0 ||
              totalhourlyEmployeeHour > 0
            ) {
              return daysToSubmitValues.some(
                (hours, i) =>
                  hours > 0 &&
                  dailyStatusArray[i].find((i) => i === 'Unsubmitted')
              )
            }
          } else if (
            this.agencyData.allowPartTimeContractorSubmissionWithoutRestriction
          ) {
            if (
              isWholeWeekInternEmployee ||
              isWholeWeekPartTimeEmployee ||
              isWholeWeekContractorTimeEmployee ||
              isWholeWeekFullTimeEmployee ||
              isWholeWeekHourlyEmployee
            ) {
              if (isWholeWeekFullTimeEmployee) {
                return fullTimeHours.some(
                  (hours, i) =>
                    hours >= daysHoursRequired[i] &&
                    dailyStatusArray[i].find((i) => i === 'Unsubmitted')
                )
              } else {
                return (
                  partTimeHours.some(
                    (hours, i) =>
                      hours > 0 &&
                      dailyStatusArray[i].find((i) => i === 'Unsubmitted')
                  ) ||
                  contractorTimeHours.some(
                    (hours, i) =>
                      hours > 0 &&
                      dailyStatusArray[i].find((i) => i === 'Unsubmitted')
                  ) ||
                  hourlyEmployee.some(
                    (hours, i) =>
                      hours > 0 &&
                      dailyStatusArray[i].find((i) => i === 'Unsubmitted')
                  ) ||
                  internEmployee.some(
                    (hours, i) =>
                      hours > 0 &&
                      dailyStatusArray[i].find((i) => i === 'Unsubmitted')
                  )
                )
              }
            } else {
              return daysToSubmitValues.some(
                (hours, i) =>
                  hours > 0 &&
                  dailyStatusArray[i].find((i) => i === 'Unsubmitted')
              )
            }
          } else {
            return daysToSubmitValues.some(
              (hours, i) =>
                hours >= daysHoursRequired[i] &&
                dailyStatusArray[i].find((i) => i === 'Unsubmitted')
            )
          }
        } else if (this.agencyData.dwIndicator === '2') {
          if (this.agencyData.allowAllEmployeesSubmissionWithoutRestriction) {
            if (
              totalInternEmployeeHour > 0 ||
              totalPartTimeHours > 0 ||
              totalcontractorTimeHours > 0 ||
              totalfullTimeHours > 0 ||
              totalhourlyEmployeeHour > 0
            ) {
              return true
            }
          } else if (
            this.agencyData.allowPartTimeContractorSubmissionWithoutRestriction
          ) {
            if (
              isWholeWeekInternEmployee ||
              isWholeWeekPartTimeEmployee ||
              isWholeWeekContractorTimeEmployee ||
              isWholeWeekFullTimeEmployee ||
              isWholeWeekHourlyEmployee
            ) {
              if (isWholeWeekFullTimeEmployee) {
                return fullTimeHoursExcludeWeekend.every(
                  (hours, i) => hours >= daysHoursRequired[i]
                )
              } else {
                return (
                  partTimeHours.some((hours) => hours > 0) ||
                  contractorTimeHours.some((hours) => hours > 0) ||
                  hourlyEmployee.some((hours) => hours > 0) ||
                  internEmployee.some((hours) => hours > 0)
                )
              }
            } else {
              return (
                fullTimeHoursExcludeWeekend.every(
                  (hours, i) => hours >= daysHoursRequired[i]
                ) &&
                (partTimeHours.some((hours) => hours > 0) ||
                  contractorTimeHours.some((hours) => hours > 0) ||
                  hourlyEmployee.some((hours) => hours > 0) ||
                  internEmployee.some((hours) => hours > 0))
              )
            }
          } else {
            return daysToSubmitValuesExcludeWeekend.every(
              (hours, i) => hours >= daysHoursRequired[i]
            )
          }
        } else if (this.agencyData.dwIndicator === '3') {
          let totalSubmittedTime = daysToSubmitValues.reduce((a, b) => a + b, 0)
          if (this.agencyData.allowAllEmployeesSubmissionWithoutRestriction) {
            if (
              totalInternEmployeeHour > 0 ||
              totalPartTimeHours > 0 ||
              totalcontractorTimeHours > 0 ||
              totalfullTimeHours > 0 ||
              totalhourlyEmployeeHour > 0
            ) {
              return true
            }
          } else if (
            this.agencyData.allowPartTimeContractorSubmissionWithoutRestriction
          ) {
            if (
              isWholeWeekInternEmployee ||
              isWholeWeekPartTimeEmployee ||
              isWholeWeekContractorTimeEmployee ||
              isWholeWeekFullTimeEmployee ||
              isWholeWeekHourlyEmployee
            ) {
              if (isWholeWeekFullTimeEmployee) {
                return (
                  totalSubmittedTime >=
                  this.timesheetHoursPerDayComputed.totalHours
                )
              } else {
                return (
                  totalPartTimeHours > 0 ||
                  totalcontractorTimeHours > 0 ||
                  totalhourlyEmployeeHour > 0 ||
                  totalInternEmployeeHour > 0
                )
              }
            } else {
              return (
                totalSubmittedTime >=
                this.timesheetHoursPerDayComputed.totalHoursFTPT
              )
            }
          } else {
            return (
              totalSubmittedTime >= this.timesheetHoursPerDayComputed.totalHours
            )
          }
        }
      }

      return false
    },
    weekDaysList: function weekDaysList() {
      if (this.dayList != null && this.dayList.length > 0) {
        var daysInWeek = window._.filter(this.dayList, function (item) {
          return item.isWeekend === false
        })

        return daysInWeek
      } else {
        return []
      }
    },
    dailyStatus() {
      let dailyStatusArrayByJob = {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
      }

      this.calendar.forEach((day) => {
        const i = moment(day.date.split('T')[0]).isoWeekday() - 1
        const timesheets = getTimesheets(this.timesheets, false, day.date)

        timesheets.forEach((item) => {
          let durationValue = this.agencyData.isPercentageAgency
            ? item.durationPercentageValue
            : item.DurationValue
          if (durationValue !== 0) {
            let dailyStats = {
              status: item.timesheetStatus,
              value: durationValue,
            }
            dailyStatusArrayByJob[i].push(dailyStats)
          }
        })
      })

      let dayStatus = []

      for (const key in dailyStatusArrayByJob) {
        // eslint-disable-next-line no-prototype-builtins
        if (dailyStatusArrayByJob.hasOwnProperty(key)) {
          if (
            dailyStatusArrayByJob[key].some(
              (item) => item.status === 'Rejected'
            )
          ) {
            dayStatus.push('rejected')
          } else {
            if (key > 4 && this.firstAvailableContract.weekendEntryEnabled) {
              if (
                dailyStatusArrayByJob[key].every(
                  (item) => item.status === 'None'
                )
              ) {
                dayStatus.push('disabled')
              } else if (
                dailyStatusArrayByJob[key].some(
                  (item) => item.status === 'Unsubmitted'
                )
              ) {
                dayStatus.push('unsubmitted')
              } else {
                if (
                  dailyStatusArrayByJob[key].every(
                    (item) =>
                      item.status === 'Approved' || item.status === 'None'
                  )
                ) {
                  dayStatus.push('approved')
                } else if (
                  dailyStatusArrayByJob[key].some(
                    (item) => item.status === 'Approved'
                  ) &&
                  dailyStatusArrayByJob[key].every(
                    (item) =>
                      item.status === 'Rejected' ||
                      item.status === 'Approved' ||
                      item.status === 'Submitted' ||
                      item.status === 'None'
                  )
                ) {
                  dayStatus.push('partiallyapproved')
                } else if (
                  dailyStatusArrayByJob[key].some(
                    (item) => item.status === 'Approved'
                  )
                ) {
                  dayStatus.push('approved')
                } else if (
                  dailyStatusArrayByJob[key].every(
                    (item) =>
                      item.status === 'Submitted' || item.status === 'None'
                  )
                ) {
                  dayStatus.push('submitted')
                } else {
                  dayStatus.push('none')
                }
              }
            } else {
              let timeNOTSafe =
                this.hoursTotalPerDay[key].hours <
                this.timesheetHoursPerDayComputed.hoursPerDay[key]

              if (this.agencyData.isPercentageAgency) {
                timeNOTSafe = this.hoursTotalPerDay[key].percent < 100
              }

              if (
                dailyStatusArrayByJob[key].every(
                  (item) => item.status === 'None'
                ) ||
                timeNOTSafe
              ) {
                dayStatus.push('disabled')
              } else if (
                dailyStatusArrayByJob[key].some(
                  (item) => item.status === 'Unsubmitted'
                )
              ) {
                dayStatus.push('unsubmitted')
              } else {
                if (
                  dailyStatusArrayByJob[key].every(
                    (item) =>
                      item.status === 'Approved' || item.status === 'None'
                  )
                ) {
                  dayStatus.push('approved')
                } else if (
                  dailyStatusArrayByJob[key].some(
                    (item) => item.status === 'Approved'
                  ) &&
                  dailyStatusArrayByJob[key].every(
                    (item) =>
                      item.status === 'Rejected' ||
                      item.status === 'Approved' ||
                      item.status === 'Submitted' ||
                      item.status === 'None'
                  )
                ) {
                  dayStatus.push('partiallyapproved')
                } else if (
                  dailyStatusArrayByJob[key].some(
                    (item) => item.status === 'Approved'
                  )
                ) {
                  dayStatus.push('approved')
                } else if (
                  dailyStatusArrayByJob[key].every(
                    (item) =>
                      item.status === 'Submitted' || item.status === 'None'
                  )
                ) {
                  dayStatus.push('submitted')
                } else {
                  dayStatus.push('none')
                }
              }
            }
          }
        }
      }

      return dayStatus
    },
  },
  updated() {
    tabIndexTotal = 5
  },
  methods: {
    ...mapActions('storeTimesheets', [
      'loadData',
      'addNewTimesheet',
      'loadOnBehalfOfMappings',
    ]),
    ...mapMutations('storeTimesheets', [
      'setTimesheetExists',
      'setTimesheetSelectedJob',
      'setLoading',
      'setTimesheetDeleteError',
      'setSelfConsentSubmitDialog',
      'setTimesheetSubmitConfirmation',
      'setPerformRemoveButtonFocus',
      'setDeDupTimesheetJobs',
      'setTimesheetHideJobError',
      'setTimesheetEditJobError',
      'setActiveCell',
    ]),
    setTooltip(index) {
      this.$set(this.tooltipsActive, index, true)
    },
    hideTooltip(index) {
      this.$set(this.tooltipsActive, index, false)
    },
    isTodayPercentage(i) {
      return this.IsPercentageByDay[i].some((entry) => entry === true)
    },
    special(day) {
      if (
        this.userData.altairCode === '10038440' &&
        (moment(day).isSame('2021-05-10') || moment(day).isSame('2021-05-11'))
      ) {
        return true
      }

      return false
    },
    getTabIndex() {
      return tabIndexTotal++
    },
    showTimesheetSearch() {
      EventBus.$emit('showTimesheetSearch')
    },
    isThresholdError(percent, hoursEntered, dayIndex) {
      if (
        this.agencyData.allowAllEmployeesSubmissionWithoutRestriction ||
        (this.agencyData.allowPartTimeContractorSubmissionWithoutRestriction &&
          (this.calendar[dayIndex].isPartTimeEmployee ||
            this.calendar[dayIndex].externalEmployeeIndicator ||
            this.calendar[dayIndex].employeeType == 'H' ||
            this.calendar[dayIndex].careerStageLevel == 'Intern'))
      ) {
        return false
      }
      if (!this.calendar[dayIndex].isRequiredByUserContract) {
        return false
      } else {
        if (percent) {
          if (this.agencyData.dwIndicator < 3) {
            return hoursEntered.percent !== 100 && dayIndex < 5
          }
          return this.hoursTotal.percent < 100 && dayIndex < 5
        } else {
          if (this.agencyData.dwIndicator < 3) {
            if (
              this.firstAvailableContract.isTaskAgency &&
              !this.calendar[dayIndex].IsHoliday
            ) {
              const hoursMinusOvertime = percisionRound(
                hoursEntered.hours -
                  (hoursEntered.overtime + hoursEntered.overtimeDouble),
                0.01
              )

              return (
                hoursMinusOvertime <
                  this.timesheetHoursPerDayComputed.hoursPerDay[dayIndex] &&
                dayIndex < 5
              )
            } else {
              return (
                hoursEntered.hours <
                  this.timesheetHoursPerDayComputed.hoursPerDay[dayIndex] &&
                dayIndex < 5
              )
            }
          }

          return (
            this.hoursTotal.tooltipHours <
              this.timesheetHoursPerDayComputed.totalHours && dayIndex < 5
          )
        }
      }
    },
    getRemainingHours(percent, hours) {
      if (percent) {
        if (this.agencyData.dwIndicator < 3) {
          return percisionRound(
            100 - hours,
            this.agencyData.timesheetDurationStep
          )
        }
        // return 100 - hours
        let weekTotal = 100 - this.hoursTotal.percent

        return percisionRound(weekTotal, this.agencyData.timesheetDurationStep)
      } else {
        if (this.agencyData.dwIndicator < 3) {
          return percisionRound(hours, this.agencyData.timesheetDurationStep)
        }

        let weekTotal =
          this.timesheetHoursPerDayComputed.totalHours -
          this.hoursTotal.tooltipHours

        return percisionRound(weekTotal, this.agencyData.timesheetDurationStep)
      }
    },
    isPartTimeOrContractorEmployee() {
      let partTimeVerifier = this.calendar.some(
        (day) =>
          day.isPartTimeEmployee ||
          day.externalEmployeeIndicator ||
          day.employeeType == 'H' ||
          day.careerStageLevel == 'Intern'
      )
      if (
        this.agencyData.allowAllEmployeesSubmissionWithoutRestriction ||
        (this.agencyData.allowPartTimeContractorSubmissionWithoutRestriction &&
          partTimeVerifier)
      ) {
        return true
      }
    },
    openTimesheetDialog(job, date, clarizenProjectId) {
      this.setActiveCell(`${(job.id + date.split('T')[0]).replaceAll('-', '')}`)

      if (moment(date).isAfter(moment().format('YYYY-MM-DD'))) {
        const dialogAlert = {
          dialogAlertType: 'error',
          dialogAlertTitle: i18n.t(
            'timesheetUI.ValidationMessages.GenericErrorTitle',
            window.CULTURE
          ),
          dialogAlertMessage: i18n.t(
            'timesheetUI.ValidationMessages.TimesheetEntryFutureNotAllowedMessage',
            window.CULTURE
          ),
        }

        EventBus.$emit('dialogAlertShow', dialogAlert)
        return
      }

      this.setTimesheetSelectedJob({ job, date })

      if (
        (job.isClarizenAssignedProject && job.isClarizenTask) ||
        job.selfAssignmentWithManageTasking
      ) {
        if (
          getTimesheetsDuplicate(
            this.timesheets,
            clarizenProjectId,
            date,
            true,
            job.isClarizenTask,
            job.clarizenTaskExternalId,
            job.task,
            this.currentDayJobFeatures(job, date).isHistoricalJob
          ).length > 0
        ) {
          this.setTimesheetExists(
            getTimesheetsDuplicate(
              this.timesheets,
              clarizenProjectId,
              date,
              true,
              job.isClarizenTask,
              job.clarizenTaskExternalId,
              job.task,
              this.currentDayJobFeatures(job, date).isHistoricalJob
            )
          )
        } else {
          this.addNewTimesheet()
        }
      } else {
        if (getTimesheets(this.timesheets, job.id, date).length > 0) {
          this.setTimesheetExists(getTimesheets(this.timesheets, job.id, date))
        } else {
          this.addNewTimesheet()
        }
      }
    },
  },
}
</script>
