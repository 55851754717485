var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": _vm.dialogWidth, scrollable: "" },
      on: {
        "click:outside": function ($event) {
          return _vm.setSelfConsentSubmitDialog(false)
        },
      },
      model: {
        value: _vm.showSelfConsentDialog,
        callback: function ($$v) {
          _vm.showSelfConsentDialog = $$v
        },
        expression: "showSelfConsentDialog",
      },
    },
    [
      _c(
        "FocusLock",
        { attrs: { returnFocus: "" } },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "d-flex justify-space-between align-start pa-4",
                },
                [
                  _c("div", { staticClass: "timesheet-entry__header" }, [
                    _c("div", { staticClass: "h1 font-weight--900 mb-1" }, [
                      _vm._v(" " + _vm._s(_vm.dialogTitle) + " "),
                    ]),
                    _c("div", { staticClass: "h5" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("timesheetUI.SelfConsent.HeadingText")
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", tabindex: "0" },
                      on: {
                        click: function ($event) {
                          return _vm.setSelfConsentSubmitDialog(false)
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              !_vm.selfConsentData.consentData
                ? _c("v-progress-linear", {
                    attrs: { indeterminate: "", color: "primary" },
                  })
                : _vm._e(),
              _vm.selfConsentData.consentData
                ? [
                    _c(
                      "v-card-text",
                      { staticClass: "pa-4" },
                      [
                        _c(
                          "v-row",
                          [
                            _c("v-col", { attrs: { sm: "5" } }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("timesheetUI.SelfConsent.Locations")
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("v-col", { attrs: { sm: "5" } }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("timesheetUI.SelfConsent.Days")
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("v-col", { attrs: { sm: "2" } }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("timesheetUI.SelfConsent.TotalHours")
                                  ) +
                                  " "
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._l(
                          _vm.selfConsentData.consentData,
                          function (item, i) {
                            return _c(
                              "v-row",
                              { key: i },
                              [
                                _c(
                                  "v-col",
                                  { staticClass: "py-2", attrs: { sm: "5" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "px-3 light-border rounded align-center d-flex",
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.location) + " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-col",
                                  { staticClass: "py-2", attrs: { sm: "5" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "px-3 light-border rounded align-center d-flex",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(item.days.join(", ")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-col",
                                  { staticClass: "py-2", attrs: { sm: "2" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "px-3 light-border rounded align-center d-flex",
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.duration) + "h "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "py-2",
                                attrs: { sm: "2", offset: "10" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "px-3 light-border rounded align-center d-flex",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.selfConsentData.totalDuration
                                        ) +
                                        "h "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex my-3" },
                          [
                            _c("v-checkbox", {
                              staticClass: "ma-0",
                              attrs: {
                                "hide-details": "",
                                color: "primary",
                                tabindex: "0",
                                "aria-labelledby": "consent-label",
                              },
                              model: {
                                value: _vm.enableSubmit,
                                callback: function ($$v) {
                                  _vm.enableSubmit = $$v
                                },
                                expression: "enableSubmit",
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "font-color--primary",
                                attrs: { id: "consent-label" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "timesheetUI.SelfConsent.ConsentText"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                    _c("v-divider"),
                  ]
                : _vm._e(),
              _c(
                "v-card-actions",
                { staticClass: "justify-space-between pa-4" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-auto",
                      attrs: {
                        color: "primary",
                        depressed: "",
                        rounded: "",
                        outlined: "",
                        tabindex: "0",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.setSelfConsentSubmitDialog(false)
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("timesheetUI.Btns.BtnClose")) + " "
                      ),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        depressed: "",
                        rounded: "",
                        type: "submit",
                        disabled: !_vm.enableSubmit || _vm.loading,
                        tabindex: "0",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("submitTimesheet")
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("timesheetUI.Btns.BtnSubmitTimesheet")
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }