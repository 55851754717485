var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex justify-space-between align-start" },
    [
      _c("div", { staticClass: "timesheet-entry__header flex-grow-1" }, [
        _c(
          "div",
          { staticClass: "d-flex justify-space-between align-center mb-2" },
          [
            _c("h2", { staticClass: "text-h2" }, [
              _vm._v(" " + _vm._s(_vm.timesheetExistsDate) + " "),
            ]),
            _c(
              "v-btn",
              {
                attrs: {
                  icon: "",
                  disabled: _vm.savingTimesheet,
                  "aria-label": "Close Dialog",
                },
                on: { click: _vm.handleClose },
              },
              [_c("v-icon", [_vm._v("mdi-close")])],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "h5 d-flex flex-wrap font-weight--400 break-word" },
          [
            _vm._v(" " + _vm._s(_vm.timesheetExistsSubtitle) + " "),
            _vm.timesheetSelectedJob.job.selfAssignmentWithManageTasking
              ? _c("span", { staticClass: "d-flex ml-1" }, [
                  _vm._v(" • "),
                  _c("span", { staticClass: "d-inline-flex ml-1 flex-wrap" }, [
                    _c("span", { staticClass: "font-color--primary" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "timesheetUI.TimesheetJobs.SelfAssignmentProjectLabel"
                          )
                        )
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm.timesheetSelectedJob.job.crossCompanyRuleTypeName
              ? _c("span", { staticClass: "accent--text job-dialog__meta" }, [
                  _c("span", { staticClass: "font-color--body" }, [
                    _vm._v(" • "),
                  ]),
                  _c("span", { staticClass: "d-inline-flex ml-1 flex-wrap" }, [
                    _vm._v(
                      _vm._s(
                        _vm.timesheetSelectedJob.job.crossCompanyRuleTypeName
                      ) + " "
                    ),
                  ]),
                  _c(
                    "span",
                    { staticClass: "d-inline-flex" },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "cross-company-rule__label" },
                        [_vm._v("mdi-star")]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }