<template>
  <div class="alert-wrapper">
    <div v-for="(error, i) in alertWeekErrors" :key="i">
      <v-alert
        :value="!!alertWeekErrors.length"
        dark
        :type="type"
        transition="scale-transition"
        min-width="300"
        max-width="500"
        dismissible
        class="transition-swing"
      >
        <!-- <v-icon left>{{ icon }}</v-icon> -->
        {{ error.error }}
      </v-alert>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'vtoast',
  data() {
    return {
      showAlert: false,
      message: '',
      type: 'error',
      // timer: 3000,
    }
  },
  computed: {
    ...mapState('storeTimesheets', ['alertWeekErrors']),
  },
  methods: {
    show(data) {
      this.message = data.message || 'missing "message".'
      this.type = data.type || 'success'
      // this.timer = data.timer || 3000
      this.showAlert = true
    },
  },
}
</script>

<style scoped>
.alert-wrapper {
  position: absolute;
  bottom: 90px;
  left: 20px;
  margin: auto;
}
</style>
