<template>
  <div v-html="message"></div>
</template>

<script>
export default {
  name: 'inline-toast',
  props: {
    message: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped>
::v-deep div * {
  color: #fff !important;
}
</style>
