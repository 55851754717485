import ErrorMessagesEn from './errors-en.js'

export const General = {
  AltairCodeNotFound: 'Altair code not found',
  LoadError:
    'There has been an error in the system, please contact a system administrator.',
  NoResultsFound: 'No results found',
  NumberDecimalPlaces: 'Number only (2 decimals)',
  ElipsisMore: ' more',
  NA: 'N/A',
  Enabled: 'Enabled',
  Disabled: 'Disabled',
  RowsPerPage: 'Rows per page',
  Required: 'Required *',
  selectAll: 'Select All',
  unselectAll: 'Unselect All',
  for: 'for',
  is: 'is',
  Btns: {
    download: 'Download',
    upload: 'Upload',
    downloading: 'Downloading',
    Save: 'Save',
    AddNew: 'Add New',
    Cancel: 'Cancel',
    SaveChanges: 'Save Changes',
    Continue: 'Continue',
    Confirm: 'Confirm',
    ClearFilter: 'Clear Filters',
    Delete: 'Delete',
    Close: 'Close',
    LogTime: 'Log Time',
    NewEntry: 'New Entry',
    Back: 'Back',
    CorrectTimesheet: 'Correct Timesheet',
    ReviewCorrection: 'Review Correction',
    RemoveCorrection: 'Remove Correction',
    Stay: 'Stay',
    Leave: 'Leave',
    Add: 'Add',
  },
  Warnings: {
    CantBeUndoneAlert: 'This process cannot be undone.',
    AreYouSure: 'Are you sure you want to do that?',
    SaveError: 'All required fields must be filled in.',
  },
  Alerts: {
    UploadSuccessful: 'Upload Successful',
    SaveMessage: 'Are you sure you wish to save?',
    SaveSuccessful: 'Save Successful',
    DeleteSuccessful: 'Data Successfully deleted.',
    UnSavedChanges: 'You have unsaved changes!',
    UnSavedChangesMessage: 'Are you sure you want to leave ?',
  },
  Name: 'Name',
  Email: 'Email',
  Nodataavailable: 'No data available',
  Addnewrow: 'Add new row',
  OldValues: 'Old values',
  Newvalues: 'New values',
  and: 'and',
}

export const timesheetUI = {
  PanelTitle: 'Timesheet',
  JobGroupPrefix: 'Job Group: ',
  Btns: {
    BtnCopyJobsFromPreviousWeekTooltip:
      'Click to copy jobs added in the previous week',
    BtnCopyJobsFromPreviousWeek: 'Copy jobs from previous week',
    BtnCopyJobsFromPreviousWeekMobile: 'Copy',
    BtnAddNewEntry: 'Add new entry',
    BtnAddNewEntryMobile: 'Add new',
    BtnSubmit: 'Submit Day',
    BtnSubmitSmaller: 'Submit',
    BtnSubmitTimesheet: 'Submit Timesheet',
    BtnUnsubmitted: 'Unsubmitted',
    BtnSubmitted: 'Submitted',
    BtnApproved: 'Approved',
    BtnPartiallyApproved: 'Partially Approved',
    BtnPartiallyApprovedSmaller: 'PA',
    BtnRejected: 'Rejected',
    BtnSubmitAll: 'Submit All',
    BtnSave: 'Save',
    BtnClose: 'Close',
    BtnDuplicateRow: 'Duplicate row',
    BtnDeleteRow: 'Remove row',
    BtnCancel: 'Cancel',
    BtnPrevWeek: 'Previous Week',
    BtnPrevWeekSmaller: 'Previous',
    BtnNextWeek: 'Next Week',
    BtnNextWeekSmaller: 'Next',
    BtnMoreInfo: 'More Info',
    BtnShowDetail: 'Show Details',
    BtnsSaving: 'Saving',
    BtnJobList: 'Add job / task',
    BtnRemoveRow: 'Remove Row',
    BtnBookAbsence: 'Book absence',
    BtnBookAbsenceModify: 'Modify',
    BtnBookAbsenceCancel: 'Cancel absence',
    BtnBookAbsenceCancelConfirm: 'Confirm cancellation',
    BtnCloseAbsenceCalendar: 'Open Timesheets',
  },
  MobileBtns: {
    BtnSubmit: 'S',
    BtnSubmitted: 'S',
    BtnApproved: 'A',
    BtnPartiallyApproved: 'PA',
    BtnRejected: 'R',
    BtnSubmitAll: 'SA',
  },
  HeaderSearchTimesheet: {
    Placeholder: 'Search current timesheet',
    NoResultsTitle: 'No results found for: <strong>${query}</strong>',
    NoEntriesFound: 'No entries found',
    BtnSearch: 'Search',
    BtnCancel: 'Cancel',
    BtnApplyFilters: ' Apply Filters',
    FilterLabel: 'Filter timesheet',
    FilterPopupLabel: 'Filters and categories',
    ClearFilters: 'clear all filters',
    FilterOptions: {
      self_assigned: 'Self Assigned',
      my_agency: 'My agency',
      other_agencies: 'Other agencies',
      assigned_job: 'Assigned Job',
      favourites: 'Favourites',
    },
    noResultsLabel: 'No Results Found',
    noResultsSubText:
      'None of your timesheet matches the searched keyword or applied filter.',
  },
  HeaderOnBehalfOf: {
    LabelOnBehalfOf: 'On behalf of',
    LabelOnBehalfOfPopupHeader: 'Onbehalf of',
    Placeholder: 'Search users',
    selectedOnBehalfOfText: 'Welcome, you are logging time on behalf of',
    gotomytimesheet: 'Go to my timesheet',
    filterByUserName: 'Filter By User Name',
    dataNotFound: "We couldn't find any results matching ",
  },
  HeaderDatePicker: {
    BtnCurrentWeek: 'Current Week',
    BtnLastWeek: 'Last Week',
  },
  HeaderCalendar: {
    LabelHoliday: 'Public Holiday',
    LabelTotal: 'Total',
    LabelProject: 'PROJECT',
    LabelPercentage: 'percentage',
    LabelHours: 'hours',
    LabelTotal2: 'Total',
    AllocationPeriod: 'Allocation Period:',
  },
  SearchJobs: {
    ShowSelfAssignmentError:
      'Please contact your Project Manager to ask them to add you to this project in rOar so you can add your time.',
    NoStudioActivities:
      'Studio activities and/or workcode need to be added to the job setup. Please contact your local support team',
    NoJobs:
      'Your role needs to be added to the job setup. Please contact your local support team.',
    NotEligible:
      'You are not eligible to use this absence work code. Please contact your HR manager.',
    multipleContractsAgencyLabel:
      'As a non-studio user, for agency, ${0} with workcode ${1}',
    multipleContractsStudioLabel:
      'As a studio user, for agency, ${0} with workcode ${1}',
    HasChangedContractTitle: `Ah, we see that you've changed contracts this week. How would you like to select this job?`,
    SearchJobsBackLabel: 'Timesheets',
    SearchJobsPanelTitle: 'Search a job',
    SearchBoxPlaceholder:
      'Search job title/ID, client, brand, agency and more...',
    AgencyFilterLabel: 'Agency Filter',
    ResultTitle: 'Your search results for',
    ResultsFor: 'jobs found. Showing ${pagNumber} of ${totalResults}',
    ResultsFoundLabel: 'Results Found',
    ShowingResultsLabel: 'Showing ${pagNumber} of ${totalResults}',
    CrossCompanyLable: 'Cross Company',
    DetailPanelTitle: 'Timesheet Detail',
    WorkCodeTitle: 'Workcode',
    ActivityTitle: 'Activity',
    TaskTitle: 'Task',
    BillableIndicatorTitle: 'Billable Indicator',
    AddedTitle: 'Added to your timesheet',
    BtnAddToTimesheet: 'Add to timesheet',
    BtnContinueSearch: 'Continue Search',
    BtnGoBackToTimesheets: 'Timesheet',
    NoResultsTitle: 'We couldn\'t find any results matching "${searchQuery}"',
    NoJobsFound: 'No jobs found',
    NoResultsTipsTitle: 'Search tips',
    NoResultsTips: `Search tips:<br />
            Check your spelling and try again.<br />
            Try searching one word or two words.<br />
            Try searching shorter descriptions.<br />
            Search by job ID, example B1200-009127-00.<br />
            Search by job title, brand name, client name or agency name.`,
    AgencyFilters: [
      { title: 'Assigned Jobs', value: 3, key: 'assignedJobs' },
      { title: 'My agency', value: 1, key: 'myAgency' },
      { title: 'Other agencies', value: 2, key: 'otherAgencies' },
      { title: 'Show All', value: 0, key: 'showAll' },
    ],
    HybridTimesheetWarning: 'Are you sure you want to do that?',
    DuplicateAssigned:
      'The task with the same number is already on your list, please confirm your selection',
    DuplicateSelfAssigned:
      'The task with the same number is already on your list',
    CantBeAddToTimesheet: "Can't be added",
    toAddAJob:
      'To add a job to your timesheet, you must first select the task name.',
    ConfirmNewRow: 'Confirm new row',
  },
  ValidationMessages: {
    GenericErrorTitle: 'Oops!',
    CantSubmitTimesheetsYetMessage:
      'You must enter a minimum of {0} hours Mon - Fri  before submitting the week.',
    TimesheetSubmittedSuccessMessageTitle: 'Thank you!',
    TimesheetSubmittedSuccessMessage: `<p class="font-size--16 mb-2">Your timesheet has been submitted for approval.</p><p><strong>Note: You can edit & resubmit your time before it's approved.</strong></p>`,
    AlreadySubmittedTimesheetsMessage:
      'You have already submitted your timesheet',
    DailyTotalDurationExceededMessage:
      'Your daily total entry should not exceed 24 hours',
    CantDeleteTimesheetWithDurationMessageTitle: '<b>Your time matters 😃</b>',
    CantDeleteTimesheetWithDurationMessage:
      'To delete this job, all entered hours must be set to zero.',
    TimesheetDurationMustBeZeroOrMore: 'Must be zero (0) or more',
    TimesheetEntryAlreadyApprovedTitle: 'Already approved',
    TimesheetEntryAlreadyApprovedMessage:
      'You cannot edit time that has already been approved.',
    TimesheetEntryFutureNotAllowed: 'Easy Tiger',
    TimesheetEntryFutureNotAllowedMessage:
      "You can't enter future time on this job",
    TimesheetNotCorrectIncrement: 'You must enter time in increments of {step}',
    TimesheetNotCorrectIncrementQuantity:
      'Only whole numbers allowed e.g. 1, 2, 3',
    TimesheetQuanitytZero:
      'Please enter a product quantity to save your time entry.',
    TimesheetNotANumber:
      'Only whole numbers or decimals values are allowed e.g. 3 or 3.5',
    TimesheetValueChangeMustSave:
      'You have changed this entry you must save or cancel the entry.',
    TimesheetReferenceRequired:
      'Reference product is required if product quantity is added',
    ErrorForTimesheetingForNonStudioJobWithStudioUserTitle: 'Opps!',
    MissingRequiredFields:
      'Mandatory field is missing. Double-click or press enter on cell to see details',
    ErrorForTimesheetingForNonStudioJobWithStudioUser:
      'This is an Agency job but your contract for this date states that you are a Studio user. <br><br>Please enter time against a job with a Studio activity. Any concern with your change in contract, please follow up with your Finance or HR team.',
    StartLimitationTitle: '<b>Sorry 😟,</b>',
    StartLimitationMessage:
      "We like your 'Roar' but you're not permitted to enter time before your agency start date",
    EndLimitationTitle: '<b>Sorry 😟,</b>',
    EndLimitationMessage:
      "You can't record time for this date. If you need to, please contact your local support team.",
    TimesheetWholeNumbers: 'Whole numbers only (i.e. 10, 33, 82...).',
    CommentLengthExceeded: 'Uh oh, max character limit is 125',
    TimesheetExceeded100: "You can't record more than 100% a day",
    NoBillableIndicators: `Oops, there's no billable indicator on this labour work code. Please seek assistance from your internal finance/Altair team to rectify.`,
    LocationMessage:
      'Important: please make sure that the selected location is updated and reflects the location where you worked on this day.',
    DeleteErrorTitle: `You can't remove this job`,
    DeleteErrorMessage: `To delete this job, all entered hours must be set to zero`,
    SubmitConfirmTitle: 'Your timesheet has been submitted',
    SubmitConfirmMessage: `Your timesheet has been submitted for approval. You can edit and resubmit your time before it's approved.`,
    SubmitConfirmMeta: `Total hours: {hours} • Submitted on {date}`,
    SubmitConfirmMetaPercent: `Total: {hours}% • Submitted on {date}`,
    EnterTime: 'Please enter your time',
    SelectWorkcode: 'Please select your Workcode',
    SelectAltairTask: 'Please select your Altair Task',
    DefaultRequired: 'Please enter your {fieldName}',
    LocationMandatorySubmit:
      'Please provide your location, you can change it before you submit your timesheet',
    LocationWarningSubmit:
      'Important: please make sure that the selected location is updatedand reflects the location where you worked or travelled on this day',
    TimesheetPPMTaskGone:
      'PPM Task no longer available for time entry on this date, only zero value allowed',
    TimesheetPPMTaskZeroOnly:
      'Duration value can only be changed to zero after PPM task is saved',
    WorkFromHome: 'Please enter your Work From Home status',
    TimesheetEntryRestriction: 'Time entry outside allocation period',
    TimesheetEntryRestrictionMessage:
      'Please note that your allocated time frame for this project runs {startDate} to {endDate}.<br/>Please adjust your time entry to fall within this timeframe.',
    TimesheetEntryPostRestriction: 'Your staffing details have changed',
    TimesheetEntryPostRestrictionMessage:
      'Your allocated time frame for this project runs {startDate} to {endDate}.<br/>Please adjust your time entry to fall within this timeframe.',
    TimesheetEntryRestrictionZeroOnly:
      'Your staffing details have changed, duration value can only be changed to zero',
    TimesheetEntryPPMTaskAllocationError:
      'Task date is outside of staffing period',
    HideJobErrorTitle: `You can't hide this job`,
    HideJobErrorMessage: `To hide this job, all entered hours should be set to zero.`,
    EditHiddenJobErrorTitle: `You can't edit this job`,
    EditHiddenJobErrorMessage: `To edit this job, you need to unhide it first.`,
    TimesheetExtraHoursDurationErrorMessage: `The total time entered for a single day should not exceed 24 hours.`,
    TimesheetExtraPercentageDurationErrorMessage: `Time recorded for a single day should not exceed 100%.`,
  },
  JobGroupHighlight: {
    AgencyCode: 'AgencyCode',
    JobID: 'Job ID',
    Week: 'Week',
    Year: 'Year',
    JobGroup: 'Job Group',
    Workcode: 'Workcode',
    WorkcodeName: 'Workcode Name',
    BillableIndicator: 'Billable Indicator',
    Activity: 'Activity',
    ActivityName: 'Activity Name',
    Task: 'Task',
    ID: 'ID',
    CrossCompanyRuleId: 'Cross Company Rule Id',
    IsStudioJob: 'Is Studio Job',
    ClarizenJobTitleForBilling: 'Job Title For Billing',
    ClarizenJobTitleForBillingName: 'Job Title For Billing Name',
  },
  EntryFormLabels: {
    TimesheetEntryLocationSettingsLabel: 'Set this location for the',
    TimesheetEntryWfhSettingsLabel: 'Set WFH status for the',
    TimesheetWeeklySettings: ['This entry only', 'Full day', 'Full week'],
    TimesheetEntryLocationsLabel: 'Location',
    TimesheetEntryProductReferenceLabel: 'Reference Product',
    TimesheetEntryQuantityLabel: 'Product Quantity',
    TimesheetEntryQuantityLabelSidenote: 'Each',
    TimesheetEntryHoursLabel:
      'Duration <span class="font-color--light">(i.e. 7, 7.5)</span>',
    TimesheetEntryDuration: 'Duration (i.e. 7, 7.5)',
    TimesheetEntryPercentLabel: 'Duration (%)',
    TimesheetEntryHoursLabelSidenote: 'Hours',
    TimesheetEntryPercentLabelSidenote: '%',
    TimesheetEntryHoursLabelComments: 'Comment (optional)',
    TimesheetEntryLabelLocations: 'Location',
    TimesheetEntryLabelClarizenTaskName: 'PPM Task',
    BtnCancel: 'Cancel',
    BtnSubmit: 'Save',
    TimesheetEntryLabelWorkcode: 'Workcode',
    TimesheetEntryLabelBI: 'Billable Indicator',
    TimesheetStudioActivity: 'Studio Activity',
    TimesheetEntryLabelTask: 'Altair Task',
    TimesheetEntryHoursLabelCommentsPlaceholder: 'Type your comment here',
    TimesheetEntryJobName: 'Job Name',
    TimesheetEntryJobID: 'Job ID',
    timesheetStatusDescriptions: {
      unsubmitted:
        'This timesheet hasn’t been submitted yet. You can still edit your details before you submit.',
      submitted: `This timesheet has been submitted for approval. You can edit and resubmit your time before it's approved.`,
      approved: 'This timesheet has been approved and can’t be edited anymore.',
      rejected:
        'This timesheet has been rejected. You can find more details and the comments below.',
      approvedEnabledSelfCorrection:
        'This timesheet has been approved. You have ${day} day(s) if you need to correct this timesheet.',
      canNotCorrect: 'This timesheet can not be corrected',
      noConfigureSelfCorrection:
        'Self-correction timeframe is not configured for your FSLA status and business unit. Contact your administrator for assistance.',
      rejectedSelfCorrection:
        'To self-correct your timesheet, please ensure that any rejected self-corrected entries are removed before resubmitting',
    },
    WIPCorrectedMessage:
      'These hours have been amended by your Finance Manager. There is no action for you to take.',
    TimesheetStatus: 'Timesheet Status',
    TimesheetEntryPsTask: 'PPM Task',
    TimesheetEntryPsTaskPlaceholder: 'Select task name',
    TimesheetEntryWfhYes: 'Yes',
    TimesheetEntryWfhNo: 'No',
    TimesheetEntryWfhLabel: 'Work From Home',
    TimesheetEntryAdditionalInformation: 'Additional information',
  },
  Empty: {
    TimesheetEmptyHasContract: 'Can’t find what you are looking for?',
    TimesheetEmptyNoContract:
      'You cannot enter time on this Agency before your transfer/hire date',
    Title: 'New week, new opportunities!',
    SubTitle:
      'Start logging your daily time and copying jobs from previous weeks using the buttons below.',
    Message:
      'Here you can log your daily time and copy jobs from your previous week. Please use the buttons below to start.',
    MessageWithAbsence:
      'Here you can log your daily time or book / log absence, and copy jobs from your previous week. Please use the buttons below to start.',
    CopyJobFromPreviousWeek: 'Copy job from previous week',
  },
  Rejected: {
    RejectedTitle: 'Why rejected?',
  },
  Footer: {
    FooterTotalLabel: 'Total',
    actions: 'actions',
  },
  InfoPanel: {
    InfoPanelTitle: 'Timesheet details',
    StatusTitle: 'Timesheet status:',
    LabelUser: 'User:',
    LabelLocation: 'Location:',
    LabelSubmittedBy: 'Submitted by:',
    LabelSubmittedOn: 'Submitted on:',
    LabelApprover: 'Approver:',
    LabelApprovedOn: 'Approved on:',
    LabelRejectedOn: 'Rejected on:',
    LabelProdActivity: 'Prod Activity:',
    LabelRefProd: 'Reference Prod:',
    LabelProdQuantity: 'Ref Prod Qty: ',
    WIPTitle: 'Timesheet adjustment',
    WIPMessage:
      'These hours have been amended by your Finance Manager. There is no action for you to take.',
    Comment: "Approver's Comment",
    ReviewTitle: `You've made following changes`,
    NoChanges: 'No changes',
    locationUpdate: {
      header:
        'Location update: We have transitioned from a city-based to a state-based setup. For example:',
      list1: 'Instead of "Gurgaon", please search for "Haryana"',
      list2: 'Instead of "Boston", please search for "Massachusetts"',
    },
  },
  TimesheetJobs: {
    ClarizenAssignTaskLabel: 'Assigned task',
    ClarizenAssignProjectLabel: 'Assigned project',
    SelfAssignmentProjectLabel: 'Self assignment',
  },
  Tooltips: {
    InlineCellInfo: 'Double-click or press-enter on cell to view details',
    DailyStatus:
      "The timesheet for ${day} is <span class='${dailyStatus}-text'>${dailyStatusName}</span>",
    Status: {
      approved: 'Approved',
      partiallyapproved: 'Partially Approved',
      submitted: 'Submitted',
      rejected: 'rejected',
      submitting: 'submitting',
      unsubmitted: 'unsubmitted',
    },
    Threshold: {
      title: "Don't forget to sum up your time",
      message:
        'Your agency requests a minimum input of {totalPerDay} hours per day before you submit your timesheet.',
      messageWeek:
        'Your agency requests a minimum input of <b>{totalPerWeek} hours per week</b> before you submit your timesheet.',
      messageMixed:
        'Your agency requires either <b>100% per day</b> or <b>{totalPerDay} hours per day</b> before you submit your timesheet.',
      messagePercentage:
        'Your agency requires <b>100% per day</b> before you submit your timesheet.',
      alertHoursSpanishNormal: `Your Agency requests a minimum input of 8 hours per day Monday - Thursday and 5.5 hours on a Friday  before you submit your timesheet.`,
      alertHoursSpanishSummer: `We've entered the summer period, Your Agency now requests a minimum input of 7 hours per day  before you submit your timesheet.`,
      alertHoursSpanishSummerEntering: `We've entered the summer period. For this week, your Agency requests a minimum input of {timeSchedule} before you submit your timesheet.`,
      alertHoursSpanishSummerLeaving: `The summer period is now ending. For this week, your Agency requests a minimum input of {timeSchedule} before you submit your timesheet.`,
      alertHours: 'Add {hours} more hours ',
      alertOvertime: 'Add <b>{hours} more hours regular time.</b> ',
      alertPercent: 'Add <b>{hours}% more</b> ',
      alertDW1: 'to submit your time for this day.',
      alertDW2: 'to submit timesheet.',
    },
    Comment: {
      said: 'said',
    },
  },
  TableColumns: {
    BillableIndicator: 'Billable Indicator',
    Duration: 'Duration',
    Status: 'Status',
    WorkCode: 'Work Code',
    Details: 'Details',
  },
  Absence: {
    AbsenceCreateTitle: 'Absence booking:',
    AbsenceType: 'Absence type',
    AbsenceDuration: 'Duration',
    AbsenceSelectedHours: 'Selected Hours',
    AbsenceSelectedDate: 'Selected Date',
    AbsenceComments: 'Add a note (optional)',
    AbsenceDetailShow: 'Show details',
    AbsenceDetailHide: 'Hide details',
    AbsenceCollapsedTitle: 'This week:',
    AbsenceModifyTitle: 'Manage absence',
    AbsenceModifyTitleOngoing: 'Confirm cancellation for ongoing absence',
    AbsenceModifyTitleCancel: 'Confirm cancellation',
    AbsenceModifyOngoingAlert:
      'Caution, you are trying to cancel the ongoing absence. Your cancel request will be sent for approval.',
    AbsenceModifyFutureAlert:
      'Caution, you are trying to cancel future absence. Your cancel request will be automatically approved.',
    UpdatedAbsenceModifyFutureAlert: 'You are about to cancel this absence.',
    AbsenceModifyPastAlert:
      'Caution, you are trying to cancel past absence. Your cancel request will be sent for approval.',
    UpdatedAbsenceModifyPastAlert: 'You are about to cancel this absence.',
    AbsenceSummaryTitle: 'Absence summary',
    AbsenceThisYearTitle: '{start} year (01/01/{year} - 31/12/{year})',
    AbsenceBreakdownMessage:
      'A breakdown of all the leave you have taken so far in the business year, broken down by type.',
    AbsenceReamainingText: 'Remaining',
    AbsenceEntitlementText: 'Entitlement',
    AbsenceNoAvailableDaysText: 'No available days',
    SendingRequest: 'Sending request',
    ModifyAbsence: 'Modify absence',
    AbsenceCancelled: 'Your absence has been cancelled.',
  },
  Status: {
    Unsubmitted: 'Unsubmitted',
    Submitted: 'Submitted',
    Approved: 'Approved',
    PartiallyApproved: 'Partially Approved',
    Rejected: 'Rejected',
  },
  ErrorDialog: {
    ErrorTitle: 'Sorry, we encountered an error',
    SuccessTitle: 'Success!',
    DialogSubtitle: 'Check the message below for more information.',
  },
  ComparisonLabels: {
    location: 'Location',
    productReference: 'Reference Product',
    productQuantity: 'Product Quantity',
    durationValue: 'Duration',
    durationPercentageValue: 'Duration (%)',
    comment: 'Comment',
    clarizenTaskName: 'PPM Task',
    workCode: 'Workcode',
    billableIndicator: 'Billable Indicator',
    activity: 'Studio Activity',
    task: 'Altair Task',
    clarizenCustomTask: 'PPM Task',
    wfh: 'Work From Home',
    selfAssignmentWithManageTasking: 'PPM Task',
  },
  SelfConsent: {
    HeadingText: `These are the location/s that you've logged`,
    Locations: 'Locations',
    Days: 'Days',
    TotalHours: 'Total Hours',
    ConsentText:
      'I certify and confirm that I have reviewed my time card and that it accurately reflects all of the hours that I worked and the location where work was performed during the workweek',
  },
  ManagePreference: {
    Heading: ' Manage your Preference for location',
    WFHHeading: 'and WFH',
    FullWeekLocationLabel: 'Full week location',
    FullWeekWFHLabel: 'Full week work from home',
    LocationLabel: 'Location',
    WFHLabel: 'Work from home',
    SelectedLocation: ' Location selected',
    ManageLocationPreferenceText: 'Location ',
    ManageWFHPreferenceText: 'and WFH',
    ManagePreferenceText: 'Location and WFH',
    MultipleLocations: 'Multiple Locations',
  },

  SearchJobValidationMessage: {
    taskAlreadyExists:
      'The task with the same name is already on your list, please confirm your selection',
    PPMAndAltairTaskAlreadyExists:
      'A job with the same PPM task and altair task is already on your list, please confirm your selection',
    AltairTaskAlreadyExists:
      'A job with the same altair task is already on your list, please confirm your selection',
  },
  AssignedJobsBanner: {
    StartText: 'You have',
    AssignTaskLabel: 'Assigned task',
    And: 'and',
    AssignProjectLabel: 'Assigned project',
    AssignedJobsBannerInYourList: 'in your list. You can add them from',
    TheJobList: 'the job list.',
  },
  TimesheetsForLabel: 'Timesheets for',
  TimesheetsSkipBar: 'Skip to Add Job in Action Bar',
  Sort: {
    BIAscName: 'BI',
    BIAsc: 'Billable first to Non-billable last',
    BIDescName: 'BI',
    BIDesc: 'Non-Billable first to Billable last',
    NameAscName: 'Task Name',
    NameAsc: 'A-Z',
    NameDesc: 'Z-A',
  },
  JobOptions: {
    RemoveJob: 'Remove Job',
    FavoriteJob: 'Favorite Job',
    UnFavoriteJob: 'Unfavorite Job',
  },
  LearnMore: {
    btnText: 'Learn more',
    Heading: 'Location Preferences',
    qustion1: {
      qustion: 'Q: What is location preferences and how does it work?',
      answer: {
        answer:
          'A: Location preference allows users to select a location for a specific day or a week. Once a location preference is set, it applies to new entries created after setting up the preferences. Location preferences can be set from two places:',
        list1:
          'The Location and WFH link on your timesheet page. It will be Location link, in case WFH is not applicable for the agency.',
        list2: 'The "Set this location for" field in the Time entry form.',
      },
    },
    qustion2: {
      qustion: 'Q: What is a full week location preference?',
      answer: {
        answer:
          'A: A full week location preference sets a single location for the  entire week.',
      },
    },
    qustion3: {
      qustion: 'Q: What is a full day location preference?',
      answer: {
        answer:
          'A: A full day location preference sets a single location for an entire day. The full day preference always takes precedence over the full week preference. For example, if you select Alaska for the full week and later select Alabama for Tuesday, the system will consider Alaska for the entire week except Tuesday, which will be Alabama. Additionally, the system will update the full day value in the preferences screen for Tuesday.',
      },
    },
    qustion4: {
      qustion:
        'Q: Can you provide an example of how location preferences work?',
      answer: {
        answer: 'A: Here is the example:',
        list1:
          'First, you select the location preference for Tuesday as Alaska for a full day. When you enter time for Tuesday, the system will automatically preselect Alaska as the location.',
        list2:
          'Next, you select the location preference for the full week as Alabama. The system will then preselect Alabama for the full week and Alaska for Tuesday.',
        list3:
          'Regardless of whether you apply the full week preference first or the full day preference, the full day preference will always have priority over the full week preference.',
      },
    },
    qustion5: {
      qustion: 'Q: What does "this entry only" mean?',
      answer: {
        answer:
          'A: "This entry only" applies to a single time entry and does not impact your preferred location set for a full day or a full week.',
      },
    },
  },
  copyRestOfWeek: {
    header: 'Copy rest of week',
    subHeader:
      'The copy feature will copy the time entry across all working days (usually Mon to Fri) within the selected week.',
    copyRestOfWeekList: {
      list1:
        'The copy feature will update from the day selected to the end of the week. (For example, if you select Wednesday, it will copy the entry to Thursday and Friday',
      list2: 'The copy feature will update one job (row) at a time',
      list3:
        'The copy feature will not update absences including public holidays',
      list4: 'The copy feature will not update Saturday or Sunday',
      list5:
        'If you already have time logged on a given day, a new entry will be created instead of overwriting the existing entry',
    },
  },
}

export const ErrorMessages = ErrorMessagesEn
