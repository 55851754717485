import axios from 'axios'
import { EventBus } from '@Shared/EventBus'
import i18n from '@Shared/i18n'
import { formatDateFrontend } from '@Shared/filters'
import { getTitle, getSubTitle } from '@Shared/formatDialogError'

const getParam = (paramName) => {
  let url = new URL(window.location.href).searchParams
  return url.get(paramName) ? url.get(paramName) : 'null'
}

const environmentsMicroService = {
  dev: 'https://dev-api.roar.pub/',
  qa: 'https://api.roar.pub/qa/',
  sandbox: 'https://api.roar.pub/sandbox/',
  prod: 'https://api.roar.pub/prod/',
}

const getEnv = () => {
  return environmentsMicroService[window.ENV.toLowerCase()]
}

const api = axios.create({
  baseURL: getEnv(),
  cache: 'no-cache',
  headers: {
    'cache-control': 'no-cache',
    pragma: 'no-cache',
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
    SessionId: window.SESSION_ID !== null ? window.SESSION_ID : '',
    ClarizenUserId:
      window.CLARIZEN_USER_ID !== null ? window.CLARIZEN_USER_ID : '',
    Culture: getParam('Culture'),
    AltairCode: getParam('AltairCode'),
  },
})

api.interceptors.request.use((config) => {
  const AUTH_TOKEN = localStorage.getItem('AUTH_TOKEN')
  const existingSessionID = localStorage.getItem('SESSION_ID')

  if (
    window.SESSION_ID &&
    window.SESSION_ID === existingSessionID &&
    AUTH_TOKEN
  ) {
    config.headers.Authorization = `Bearer ${AUTH_TOKEN}`
  }

  if (window.SESSION_ID && window.SESSION_ID !== existingSessionID) {
    localStorage.setItem('SESSION_ID', window.SESSION_ID)
  }

  return config
})

api.interceptors.response.use(
  (response) => {
    if (response.headers?.['x-refreshtoken']) {
      localStorage.setItem('AUTH_TOKEN', response.headers['x-refreshtoken'])
    }

    return response
  },

  (error) => {
    if (!error.response) {
      return Promise.reject(error)
    }

    const customErrors = [5004]

    const jobErrors = [
      5067, 5068, 5069, 5070, 5071, 5072, 5073, 5074, 5075, 5076, 5078, 5079,
      5077,
    ]

    if (
      !customErrors.includes(error.response?.data.code) &&
      !window.DISABLE_GLOBAL_ALERT
    ) {
      let data = ''
      let dialogAlertTitle = ''
      let dialogAlertSubTitle = ''
      let customMessage = ''

      const defaultMessageHandler = () => {
        if (error.response?.data.code > 0) {
          customMessage = i18n.t(`ErrorMessages.${error.response?.data.code}`, {
            data,
          })
        } else {
          customMessage = error.response?.data.message
        }
      }

      if (error.response?.data.errorData) {
        if (jobErrors.includes(error.response.data.code)) {
          dialogAlertTitle = getTitle(error.response.data.errorData[0]?.date)
          dialogAlertSubTitle = getSubTitle(error.response.data.errorData[0])
          switch (error.response.data.code) {
            case 5077:
              {
                let project_start_date = formatDateFrontend(
                  error.response.data.errorData[0].projectStartDate.split(
                    'T'
                  )[0]
                )
                let project_end_date = formatDateFrontend(
                  error.response.data.errorData[0].projectEndDate.split('T')[0]
                )
                customMessage = i18n
                  .t(`ErrorMessages.${error.response.data.code}`)
                  .replace('{projectStartDate}', project_start_date)
                  .replace('{projectEndDate}', project_end_date)
              }

              break

            default:
              defaultMessageHandler()
              break
          }
        } else {
          switch (error.response.data.code) {
            case 5041:
            case 5044:
              data = formatDateFrontend(
                error.response.data.errorData[0].timesheetMinDate.split('T')[0]
              )
              customMessage = i18n.t(
                `ErrorMessages.${error.response.data.code}`,
                { data }
              )
              break

            case 5054:
              customMessage = error.response.data.message
                ? error.response.data.message
                : i18n.t(`ErrorMessages.${error.response.data.code}`)
              break

            case 5080:
              customMessage = i18n
                .t(`ErrorMessages.${error.response.data.code}`)
                .replace('{jobID}', error.response.data.errorData[0].job_ID)
              break

            case 5081:
              customMessage = i18n.t(
                `ErrorMessages.${error.response.data.code}`
              )
              dialogAlertTitle = 'Timesheet entry not allowed'
              dialogAlertSubTitle = getSubTitle(
                error.response.data.errorData[0]
              )
              break

            case 5082:
              customMessage = i18n.t(
                `ErrorMessages.${error.response.data.code}`
              )
              dialogAlertTitle = 'Timesheet submission not allowed'
              dialogAlertSubTitle = getSubTitle(
                error.response.data.errorData[0]
              )
              break

            case 5083:
            case 5084:
              customMessage = i18n.t(
                `timesheetUI.ValidationMessages.TimesheetEntryPostRestrictionMessage`,
                {
                  startDate: formatDateFrontend(
                    error.response.data.errorData[0].allocationStartDate.split(
                      'T'
                    )[0]
                  ),
                  endDate: formatDateFrontend(
                    error.response.data.errorData[0].allocationEndDate.split(
                      'T'
                    )[0]
                  ),
                }
              )
              dialogAlertTitle = i18n.t(
                `timesheetUI.ValidationMessages.TimesheetEntryRestriction`
              )
              break

            default:
              error.response.data.errorData.forEach((a) => {
                if (a.timesheetsError) {
                  const keys = Object.keys(a.timesheetsError)

                  data = `<ul class="mt-6">
                                ${keys
                                  .map((b) => {
                                    return `<li>
                                        <div>${
                                          a.timesheetsError[b]
                                            .clarizenProjectName ||
                                          a.timesheetsError[b].projectName ||
                                          a.timesheetsError[b].jobName
                                        } - ${
                                      a.timesheetsError[b].clarizenTaskName ||
                                      a.timesheetsError[b].workItemName ||
                                      a.timesheetsError[b].jobId
                                    }</div>
                                        </li>`
                                  })
                                  .join('')}    
                                </ul>`
                }
              })

              defaultMessageHandler()
              break
          }
        }
      } else {
        defaultMessageHandler()
      }

      const dialogAlert = {
        dialogAlertType: 'error',
        dialogAlertTitle: dialogAlertTitle,
        dialogAlertSubTitle: dialogAlertSubTitle,
        dialogAlertMessage: customMessage,
      }

      if (error.config.isInline) {
        error.response.data.message = customMessage
        return Promise.reject(error)
      }

      EventBus.$emit('dialogAlertShow', dialogAlert)
    }

    return Promise.reject(error)
  }
)

export default api
