<template>
  <div
    v-if="Object.keys(absencesWithinRange).length"
    :class="{
      'loading-rows': loadingAbsenceData,
    }"
  >
    <v-expansion-panels flat v-model="absencePanel">
      <v-expansion-panel class="absence-group--panel">
        <v-expansion-panel-header hide-actions class="h3 font-weight--600">
          <template v-slot:default="{ open }">
            <v-icon
              :size="30"
              class="mr-2"
              color="primary"
              :style="{ transform: open ? 'rotate(90deg)' : 'rotate(0)' }"
              >mdi-chevron-right</v-icon
            >
            <p class="font-size--18 font-weight--600 width--100 mb-0">
              Absence
            </p>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <AbsenceRowGroup>
            <template v-for="(absences, entitlementId) in createdAbsences">
              <GridRow
                :key="entitlementId"
                v-if="absencesWithinRange[entitlementId]"
              >
                <template #col1>
                  <GridCell>
                    <div class="d-flex justify-space-between align-center py-0">
                      <span class="font-size--12">{{
                        absences[0].leaveType
                      }}</span>

                      <v-menu
                        :close-on-content-click="true"
                        :offset-x="true"
                        aria-label="`Absence Row action menu"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <VBtn
                            v-bind="attrs"
                            v-on="on"
                            min-width="28"
                            height="28"
                            class="pa-0 rounded-lg"
                            x-small
                            text
                            color="primary"
                          >
                            <VIcon>mdi-dots-horizontal</VIcon>
                          </VBtn>
                        </template>
                        <v-list dense width="200">
                          <v-list-item
                            class="cursor-pointer primary--text rounded-lg overflow-hidden"
                            @click="showAbsenceModifyFormDialog(absences)"
                            :aria-label="`Modify ${absences[0].leaveType}`"
                          >
                            <v-list-item-icon>
                              <v-icon size="20"> mdi-pencil-outline</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                              <v-list-item-title>
                                {{ $t('timesheetUI.Absence.ModifyAbsence') }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </GridCell>
                </template>
                <template
                  v-for="(day, i2) in calendar"
                  v-slot:[day.dayName.toLowerCase()]
                >
                  <GridCell
                    :key="`${i2}-day`"
                    :class="{
                      background:
                        (day.isWeekend &&
                          !firstAvailableContract.weekendEntryEnabled) ||
                        (day.isHoliday && !agencyData.enableAbsenceModule) ||
                        checkWeekend(day.date),
                    }"
                    :disabled="day.isWeekend && !day.weekendEntryEnabled"
                  >
                    <TimeEntryCell
                      v-if="day.isWeekend ? day.weekendEntryEnabled : true"
                      :value="getAbsenceTotal(absences, day.date)"
                      :day="day"
                      readonly
                    />
                  </GridCell>
                </template>

                <template #total>
                  <GridCell class="job-group--week-total">
                    <div class="d-flex justify-center">
                      {{ getAbsenceTotalByAbsence(absences) }}
                    </div>
                  </GridCell>
                </template>
              </GridRow>
            </template>
          </AbsenceRowGroup>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import moment from 'moment'
import GridRow from '../grid/GridRow.vue'
import GridCell from '../grid/GridCell.vue'
import AbsenceRowGroup from '../grid/GridRowGroup.vue'
import TimeEntryCell from '../time-entry/TimeEntryCell.vue'
import { calculateAbsencePercentage } from '@/utils/absence'
// import { countWeekendDays } from '@/utils/absence'

export default {
  data() {
    return {
      collapsed: true,
      activeLeaveTypes: [],
      panel: 0,
    }
  },
  components: {
    GridRow,
    GridCell,
    AbsenceRowGroup,
    TimeEntryCell,
  },

  computed: {
    ...mapState('storeAbsenceTracker', [
      'absenceData',
      'loadingAbsenceData',
      'timestamp',
      'createdAbsences',
      'absenceStatusMap',
      'expandAbsencePanel',
    ]),
    ...mapState('storeTimesheets', ['calendar', 'firstAvailableContract']),
    ...mapGetters('storeTimesheets', ['selectedDate', 'agencyData']),
    ...mapGetters('storeAbsenceTracker', [
      'canShowAbsenceOnTimesheet',
      'getAbsenceTotalByAbsence',
    ]),

    absencePanel: {
      get() {
        return this.expandAbsencePanel
      },
      set(val) {
        this.setExpandAbsencePanel(val)
      },
    },

    absencesWithinRange() {
      const startOfWeek = this.selectedDate.startOfWeek
      const endOfWeek = this.selectedDate.endOfWeek
      // const show = []
      const entitlementIdsInCurrentWeek = {}

      for (let [entitlementId, absences] of Object.entries(
        this.createdAbsences
      )) {
        if (!absences.length) continue

        const absencesInCurrentWeek = absences.filter((absence) => {
          if (
            !absence.rangeId &&
            moment(absence.absenceDate).isSameOrAfter(startOfWeek) &&
            moment(absence.absenceDate).isSameOrBefore(endOfWeek)
          ) {
            return absence
          }

          if (absence.rangeId) {
            const absenceStartDate = moment(absence.rangeStartDate)
            const absenceEndDate = moment(absence.rangeEndDate)

            let isAbsenceWithinThisWeek = false

            this.calendar.forEach((day) => {
              if (
                (moment(day.date).day() == 6 || moment(day.date).day() == 0) &&
                !day.weekendEntryEnabled
              ) {
                return
              }

              if (
                moment(day.date).isBetween(
                  absenceStartDate,
                  absenceEndDate,
                  undefined,
                  '[]'
                )
              ) {
                isAbsenceWithinThisWeek = true
              }
            })

            if (isAbsenceWithinThisWeek) return absence
          }
        })

        if (
          absencesInCurrentWeek.some((absence) =>
            this.canShowAbsenceOnTimesheet(absence.status)
          )
        ) {
          entitlementIdsInCurrentWeek[entitlementId] = true
        }
      }

      return entitlementIdsInCurrentWeek
    },
  },
  methods: {
    ...mapMutations('storeAbsenceTracker', [
      'setCustomModifyData',
      'setExpandAbsencePanel',
    ]),
    showAbsenceModifyFormDialog(absences) {
      this.setCustomModifyData(absences)
    },
    getAbsenceTotal(absenses, date) {
      let total = 0
      absenses.forEach((a) => {
        if (this.canShowAbsenceOnTimesheet(a.status)) {
          if (moment(date).day() == 6 || moment(date).day() == 0) {
            return 0
          }

          const startDate = a.rangeId
            ? moment(a.rangeStartDate)
            : moment(a.absenceDate)
          const endDate = a.rangeId
            ? moment(a.rangeEndDate)
            : moment(a.absenceDate)
          let dayCount = endDate.diff(startDate, 'days') + 1

          if (!a.rangeId) {
            if (moment(a.absenceDate).isSame(date)) {
              total = parseFloat(
                parseFloat(a.leavesUtilizedInHours / dayCount).toFixed(2)
              )
            }
          }

          if (a.rangeId) {
            if (moment(date).isBetween(startDate, endDate, undefined, '[]')) {
              total = this.firstAvailableContract.requiredTimesheetHours
            }
          }
        }
      })

      if (this.agencyData.isPercentageAgency) {
        return calculateAbsencePercentage(
          total,
          this.agencyData.timesheetHoursPerDay
        )
      } else {
        return total
      }
    },
    checkWeekend(date) {
      return moment(date).day() === 6 || moment(date).day() === 0
    },
  },
}
</script>

<style lang="scss" scoped>
.loading-rows {
  opacity: 0.4;
}

.absence-group--panel {
  .v-expansion-panel-header {
    width: fit-content;
  }
}
</style>
