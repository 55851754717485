<template>
  <div class="d-flex">
    <div class="flex-grow-1">
      <WeekNavigation />
    </div>

    <OnBehalfOf />
  </div>
</template>

<script>
import WeekNavigation from './WeekNavigation.vue'
import OnBehalfOf from '../on-behalf-of'

export default {
  name: 'NavigationHeader',
  components: {
    WeekNavigation,
    OnBehalfOf,
  },
}
</script>
