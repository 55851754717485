var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex" },
    [
      (!_vm.showForm || _vm.timesheetDetail?.id) &&
      !_vm.isTimesheetBeingCorrected
        ? _c(
            "v-btn",
            {
              key: "newEntry",
              staticClass:
                "font-weight--600 timesheet-entry__actions--new-entry",
              attrs: {
                outlined: "",
                rounded: "",
                disabled:
                  _vm.loading || _vm.savingTimesheet || _vm.loadingFeatures,
              },
              on: { click: _vm.addNew },
            },
            [_vm._v(_vm._s(_vm.$t("General.Btns.NewEntry")))]
          )
        : _vm._e(),
      _vm.agencyData.enableSelfCorrection
        ? [
            !_vm.isTimesheetBeingCorrected &&
            _vm.isTimesheetApproved &&
            _vm.timesheetDetail?.id
              ? [
                  _c(
                    "v-btn",
                    {
                      key: "correctTimesheet",
                      staticClass:
                        "font-weight--600 timesheet-entry__actions--save px-5 ml-auto",
                      attrs: {
                        rounded: "",
                        depressed: "",
                        color: "primary",
                        disabled:
                          _vm.loading ||
                          _vm.savingTimesheet ||
                          _vm.loadingFeatures ||
                          !_vm.agencyData.enableSelfCorrection ||
                          !_vm.isCorrectionValid ||
                          _vm.timesheetDetail.isSelfCorrected,
                      },
                      on: { click: _vm.handleTimesheetCorrection },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("General.Btns.CorrectTimesheet")) +
                          " "
                      ),
                    ]
                  ),
                ]
              : _vm._e(),
            _vm.timesheetDetail?.id &&
            _vm.isTimesheetBeingCorrected &&
            !_vm.isTimesheetBeingReviewed
              ? [
                  _c(
                    "v-btn",
                    {
                      key: "cancel",
                      staticClass:
                        "font-weight--600 timesheet-entry__actions--new-entry",
                      attrs: { outlined: "", rounded: "" },
                      on: { click: _vm.handleCancel },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("General.Btns.Cancel")) + " ")]
                  ),
                  _vm.validForEntry
                    ? _c(
                        "v-btn",
                        {
                          key: "reviewCorrection",
                          staticClass:
                            "font-weight--600 timesheet-entry__actions--save px-5 ml-auto",
                          attrs: {
                            rounded: "",
                            depressed: "",
                            color: "primary",
                            disabled:
                              _vm.invalid ||
                              !_vm.hasTimeEntryChanged ||
                              _vm.shouldReviewBtnDisable,
                          },
                          on: {
                            click: () => _vm.setIsTimesheetBeingReviewed(true),
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("General.Btns.ReviewCorrection")) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm.timesheetDetail &&
            _vm.timesheetDetail.timesheetStatus === "Rejected" &&
            _vm.agencyData.enableSelfCorrection &&
            _vm.timesheetDetail.selfCorrectedHours < 0
              ? [
                  _c(
                    "v-btn",
                    {
                      key: "removeCorrection",
                      staticClass:
                        "font-weight--600 timesheet-entry__actions--save px-5 ml-auto",
                      attrs: {
                        rounded: "",
                        depressed: "",
                        color: "primary",
                        disabled:
                          _vm.loadingFeatures ||
                          _vm.timesheetTotals >
                            (_vm.timesheetDetail &&
                            _vm.timesheetDetail.isPercentageEntry
                              ? 100
                              : 24),
                      },
                      on: { click: () => _vm.saveTimesheet() },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("General.Btns.RemoveCorrection")) +
                          " "
                      ),
                    ]
                  ),
                ]
              : _vm._e(),
            _vm.isTimesheetBeingReviewed
              ? [
                  _c(
                    "v-btn",
                    {
                      key: "back",
                      staticClass:
                        "font-weight--600 timesheet-entry__actions--new-entry",
                      attrs: { outlined: "", rounded: "" },
                      on: {
                        click: () => _vm.setIsTimesheetBeingReviewed(false),
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("General.Btns.Back")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      key: "confirm",
                      staticClass:
                        "font-weight--600 timesheet-entry__actions--save px-5 ml-auto",
                      attrs: {
                        rounded: "",
                        depressed: "",
                        color: "primary",
                        loading: _vm.savingTimesheet,
                        disabled: !_vm.timesheetDetail?.id,
                      },
                      on: { click: _vm.handleConfirm },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("General.Btns.Confirm")) + " ")]
                  ),
                ]
              : _vm._e(),
          ]
        : _vm._e(),
      _vm.validForEntry &&
      !_vm.isTimesheetApproved &&
      !!_vm.timesheetDetail &&
      !(
        _vm.timesheetDetail &&
        _vm.timesheetDetail.timesheetStatus === "Rejected" &&
        _vm.agencyData.enableSelfCorrection &&
        _vm.timesheetDetail.selfCorrectedHours < 0
      )
        ? _c(
            "v-btn",
            {
              key: "save",
              staticClass:
                "font-weight--600 timesheet-entry__actions--save px-5 ml-auto",
              attrs: {
                rounded: "",
                depressed: "",
                color: "primary",
                type: "submit",
                loading: _vm.savingTimesheet,
                disabled:
                  _vm.loading ||
                  _vm.savingTimesheet ||
                  _vm.invalid ||
                  _vm.loadingFeatures ||
                  (_vm.showForm && _vm.isTimesheetApproved) ||
                  !_vm.hasTimeEntryChanged ||
                  !_vm.isEmpty(_vm.preparingForAutoSave),
              },
            },
            [_vm._v(_vm._s(_vm.$t("General.Btns.Save")))]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }