var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        {
          staticClass: "pb-0",
          attrs: { cols: _vm.isTimeEntryDrawerExpanded ? 6 : 12 },
        },
        [
          _c(
            "div",
            { staticClass: "mb-4" },
            [
              _c("v-skeleton-loader", {
                attrs: { type: "text", "max-width": 100 },
              }),
              _c("v-skeleton-loader", {
                attrs: { type: "button", "max-width": "100%" },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("v-skeleton-loader", {
                attrs: { type: "text", "max-width": 100 },
              }),
              _c("v-skeleton-loader", {
                attrs: { type: "button", "max-width": "100%" },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "v-col",
        {
          staticClass: "pb-0",
          attrs: { cols: _vm.isTimeEntryDrawerExpanded ? 6 : 12 },
        },
        [
          _c(
            "div",
            { staticClass: "mb-4" },
            [
              _c("v-skeleton-loader", {
                attrs: { type: "text", "max-width": 100 },
              }),
              _c("v-skeleton-loader", {
                attrs: { type: "button", "max-width": "100%" },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("v-skeleton-loader", {
                attrs: { type: "text", "max-width": 100 },
              }),
              _c("v-skeleton-loader", {
                attrs: { type: "button", "max-width": "100%" },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }