<template>
  <v-dialog v-model="dialog" :width="680" scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="accent"
        text
        x-small
        :class="[
          'font-weight--600 text-decoration-underline px-0',
          screen === 'location-dialog' ? 'font-size--14' : 'font-size--12',
        ]"
        v-bind="attrs"
        v-on="on"
      >
        {{ $t('timesheetUI.LearnMore.btnText') }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="px-0 py-0 flex-column">
        <div class="px-6 py-5 width--100">
          <div class="d-flex justify-space-between align-center width--100">
            <div>
              <div class="font-weight--600 font-size--24">
                {{ $t('timesheetUI.LearnMore.Heading') }}
              </div>
            </div>
            <v-btn icon aria-label="Close Dialog" @click="handleClose">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
        <v-divider role="presentation" class="width--100"></v-divider>
      </v-card-title>

      <v-divider role="presentation" class="width--100"></v-divider>
      <div
        class="font-size--14 font-weight--500 primary--text py-4 px-6 overflow-y-auto inner-container"
      >
        <div class="font-weight--600">
          {{ $t('timesheetUI.LearnMore.qustion1.qustion') }}
        </div>
        <div>
          {{ $t('timesheetUI.LearnMore.qustion1.answer.answer') }}
        </div>

        <ol type="1">
          <li>
            {{ $t('timesheetUI.LearnMore.qustion1.answer.list1') }}
          </li>

          <li>
            {{ $t('timesheetUI.LearnMore.qustion1.answer.list2') }}
          </li>
        </ol>

        <div class="font-weight--600">
          {{ $t('timesheetUI.LearnMore.qustion2.qustion') }}
        </div>
        <div class="mb-2">
          {{ $t('timesheetUI.LearnMore.qustion2.answer.answer') }}
        </div>
        <div class="font-weight--600">
          {{ $t('timesheetUI.LearnMore.qustion3.qustion') }}
        </div>
        <div class="mb-2">
          {{ $t('timesheetUI.LearnMore.qustion3.answer.answer') }}
        </div>
        <div class="font-weight--600">
          {{ $t('timesheetUI.LearnMore.qustion4.qustion') }}
        </div>
        <div class="mb-2">
          {{ $t('timesheetUI.LearnMore.qustion4.answer.answer') }}
        </div>

        <ul class="mb-2">
          <li>
            {{ $t('timesheetUI.LearnMore.qustion4.answer.list1') }}
          </li>
          <li>
            {{ $t('timesheetUI.LearnMore.qustion4.answer.list2') }}
          </li>
          <li>
            {{ $t('timesheetUI.LearnMore.qustion4.answer.list3') }}
          </li>
        </ul>

        <div class="font-weight--600">
          {{ $t('timesheetUI.LearnMore.qustion5.qustion') }}
        </div>
        <div>
          {{ $t('timesheetUI.LearnMore.qustion5.answer.answer') }}
        </div>
      </div>
      <v-divider role="presentation" class="width--100"></v-divider>
      <v-card-actions>
        <v-btn
          @click="handleClose"
          outlined
          rounded
          class="font-weight--600 preference-actions--close my-4"
          >{{ $t('General.Btns.Close') }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'LearnMore',

  props: {
    screen: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    handleClose() {
      this.dialog = false
    },
  },
}
</script>
<style>
.inner-container {
  max-height: calc(100vh * 0.7);
}
</style>
