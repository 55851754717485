<template>
  <v-icon
    class="material-symbols-outlined status-icon"
    :color="statusConfig[status].color ?? '#000'"
    :aria-label="statusConfig[status].aria_label ?? 'Status'"
    :tabindex="tabindex"
    :size="size"
  >
    {{ statusConfig[status].icon }}
  </v-icon>
</template>

<script>
//TODO: use colors from themes
const statusConfig = {
  submitted: {
    icon: 'mdi-clock',
    aria_label: 'Time Submitted',
    color: 'accent',
  },
  approved: {
    icon: 'mdi-check-circle',
    aria_label: 'Time Approved',
    color: '#006A3A',
  },
  rejected: {
    icon: 'mdi-close-circle',
    aria_label: 'Time Rejected',
    color: 'error',
  },
}

export default {
  name: 'StatusIcon',
  props: {
    status: String,
    size: {
      type: String,
      default: '10px',
    },
    tabindex: {
      type: Number,
      default: 0,
    },
  },
  data: function () {
    return {
      statusConfig,
    }
  },
}
</script>
