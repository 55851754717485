<template>
  <v-alert
    :value="showSnackbar"
    color="accent-light"
    transition="slide-y-transition"
    class="py-2"
  >
    <div class="justify-space-between d-flex font-size--14 align-center">
      <span class="d-flex black--text">
        <v-icon color="black" class="mdi mdi-information-outline"></v-icon>
        <span class="ml-3">
          <span class="font-weight--500">{{
            $t('timesheetUI.HeaderOnBehalfOf.selectedOnBehalfOfText')
          }}</span>
          <span class="font-weight--600"> {{ OBOUserName }}. </span>
        </span>
      </span>

      <v-btn
        class="font-weight--600 font-size--14"
        @click="gotomytimesheet"
        color="primary"
        rounded
        depressed
        :disabled="loading"
      >
        {{ $t('timesheetUI.HeaderOnBehalfOf.gotomytimesheet') }}
      </v-btn>
    </div>
  </v-alert>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  name: 'AlertOnBehalfOf',
  data() {
    return {
      showSnackbar: false,
    }
  },
  computed: {
    ...mapState('storeTimesheets', ['loading', 'OBOUserName']),
  },
  methods: {
    ...mapMutations('storeTimesheets', [
      'setOnBehalfOfUser',
      'hideTimeEntryDrawer',
    ]),
    ...mapActions('storeTimesheets', ['loadData']),
    gotomytimesheet() {
      this.hideTimeEntryDrawer()
      this.setOnBehalfOfUser({ altairCodeOBO: null, OBOUserName: null })
      this.showSnackbar = false
      this.loadData('initialLoad')
    },
  },
  watch: {
    OBOUserName() {
      if (this.OBOUserName) {
        this.showSnackbar = true
      }
    },
  },
}
</script>
