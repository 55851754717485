<template>
  <v-btn
    rounded
    depressed
    color="button-background"
    class="ma-0 font-weight--600 font-size--14"
    @click="goToAbsenceCalendar"
  >
    {{ $t('timesheetUI.Btns.BtnBookAbsence') }}
  </v-btn>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('storeAbsenceTracker', [
      'loadingAbsenceData',
      'altairCode',
      'altairCodeOBO',
    ]),
  },
  methods: {
    goToAbsenceCalendar() {
      const applicationLoadedIn =
        window.getParameterByName('from') ||
        window.getParameterByName('disableRedirect')

      const envsClarizen = {
        local: `https://eu.clarizentb.com/Clarizen/Panels/CustomPage/AbsenceCalendar/${
          this.altairCodeOBO ? '?AltairCodeOBO=' + this.altairCodeOBO : ''
        }`,
        qa: `https://eu.clarizentb.com/Clarizen/Panels/CustomPage/AbsenceCalendar/${
          this.altairCodeOBO ? '?AltairCodeOBO=' + this.altairCodeOBO : ''
        }`,
        sandbox: `https://eu.clarizentb.com/Clarizen/Panels/CustomPage/AbsenceCalendar/${
          this.altairCodeOBO ? '?AltairCodeOBO=' + this.altairCodeOBO : ''
        }`,
        prod: `https://eu1.clarizen.com/Clarizen/Panels/CustomPage/AbsenceCalendar/${
          this.altairCodeOBO ? '?AltairCodeOBO=' + this.altairCodeOBO : ''
        }`,
      }
      const envsAdmin = {
        local: `https://admin-qa.roar.pub/a/AbsenceCalendar/${
          this.altairCodeOBO ? '?AltairCodeOBO=' + this.altairCodeOBO : ''
        }`,
        qa: `https://admin-qa.roar.pub/a/AbsenceCalendar/${
          this.altairCodeOBO ? '?AltairCodeOBO=' + this.altairCodeOBO : ''
        }`,
        sandbox: `https://admin-sandbox.roar.pub/a/AbsenceCalendar/${
          this.altairCodeOBO ? '?AltairCodeOBO=' + this.altairCodeOBO : ''
        }`,
        prod: `https://admin-prod.roar.pub/a/AbsenceCalendar/${
          this.altairCodeOBO ? '?AltairCodeOBO=' + this.altairCodeOBO : ''
        }`,
      }
      const url =
        applicationLoadedIn === 'clarizen'
          ? envsClarizen[window.ENV]
          : envsAdmin[window.ENV]
      window.parent.location.href = url
    },
  },
}
</script>
