<template>
  <div>
    <FooterHourRow
      v-if="isAnyJobPresentOnTimesheet"
      :tooltipsActive="tooltipsActive"
      :submittingTimesheets="submittingTimesheets"
      :IsPercentageByDay="IsPercentageByDay"
      :updateSubmittingTimesheets="updateSubmittingTimesheets"
      class="mb-1"
    />
    <FooterActionRow
      :tooltipsActive="tooltipsActive"
      :submittingTimesheets="submittingTimesheets"
      :IsPercentageByDay="IsPercentageByDay"
      :updateSubmittingTimesheets="updateSubmittingTimesheets"
    />
  </div>
</template>

<script>
import FooterHourRow from './FooterHourRow.vue'
import FooterActionRow from './FooterActionRow.vue'
import { EventBus } from '@Shared/EventBus'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'TimesheetFooter',
  components: {
    FooterHourRow,
    FooterActionRow,
  },
  data() {
    return {
      tooltipsActive: [],
      submittingTimesheets: false,
    }
  },
  computed: {
    ...mapGetters('storeTimesheets', ['isAnyJobPresentOnTimesheet']),
    ...mapState('storeTimesheets', ['IsPercentageByDay']),
  },
  methods: {
    updateSubmittingTimesheets(value) {
      this.submittingTimesheets = value
    },
  },
  created() {
    EventBus.$on('dialogAlertChange', (show) => {
      if (!show) {
        this.submittingTimesheets = false
      }
    })
  },
}
</script>
