<template>
  <v-row>
    <v-col :cols="isTimeEntryDrawerExpanded ? 6 : 12" class="pb-0">
      <div class="mb-4">
        <v-skeleton-loader type="text" :max-width="100"></v-skeleton-loader>
        <v-skeleton-loader type="button" max-width="100%"></v-skeleton-loader>
      </div>
      <div>
        <v-skeleton-loader type="text" :max-width="100"></v-skeleton-loader>
        <v-skeleton-loader type="button" max-width="100%"></v-skeleton-loader>
      </div>
    </v-col>
    <v-col :cols="isTimeEntryDrawerExpanded ? 6 : 12" class="pb-0">
      <div class="mb-4">
        <v-skeleton-loader type="text" :max-width="100"></v-skeleton-loader>
        <v-skeleton-loader type="button" max-width="100%"></v-skeleton-loader>
      </div>
      <div>
        <v-skeleton-loader type="text" :max-width="100"></v-skeleton-loader>
        <v-skeleton-loader type="button" max-width="100%"></v-skeleton-loader>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'TimeEntrySkeletonLoader',
  computed: {
    ...mapState('storeTimesheets', ['isTimeEntryDrawerExpanded']),
  },
}
</script>
<style scoped lang="scss">
.v-skeleton-loader::v-deep .v-skeleton-loader__button {
  width: 100%;
  border-radius: $app-border-radius;
}
</style>
