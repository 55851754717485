var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-btn",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.onBehalfOfMappings.length > 0,
              expression: "onBehalfOfMappings.length > 0",
            },
          ],
          staticClass: "font-size--14 font-weight--600 light-border",
          attrs: { disabled: _vm.loading, outlined: "", rounded: "" },
          on: { click: _vm.openModal },
        },
        [
          _c(
            "v-icon",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.OBOUserName,
                  expression: "!OBOUserName",
                },
              ],
              staticClass: "mr-2",
            },
            [_vm._v("mdi-plus")]
          ),
          _c("span", [
            _vm._v(
              " " +
                _vm._s(_vm.$t("timesheetUI.HeaderOnBehalfOf.LabelOnBehalfOf")) +
                " "
            ),
          ]),
          _c("span", { staticClass: "accent--text" }, [
            _vm._v("   " + _vm._s(_vm.OBOUserName)),
          ]),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "800" },
          on: { "click:outside": _vm.closeModal },
          model: {
            value: _vm.showOnBehalfOfModal,
            callback: function ($$v) {
              _vm.showOnBehalfOfModal = $$v
            },
            expression: "showOnBehalfOfModal",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "justify-space-between py-5" },
                [
                  _c("h2", { staticClass: "font-weight--600 text-h2" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "timesheetUI.HeaderOnBehalfOf.LabelOnBehalfOfPopupHeader"
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: "",
                        "aria-label": "Close On Behalf of Dialog",
                      },
                      on: { click: _vm.closeModal },
                    },
                    [
                      _c("v-icon", { attrs: { color: "primary" } }, [
                        _vm._v("mdi-close"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-text",
                { staticClass: "font-size--14 font-weight--600 py-5" },
                [
                  _c("div", { staticClass: "mb-2" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "timesheetUI.HeaderOnBehalfOf.filterByUserName"
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c("v-text-field", {
                    staticClass:
                      "rounded-lg mb-4 font-size--14 font-weight--400",
                    attrs: {
                      placeholder: "Search by user name",
                      outlined: "",
                      dense: "",
                      "hide-details": "",
                      autofocus: "",
                    },
                    model: {
                      value: _vm.searchTerm,
                      callback: function ($$v) {
                        _vm.searchTerm = $$v
                      },
                      expression: "searchTerm",
                    },
                  }),
                  _c("v-divider", { staticClass: "mb-4" }),
                  _vm.onBehalfOfFilterData.length < 1
                    ? _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "timesheetUI.HeaderOnBehalfOf.dataNotFound"
                              )
                            ) +
                            ' "' +
                            _vm._s(_vm.searchTerm) +
                            '" '
                        ),
                      ])
                    : _vm._e(),
                  _vm.onBehalfOfFilterData.length > 0
                    ? _c("v-simple-table", {
                        staticClass: "OBO-table",
                        attrs: { "fixed-header": "", height: "300px" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function () {
                                return [
                                  _c(
                                    "thead",
                                    { staticClass: "OBO-table--header" },
                                    [
                                      _c(
                                        "tr",
                                        {
                                          staticClass: "OBO-table--header-row",
                                        },
                                        [
                                          _c(
                                            "th",
                                            {
                                              staticClass:
                                                "text-left font-size--12 font-weight--500 background",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("General.Name")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "th",
                                            {
                                              staticClass:
                                                "text-left font-size--12 font-weight--500 background",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("General.Email")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c("th", {
                                            staticClass: "text-left background",
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "tbody",
                                    [
                                      _vm._l(
                                        _vm.onBehalfOfFilterData,
                                        function (item, index) {
                                          return _c(
                                            "tr",
                                            {
                                              key: `${item.targetAltairCode}-${index}`,
                                              staticClass: "font-weight--500",
                                              class: {
                                                "cursor-pointer": true,
                                                background:
                                                  index % 2 !== 0 &&
                                                  _vm.selectedUser
                                                    .targetAltairCode !==
                                                    item.targetAltairCode,
                                                "accent-light":
                                                  _vm.selectedUser
                                                    .targetAltairCode ===
                                                  item.targetAltairCode,
                                              },
                                              attrs: {
                                                tabindex: "0",
                                                "aria-selected": `${
                                                  _vm.selectedUser
                                                    .targetAltairCode ===
                                                  item.targetAltairCode
                                                }`,
                                                role: "row",
                                                "aria-label": `Select row for user ${item.targetUserName}`,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleRowClick(
                                                    item
                                                  )
                                                },
                                                keypress: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  return _vm.handleRowClick(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "td",
                                                { attrs: { role: "cell" } },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.targetUserName)
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { attrs: { role: "cell" } },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.targetLionEmail)
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { attrs: { role: "cell" } },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "w-5" },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                _vm.selectedUser
                                                                  .targetAltairCode ===
                                                                item.targetAltairCode,
                                                              expression:
                                                                "\n                        selectedUser.targetAltairCode ===\n                        item.targetAltairCode\n                      ",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "checkbox-icon rounded-circle font-size--18 font-weight--600",
                                                          attrs: {
                                                            color: "primary",
                                                          },
                                                        },
                                                        [_vm._v("mdi-check")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      _c("tr", {
                                        staticClass: "table-bottom-border",
                                      }),
                                    ],
                                    2
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          800702784
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-space-between py-5 px-6" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "font-weight--600",
                      attrs: { outlined: "", rounded: "" },
                      on: { click: _vm.closeModal },
                    },
                    [_vm._v(_vm._s(_vm.$t("General.Btns.Close")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "font-weight--600",
                      attrs: {
                        rounded: "",
                        color: "primary",
                        depressed: "",
                        disabled: !_vm.selectedUser.targetLionEmail,
                      },
                      on: { click: _vm.addUserOnBehalfOf },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("General.Btns.Add")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }