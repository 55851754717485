<template>
  <HeaderGrid />
</template>

<script>
import HeaderGrid from './HeaderGrid.vue'

export default {
  name: 'GridHeader',
  components: {
    HeaderGrid,
  },
}
</script>
