var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VBtn",
    {
      staticClass: "light-border white",
      attrs: {
        icon: "",
        small: "",
        role: "img",
        "aria-label": "Job added to timesheet, not an action button",
      },
    },
    [
      _c("img", {
        attrs: {
          src: require("@/assets/icons/doc-check.svg"),
          alt: "Job added to timesheet",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }