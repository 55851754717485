var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "alert-wrapper" },
    _vm._l(_vm.alertWeekErrors, function (error, i) {
      return _c(
        "div",
        { key: i },
        [
          _c(
            "v-alert",
            {
              staticClass: "transition-swing",
              attrs: {
                value: !!_vm.alertWeekErrors.length,
                dark: "",
                type: _vm.type,
                transition: "scale-transition",
                "min-width": "300",
                "max-width": "500",
                dismissible: "",
              },
            },
            [_vm._v(" " + _vm._s(error.error) + " ")]
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }