<template>
  <div class="mb-10 manage-pref-setting-select" v-if="!copyRestOfWeekSelected">
    <div class="d-flex">
      <v-label
        class="font-weight--600 font-size--12 d-flex align-center gap-2 flex-grow-1"
        for="locationPreferenceSelect"
        :aria-label="`Set this location options menu: selected ${selectedValue}`"
        :aria-live="isUpdated ? 'polite' : 'off'"
        >{{
          $t('timesheetUI.EntryFormLabels.TimesheetEntryLocationSettingsLabel')
        }}
      </v-label>
      <LearnMore />
    </div>
    <div class="d-flex align-center gap-2 mt-2">
      <v-select
        id="locationPreferenceSelect"
        role="listbox"
        class="mt-0"
        :items="$t('timesheetUI.EntryFormLabels.TimesheetWeeklySettings')"
        item-text="text"
        dense
        outlined
        :height="28"
        :aria-label="`Set this location options menu: selected ${selectedValue}`"
        v-model="selectedValue"
        :menu-props="{
          offsetY: true,
        }"
        hide-details
        append-icon="mdi-chevron-down"
        @change="handleUpdate"
        @keydown.up="announceChange"
        @keydown.down="announceChange"
      >
      </v-select>
      <div aria-live="polite" aria-atomic="true" class="sr-only">
        {{ selectedValue }}
      </div>

      <!-- <v-btn
        rounded
        elevation="0"
        :class="[
          'font-size--14 font-weight--600 px-2',
          { is__updated: isUpdated },
        ]"
        height="28"
        :disabled="isUpdated || savingSettings"
        @click="handleUpdate"
        v-if="selectedValue !== 'This entry only'"
        role="button"
        :aria-label="`Update location for ${selectedValue}`"
        :aria-live="isUpdated ? 'polite' : 'off'"
      >
        <v-icon size="16" class="mr-1" v-if="isUpdated"
          >mdi-check-circle-outline</v-icon
        >
        <v-icon size="16" class="mr-1" v-else>mdi-autorenew</v-icon>

        {{ isUpdated ? 'Updated' : 'Update' }}
      </v-btn> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import LearnMore from '../../learn-more/LocationLearnMore.vue'

export default {
  name: 'TimesheetManagePreferenceLocationSelect',
  components: { LearnMore },
  props: ['shouldUpdateLocation'],
  data() {
    return {
      selectedValue: '',
      isUpdated: false,
      docState: 'saved',
    }
  },
  watch: {
    'timesheetDetail.locationCode': {
      deep: true,
      immediate: true,
      handler() {
        this.updateButtonStatus()
      },
    },
    selectedValue(_, newVal) {
      if (newVal && newVal.length > 0) {
        this.setHasTimeEntryChanged({ e: true, data: null })
      }
      this.updateButtonStatus()
      // this.handleUpdate()
    },
    userSettings: {
      deep: true,
      handler() {
        this.updateButtonStatus()
      },
    },
    shouldUpdateLocation() {
      this.handleUpdate()
    },
  },
  computed: {
    ...mapState('storeTimesheets', [
      'timesheetDetail',
      'timesheetSelectedJob',
      'userData',
      'savingSettings',
      'userSettings',
      'copyRestOfWeekSelected',
      'doLocationWFHSaveAction',
    ]),
    ...mapGetters('storeTimesheets', [
      'selectedDay',
      'getUserSettingByDay',
      'getUserSettingByWeek',
      'selectedDate',
    ]),
    defaultValue() {
      if (
        this.getUserSettingByDay?.location === this.timesheetDetail.locationCode
      ) {
        return 'Full day'
      }

      if (
        this.getUserSettingByWeek?.location ===
        this.timesheetDetail.locationCode
      ) {
        return 'Full week'
      }

      return 'This entry only'
    },
  },
  methods: {
    ...mapActions('storeTimesheets', ['saveUserSettings']),
    ...mapMutations('storeTimesheets', [
      'setPendingUserLocationSettings',
      'setHasTimeEntryChanged',
    ]),
    updateButtonStatus() {
      if (
        this.selectedValue === 'Full day' &&
        this.getUserSettingByDay?.location === this.timesheetDetail.locationCode
      ) {
        return (this.isUpdated = true)
      }

      if (
        this.selectedValue === 'Full week' &&
        this.getUserSettingByWeek?.location ===
          this.timesheetDetail.locationCode
      ) {
        return (this.isUpdated = true)
      }

      this.isUpdated = false
    },
    handleUpdate() {
      if (
        !this.timesheetDetail.locationCode ||
        this.selectedValue === 'This entry only'
      ) {
        this.setPendingUserLocationSettings(null)
        return
      }

      let payload = {
        altairCode: this.userData.altairCode,
        year: this.selectedDate.year,
        week: this.selectedDate.weekOfYear,
      }

      if (this.selectedValue === 'Full week') {
        if (this.getUserSettingByWeek) {
          payload = {
            ...payload,
            ...this.getUserSettingByWeek,
          }
        }
        payload = {
          ...payload,
          location: this.timesheetDetail.locationCode,
          isLocationFullDay: false,
          isLocationRestOfWeek: true,
          isWfhFullDay: false,
          isWfhRestOfWeek: true,
        }
      }

      if (this.selectedValue === 'Full day') {
        if (this.getUserSettingByDay) {
          payload = {
            ...payload,
            ...this.getUserSettingByDay,
          }
        }

        payload = {
          ...payload,
          location: this.timesheetDetail.locationCode,
          date: this.timesheetSelectedJob.date,
          isLocationFullDay: true,
          isLocationRestOfWeek: false,
          isWfhFullDay: true,
          isWfhRestOfWeek: false,
        }
      }

      this.setPendingUserLocationSettings({ settings: [payload] })
      // this.saveUserSettings({ settings: [payload] })

      // this.setLocationWFHSaveAction({
      //   type: 'location',
      //   action: payload,
      // })
    },
  },
  destroyed() {
    this.setPendingUserLocationSettings(null)
  },
  created() {
    this.selectedValue = this.defaultValue
  },
  announceChange() {
    const region = this.$refs.announceRegion
    region.textContent = this.selectedValue
  },
}
</script>

<style scoped lang="scss">
.manage-pref-setting-select::v-deep {
  .v-select {
    font-size: 14px !important;
    .v-input__slot {
      min-height: auto !important;
      padding: 0 8px !important;
    }
    .v-input__append-inner {
      margin-top: 2px !important;
      .v-input__icon {
        min-width: 12px !important;
        width: 12px !important;
      }
    }
  }
  .v-btn {
    &.v-btn--disabled {
      &.is__updated {
        background-color: #ebfaed !important;
        color: $primary !important;
      }
    }
    .v-icon {
      color: $primary !important;
    }
  }
}
</style>
