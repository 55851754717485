var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.savingTimesheet
    ? _c(
        "div",
        [
          _c("v-data-table", {
            staticClass: "timesheet-entry-table",
            attrs: {
              items: _vm.timesheetExists,
              headers: _vm.headers,
              "hide-default-footer": "",
              "footer-props": {
                "items-per-page-text": _vm.$t("General.RowsPerPage"),
                "items-per-page-options": [500],
              },
              "item-class": _vm.getTimesheetStatusClass,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: `item.durationValue`,
                  fn: function ({ item }) {
                    return [
                      item.timesheetStatus === "Approved" ||
                      item.isClarizenAssignedProject ||
                      (item.isSelfCorrected && item.selfCorrectedHours < 0)
                        ? _c("div", { staticClass: "pl-2" }, [
                            item.isPercentageEntry
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(item.durationPercentageValue) +
                                      " "
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(
                                    " " + _vm._s(item.durationValue) + " "
                                  ),
                                ]),
                          ])
                        : _c("div", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "timesheet-entry-table__duration-input",
                              },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    rules: _vm.timesheetDetailFeatures
                                      ? "required|durationValidation"
                                      : "",
                                    name: _vm.$t(
                                      "timesheetUI.EntryFormLabels.TimesheetEntryDuration"
                                    ),
                                    vid: item.id,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-center",
                                              },
                                              [
                                                item.isPercentageEntry
                                                  ? _c("v-text-field", {
                                                      staticClass:
                                                        "my-2 mini-input",
                                                      attrs: {
                                                        dense: "",
                                                        outlined: "",
                                                        "full-width": "",
                                                        "hide-details":
                                                          errors.length === 0,
                                                        "error-messages":
                                                          errors,
                                                        disabled:
                                                          _vm.savingTimesheet ||
                                                          item.restrictedPostEntry,
                                                        height: "30px",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          return _vm.setTableToSave(
                                                            item
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          item.durationPercentageValue,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "durationPercentageValue",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.durationPercentageValue",
                                                      },
                                                    })
                                                  : _c("v-text-field", {
                                                      staticClass:
                                                        "my-2 mini-input",
                                                      attrs: {
                                                        dense: "",
                                                        outlined: "",
                                                        "full-width": "",
                                                        "hide-details":
                                                          errors.length === 0,
                                                        "error-messages":
                                                          errors,
                                                        disabled:
                                                          _vm.savingTimesheet ||
                                                          item.restrictedPostEntry,
                                                        height: "30px",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          return _vm.setTableToSave(
                                                            item
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          item.durationValue,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "durationValue",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.durationValue",
                                                      },
                                                    }),
                                                _c(
                                                  "div",
                                                  { staticClass: "ml-1" },
                                                  [
                                                    item.isPercentageEntry
                                                      ? _c("span", [
                                                          _vm._v("%"),
                                                        ])
                                                      : _c("span", [
                                                          _vm._v("h"),
                                                        ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                          ]),
                    ]
                  },
                },
                {
                  key: `item.workCodeName`,
                  fn: function ({ item }) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            item.jobTitleForBilling
                              ? `${item.jobTitleForBilling} - ${item.jobTitleForBillingName}`
                              : `${item.workCode} - ${item.workCodeName}`
                          )
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: `item.activityName`,
                  fn: function ({ item }) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(`${item.activity} - ${item.activityName}`)
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: `item.productReference`,
                  fn: function ({ item }) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            `${item.productReference} - ${item.productReferenceName}`
                          )
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: `item.timesheetStatus`,
                  fn: function ({ item }) {
                    return [
                      item.timesheetStatus === "Unsubmitted"
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("timesheetUI.Status.Unsubmitted"))
                            ),
                          ])
                        : _vm._e(),
                      item.timesheetStatus === "Submitted"
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("timesheetUI.Status.Submitted"))
                            ),
                          ])
                        : _vm._e(),
                      item.timesheetStatus === "Approved"
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("timesheetUI.Status.Approved"))
                            ),
                          ])
                        : _vm._e(),
                      item.timesheetStatus === "Rejected"
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("timesheetUI.Status.Rejected"))
                            ),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: `item.details`,
                  fn: function ({ item }) {
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            outlined: "",
                            small: "",
                            color: "primary",
                            "aria-label": `Show details for time entry ${_vm.findItemIndex(
                              item
                            )}`,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.openTimesheetDetail(item)
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("timesheetUI.Btns.BtnShowDetail")) +
                              " "
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          }),
          _c("div", { staticClass: "d-flex mt-5 mb-4 ml-5" }, [
            _c("div", [_vm._v(_vm._s(_vm.timesheetTotals))]),
            _c("div", [
              _vm.timesheetExists[0].isPercentageEntry
                ? _c("span", [_vm._v("%")])
                : _c("span", [_vm._v("h")]),
            ]),
            _c("div", { staticClass: "ml-5" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("timesheetUI.HeaderCalendar.LabelTotal2")) +
                  " "
              ),
            ]),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }