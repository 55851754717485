var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dialog
    ? _c(
        "v-dialog",
        {
          attrs: { "max-width": "660px" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "FocusLock",
            { attrs: { returnFocus: "" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass:
                        "d-flex justify-space-between align-start pa-4",
                    },
                    [
                      _c("div", [
                        _c(
                          "div",
                          { staticClass: "h1 mb-1 font-color--primary" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "timesheetUI.ValidationMessages.SubmitConfirmTitle"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "h5" }, [
                          _vm.submittedData.isPercentage
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "timesheetUI.ValidationMessages.SubmitConfirmMetaPercent",
                                        {
                                          hours: _vm.submittedData.totalHours,
                                          date: _vm.submittedData.submittedDate,
                                        }
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "timesheetUI.ValidationMessages.SubmitConfirmMeta",
                                        {
                                          hours: _vm.submittedData.totalHours,
                                          date: _vm.submittedData.submittedDate,
                                        }
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                        ]),
                      ]),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", "aria-label": "Close modal" },
                          on: {
                            click: function ($event) {
                              _vm.dialog = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  !_vm.isTemporaryExternalEmployee ? _c("v-divider") : _vm._e(),
                  !_vm.isTemporaryExternalEmployee
                    ? _c(
                        "v-card-text",
                        { staticClass: "px-4" },
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", [
                                _c(
                                  "div",
                                  { staticClass: "font-color--primary" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "timesheetUI.ValidationMessages.SubmitConfirmMessage"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    { staticClass: "justify-end pa-4" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-auto px-4",
                          attrs: {
                            color: "primary",
                            depressed: "",
                            rounded: "",
                            outlined: "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.dialog = false
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("General.Btns.Close")) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }