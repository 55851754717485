var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dialogShow
    ? _c(
        "v-dialog",
        {
          attrs: { width: "665" },
          model: {
            value: _vm.dialogShow,
            callback: function ($$v) {
              _vm.dialogShow = $$v
            },
            expression: "dialogShow",
          },
        },
        [
          _c(
            "FocusLock",
            { attrs: { returnFocus: "" } },
            [
              _c(
                "v-card",
                {
                  attrs: {
                    role: "alert",
                    "aria-label": "Alert: There has been an error",
                    "aria-live": "polite",
                    tabindex: "0",
                  },
                },
                [
                  _c(
                    "v-card-title",
                    {
                      class: [
                        "justify-space-between align-start py-5 px-6",
                        `${_vm.dialogAlertType}--text`,
                      ],
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex-grow-1 d-flex flex-column" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "text-h2 mb-2 d-flex justify-space-between",
                            },
                            [
                              _vm.dialogAlertTitle
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.dialogAlertTitle)),
                                  ])
                                : _vm._e(),
                              _vm.dialogAlertType && !_vm.dialogAlertTitle
                                ? _c("span", [
                                    _vm.dialogAlertType.toLowerCase() ===
                                    "error"
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "timesheetUI.ErrorDialog.ErrorTitle"
                                              )
                                            )
                                          ),
                                        ])
                                      : _vm.dialogAlertType.toLowerCase() ===
                                        "success"
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "timesheetUI.ErrorDialog.SuccessTitle"
                                              )
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    icon: "",
                                    "aria-label": "Close modal",
                                  },
                                  on: { click: _vm.closeDialog },
                                },
                                [_c("v-icon", [_vm._v("mdi-close")])],
                                1
                              ),
                            ],
                            1
                          ),
                          !_vm.dialogAlertSubTitle
                            ? _c(
                                "div",
                                { staticClass: "h5 font-weight--400" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "timesheetUI.ErrorDialog.DialogSubtitle"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _c("div", {
                                staticClass: "h5 font-weight--400",
                                domProps: {
                                  innerHTML: _vm._s(_vm.dialogAlertSubTitle),
                                },
                              }),
                        ]
                      ),
                    ]
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticClass: "py-5 px-6" },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { md: "12" } }, [
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(_vm.dialogAlertMessage),
                              },
                            }),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    { staticClass: "py-5 px-6" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            rounded: "",
                            outlined: "",
                            width: "90",
                            height: "40",
                          },
                          on: { click: _vm.closeDialog },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("General.Btns.Close")) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }