var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("GridRow", {
        scopedSlots: _vm._u(
          [
            {
              key: "col1",
              fn: function () {
                return [
                  _c(
                    "GridCell",
                    { attrs: { id: "sortDropdownDontainer" } },
                    [_c("JobsSortDropdown")],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            _vm._l(_vm.calendar, function (day) {
              return {
                key: day.dayName.toLowerCase(),
                fn: function () {
                  return [
                    _c(
                      "GridCell",
                      {
                        key: day.dayName,
                        class: `text-h6 text-center ${
                          day.isHoliday && !_vm.agencyData.enableAbsenceModule
                            ? "info"
                            : ""
                        }`,
                      },
                      [
                        _c("div", { staticClass: "d-flex justify-center" }, [
                          _c(
                            "div",
                            {
                              class: {
                                "today-header-cell": _vm.isToday(day),
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(day.localeDayName) +
                                  " " +
                                  _vm._s(day.day) +
                                  " "
                              ),
                              day.isHoliday &&
                              !_vm.agencyData.enableAbsenceModule
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "font-size--10 font-weight-regular mt-1",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "timesheetUI.HeaderCalendar.LabelHoliday"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]
                },
                proxy: true,
              }
            }),
            {
              key: "total",
              fn: function () {
                return [
                  _c("GridCell", { staticClass: "text-h6 text-center" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("timesheetUI.HeaderCalendar.LabelTotal")
                        ) + " "
                      ),
                    ]),
                    _vm.agencyData.isPercentageAgency
                      ? _c("span", [
                          _vm.$vuetify.breakpoint.smAndDown
                            ? _c("span", [_vm._v("%")])
                            : _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "timesheetUI.HeaderCalendar.LabelPercentage"
                                    )
                                  )
                                ),
                              ]),
                        ])
                      : _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("timesheetUI.HeaderCalendar.LabelHours")
                            )
                          ),
                        ]),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }