<template>
  <div class="">
    <div aria-live="assertive" class="sr-only" v-text="liveRegionText"></div>
    <div ref="errorContainer"></div>
    <v-row>
      <v-col :cols="isTimeEntryDrawerExpanded ? 6 : 12" class="pb-0">
        <!-- Percentage User Duration -->
        <div
          v-if="
            timesheetDetailFeatures.percentageOrDuration.data.isPercentageAgency
          "
        >
          <v-label
            v-if="
              timesheetDetailFeatures.percentageOrDuration.data
                .isPercentageAgency
            "
            for="durationInput"
            >{{ $t('timesheetUI.EntryFormLabels.TimesheetEntryPercentLabel') }}
            <span class="required">*</span></v-label
          >
          <v-label v-else for="durationInput"
            >{{ $t('timesheetUI.EntryFormLabels.TimesheetEntryDuration') }}
            <span class="required">*</span></v-label
          >

          <validation-provider
            v-slot="{ errors }"
            rules="required|durationValidation"
            name="DurationField"
          >
            <v-text-field
              id="durationInput"
              outlined
              dense
              autofocus
              placeholder="Duration"
              ref="timeEntryInput"
              v-model="timesheetDetail.durationPercentageValue"
              :error-messages="errors"
              aria-describedby="durationInputError"
              @focus="handleDurationFocus(errors)"
            >
              <template v-slot:message="{ message }">
                <div aria-live="polite" id="durationInputError">
                  {{ message }}
                </div>
              </template>
            </v-text-field>
          </validation-provider>
        </div>

        <!-- Non Percentage User Duration -->
        <div v-else>
          <v-label for="durationInput"
            >{{ $t('timesheetUI.EntryFormLabels.TimesheetEntryDuration') }}
            <span class="required">*</span></v-label
          >
          <validation-provider
            v-slot="{ errors }"
            rules="required|durationValidation"
            name="DurationField"
          >
            <v-text-field
              id="durationInput"
              outlined
              aria-required="true"
              dense
              autofocus
              placeholder="Duration"
              ref="timeEntryInput"
              v-model="timesheetDetail.durationValue"
              :error-messages="errors"
              aria-describedby="durationInputError"
              @focus="handleDurationFocus(errors)"
            >
              <template v-slot:message="{ message }">
                <div aria-live="polite" id="durationInputError">
                  {{ message }}
                </div>
              </template>
            </v-text-field>
          </validation-provider>
        </div>

        <!-- Location -->
        <div
          v-if="timesheetDetailFeatures.location.required"
          id="locationInput-container"
          class="attached-dropdown"
        >
          <v-label for="locationInput"
            >{{
              $t('timesheetUI.EntryFormLabels.TimesheetEntryLabelLocations')
            }}
            <span class="required">*</span></v-label
          >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Location"
          >
            <v-autocomplete
              role="combobox"
              id="locationInput"
              attach="#locationInput-container"
              ref="locationInput"
              aria-required="true"
              :menu-props="{
                offsetY: true,
                nudgeBottom: 28,
                contentClass: 'location-list-indented',
              }"
              outlined
              dense
              :filter="customFilter"
              :items="timesheetDetailFeatures.location.data.locations"
              v-model="locationInLocal"
              item-text="label"
              item-value="value"
              :placeholder="
                $t('timesheetUI.EntryFormLabels.TimesheetEntryLabelLocations')
              "
              :error-messages="errors"
              @change="handleLocation"
              @keydown="arrowKeyHandler"
              clearable
              @focus="handleLocationAnnounce(errors)"
            ></v-autocomplete>
          </validation-provider>
        </div>

        <!-- Rest of the week/ Full Day -->
        <timesheet-manage-preference-location-select
          v-if="
            timesheetDetailFeatures.location.required && !isMidWeekAgencyChange
          "
          :should-update-location="updateLocationSettings"
        />

        <!-- WorkCode -->
        <div
          v-if="!isHideTimesheetWorkcode"
          id="workcodeInput-container"
          class="attached-dropdown"
        >
          <v-label for="workcodeInput"
            >{{
              $t('timesheetUI.EntryFormLabels.TimesheetEntryLabelWorkcode')
            }}
            <span class="required">*</span></v-label
          >
          <v-text-field
            id="workcodeInput"
            v-if="
              selectedPPMTask.jobTitleForBilling ||
              timesheetDetail.disablePPMSelect ||
              (agencyData.isRigidAgency &&
                timesheetDetailFeatures.workcode.data.workCodes.length === 1)
            "
            :value="computedWorkCodeText"
            outlined
            dense
            disabled
            aria-describedby="workcodeInputError"
          >
            <template v-slot:message="{ message }">
              <div aria-live="polite" id="workcodeInputError">
                {{ message }}
              </div>
            </template>
          </v-text-field>

          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Workcode"
            v-else
          >
            <v-autocomplete
              role="listbox"
              id="workcodeInput"
              ref="workcodeInput"
              attach="#workcodeInput-container"
              :menu-props="{
                offsetY: true,
                nudgeBottom: 28,
                allowOverflow: true,
              }"
              outlined
              dense
              v-model="timesheetDetail.workCode"
              @change="loadBillableIndicators(timesheetDetail.workCode)"
              :items="workCodeItems"
              :error-messages="errors"
              item-text="label"
              item-value="value"
              placeholder="Workcode"
              :disabled="disabledWorkCode"
              @keydown="arrowKeyHandler"
              clearable
              @focus="announceError(errors)"
            ></v-autocomplete>
          </validation-provider>
        </div>
      </v-col>
      <v-col
        :cols="isTimeEntryDrawerExpanded ? 6 : 12"
        :class="['pb-0', { 'pt-0': !isTimeEntryDrawerExpanded }]"
      >
        <!-- Billable Indicator -->
        <div id="billableIndicator-container" class="attached-dropdown">
          <v-label
            >{{ $t('timesheetUI.EntryFormLabels.TimesheetEntryLabelBI') }}
            <span class="required">*</span></v-label
          >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Billable Indicator"
          >
            <v-combobox
              role="listbox"
              id="billableIndicator"
              ref="billableIndicator"
              attach="#billableIndicator-container"
              :menu-props="{
                offsetY: true,
                nudgeBottom: 28,
              }"
              aria-required="true"
              outlined
              dense
              :items="timesheetDetailBillableIndicators"
              v-model="timesheetDetail.billableIndicator"
              item-text="label"
              placeholder="Billable Indicator"
              :error-messages="errors"
              :loading="loadingBI"
              return-object
              :disabled="
                isTimesheetApproved ||
                timesheetDetailBillableIndicators.length <= 1 ||
                timesheetDetail.disablePPMSelect ||
                disablePPMWorkcode
              "
              @keydown="arrowKeyHandler"
              @focus="announceError(errors)"
            ></v-combobox>
          </validation-provider>
        </div>

        <!-- Work from home -->
        <div
          v-if="
            timesheetDetailFeatures.location.required &&
            getActiveAgencyData(timesheetSelectedJob.date).isWFHActive
          "
          id="wfhInput-container"
          class="attached-dropdown"
        >
          <v-label for="wfhInput"
            >{{ $t('timesheetUI.EntryFormLabels.TimesheetEntryWfhLabel') }}
            <span class="required">*</span></v-label
          >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Work From Home"
          >
            <v-select
              role="listbox"
              id="wfhInput"
              ref="wfhInput"
              aria-required="true"
              outlined
              dense
              attach="#wfhInput-container"
              :items="wfhOptions"
              v-model="timesheetDetail.wfh"
              item-text="label"
              item-value="value"
              :placeholder="
                $t('timesheetUI.EntryFormLabels.TimesheetEntryWfhLabel')
              "
              :error-messages="
                (errors &&
                  errors.length > 0 && [
                    $t('timesheetUI.ValidationMessages.WorkFromHome'),
                  ]) ||
                []
              "
              @keydown="arrowKeyHandler"
              :menu-props="{
                nudgeBottom: 28,
                offsetY: true,
                bottom: true,
              }"
              @keyup.down="$event.target.click()"
              @keyup.up="$event.target.click()"
              @focus="announceError(errors)"
              @change="handlewFHChange"
            ></v-select>
          </validation-provider>
        </div>

        <!-- Rest of the week/ Full Day -->
        <timesheet-manage-preference-wfh-select
          v-if="
            timesheetDetailFeatures.location.required &&
            getActiveAgencyData(timesheetSelectedJob.date).isWFHActive &&
            !isMidWeekAgencyChange
          "
          :should-update-wfh="updateWFHSettings"
        />

        <!-- Comment -->
        <div>
          <v-label for="commentInput">{{
            $t('timesheetUI.EntryFormLabels.TimesheetEntryHoursLabelComments')
          }}</v-label>
          <validation-provider
            v-slot="{ errors }"
            rules="maxComment"
            name="Comment"
          >
            <v-text-field
              id="commentInput"
              outlined
              dense
              :placeholder="
                $t(
                  'timesheetUI.EntryFormLabels.TimesheetEntryHoursLabelCommentsPlaceholder'
                )
              "
              v-model="timesheetDetail.comment"
              :error-messages="errors"
              counter
              maxlength="125"
              aria-describedby="commentInputError"
              @focus="announceError(errors)"
            >
              <template v-slot:message="{ message }">
                <div aria-live="polite" id="commentInputError">
                  {{ message }}
                </div>
              </template>
            </v-text-field>
          </validation-provider>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { mapActions, mapState, mapGetters } from 'vuex'
import TimesheetManagePreferenceLocationSelect from './TimesheetManagePreferenceLocationSelect.vue'
import TimesheetManagePreferenceWfhSelect from './TimesheetManagePreferenceWfhSelect.vue'
import {
  arrowKeyHandler as arrowKeyHandlerClick,
  handleArrowKeys,
} from '@Shared/filters'
import i18n from '@Shared/i18n'
import { uniqBy, isEmpty, debounce } from 'lodash'
import { announceErrorHandler } from '@Shared/announceError'

export default {
  components: {
    ValidationProvider,
    TimesheetManagePreferenceLocationSelect,
    TimesheetManagePreferenceWfhSelect,
  },
  data() {
    return {
      locationSettings: null,
      WfhSetting: null,
      locationInLocal: null,
      wfhOptions: [
        {
          label: i18n.t(
            'timesheetUI.EntryFormLabels.TimesheetEntryWfhYes',
            window.CULTURE
          ),
          value: true,
        },
        {
          label: i18n.t(
            'timesheetUI.EntryFormLabels.TimesheetEntryWfhNo',
            window.CULTURE
          ),
          value: false,
        },
      ],
      liveRegionText: '',
      updateLocationSettings: null,
      updateWFHSettings: null,
    }
  },
  watch: {
    'timesheetDetail.location': {
      handler() {
        this.checkLocationExists()
      },
    },
  },
  computed: {
    ...mapState('storeTimesheets', [
      'timesheetDetail',
      'timesheetDetailBillableIndicators',
      'isTimeEntryDrawerExpanded',
      'timesheetDetailFeatures',
      'loadingBI',
      'agencyData',
      'selectedPPMTask',
      'timesheetSelectedJob',
      'isTimesheetBeingCorrected',
      'isTimesheetBeingReviewed',
    ]),
    ...mapGetters('storeTimesheets', [
      'isHideTimesheetWorkcode',
      'isSelfAssignmentv1_4',
      'isAllowPTOWorkCode',
      'isTimesheetApproved',
      'isMidWeekAgencyChange',
      'getActiveAgencyData',
    ]),
    computedWorkCodeText() {
      if (this.timesheetDetail.jobTitleForBilling) {
        return `${this.timesheetDetail.jobTitleForBilling} - ${this.timesheetDetail.jobTitleForBillingName}`
      }

      if (this.timesheetDetail.workCode) {
        return `${this.timesheetDetail.workCode} - ${this.timesheetDetail.workCodeName}`
      }

      return i18n.t(
        'timesheetUI.EntryFormLabels.TimesheetEntryLabelWorkcode',
        window.CULTURE
      )
    },
    disabledWorkCode: {
      cache: false,
      get() {
        // below condition added for house job

        if (this.isTimesheetApproved) {
          return true
        }

        if (
          (this.timesheetDetail.brandName === 'HOUSE' ||
            this.timesheetDetail.jobGroupName === 'House / Admin') &&
          this.timesheetDetail.timesheetStatus !== 'Submitted'
        ) {
          return false
        }
        if (
          (this.timesheetDetail.brandName === 'HOUSE' ||
            this.timesheetDetail.jobGroupName === 'House / Admin') &&
          this.timesheetDetail.timesheetStatus === 'Submitted'
        ) {
          return true
        }

        if (this.timesheetDetailFeatures.workcode.data.workCodes.length === 1) {
          return true
        }

        if (this.timesheetDetailFeatures.workcode.data.workCodes.length > 1) {
          return !!this.timesheetDetailFeatures.workcode.data.workCodes.find(
            (workcode) => workcode.selected
          )
        }

        if (this.agencyData.isRigidAgency) {
          //for rigid agency
          if (
            !this.timesheetSelectedJob.job.isClarizenTask ||
            !this.timesheetSelectedJob.job.selfAssignmentWithManageTasking
          ) {
            return true
          }
          if (!this.selectedPPMTask) {
            // non assigned project
            return true
          } else {
            //for assigned project
            return true
          }
        } else {
          //for non rigid agency
          if (!this.selectedPPMTask) {
            // non assigned project
            if (this.selectedPPMTask.jobTitleForBilling) {
              return true
            }
          } else {
            //for assigned project
            if (
              this.selectedPPMTask.resourceDetails &&
              !this.timesheetDetailFeatures.clarizenCustomTask.required &&
              !this.isSelfAssignmentv1_4
            ) {
              return true
            } else if (this.selectedPPMTask.jobTitleForBilling) {
              return true
            }
          }
        }
        return false
      },
    },
    workCodeItems: {
      cache: false,
      get() {
        if (this.disabledWorkCode) {
          if (this.selectedPPMTask && !isEmpty(this.selectedPPMTask)) {
            return [
              ...this.timesheetDetailFeatures.workcode.data.workCodes,
              {
                label: `${this.selectedPPMTask.workCode} - ${this.selectedPPMTask.workCodeName}`,
                value: this.selectedPPMTask.workCode,
                selected: true,
                country: '',
              },
            ]
          } else if (this.timesheetDetail.workCode) {
            return [
              ...this.timesheetDetailFeatures.workcode.data.workCodes,
              {
                label: `${this.timesheetDetail.workCode} - ${this.timesheetDetail.workCodeName}`,
                value: this.timesheetDetail.workCode,
                selected: true,
                country: '',
              },
            ]
          } else {
            return this.timesheetDetailFeatures.workcode.data.workCodes
          }
        } else {
          return this.timesheetDetailFeatures.workcode.data.workCodes
        }
      },
    },
    disablePPMWorkcode: {
      cache: false,
      get() {
        if (
          this.timesheetSelectedJob.job?.isClarizenTask ||
          this.timesheetSelectedJob.job?.isClarizenAssignedProject ||
          this.timesheetSelectedJob.job?.selfAssignmentWithManageTasking
        ) {
          return true
        }
        return false
      },
    },
  },
  methods: {
    ...mapActions('storeTimesheets', [
      'loadBillableIndicators',
      'loadValidationLogic',
      'getAvailableFeaturesByLocation',
      'loadWorkcodeandBillableIndicators',
    ]),
    handleDurationFocus(errors) {
      this.$refs.timeEntryInput.$el.querySelector('input').select()
      this.$emit('focus')
      this.announceError(errors)
    },
    announceError(errors) {
      announceErrorHandler(errors[0], this.$refs.errorContainer)
    },
    handleLocationAnnounce(errors) {
      setTimeout(() => {
        const infoMsg = document.querySelector('.time-entry__info-message')

        if (infoMsg) {
          infoMsg.setAttribute('aria-live', 'polite')
          infoMsg.setAttribute('role', 'alert')
        }
      }, 2000)

      this.announceError(errors)
    },
    handleLocation($e) {
      const selectedLocation =
        this.timesheetDetailFeatures.location.data.locations.find(
          (location) => location.value === $e
        )
      this.timesheetDetail.location = selectedLocation?.label
      this.timesheetDetail.countryCode = selectedLocation?.countryCode
      this.timesheetDetail.locationLabel = selectedLocation?.label
      this.timesheetDetail.locationCode = selectedLocation?.value
      if (this.isAllowPTOWorkCode) {
        this.timesheetDetail.workCode = null
        this.getAvailableFeaturesByLocation(this.timesheetDetail.location)
      }

      this.updateLocationSettings = Date.now()
    },
    handlewFHChange() {
      this.updateWFHSettings = Date.now()
    },
    arrowKeyHandler: debounce(function ($e) {
      arrowKeyHandlerClick($e)

      handleArrowKeys(this, $e, [
        'workCodeInput',
        'billableIndicator',
        'locationInput',
        'wfhInput',
      ])
    }, 300),
    customFilter(item, queryText, itemText) {
      if (item.header) {
        const results = uniqBy(
          this.timesheetDetailFeatures.location.data.locations.filter(
            (a) =>
              a.label &&
              a.label
                .toLocaleLowerCase()
                .indexOf(queryText.toLocaleLowerCase()) > -1
          ),
          'country'
        ).map((a) => a.country)
        return results?.includes(item.header)
      }

      return (
        itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      )
    },
    checkLocationExists() {
      if (this.timesheetDetailFeatures.location?.data?.locations) {
        const locations = this.timesheetDetailFeatures.location.data.locations

        const found = locations.find((a) => {
          if (
            this.timesheetDetail.locationCode === a.value &&
            this.timesheetDetail.countryCode === a.countryCode
          ) {
            return a
          }
        })

        if (!found) {
          locations.push({
            country: null,
            label: this.timesheetDetail.location,
            value: this.timesheetDetail.locationCode,
            selected: true,
            countryCode: this.timesheetDetail.countryCode,
          })
        }

        if (this.timesheetDetail?.locationCode?.length > 0) {
          this.locationInLocal = this.timesheetDetail.locationCode
        }
      }
    },
  },
  mounted() {
    this.loadValidationLogic()
    this.checkLocationExists()
    this.loadWorkcodeandBillableIndicators()
  },
}
</script>

<style lang="scss" scoped>
.attached-dropdown::v-deep {
  position: relative;
}
</style>
