<template>
  <v-expansion-panels flat multiple v-model="panels">
    <template>
      <v-expansion-panel
        v-for="header in panelHeader"
        :key="`row-${header}`"
        class="job-group--panel"
      >
        <v-expansion-panel-header hide-actions :class="`h3 font-weight--600`">
          <template v-slot:default="{ open }">
            <v-icon
              :size="30"
              class="mr-2"
              color="primary"
              :style="{ transform: open ? 'rotate(90deg)' : 'rotate(0)' }"
              >mdi-chevron-right</v-icon
            >
            <p class="font-size--18 font-weight--600 width--100 mb-0">
              {{ $t('timesheetUI.JobGroupPrefix') }}
              {{ header }}
            </p>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <JobRowGroup>
            <template v-for="(timesheetJob, i) in jobList">
              <JobRow
                :key="`row-${header}-${timesheetJob.id}`"
                v-if="timesheetJob.jobGroupName === header"
              >
                <template #col1>
                  <GridCell>
                    <div class="d-flex justify-space-between align-center">
                      <div>
                        <div
                          v-if="showFocusIcon(timesheetJob)"
                          class="drawer-indicator"
                        >
                          <v-icon color="accent" size="10">
                            mdi-triangle mdi-rotate-90
                          </v-icon>
                        </div>
                        <div class="break-word">
                          <a
                            class="pa-0 clarizen-custom-task-button mb-1"
                            text
                            plain
                            color="primary"
                            height="auto"
                            :ripple="false"
                            v-if="
                              (timesheetJob.isClarizenTask &&
                                businessRole === 'ROAR PROJECT MANAGER' &&
                                !timesheetJob.selfAssignmentWithManageTasking) ||
                              (timesheetJob.isClarizenAssignedProject &&
                                timesheetJob.isClarizenTask &&
                                !timesheetJob.selfAssignmentWithManageTasking)
                            "
                            @click="
                              gotoClarizenProject(
                                timesheetJob.clarizenProjectId
                              )
                            "
                            @keypress.enter="
                              gotoClarizenProject(
                                timesheetJob.clarizenProjectId
                              )
                            "
                            tabindex="0"
                            :aria-label="`Open: ${timesheetJob.clarizenProjectName}`"
                          >
                            <span class="font-size--14 font-weight--600">{{
                              timesheetJob.clarizenProjectName
                            }}</span>
                          </a>
                          <span
                            v-else
                            tabindex="0"
                            class="primary--text font-size--14 font-weight--600"
                            >{{ timesheetJob.jobName }}</span
                          >

                          <v-icon
                            size="14"
                            color="warning"
                            class="ml-1"
                            v-show="timesheetJob.isFavJob_V2"
                            tabindex="0"
                            aria-label="Job is favourited"
                            aria-hidden="false"
                            >mdi-star-outline</v-icon
                          >
                        </div>

                        <div
                          v-if="
                            timesheetJob.clarizenTaskName ||
                            timesheetJob.taskName
                          "
                          class="break-word"
                        >
                          <span
                            v-if="timesheetJob.clarizenTaskName"
                            class="font-size--10 font-weight--600 accent-light rounded-lg text-uppercase px-2"
                            style="padding-top: 2px; padding-bottom: 2px"
                          >
                            {{ timesheetJob.clarizenTaskName }}
                          </span>

                          <span
                            v-if="timesheetJob.taskName"
                            :class="[
                              'font-size--10 font-weight--600 accent-light rounded-lg text-uppercase px-2',
                              {
                                'ml-1': timesheetJob.clarizenTaskName,
                              },
                            ]"
                            style="padding-top: 2px; padding-bottom: 2px"
                          >
                            {{ timesheetJob.taskName }}
                          </span>
                        </div>

                        <div class="font-size--12">
                          <span>{{ timesheetJob.job_ID }}</span>

                          <!-- <template
                            v-if="
                              timesheetJob.selfAssignmentWithManageTasking ||
                              timesheetJob.isClarizenTask
                            "
                          >
                            <template
                              v-if="
                                timesheetJob.selfAssignmentWithManageTasking
                              "
                            >
                              <span class="mx-1">•</span
                              ><span class="accent--text">
                                {{
                                  $t(
                                    'timesheetUI.TimesheetJobs.SelfAssignmentProjectLabel'
                                  )
                                }}
                              </span>
                            </template>
                          </template> -->

                          <span
                            v-if="timesheetJob.crossCompanyRuleTypeName"
                            class="font-color--primary"
                          >
                            <span class="mx-1">•</span
                            ><span class="accent--text">{{
                              timesheetJob.crossCompanyRuleTypeName
                            }}</span>
                            <v-icon size="12" color="accent">
                              mdi-star-outline
                            </v-icon>
                          </span>
                        </div>

                        <p
                          class="mb-0 font-size--10 warning-dark--text"
                          v-if="timesheetJob.allocationStartDate"
                        >
                          <span
                            v-if="
                              allocationYear(
                                timesheetJob.allocationStartDate
                              ) !== 9999
                            "
                          >
                            {{
                              $t('timesheetUI.HeaderCalendar.AllocationPeriod')
                            }}
                            {{
                              timesheetJob.allocationStartDate.split('T')[0]
                                | formatDateFrontend
                            }}
                            -
                            {{
                              timesheetJob.allocationEndDate.split('T')[0]
                                | formatDateFrontend
                            }}
                          </span>
                          <span v-else>Allocation Period: N/A</span>
                        </p>
                      </div>

                      <v-menu
                        :close-on-content-click="true"
                        :offset-x="true"
                        :aria-label="`Job Row action menu: ${timesheetJob.jobName} - ${timesheetJob.job_ID}`"
                        content-class="job-group__row_menu_container"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <VBtn
                            v-bind="attrs"
                            v-on="on"
                            :id="`job-${timesheetJob.id}`"
                            min-width="28"
                            height="28"
                            class="pa-0 rounded-lg"
                            x-small
                            text
                            color="primary"
                          >
                            <VIcon>mdi-dots-horizontal</VIcon>
                          </VBtn>
                        </template>
                        <v-list dense width="200">
                          <v-list-item
                            class="cursor-pointer primary--text rounded-lg overflow-hidden"
                            v-for="item in getJobRowActions(timesheetJob)"
                            :key="item.value"
                            @click="
                              handleJobRowAction(item.value, timesheetJob, i)
                            "
                            :aria-label="`${item.label}:  ${timesheetJob.jobName} - ${timesheetJob.job_ID}`"
                          >
                            <v-list-item-icon>
                              <v-icon> {{ item.icon }}</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                              <v-list-item-title>{{
                                item.label
                              }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </GridCell>
                </template>

                <template
                  v-for="(day, dayIndex) in calendar"
                  v-slot:[day.dayName.toLowerCase()]
                >
                  <TimesheetCell
                    :key="day.dayName"
                    :day="day"
                    :timesheetJob="timesheetJob"
                    :job-index="i"
                    :day-index="dayIndex"
                    :openActions="openActions"
                  />
                </template>

                <template #total>
                  <GridCell class="job-group--week-total">
                    <div
                      :aria-label="`${$t('General.for')} ${
                        timesheetJob.jobName
                      } - ${$t('timesheetUI.HeaderCalendar.LabelTotal')} ${$t(
                        'timesheetUI.HeaderCalendar.LabelProject'
                      )} ${
                        agencyData.isPercentageAgency
                          ? $t('timesheetUI.HeaderCalendar.LabelPercentage')
                          : $t('timesheetUI.HeaderCalendar.LabelHours')
                      } ${$t('General.is')} ${
                        agencyData.isPercentageAgency
                          ? hoursTotalPerJob[i].percent
                          : hoursTotalPerJob[i].hours
                      }`"
                      md="3"
                      class="d-flex justify-center align-center"
                    >
                      <div>
                        {{
                          agencyData.isPercentageAgency
                            ? hoursTotalPerJob[i].percent
                            : hoursTotalPerJob[i].hours
                        }}
                      </div>
                    </div>
                  </GridCell>

                  <div
                    class="offscreen"
                    :aria-label="`${$t('General.for')} ${
                      timesheetJob.jobName
                    } - ${$t('timesheetUI.HeaderCalendar.LabelTotal')} ${$t(
                      'timesheetUI.HeaderCalendar.LabelProject'
                    )} ${
                      agencyData.isPercentageAgency
                        ? $t('timesheetUI.HeaderCalendar.LabelPercentage')
                        : $t('timesheetUI.HeaderCalendar.LabelHours')
                    } ${$t('General.is')} ${
                      agencyData.isPercentageAgency
                        ? hoursTotalPerJob[i].percent
                        : hoursTotalPerJob[i].hours
                    }`"
                  ></div>
                </template>
              </JobRow>
            </template>
          </JobRowGroup>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </template>
  </v-expansion-panels>
</template>

<script>
import {
  gotoClarizenProject,
  getTimesheets,
  isTimesheetRejected,
  checkRestriction,
  getTimesheetsDuplicate,
  isJobHidden,
} from '@Shared/filters'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import JobRowGroup from './grid/GridRowGroup.vue'
import JobRow from './grid/GridRow.vue'
import GridCell from './grid/GridCell.vue'
import TimesheetCell from './job-group/TimesheetCell.vue'
import moment from 'moment'
import i18n from '@Shared/i18n'
import { DEFAULT_PANELS } from '../utils/jobHelper'

export default {
  name: 'TimesheetJobGroups',
  props: ['openActions'],
  data() {
    return {
      panels: [...DEFAULT_PANELS],
      jobRowActions: [
        {
          label: i18n.t('timesheetUI.JobOptions.RemoveJob', window.CULTURE),
          value: 'remove',
          icon: 'mdi-trash-can-outline',
        },
        {
          label: i18n.t('timesheetUI.JobOptions.FavoriteJob', window.CULTURE),
          value: 'favorite',
          icon: 'mdi-star-outline',
        },
        {
          label: i18n.t('timesheetUI.JobOptions.UnFavoriteJob', window.CULTURE),
          value: 'unfavorite',
          icon: 'mdi-star-outline',
        },
      ],
      isEditing: false,
      panelHeader: [],
      jobList: [],
    }
  },
  components: {
    TimesheetCell,
    JobRowGroup,
    JobRow,
    GridCell,
  },
  computed: {
    ...mapGetters('storeTimesheets', [
      'timesheetJobs',
      'groupedTimesheetJobs',
      'isShowHideVisibilityEnabled',
      'hoursTotalPerJob',
      'currentDayJobFeatures',
    ]),
    ...mapState('storeTimesheets', [
      'timesheets',
      'timesheetExists',
      'timesheetDetail',
      'userData',
      'calendar',
      'agencyData',
      'businessRole',
      'isTimeEntryDrawerOpen',
      'timesheetSelectedJob',
      'expansionPanels',
    ]),
  },
  methods: {
    ...mapActions('storeTimesheets', [
      'loadData',
      'markJobAsFavorite',
      'markJobAsUnfavorite',
      'removeAssignedJob',
      'deleteUserJob',
    ]),
    ...mapMutations('storeTimesheets', [
      'setTimesheetHideJobError',
      'setTimesheetDeleteError',
      'setPerformRemoveButtonFocus',
      'hideTimeEntryDrawer',
      'setExpansionPanels',
    ]),
    gotoClarizenProject,
    getTimesheets,
    isTimesheetRejected,
    isJobHidden,
    checkRestriction(day, timesheetJob) {
      let durationValue = 0

      if (
        timesheetJob.isClarizenAssignedProject &&
        timesheetJob.isClarizenTask
      ) {
        durationValue = getTimesheetsDuplicate(
          this.timesheets,
          timesheetJob.clarizenProjectId,
          day.date,
          false,
          timesheetJob.isClarizenTask,
          timesheetJob.clarizenTaskExternalId,
          timesheetJob.task,
          this.currentDayJobFeatures(timesheetJob, day.date).isHistoricalJob
        )
      } else {
        durationValue = getTimesheets(
          this.timesheets,
          timesheetJob.id,
          day.date,
          'durationValue'
        )
      }

      return checkRestriction(day, timesheetJob, durationValue)
    },
    async timesheetDeleteJob(job, jobIndex) {
      if (
        this.hoursTotalPerJob[jobIndex].percent ||
        this.hoursTotalPerJob[jobIndex].hours
      ) {
        this.setTimesheetDeleteError({
          dialog: true,
          data: job,
        })

        return
      }

      if (job.isClarizenTask && !job.selfAssignmentWithManageTasking) {
        await this.removeAssignedJob(job)
      } else {
        await this.deleteUserJob(job)
      }

      this.hideTimeEntryDrawer()
    },
    handleJobRowAction(action, job, jobIndex) {
      switch (action) {
        case 'remove':
          this.timesheetDeleteJob(job, jobIndex)
          break

        case 'favorite':
          this.markJobAsFavorite(job.id)
          break

        case 'unfavorite':
          this.markJobAsUnfavorite(job.id)
          break
      }
    },

    toggleEditCell() {
      this.isEditing = !this.isEditing
    },
    handleCellClick() {
      this.toggleEditCell()
      return

      // this.openActions.openTimesheetDialog(
      //   this.timesheetJob,
      //   day.date,
      //   (this.timesheetJob.isClarizenAssignedProject ||
      //     this.timesheetJob.selfAssignmentWithManageTasking) &&
      //     this.timesheetJob.clarizenProjectId
      // )
    },
    showFocusIcon(job) {
      return (
        this.isTimeEntryDrawerOpen &&
        this.timesheetSelectedJob.job.id === job.id
      )
    },
    getJobRowActions(job) {
      const favValue = job.isFavJob_V2 ? 'favorite' : 'unfavorite'
      return this.jobRowActions.filter(
        (item) =>
          item.value !== favValue &&
          !(favValue === 'favorite' && item.value === 'remove')
      )
    },

    allocationYear(allocationDate) {
      return moment(new Date(allocationDate)).year()
    },
  },
  watch: {
    timesheetJobs: {
      handler() {},
      immediate: true,
    },
    groupedTimesheetJobs: {
      handler() {
        this.panelHeader = Object.keys(this.groupedTimesheetJobs.groupedJobs)
        this.jobList = this.groupedTimesheetJobs.sortedJobs

        this.panels = this.panelHeader.map((_, i) => i)
      },
      deep: true,
      immediate: true,
    },
    panels() {
      if (!this.panels.length) this.panels = [...DEFAULT_PANELS]
    },
  },
}
</script>

<style lang="scss" scoped>
.job-group--panel {
  .v-expansion-panel-header {
    width: fit-content;
  }
}

.drawer-indicator {
  position: absolute;
  left: 5px;
}
</style>
