var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dialog
    ? _c(
        "v-dialog",
        {
          attrs: { "max-width": "660px" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { role: "alert", "aria-live": "polite" } },
            [
              _c(
                "v-card-title",
                {
                  staticClass: "d-flex justify-space-between align-start pa-4",
                },
                [
                  _c(
                    "div",
                    {
                      attrs: {
                        "aria-label": `Alert: ${_vm.$t(
                          "timesheetUI.ValidationMessages.EditHiddenJobErrorTitle"
                        )} - ${_vm.$t(
                          "timesheetUI.ValidationMessages.EditHiddenJobErrorMessage"
                        )}`,
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "h1 mb-1 error--text font-weight-bold" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "timesheetUI.ValidationMessages.EditHiddenJobErrorTitle"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "h5 font-color--error" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "timesheetUI.ValidationMessages.EditHiddenJobErrorMessage"
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", "aria-label": "Close" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-text",
                { staticClass: "px-4" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pb-0", attrs: { md: "6" } },
                        [
                          _c("v-label", { staticClass: "mb-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "timesheetUI.EntryFormLabels.TimesheetEntryJobName"
                                )
                              )
                            ),
                          ]),
                          _c("v-text-field", {
                            staticClass: "mt-2",
                            attrs: {
                              outlined: "",
                              dense: "",
                              value: _vm.editHiddenJobData.jobName,
                              disabled: "",
                              "hide-details": "",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pb-0", attrs: { md: "6" } },
                        [
                          _c("v-label", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "timesheetUI.EntryFormLabels.TimesheetEntryJobID"
                                )
                              )
                            ),
                          ]),
                          _c("v-text-field", {
                            staticClass: "mt-2",
                            attrs: {
                              outlined: "",
                              dense: "",
                              value: _vm.editHiddenJobData.job_ID,
                              disabled: "",
                              "hide-details": "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                { staticClass: "justify-end pa-4" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-auto",
                      attrs: {
                        color: "primary",
                        depressed: "",
                        "min-width": "160px",
                        outlined: "",
                      },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("General.Btns.Close")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }