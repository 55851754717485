var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex flex-column align-center mt-13" }, [
    _c("div", { staticClass: "font-size--24 font-weight--600 mb-2" }, [
      _vm._v(
        " " +
          _vm._s(_vm.$t("timesheetUI.HeaderSearchTimesheet.noResultsLabel")) +
          " "
      ),
    ]),
    _c("div", { staticClass: "h2 font-weight--600 subtext mb-9" }, [
      _vm._v(
        " " +
          _vm._s(_vm.$t("timesheetUI.HeaderSearchTimesheet.noResultsSubText")) +
          " "
      ),
    ]),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", { attrs: { src: require("@/assets/icons/noresults.svg") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }