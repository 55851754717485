<template>
  <v-menu
    :close-on-content-click="false"
    offset-y
    attach=".timesheet-header__search"
    content-class="filter-panel elevation-8"
    nudge-bottom="10"
    v-model="isFilterOpen"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        small
        v-on="on"
        v-bind="attrs"
        color="primary"
        :aria-label="$t('timesheetUI.HeaderSearchTimesheet.FilterLabel')"
        class="mr-2"
      >
        <v-icon size="20">mdi-tune</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-text>
        <div class="d-flex justify-center flex-column align-center">
          <span class="pt-5 pb-4 font-weight--500 primary--text">{{
            $t('timesheetUI.HeaderSearchTimesheet.FilterPopupLabel')
          }}</span>
          <v-chip-group
            column
            multiple
            v-model="selectedFilters"
            role="group"
            aria-label="Filter options"
          >
            <div class="justify-center v-slide-group__content">
              <v-chip
                v-for="tag in globalFilterOptions"
                :key="tag.label"
                :aria-label="tag.label"
                :value="tag"
                :disabled="disabledFilters.includes(tag.label)"
                role="button"
                :aria-pressed="`${selectedFilters.includes(tag)}`"
              >
                <v-icon color="primary" class="mr-1" size="18">{{
                  tag.icon
                }}</v-icon>
                {{ tag.label }}
              </v-chip>
            </div>
          </v-chip-group>
        </div>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions class="px-4 py-6">
        <v-btn
          rounded
          depressed
          color="primary"
          @click="handleApplyFilters"
          class="font-weight--600 timesheet-entry__actions--save px-5 ml-auto"
        >
          {{ $t('timesheetUI.HeaderSearchTimesheet.BtnApplyFilters') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>
import { some } from 'lodash'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'SearchFilterPopover',
  data() {
    return {
      localSelectedFilters: this.globalSearchSelectedFilters || [],
    }
  },
  computed: {
    ...mapState('storeTimesheets', [
      'globalFilterOptions',
      'globalSearchSelectedFilters',
      'filterOpen',
    ]),
    isFilterOpen: {
      get() {
        return this.filterOpen
      },
      set(val) {
        this.setFilterOpen(val)
      },
    },
    selectedFilters: {
      get() {
        return this.localSelectedFilters
      },
      set(val) {
        this.localSelectedFilters = [...val]
      },
    },
    disabledFilters() {
      const disabledFilters = []

      this.globalFilterOptions.forEach((filter) => {
        if (
          filter.disableTarget &&
          some(this.selectedFilters, { label: filter.disableTarget })
        ) {
          disabledFilters.push(filter.label)
        }
      })
      return disabledFilters
    },
  },
  watch: {
    globalSearchSelectedFilters: {
      deep: true,
      handler() {
        this.localSelectedFilters = [...this.globalSearchSelectedFilters]
      },
    },
  },
  methods: {
    ...mapMutations('storeTimesheets', [
      'setGlobalSearchSelectedFilters',
      'setFilterOpen',
    ]),
    handleApplyFilters() {
      this.setGlobalSearchSelectedFilters(this.localSelectedFilters)
      this.setFilterOpen(false)
    },
  },
}
</script>
<style lang="scss" scoped>
.filter-panel {
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
  width: auto !important;
  max-width: none !important;
}
.v-chip {
  border: 1px solid transparent;
  background-color: $easyGrey3 !important;
  &--active {
    border-color: $accent;
  }
}
</style>
