var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    {
      staticClass: "ma-0 font-weight--600 font-size--14",
      attrs: { rounded: "", depressed: "", color: "button-background" },
      on: { click: _vm.goToAbsenceCalendar },
    },
    [_vm._v(" " + _vm._s(_vm.$t("timesheetUI.Btns.BtnBookAbsence")) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }