<template>
  <div class="mb-10 manage-pref-setting-select" v-if="!copyRestOfWeekSelected">
    <v-label class="font-weight--600 font-size--12" for="wfhPreferenceSelect"
      >{{ $t('timesheetUI.EntryFormLabels.TimesheetEntryWfhSettingsLabel') }}
    </v-label>
    <div class="d-flex align-center gap-2 mt-2">
      <v-select
        id="wfhPreferenceSelect"
        class="mt-0"
        role="listbox"
        :items="$t('timesheetUI.EntryFormLabels.TimesheetWeeklySettings')"
        item-text="text"
        dense
        outlined
        :height="28"
        v-model="selectedValue"
        :menu-props="{
          offsetY: true,
        }"
        hide-details
        append-icon="mdi-chevron-down"
        @change="handleUpdate"
        @keydown.up="announceChange"
        @keydown.down="announceChange"
      >
      </v-select>
      <div aria-live="polite" aria-atomic="true" class="sr-only">
        {{ selectedValue }}
      </div>

      <!-- <v-btn
        rounded
        elevation="0"
        :class="[
          'font-size--14 font-weight--600 px-2',
          { is__updated: isUpdated },
        ]"
        height="28"
        :disabled="isUpdated"
        @click="handleUpdate"
        v-if="selectedValue !== 'This entry only'"
      >
        <v-icon size="16" class="mr-1" v-if="isUpdated"
          >mdi-check-circle-outline</v-icon
        >
        <v-icon size="16" class="mr-1" v-else>mdi-autorenew</v-icon>
        {{ isUpdated ? 'Updated' : 'Update' }}
      </v-btn> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'

export default {
  name: 'TimesheetManagePreferenceWfhSelect',

  props: {
    shouldUpdateWfh: {
      default: null,
    },
  },

  data() {
    return {
      selectedValue: '',
      isUpdated: false,
    }
  },
  watch: {
    'timesheetDetail.wfh': {
      deep: true,
      immediate: true,
      handler() {
        this.updateButtonStatus()
      },
    },
    selectedValue(_, newVal) {
      if (newVal && newVal.length > 0) {
        this.setHasTimeEntryChanged({ e: true, data: null })
      }
      this.updateButtonStatus()
      // this.handleUpdate()
    },
    userSettings: {
      deep: true,
      handler() {
        this.updateButtonStatus()
      },
    },
    shouldUpdateWfh() {
      this.handleUpdate()
    },
  },
  computed: {
    ...mapState('storeTimesheets', [
      'timesheetDetail',
      'timesheetSelectedJob',
      'userSettings',
      'userData',
      'copyRestOfWeekSelected',
    ]),
    ...mapGetters('storeTimesheets', [
      'selectedDay',
      'getUserSettingByDay',
      'getUserSettingByWeek',
      'selectedDate',
    ]),
    transformedWfhValue() {
      if (this.timesheetDetail.wfh === '') return this.timesheetDetail.wfh
      return this.timesheetDetail.wfh ? 'Yes' : 'No'
    },
    getDefaultValue() {
      if (this.getUserSettingByDay?.wfh === this.transformedWfhValue) {
        return 'Full day'
      }

      if (this.getUserSettingByWeek?.wfh === this.transformedWfhValue) {
        return 'Full week'
      }

      return 'This entry only'
    },
  },
  methods: {
    ...mapActions('storeTimesheets', ['saveUserSettings']),
    ...mapMutations('storeTimesheets', [
      'setPendingUserWFHSettings',
      'setHasTimeEntryChanged',
    ]),
    updateButtonStatus() {
      if (
        this.selectedValue === 'Full day' &&
        this.getUserSettingByDay?.wfh === this.transformedWfhValue
      ) {
        return (this.isUpdated = true)
      }

      if (
        this.selectedValue === 'Full week' &&
        this.getUserSettingByWeek?.wfh === this.transformedWfhValue
      ) {
        return (this.isUpdated = true)
      }

      this.isUpdated = false
    },
    handleUpdate() {
      if (
        !this.timesheetDetail.wfh === '' ||
        this.selectedValue === 'This entry only'
      ) {
        this.setPendingUserWFHSettings(null)
        return
      }

      let payload = {
        altairCode: this.userData.altairCode,
        year: this.selectedDate.year,
        week: this.selectedDate.weekOfYear,
      }

      if (this.selectedValue === 'Full week') {
        if (this.getUserSettingByWeek) {
          payload = {
            ...payload,
            ...this.getUserSettingByWeek,
          }
        }
        payload = {
          ...payload,
          wfh: this.timesheetDetail.wfh ? 'Yes' : 'No',
          isLocationFullDay: false,
          isLocationRestOfWeek: true,
          isWfhFullDay: false,
          isWfhRestOfWeek: true,
        }
      }

      if (this.selectedValue === 'Full day') {
        if (this.getUserSettingByDay) {
          payload = {
            ...payload,
            ...this.getUserSettingByDay,
          }
        }

        payload = {
          ...payload,
          wfh: this.timesheetDetail.wfh ? 'Yes' : 'No',
          date: this.timesheetSelectedJob.date,
          isLocationFullDay: true,
          isLocationRestOfWeek: false,
          isWfhFullDay: true,
          isWfhRestOfWeek: false,
        }
      }

      this.setPendingUserWFHSettings({ settings: [payload] })
      // this.saveUserSettings({ settings: [payload] })

      // this.setLocationWFHSaveAction({
      //   type: 'wfh',
      //   action: payload,
      // })
    },
    announceChange() {
      const region = this.$refs.announceRegion
      region.textContent = this.selectedValue
    },
  },
  destroyed() {
    this.setPendingUserWFHSettings(null)
  },
  created() {
    this.selectedValue = this.getDefaultValue
  },
}
</script>

<style scoped lang="scss">
.manage-pref-setting-select::v-deep {
  .v-select {
    font-size: 14px !important;
    .v-input__slot {
      min-height: auto !important;
      padding: 0 8px !important;
    }
    .v-input__append-inner {
      margin-top: 2px !important;
      .v-input__icon {
        min-width: 12px !important;
        width: 12px !important;
      }
    }
  }
  .v-btn {
    &.v-btn--disabled {
      &.is__updated {
        background-color: #ebfaed !important;
        color: $primary !important;
      }
    }
    .v-icon {
      color: $primary !important;
    }
  }
}
</style>
