var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GridCell",
    {
      key: _vm.day.dayName,
      staticClass: "job-group__day",
      attrs: {
        disabled:
          _vm.checkRestriction(_vm.day, _vm.timesheetJob).disabled &&
          _vm.duration == 0,
      },
    },
    [
      !(
        _vm.checkRestriction(_vm.day, _vm.timesheetJob).disabled &&
        _vm.duration == 0
      )
        ? _c(
            "div",
            {
              key: _vm.day.dayName,
              class: [
                "height-100",
                {
                  "job-group__day--rejected":
                    _vm.isTimesheetRejected(
                      !_vm.timesheetJob.isClarizenAssignedProject &&
                        !_vm.timesheetJob.selfAssignmentWithManageTasking
                        ? _vm.getTimesheets(
                            _vm.timesheets,
                            _vm.timesheetJob.id,
                            _vm.day.date
                          )
                        : _vm.getTimesheetsDuplicate(
                            this.timesheets,
                            _vm.timesheetJob.clarizenProjectId,
                            _vm.day.date,
                            true,
                            _vm.timesheetJob.isClarizenTask,
                            _vm.timesheetJob.clarizenTaskExternalId,
                            _vm.timesheetJob.task,
                            _vm.currentDayJobFeatures?.isHistoricalJob
                          )
                    ).length &&
                    !(_vm.isContractor && _vm.agencyData.hideTimesheetWorkcode),
                  "job-group__day--weekend": _vm.day.isWeekend,
                  "job-group__day--holiday": _vm.day.isHoliday,
                  "timesheet-hidden-job-cell": _vm.isJobHidden(
                    _vm.hoursTotalPerJob,
                    _vm.timesheetJob,
                    _vm.jobIndex
                  ),
                },
              ],
              attrs: {
                "aria-label": `${
                  _vm.isFutureDate
                    ? _vm.$t(
                        "timesheetUI.ValidationMessages.TimesheetEntryFutureNotAllowedMessage"
                      )
                    : _vm.cellValueAndLabel(_vm.day).value
                }`,
              },
            },
            [
              _vm.checkRestriction(_vm.day, _vm.timesheetJob).disabled &&
              !_vm.timesheetDialogVisibility
                ? _c("div", {
                    staticClass: "disable-click-handler",
                    on: {
                      click: () =>
                        _vm.handleResticted(
                          _vm.checkRestriction(_vm.day, _vm.timesheetJob),
                          _vm.timesheetJob,
                          _vm.day,
                          _vm.timesheetJob.isClarizenAssignedProject &&
                            _vm.timesheetJob.clarizenProjectId
                        ),
                    },
                  })
                : _vm._e(),
              _vm.day.isRequiredByUserContract &&
              !_vm.currentDayJobFeatures?.availablefeatures?.features
                ?.temporaryExternalEmployee?.required &&
              !(_vm.isContractor && _vm.agencyData.hideTimesheetWorkcode)
                ? _c("TimesheetDayCellStatusIcons", {
                    attrs: { day: _vm.day, timesheetJob: _vm.timesheetJob },
                  })
                : _vm._e(),
              _c("TimeEntryCell", {
                attrs: {
                  id: `${(
                    _vm.timesheetJob.id + _vm.day.date.split("T")[0]
                  ).replaceAll("-", "")}`,
                  alwaysAllowEdit: true,
                  value: _vm.duration,
                  multiple: _vm.getTimesheetEntryCount(_vm.day.date) > 1,
                  day: _vm.day,
                  readonly:
                    _vm.isSingleEntryApproved ||
                    _vm.isTimeEntryDrawerOpen ||
                    _vm.isTimesheetSaveInProgress ||
                    _vm.loading ||
                    _vm.isAsyncValidationInProgress,
                  disabled:
                    _vm.checkRestriction(_vm.day, _vm.timesheetJob).disabled ||
                    _vm.isFutureDate ||
                    _vm.navigationLoading,
                  error: _vm.isTimesheetSaveInProgress ? "" : _vm.error,
                  loading:
                    _vm.isTimesheetSaveInProgress ||
                    _vm.isAsyncValidationInProgress,
                  "should-glow": _vm.shouldGlow,
                  jobId: _vm.timesheetJob.id,
                  jobName: _vm.timesheetJob.jobName,
                  "active-cell": _vm.activeCell,
                },
                on: {
                  input: _vm.handleDurationInput,
                  change: _vm.debouncedSave,
                  dblclick: _vm.handleCellDblClick,
                  enterdown: _vm.handleEnterDown,
                  focus: _vm.handleInputFocus,
                  blur: _vm.handleBlur,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }