<template>
  <v-dialog v-model="dialogOpen" :width="680" scrollable>
    <v-card v-if="dialogOpen">
      <FocusLock returnFocus>
        <validation-observer ref="preferenceObserver">
          <v-form
            class="preference-form"
            @submit.prevent="savePreference"
            lazy-validation
            :disabled="savingSettings"
          >
            <div class="white d-flex flex-column form-inner" data-no-focus-lock>
              <v-card-title class="px-0 py-0 flex-column">
                <div class="px-6 py-5 width--100">
                  <div
                    class="d-flex justify-space-between align-start width--100"
                  >
                    <div>
                      <div class="font-weight--600 mb-2 font-size--24">
                        {{ $t('timesheetUI.ManagePreference.Heading') }}
                        <span v-if="agencyData.isWFHActive">
                          {{
                            $t('timesheetUI.ManagePreference.WFHHeading')
                          }}</span
                        >
                      </div>
                      <div class="font-weight--400 h5 d-flex align-center">
                        <span>{{ selectedWeekRangeText }} </span>
                        <span class="mx-1"> | </span>
                        <LearnMore screen="location-dialog" />
                      </div>
                    </div>
                    <v-btn icon aria-label="Close Dialog" @click="handleClose">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </div>
                  <!-- <v-alert
                  outlined
                  dense
                  dark
                  type="error"
                  class="mt-2 mb-0 width--100"
                  v-if="changed"
                >
                  You have unsaved changes
                </v-alert> -->
                </div>
                <v-divider role="presentation" class="width--100"></v-divider>
              </v-card-title>

              <v-card-text class="px-6 py-6" ref="content_scrollable">
                <v-row class="align-end"
                  ><v-col :cols="agencyData.isWFHActive ? 6 : 12">
                    <validation-provider name="dialogFullWeekLocation">
                      <v-label for="dialogFullWeekLocation">{{
                        $t('timesheetUI.ManagePreference.FullWeekLocationLabel')
                      }}</v-label>
                      <v-autocomplete
                        id="dialogFullWeekLocation"
                        ref="dialogFullWeekLocation"
                        role="combobox"
                        dense
                        return-object
                        :value="getFullWeekSelectedValue('LOCATION')"
                        :items="locationList"
                        item-text="label"
                        :filter="customFilter"
                        clearable
                        @change="(val) => handleFullWeekChange('LOCATION', val)"
                        :placeholder="
                          $t(
                            'timesheetUI.EntryFormLabels.TimesheetEntryLabelLocations'
                          )
                        "
                        item-value="value"
                        outlined
                        :menu-props="{
                          offsetY: true,
                        }"
                        hide-details
                        append-icon="mdi-chevron-down"
                        @keydown="arrowKeyHandler"
                      >
                      </v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <v-col v-if="agencyData.isWFHActive" cols="6">
                    <validation-provider name="dialogFullWeekWfh">
                      <v-label for="dialogFullWeekWfh">{{
                        $t('timesheetUI.ManagePreference.FullWeekWFHLabel')
                      }}</v-label>
                      <v-select
                        id="dialogFullWeekWfh"
                        ref="dialogFullWeekWfh"
                        role="listbox"
                        dense
                        outlined
                        :value="getFullWeekSelectedValue('WFH')"
                        :items="wfhOptions"
                        item-text="label"
                        item-value="label"
                        :menu-props="{
                          offsetY: true,
                        }"
                        :placeholder="
                          $t(
                            'timesheetUI.EntryFormLabels.TimesheetEntryWfhLabel'
                          )
                        "
                        @change="(val) => handleFullWeekChange('WFH', val)"
                        hide-details
                        @keydown="arrowKeyHandler"
                        @keyup.down="$event.target.click()"
                        @keyup.up="$event.target.click()"
                        append-icon="mdi-chevron-down"
                        clearable
                      >
                      </v-select>
                    </validation-provider>
                  </v-col>

                  <template v-for="item in daysInWeek">
                    <v-col
                      v-if="isLocationActiveForThisDay(item.date)"
                      :cols="agencyData.isWFHActive ? 6 : 12"
                      :key="`${item.day}-location`"
                    >
                      <h3 class="accent--text mb-2">
                        {{ item.formattedDate }}
                      </h3>
                      <validation-provider
                        :name="`dialogFullWeekLocation-${item.day}`"
                      >
                        <v-label
                          :aria-label="`${$t(
                            'timesheetUI.ManagePreference.LocationLabel'
                          )} on ${item.formattedDate}`"
                          :for="`dialogFullWeekLocation-${item.day}`"
                          >{{
                            $t('timesheetUI.ManagePreference.LocationLabel')
                          }}</v-label
                        >
                        <v-autocomplete
                          clearable
                          :id="`dialogFullWeekLocation-${item.day}`"
                          :ref="`dialogFullWeekLocation-${item.day}`"
                          role="listbox"
                          dense
                          outlined
                          item-text="label"
                          item-value="value"
                          :placeholder="
                            $t(
                              'timesheetUI.EntryFormLabels.TimesheetEntryLabelLocations'
                            )
                          "
                          return-object
                          :value="
                            getFullDaySelectedValue(
                              'LOCATION',
                              item.day.toLowerCase()
                            )
                          "
                          :filter="customFilter"
                          :items="locationList"
                          @change="
                            (val) =>
                              handleFullDayChange(
                                'LOCATION',
                                item.day.toLowerCase(),
                                val
                              )
                          "
                          :menu-props="{
                            offsetY: true,
                          }"
                          hide-details
                          append-icon="mdi-chevron-down"
                          @keydown="arrowKeyHandler"
                        >
                        </v-autocomplete>
                      </validation-provider>
                    </v-col>

                    <v-col
                      v-if="
                        isLocationActiveForThisDay(item.date) &&
                        agencyData.isWFHActive
                      "
                      cols="6"
                      :key="`${item.day}-wfh`"
                    >
                      <validation-provider
                        :name="`dialogFullWeekWfh-${item.day}`"
                      >
                        <v-label
                          :aria-label="`${$t(
                            'timesheetUI.ManagePreference.WFHLabel'
                          )} on ${item.formattedDate}`"
                          :for="`dialogFullWeekWfh-${item.day}`"
                          >{{
                            $t('timesheetUI.ManagePreference.WFHLabel')
                          }}</v-label
                        >
                        <v-select
                          :id="`dialogFullWeekWfh-${item.day}`"
                          :ref="`dialogFullWeekWfh-${item.day}`"
                          role="listbox"
                          dense
                          outlined
                          :placeholder="
                            $t(
                              'timesheetUI.EntryFormLabels.TimesheetEntryWfhLabel'
                            )
                          "
                          :menu-props="{
                            offsetY: true,
                          }"
                          :value="
                            getFullDaySelectedValue(
                              'WFH',
                              item.day.toLowerCase()
                            )
                          "
                          @change="
                            (val) =>
                              handleFullDayChange(
                                'WFH',
                                item.day.toLowerCase(),
                                val
                              )
                          "
                          :items="wfhOptions"
                          item-text="label"
                          item-value="label"
                          hide-details
                          @keydown="arrowKeyHandler"
                          @keyup.down="$event.target.click()"
                          @keyup.up="$event.target.click()"
                          append-icon="mdi-chevron-down"
                        >
                        </v-select>
                      </validation-provider>
                    </v-col>
                  </template>
                </v-row>
              </v-card-text>

              <v-card-actions class="px-0 py-0 flex-column">
                <v-divider role="presentation" class="width--100"></v-divider>
                <div class="px-6 py-5 d-flex width--100">
                  <v-btn
                    @click="handleClose"
                    outlined
                    rounded
                    class="font-weight--600 preference-actions--close"
                    >{{ $t('General.Btns.Close') }}</v-btn
                  >
                  <v-btn
                    type="submit"
                    rounded
                    depressed
                    color="primary"
                    :loading="savingSettings"
                    :disabled="!enableSaveButton || savingSettings"
                    class="font-weight--600 px-5 ml-auto"
                    >{{ $t('General.Btns.Save') }}</v-btn
                  >
                </div>
              </v-card-actions>
            </div>
          </v-form>
        </validation-observer>
      </FocusLock>
    </v-card>
  </v-dialog>
</template>
<script>
import i18n from '@Shared/i18n'
import { uniqBy } from 'lodash'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import {
  arrowKeyHandler as arrowKeyHandlerClick,
  handleArrowKeys,
} from '@Shared/filters'
import { debounce } from 'lodash'
import moment from 'moment'
import { isEqual } from 'lodash'
import LearnMore from './learn-more/LocationLearnMore.vue'
import FocusLock from 'vue-focus-lock'

export default {
  name: 'ManagePreferenceDialog',
  components: { ValidationObserver, ValidationProvider, LearnMore, FocusLock },
  data() {
    return {
      wfhOptions: [
        {
          label: i18n.t(
            'timesheetUI.EntryFormLabels.TimesheetEntryWfhYes',
            window.CULTURE
          ),
          value: true,
        },
        {
          label: i18n.t(
            'timesheetUI.EntryFormLabels.TimesheetEntryWfhNo',
            window.CULTURE
          ),
          value: false,
        },
      ],
      localSettings: null,
      defaultSettingObj: {
        altairCode: '',
        year: 0,
        week: 0,
        location: '',
        isLocationFullDay: true,
        isLocationRestOfWeek: true,
        wfh: '',
        isWfhFullDay: true,
        isWfhRestOfWeek: true,
      },
    }
  },

  computed: {
    ...mapState('storeTimesheets', [
      'preferenceDialogOpen',
      'locationList',
      'userSettings',
      'loading',
      'userData',
      'savingSettings',
      'timesheetDetail',
      'isTimeEntryDrawerOpen',
      'agencyData',
    ]),
    ...mapGetters('storeTimesheets', [
      'selectedWeekRangeText',
      'getDaysInWeek',
      'selectedDate',
      'newTimesheetObj',
      'isLocationActiveForThisDay',
    ]),
    dialogOpen: {
      get() {
        return this.preferenceDialogOpen
      },
      set(newVal) {
        newVal ? this.showPreferenceDialog() : this.handleClose()
      },
    },
    daysInWeek() {
      return this.getDaysInWeek
    },
    fullWeekSetting() {
      return this.localSettings?.find(
        (s) => s.isLocationRestOfWeek || s.isWfhRestOfWeek
      )
    },
    fulldaySettings() {
      return this.localSettings?.filter(
        (s) => s.isLocationFullDay || s.isWfhFullDay
      )
    },
    enableSaveButton() {
      return (
        this.localSettings &&
        this.localSettings.some((a) => {
          return a.location !== '' || a.wfh !== ''
        }) &&
        !isEqual(this.localSettings, this.userSettings)
      )
    },
  },
  watch: {
    userSettings: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (!newVal) return
        this.localSettings = [...newVal]
        if (this.showPreferenceDialog) {
          this.syncUserSettingsWithForm()
        }
      },
    },
    dialogOpen(open) {
      if (open) {
        this.$set(this, 'localSettings', [...this.userSettings])
      }
      this.$refs?.content_scrollable?.scrollTo(0, 0)
    },
  },
  methods: {
    ...mapMutations('storeTimesheets', [
      'showPreferenceDialog',
      'closePreferenceDialog',
      'setPreference',
      'setTimesheetDetail',
    ]),
    ...mapActions('storeTimesheets', [
      'saveUserSettings',
      'syncUserSettingsWithForm',
    ]),
    arrowKeyHandler: debounce(function ($e) {
      arrowKeyHandlerClick($e)

      handleArrowKeys(this, $e, [
        'dialogFullWeekLocation',
        'dialogFullWeekWfh',
        // ...this.daysInWeek.map((d) => `dialogFulldayLocation-${d.day}`),
        // ...this.daysInWeek.map((d) => `dialogFulldayWfh-${d.day}`),
      ])
    }, 300),
    getFullWeekSelectedValue(type) {
      if (!this.localSettings) return
      if (type === 'LOCATION') {
        return this.fullWeekSetting?.location ?? ''
      }

      if (type === 'WFH') {
        return this.fullWeekSetting?.wfh ?? ''
      }
    },
    getFullDaySelectedValue(type, day) {
      if (!this.fulldaySettings) return
      const settingForDay = this.fulldaySettings.find(
        (s) =>
          moment(s?.date).format('dddd').toLowerCase() === day.toLowerCase()
      )

      if (type === 'LOCATION') {
        return settingForDay?.location !== undefined
          ? settingForDay?.location
          : this.fullWeekSetting?.location !== undefined
          ? this.fullWeekSetting?.location
          : ''
      }
      if (type === 'WFH') {
        return (
          (settingForDay?.wfh !== '' ? settingForDay?.wfh : undefined) ??
          this.fullWeekSetting?.wfh ??
          ''
        )
      }
    },
    customFilter(item, queryText, itemText) {
      if (item.header) {
        const results = uniqBy(
          this.locationList.filter(
            (a) =>
              a.label &&
              a.label
                .toLocaleLowerCase()
                .indexOf(queryText.toLocaleLowerCase()) > -1
          ),
          'country'
        ).map((a) => a.country)
        return results?.includes(item.header)
      }

      return (
        itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      )
    },
    handleFullWeekChange(type, item) {
      const newFullWeekObj = {
        altairCode: this.userData.altairCode,
        year: this.selectedDate.year,
        week: this.selectedDate.weekOfYear,
        location: '',
        isLocationFullDay: false,
        isLocationRestOfWeek: true,
        wfh: '',
        isWfhFullDay: false,
        isWfhRestOfWeek: true,
      }
      if (type === 'LOCATION') {
        if (this.fullWeekSetting) {
          // update existing setting
          this.localSettings = this.localSettings.map((s) => {
            if (s.isLocationRestOfWeek || !s.location) {
              return {
                ...s,
                location: item?.value ?? '',
              }
            }
            return s
          })
        } else {
          //add new setting
          this.localSettings.push({
            ...newFullWeekObj,
            location: item.value,
          })
        }
        return
      }
      if (type === 'WFH') {
        if (this.fullWeekSetting) {
          this.localSettings = this.localSettings.map((s) => {
            if (s.isWfhRestOfWeek || s.isLocationRestOfWeek) {
              return {
                ...s,
                wfh: item,
              }
            }
            return s
          })
        } else {
          this.localSettings.push({
            ...newFullWeekObj,
            wfh: item,
          })
        }

        return
      }
    },
    handleFullDayChange(type, day, item) {
      const newFullDayObj = {
        altairCode: this.userData.altairCode,
        year: this.selectedDate.year,
        week: this.selectedDate.weekOfYear,
        date: moment()
          .isoWeekday(day)
          .isoWeek(this.selectedDate.weekOfYear)
          .toISOString(),
        isLocationFullDay: true,
        isLocationRestOfWeek: false,
        isWfhFullDay: true,
        isWfhRestOfWeek: false,
      }

      const settingForDayPresent = this.localSettings.find(
        (s) =>
          s?.date &&
          moment(s.date).format('dddd').toLowerCase() === day.toLowerCase()
      )

      if (type === 'LOCATION') {
        if (settingForDayPresent) {
          // update existing setting
          this.localSettings = this.localSettings.map((s) => {
            if (s.date === settingForDayPresent.date) {
              return {
                ...s,
                date: settingForDayPresent.date,
                location: item?.value ?? '',
              }
            }
            return s
          })
        } else {
          this.localSettings.push({
            ...newFullDayObj,
            location: item?.value || '',
          })
        }
        return
      }
      if (type === 'WFH') {
        if (settingForDayPresent) {
          //update existing setting
          this.localSettings = this.localSettings.map((s) => {
            if (s.date === settingForDayPresent.date) {
              return {
                ...s,
                date: settingForDayPresent.date,
                wfh: item,
              }
            }
            return s
          })
        } else {
          this.localSettings.push({
            ...newFullDayObj,
            wfh: item,
          })
        }
        return
      }
    },
    savePreference() {
      return this.$refs.preferenceObserver.validate().then((valid) => {
        if (valid) {
          return this.saveUserSettings({
            settings: this.localSettings,
            isPreferenceDialog: true,
          }).then(() => {
            this.$refs.preferenceObserver?.reset()
          })
        }
      })
    },
    handleClose() {
      this.localSettings = null
      this.closePreferenceDialog()
    },
  },
}
</script>
<style lang="scss">
.preference-actions--close {
  border-color: $border-color;
}

.preference-form {
  .v-input {
    margin-top: 6px;
  }
}

.v-dialog--scrollable {
  .form-inner {
    max-height: calc(100vh * 0.9);
    height: auto;
    overflow: hidden;
  }
  .v-card__title,
  .v-card__actions {
    flex: 0 0 auto;
  }
  .v-card__text {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow-y: auto;
    flex: 1 1 auto;
  }
}
</style>
