import {
  clientMicroServiceGet,
  clientMicroServicePost,
} from '@apis/clientService'

export const getEntitlements = async (params) => {
  const entitlementsResponse = await clientMicroServiceGet(
    '/VacationTracker/NewAbsences/GetEntitlements',
    params
  )
  return entitlementsResponse
}

export const getAbsences = async (params) => {
  const absencesResponse = await clientMicroServiceGet(
    '/VacationTracker/NewAbsences/GetAbsences',
    params
  )
  return absencesResponse
}

export const getPublicHolidays = async (params) => {
  const publicHolidaysResponse = await clientMicroServiceGet(
    '/VacationTracker/NewAbsences/getPublicHolidays',
    params
  )
  return publicHolidaysResponse
}

export const createAbsence = async (payload) => {
  const createAbsenceResponse = await clientMicroServicePost(
    '/VacationTracker/NewAbsences/CreateAbsence',
    payload
  )
  return createAbsenceResponse
}

export const cancelAbsence = async (payload) => {
  const cancelAbsenceResponse = await clientMicroServicePost(
    '/VacationTracker/NewAbsences/CancelAbsence',
    payload
  )
  return cancelAbsenceResponse
}

export const cancelApprovedAbsence = async (payload) => {
  const cancelApprovedAbsenceResponse = await clientMicroServicePost(
    '/VacationTracker/NewAbsences/CancelApprovedAbsence',
    payload
  )
  return cancelApprovedAbsenceResponse
}
