var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: 680, scrollable: "" },
      model: {
        value: _vm.dialogOpen,
        callback: function ($$v) {
          _vm.dialogOpen = $$v
        },
        expression: "dialogOpen",
      },
    },
    [
      _vm.dialogOpen
        ? _c(
            "v-card",
            [
              _c(
                "FocusLock",
                { attrs: { returnFocus: "" } },
                [
                  _c(
                    "validation-observer",
                    { ref: "preferenceObserver" },
                    [
                      _c(
                        "v-form",
                        {
                          staticClass: "preference-form",
                          attrs: {
                            "lazy-validation": "",
                            disabled: _vm.savingSettings,
                          },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.savePreference.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "white d-flex flex-column form-inner",
                              attrs: { "data-no-focus-lock": "" },
                            },
                            [
                              _c(
                                "v-card-title",
                                { staticClass: "px-0 py-0 flex-column" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "px-6 py-5 width--100" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-space-between align-start width--100",
                                        },
                                        [
                                          _c("div", [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "font-weight--600 mb-2 font-size--24",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "timesheetUI.ManagePreference.Heading"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _vm.agencyData.isWFHActive
                                                  ? _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "timesheetUI.ManagePreference.WFHHeading"
                                                            )
                                                          )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "font-weight--400 h5 d-flex align-center",
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.selectedWeekRangeText
                                                    ) + " "
                                                  ),
                                                ]),
                                                _c(
                                                  "span",
                                                  { staticClass: "mx-1" },
                                                  [_vm._v(" | ")]
                                                ),
                                                _c("LearnMore", {
                                                  attrs: {
                                                    screen: "location-dialog",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                icon: "",
                                                "aria-label": "Close Dialog",
                                              },
                                              on: { click: _vm.handleClose },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-close"),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c("v-divider", {
                                    staticClass: "width--100",
                                    attrs: { role: "presentation" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                {
                                  ref: "content_scrollable",
                                  staticClass: "px-6 py-6",
                                },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "align-end" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: _vm.agencyData.isWFHActive
                                              ? 6
                                              : 12,
                                          },
                                        },
                                        [
                                          _c(
                                            "validation-provider",
                                            {
                                              attrs: {
                                                name: "dialogFullWeekLocation",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-label",
                                                {
                                                  attrs: {
                                                    for: "dialogFullWeekLocation",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "timesheetUI.ManagePreference.FullWeekLocationLabel"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c("v-autocomplete", {
                                                ref: "dialogFullWeekLocation",
                                                attrs: {
                                                  id: "dialogFullWeekLocation",
                                                  role: "combobox",
                                                  dense: "",
                                                  "return-object": "",
                                                  value:
                                                    _vm.getFullWeekSelectedValue(
                                                      "LOCATION"
                                                    ),
                                                  items: _vm.locationList,
                                                  "item-text": "label",
                                                  filter: _vm.customFilter,
                                                  clearable: "",
                                                  placeholder: _vm.$t(
                                                    "timesheetUI.EntryFormLabels.TimesheetEntryLabelLocations"
                                                  ),
                                                  "item-value": "value",
                                                  outlined: "",
                                                  "menu-props": {
                                                    offsetY: true,
                                                  },
                                                  "hide-details": "",
                                                  "append-icon":
                                                    "mdi-chevron-down",
                                                },
                                                on: {
                                                  change: (val) =>
                                                    _vm.handleFullWeekChange(
                                                      "LOCATION",
                                                      val
                                                    ),
                                                  keydown: _vm.arrowKeyHandler,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.agencyData.isWFHActive
                                        ? _c(
                                            "v-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c(
                                                "validation-provider",
                                                {
                                                  attrs: {
                                                    name: "dialogFullWeekWfh",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-label",
                                                    {
                                                      attrs: {
                                                        for: "dialogFullWeekWfh",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "timesheetUI.ManagePreference.FullWeekWFHLabel"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c("v-select", {
                                                    ref: "dialogFullWeekWfh",
                                                    attrs: {
                                                      id: "dialogFullWeekWfh",
                                                      role: "listbox",
                                                      dense: "",
                                                      outlined: "",
                                                      value:
                                                        _vm.getFullWeekSelectedValue(
                                                          "WFH"
                                                        ),
                                                      items: _vm.wfhOptions,
                                                      "item-text": "label",
                                                      "item-value": "label",
                                                      "menu-props": {
                                                        offsetY: true,
                                                      },
                                                      placeholder: _vm.$t(
                                                        "timesheetUI.EntryFormLabels.TimesheetEntryWfhLabel"
                                                      ),
                                                      "hide-details": "",
                                                      "append-icon":
                                                        "mdi-chevron-down",
                                                      clearable: "",
                                                    },
                                                    on: {
                                                      change: (val) =>
                                                        _vm.handleFullWeekChange(
                                                          "WFH",
                                                          val
                                                        ),
                                                      keydown:
                                                        _vm.arrowKeyHandler,
                                                      keyup: [
                                                        function ($event) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "down",
                                                              40,
                                                              $event.key,
                                                              [
                                                                "Down",
                                                                "ArrowDown",
                                                              ]
                                                            )
                                                          )
                                                            return null
                                                          return $event.target.click()
                                                        },
                                                        function ($event) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "up",
                                                              38,
                                                              $event.key,
                                                              ["Up", "ArrowUp"]
                                                            )
                                                          )
                                                            return null
                                                          return $event.target.click()
                                                        },
                                                      ],
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._l(_vm.daysInWeek, function (item) {
                                        return [
                                          _vm.isLocationActiveForThisDay(
                                            item.date
                                          )
                                            ? _c(
                                                "v-col",
                                                {
                                                  key: `${item.day}-location`,
                                                  attrs: {
                                                    cols: _vm.agencyData
                                                      .isWFHActive
                                                      ? 6
                                                      : 12,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "h3",
                                                    {
                                                      staticClass:
                                                        "accent--text mb-2",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.formattedDate
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "validation-provider",
                                                    {
                                                      attrs: {
                                                        name: `dialogFullWeekLocation-${item.day}`,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-label",
                                                        {
                                                          attrs: {
                                                            "aria-label": `${_vm.$t(
                                                              "timesheetUI.ManagePreference.LocationLabel"
                                                            )} on ${
                                                              item.formattedDate
                                                            }`,
                                                            for: `dialogFullWeekLocation-${item.day}`,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "timesheetUI.ManagePreference.LocationLabel"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c("v-autocomplete", {
                                                        ref: `dialogFullWeekLocation-${item.day}`,
                                                        refInFor: true,
                                                        attrs: {
                                                          clearable: "",
                                                          id: `dialogFullWeekLocation-${item.day}`,
                                                          role: "listbox",
                                                          dense: "",
                                                          outlined: "",
                                                          "item-text": "label",
                                                          "item-value": "value",
                                                          placeholder: _vm.$t(
                                                            "timesheetUI.EntryFormLabels.TimesheetEntryLabelLocations"
                                                          ),
                                                          "return-object": "",
                                                          value:
                                                            _vm.getFullDaySelectedValue(
                                                              "LOCATION",
                                                              item.day.toLowerCase()
                                                            ),
                                                          filter:
                                                            _vm.customFilter,
                                                          items:
                                                            _vm.locationList,
                                                          "menu-props": {
                                                            offsetY: true,
                                                          },
                                                          "hide-details": "",
                                                          "append-icon":
                                                            "mdi-chevron-down",
                                                        },
                                                        on: {
                                                          change: (val) =>
                                                            _vm.handleFullDayChange(
                                                              "LOCATION",
                                                              item.day.toLowerCase(),
                                                              val
                                                            ),
                                                          keydown:
                                                            _vm.arrowKeyHandler,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.isLocationActiveForThisDay(
                                            item.date
                                          ) && _vm.agencyData.isWFHActive
                                            ? _c(
                                                "v-col",
                                                {
                                                  key: `${item.day}-wfh`,
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "validation-provider",
                                                    {
                                                      attrs: {
                                                        name: `dialogFullWeekWfh-${item.day}`,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-label",
                                                        {
                                                          attrs: {
                                                            "aria-label": `${_vm.$t(
                                                              "timesheetUI.ManagePreference.WFHLabel"
                                                            )} on ${
                                                              item.formattedDate
                                                            }`,
                                                            for: `dialogFullWeekWfh-${item.day}`,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "timesheetUI.ManagePreference.WFHLabel"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c("v-select", {
                                                        ref: `dialogFullWeekWfh-${item.day}`,
                                                        refInFor: true,
                                                        attrs: {
                                                          id: `dialogFullWeekWfh-${item.day}`,
                                                          role: "listbox",
                                                          dense: "",
                                                          outlined: "",
                                                          placeholder: _vm.$t(
                                                            "timesheetUI.EntryFormLabels.TimesheetEntryWfhLabel"
                                                          ),
                                                          "menu-props": {
                                                            offsetY: true,
                                                          },
                                                          value:
                                                            _vm.getFullDaySelectedValue(
                                                              "WFH",
                                                              item.day.toLowerCase()
                                                            ),
                                                          items: _vm.wfhOptions,
                                                          "item-text": "label",
                                                          "item-value": "label",
                                                          "hide-details": "",
                                                          "append-icon":
                                                            "mdi-chevron-down",
                                                        },
                                                        on: {
                                                          change: (val) =>
                                                            _vm.handleFullDayChange(
                                                              "WFH",
                                                              item.day.toLowerCase(),
                                                              val
                                                            ),
                                                          keydown:
                                                            _vm.arrowKeyHandler,
                                                          keyup: [
                                                            function ($event) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "down",
                                                                  40,
                                                                  $event.key,
                                                                  [
                                                                    "Down",
                                                                    "ArrowDown",
                                                                  ]
                                                                )
                                                              )
                                                                return null
                                                              return $event.target.click()
                                                            },
                                                            function ($event) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "up",
                                                                  38,
                                                                  $event.key,
                                                                  [
                                                                    "Up",
                                                                    "ArrowUp",
                                                                  ]
                                                                )
                                                              )
                                                                return null
                                                              return $event.target.click()
                                                            },
                                                          ],
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      }),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-card-actions",
                                { staticClass: "px-0 py-0 flex-column" },
                                [
                                  _c("v-divider", {
                                    staticClass: "width--100",
                                    attrs: { role: "presentation" },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "px-6 py-5 d-flex width--100",
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "font-weight--600 preference-actions--close",
                                          attrs: { outlined: "", rounded: "" },
                                          on: { click: _vm.handleClose },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("General.Btns.Close"))
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "font-weight--600 px-5 ml-auto",
                                          attrs: {
                                            type: "submit",
                                            rounded: "",
                                            depressed: "",
                                            color: "primary",
                                            loading: _vm.savingSettings,
                                            disabled:
                                              !_vm.enableSaveButton ||
                                              _vm.savingSettings,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("General.Btns.Save"))
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }