var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return true
    ? _c(
        "v-dialog",
        {
          attrs: { "max-width": "660px" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "FocusLock",
            { attrs: { returnFocus: "" } },
            [
              _c(
                "v-card",
                {
                  attrs: {
                    role: "alert",
                    "aria-live": "polite",
                    "append-icon": "mdi-close",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "py-5 px-6" },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "d-flex justify-space-between pa-0" },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "text-h2 error--text",
                              attrs: {
                                "aria-label": `Alert: ${_vm.$t(
                                  "timesheetUI.ValidationMessages.DeleteErrorTitle"
                                )} - ${_vm.$t(
                                  "timesheetUI.ValidationMessages.DeleteErrorMessage"
                                )}`,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "timesheetUI.ValidationMessages.DeleteErrorTitle"
                                  )
                                )
                              ),
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", "aria-label": "Close modal" },
                              on: {
                                click: function ($event) {
                                  _vm.dialog = false
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-card-subtitle", { staticClass: "pa-0 mt-2" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "timesheetUI.ValidationMessages.DeleteErrorMessage"
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c("v-card-text", { staticClass: "py-5 px-6" }, [
                    _c("div", { staticClass: "d-flex gap-6" }, [
                      _c(
                        "div",
                        { staticClass: "flex-grow-1" },
                        [
                          _c("v-label", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "timesheetUI.EntryFormLabels.TimesheetEntryJobName"
                                )
                              )
                            ),
                          ]),
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              value: _vm.deletingData
                                ? _vm.deletingData.jobName
                                : "",
                              disabled: "",
                              "hide-details": "",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "flex-grow-1" },
                        [
                          _c("v-label", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "timesheetUI.EntryFormLabels.TimesheetEntryJobID"
                                )
                              )
                            ),
                          ]),
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              value: _vm.deletingData
                                ? _vm.deletingData.job_ID
                                : "",
                              disabled: "",
                              "hide-details": "",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    { staticClass: "py-5 px-6" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-auto",
                          attrs: {
                            color: "primary",
                            depressed: "",
                            "min-width": "88px",
                            height: "40px",
                            rounded: "",
                            outlined: "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.dialog = false
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("General.Btns.Close")) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }