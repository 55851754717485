<template>
  <div class="d-flex justify-space-between align-start">
    <div class="timesheet-entry__header flex-grow-1">
      <div class="d-flex justify-space-between align-center mb-2">
        <h2 class="text-h2">
          {{ timesheetExistsDate }}
        </h2>
        <v-btn
          icon
          @click="handleClose"
          :disabled="savingTimesheet"
          aria-label="Close Dialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="h5 d-flex flex-wrap font-weight--400 break-word">
        {{ timesheetExistsSubtitle }}

        <span
          class="d-flex ml-1"
          v-if="timesheetSelectedJob.job.selfAssignmentWithManageTasking"
        >
          •
          <span class="d-inline-flex ml-1 flex-wrap">
            <span class="font-color--primary">{{
              $t('timesheetUI.TimesheetJobs.SelfAssignmentProjectLabel')
            }}</span>
          </span>
        </span>

        <!-- <span
          v-else-if="
            timesheetSelectedJob.job.isClarizenAssignedProject &&
            timesheetSelectedJob.job.isClarizenTask
          "
          class="d-inline-flex flex-wrap font-color--primary ml-1"
        >
          •
          <span class="ml-1 d-flex"
            ><span
              >{{
                $t('timesheetUI.TimesheetJobs.ClarizenAssignProjectLabel')
              }} </span
            ><v-icon color="primary" small class="clarizen-task__label"
              >mdi-check</v-icon
            ></span
          >
        </span>

        <span
          class="d-flex ml-1"
          v-else-if="
            timesheetSelectedJob.job.isClarizenTask ||
            timesheetSelectedJob.job.selfAssignmentWithManageTasking
          "
        >
          • {{ timesheetSelectedJob.job.clarizenTaskName }} •
          <span class="d-inline-flex ml-1 flex-wrap">
            <span class="font-color--primary">{{
              $t('timesheetUI.TimesheetJobs.ClarizenAssignTaskLabel')
            }}</span>
          </span>
        </span> -->

        <span
          v-if="timesheetSelectedJob.job.crossCompanyRuleTypeName"
          class="accent--text job-dialog__meta"
        >
          <span class="font-color--body"> • </span>
          <span class="d-inline-flex ml-1 flex-wrap"
            >{{ timesheetSelectedJob.job.crossCompanyRuleTypeName }}
          </span>
          <span class="d-inline-flex">
            <v-icon class="cross-company-rule__label">mdi-star</v-icon></span
          >
        </span>
      </div>
    </div>

    <!-- <v-btn
      icon
      @click="handleClose"
      :disabled="loading || savingTimesheet || loadingFeatures"
      aria-label="Close Dialog"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn> -->
  </div>
</template>
<script>
import { mapState } from 'vuex'
import moment from 'moment'

export default {
  name: 'TimeEntryDrawerHeader.vue',
  props: {
    handleClose: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState('storeTimesheets', [
      'timesheetSelectedJob',
      'loading',
      'savingTimesheet',
      'loadingFeatures',
      'observer',
    ]),
    timesheetExistsDate() {
      const locale = window.CULTURE === 'fr-FR' ? 'fr' : 'en'
      return moment(this.timesheetSelectedJob.date.split('T')[0])
        .locale(locale)
        .format('dddd, DD MMM')
    },
    timesheetExistsSubtitle() {
      return `${
        this.timesheetSelectedJob.job.isClarizenTask ||
        this.timesheetSelectedJob.job.selfAssignmentWithManageTasking
          ? this.timesheetSelectedJob.job.clarizenProjectName
          : this.timesheetSelectedJob.job.jobName
      } • ${this.timesheetSelectedJob.job.brandName} • ${
        this.timesheetSelectedJob.job.agencyName
      } • ${this.timesheetSelectedJob.job.job_ID}`
    },
  },
}
</script>
<style lang="scss" scoped>
// .timesheet-entry__header {
//   h1 {
//     font-size: 24px;
//   }
// }
</style>
