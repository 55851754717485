var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VBtn",
    {
      attrs: {
        depressed: "",
        rounded: "",
        color: _vm.color || "button-background",
        height: _vm.height || 40,
      },
      on: { click: _vm.copyJobsFromPreviousWeek },
    },
    [
      _c("span", { staticClass: "font-weight--600" }, [
        _vm._v(_vm._s(_vm.$t("timesheetUI.Empty.CopyJobFromPreviousWeek"))),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }