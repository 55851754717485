var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VRow",
    { staticClass: "root-row mx-n5", attrs: { "no-gutters": "" } },
    [
      _c("div", { staticClass: "ml-5" }),
      _c(
        "VCol",
        { staticClass: "root-column" },
        [
          _vm.$slots.default
            ? [_vm._t("default")]
            : _c("GridRow", {
                attrs: { "no-borders": _vm.noBorders },
                scopedSlots: _vm._u(
                  [
                    _vm._l(_vm.$slots, function (_, slotName) {
                      return {
                        key: slotName,
                        fn: function () {
                          return [_vm._t(slotName)]
                        },
                        proxy: true,
                      }
                    }),
                  ],
                  null,
                  true
                ),
              }),
        ],
        2
      ),
      _c("div", { staticClass: "mr-5" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }