<template>
  <div class="grid-row-group">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'GridRowGroup',
}
</script>

<style scoped lang="scss">
.grid-row-group {
  border: 1px solid $border-color;
  border-radius: var(--grid-row-border-radius);
}
::v-deep .row {
  border: none;
  border-radius: unset;

  &:not(:last-child) {
    border-bottom: 1px solid $border-color;
  }

  .col {
    border-radius: unset;
  }

  &:first-child {
    .col:first-child {
      border-top-left-radius: var(--grid-cell-border-radius);
    }

    .col:last-child {
      border-top-right-radius: var(--grid-cell-border-radius);
    }
  }

  &:last-child {
    .col:first-child {
      border-bottom-left-radius: var(--grid-cell-border-radius);
    }

    .col:last-child {
      border-bottom-right-radius: var(--grid-cell-border-radius);
    }
  }
}
</style>
