<template>
  <div
    class="time-entry-cell height-100 d-flex"
    :class="`${multiple ? 'multiple-entries' : ''}`"
    @dblclick="$emit('dblclick', $event.target.value)"
  >
    <VTextField
      :value="value"
      ref="timeEntryInput"
      @focus.prevent="selectCellText"
      @blur="$emit('blur', $event)"
      :class="`time-entry--cell glow ${shouldGlow ? 'active' : ''} ${
        activeCell === id ? 'blue-glow' : ''
      }`"
      dense
      outlined
      :readonly="multiple || readonly"
      @input="$emit('input', $event)"
      @keydown="handleKeyDown"
      :error="!!error"
      :disabled="disabled"
      :loading="loading"
      :id="id"
      :messages="error"
      :data-timsheet-cell-job="disabled ? null : jobId"
      :aria-label="`${jobName || ''} - ${humanFriendlyDate}, ${$t(
        'timesheetUI.Tooltips.InlineCellInfo'
      )}`"
    >
      <template v-if="error" #prepend-inner>
        <div class="d-flex flex-column">
          <Tooltip top>
            <template v-slot:trigger="{ manualHandler }">
              <VBtn
                aria-label="warning message"
                :aria-describedby="`error-message-${id}`"
                x-small
                icon
                color="error"
                @focus="() => manualHandler(true)"
                @blur="() => manualHandler(false)"
              >
                <VIcon>mdi-information-outline</VIcon>
              </VBtn>
            </template>
            <template v-slot:body>
              <span :id="`error-message-${id}`" v-html="error"></span>
            </template>
          </Tooltip>
          <!-- <a class="mt-1" style="font-size: 10px">details</a> -->
        </div>
      </template>

      <template v-slot:message="{}">
        <div v-if="error" class="text-center message-container">
          <a
            class="font-size--10 error--text"
            tabindex="0"
            @click="$emit('dblclick', $event.target.value)"
            @keypress.enter="$emit('dblclick', $event.target.value)"
            >{{ $t('timesheetUI.TableColumns.Details') }}</a
          >
        </div></template
      >
    </VTextField>
  </div>
</template>

<script>
import moment from 'moment'
import Tooltip from '../TimesheetTooltipV2'

export default {
  name: 'TimeEntryCell',
  components: {
    Tooltip,
  },
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Number, String],
      required: true,
    },
    error: {
      type: String,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    shouldGlow: {
      type: Boolean,
      default: false,
    },
    id: {
      default: '',
    },
    jobId: {
      type: [String, null, undefined],
      default: null,
    },
    jobName: {
      type: [String, null, undefined],
      default: null,
    },
    day: {
      type: Object,
      default: () => {},
    },
    activeCell: {
      type: String,
      default: undefined,
    },
    timesheetStatus: {
      type: String,
      default: undefined,
    },
  },
  methods: {
    selectCellText() {
      this.$refs.timeEntryInput.$el.querySelector('input').select()
      this.$emit('focus')
    },

    handleKeyDown(e) {
      if (![13].includes(e.keyCode)) return

      this.$emit('enterdown')
    },
  },
  computed: {
    humanFriendlyDate() {
      return moment(this.day.date).format('dddd MMMM YYYY')
    },
  },
}
</script>

<style scoped lang="scss">
.time-entry-cell.multiple-entries {
  border-radius: $app-border-radius;
  box-shadow: $border-color 0px 0px 0px 1px inset,
    rgb(255, 255, 255) -2px 2px 0px -1px, $border-color -2px 2px,
    rgb(255, 255, 255) -4px 4px 0px -1px, $border-color -4px 4px;
}

.time-entry-cell {
  .message-container {
    min-height: 15px;
  }
}

.time-entry-cell::v-deep {
  .v-input {
    align-items: stretch;
  }

  .v-input__control {
    align-items: stretch;
  }

  .v-input__slot {
    flex-grow: 1;
    margin: 0;
  }

  input {
    text-align: right;
  }

  .v-messages {
    min-height: 0;
  }

  .v-text-field__details {
    flex-grow: 0;
    margin: 0;
    padding: 0;
    min-height: 0;
  }
}
</style>
