<template>
  <div class="d-flex align-center">
    <div>
      <span v-if="!agencyData.isPercentageAgency">{{ hoursTotal.hours }}</span>
      <span v-else-if="agencyData.isPercentageAgency && mixedTimeEntry"
        >{{ hoursTotal.hours }}<span v-if="mixedTimeEntry">h</span></span
      >
      <span v-else>{{ hoursTotal.percent }}</span>
    </div>
    <timesheet-tooltip-v2 top v-if="!disableTooltip">
      <template v-slot:trigger>
        <v-btn
          icon
          x-small
          color="error"
          :aria-label="`warning message`"
          aria-describedby="dailyHoursTotalMessage"
        >
          <span
            id="dailyHoursTotalMessage"
            v-show="false"
            v-html="getTooltipMessage"
          ></span>
          <v-icon>mdi-information-outline</v-icon>
        </v-btn>
      </template>
      <template v-slot:body>
        <div id="dailyHoursTotal">
          <div class="pa-2" v-if="isTimeSaved">
            <div>
              <template v-if="!agencyData.isPercentageAgency">
                {{ timesheetStatusStats.approved.hours }}h
              </template>
              <span v-else-if="agencyData.isPercentageAgency && mixedTimeEntry"
                >{{ timesheetStatusStats.approved.hours
                }}<span v-if="mixedTimeEntry">h</span></span
              >
              <template v-else>
                {{ timesheetStatusStats.approved.percent }}%
              </template>
              {{ $t('timesheetUI.Status.Approved') }}
            </div>
            <div>
              <template v-if="!agencyData.isPercentageAgency">
                {{ timesheetStatusStats.rejected.hours }}h
              </template>
              <span v-else-if="agencyData.isPercentageAgency && mixedTimeEntry"
                >{{ timesheetStatusStats.rejected.hours
                }}<span v-if="mixedTimeEntry">h</span></span
              >
              <template v-else>
                {{ timesheetStatusStats.rejected.percent }}%
              </template>
              {{ $t('timesheetUI.Status.Rejected') }}
            </div>
            <div>
              <template v-if="!agencyData.isPercentageAgency">
                {{ timesheetStatusStats.submitted.hours }}h
              </template>
              <span v-else-if="agencyData.isPercentageAgency && mixedTimeEntry"
                >{{ timesheetStatusStats.submitted.hours
                }}<span v-if="mixedTimeEntry">h</span></span
              >
              <template v-else>
                {{ timesheetStatusStats.submitted.percent }}%
              </template>
              {{ $t('timesheetUI.Status.Submitted') }}
            </div>
          </div>
          <div
            class="pa-2"
            v-else-if="getStatusMessage"
            v-html="getStatusMessage"
          ></div>
        </div>
      </template>
    </timesheet-tooltip-v2>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import TimesheetTooltipV2 from '../../TimesheetTooltipV2'
import i18n from '@Shared/i18n'

export default {
  name: 'PercentageTotal',
  components: {
    TimesheetTooltipV2,
  },
  props: [
    'disableTooltip',
    'timesheetCanBeSubmitted',
    'hoursTotal',
    'mixedTimeEntry',
    'timesheetHoursPerDayComputed',
    'timeSchduleComputed',
  ],
  computed: {
    ...mapState('storeTimesheets', ['agencyData', 'timesheets']),
    ...mapGetters('storeTimesheets', ['timesheetStatusStats']),
    isTimeSaved() {
      // return this.hoursTotal.hours > 0 || this.hoursTotal.percent > 0
      return (
        this.timesheets.filter((t) => t.timesheetStatus !== 'Unsubmitted')
          .length > 0
      )
    },
    getTooltipMessage() {
      let approvedMessage = ''
      let rejectedMessage = ''
      let submittedMessage = ''
      if (this.isTimeSaved) {
        if (!this.agencyData.isPercentageAgency) {
          approvedMessage = `${this.timesheetStatusStats.approved.hours} h`
          rejectedMessage = `${this.timesheetStatusStats.rejected.hours} h`
          submittedMessage = `${this.timesheetStatusStats.submitted.hours} h`
        } else if (this.agencyData.isPercentageAgency && this.mixedTimeEntry) {
          approvedMessage = `${this.timesheetStatusStats.approved.hours} h`
          rejectedMessage = `${this.timesheetStatusStats.rejected.hours} h`
          submittedMessage = `${this.timesheetStatusStats.submitted.hours} h`
        } else {
          approvedMessage = `${this.timesheetStatusStats.approved.percent} %`
          rejectedMessage = `${this.timesheetStatusStats.rejected.percent} %`
          submittedMessage = `${this.timesheetStatusStats.submitted.percent} %`
        }
        approvedMessage += this.$t('timesheetUI.Status.Approved')
        rejectedMessage += this.$t('timesheetUI.Status.Rejected')
        submittedMessage += this.$t('timesheetUI.Status.Submitted')
        return {
          approvedMessage,
          rejectedMessage,
          submittedMessage,
        }
      }
      return this.getStatusMessage
    },
    getStatusMessage() {
      if (
        !this.agencyData.isPercentageAgency &&
        (this.agencyData.dwIndicator === '1' ||
          this.agencyData.dwIndicator === '2')
      ) {
        if (
          this.timesheetHoursPerDayComputed.calendarType === 'normalTimesheet'
        ) {
          return i18n.t('timesheetUI.Tooltips.Threshold.message', {
            totalPerDay: this.timesheetHoursPerDayComputed.allValueSame,
          })
        } else if (
          this.timesheetHoursPerDayComputed.calendarType ===
          'spanishNormalTimesheet'
        ) {
          return i18n.t(
            'timesheetUI.Tooltips.Threshold.alertHoursSpanishNormal'
          )
        } else if (
          this.timesheetHoursPerDayComputed.calendarType ===
          'spanishSummerTimesheet'
        ) {
          return i18n.t(
            'timesheetUI.Tooltips.Threshold.alertHoursSpanishSummer'
          )
        } else if (
          this.timesheetHoursPerDayComputed.calendarType ===
          'spanishLeavingSummerTimesheet'
        ) {
          return i18n.t(
            'timesheetUI.Tooltips.Threshold.alertHoursSpanishSummerLeaving',
            {
              timeSchedule: this.timeSchduleComputed,
            }
          )
        } else if (
          this.timesheetHoursPerDayComputed.calendarType ===
          'spanishEnteringSummerTimesheet'
        ) {
          return i18n.t(
            'timesheetUI.Tooltips.Threshold.alertHoursSpanishSummerEntering',
            {
              timeSchedule: this.timeSchduleComputed,
            }
          )
        } else {
          return false
        }
      } else if (
        !this.agencyData.isPercentageAgency &&
        this.agencyData.dwIndicator === '3'
      ) {
        return i18n.t('timesheetUI.Tooltips.Threshold.messageWeek', {
          totalPerWeek: this.timesheetHoursPerDayComputed.totalHours,
        })
      } else if (this.agencyData.isPercentageAgency && this.mixedTimeEntry) {
        return i18n.t('timesheetUI.Tooltips.Threshold.messageMixed', {
          totalPerDay: this.agencyData.timesheetHoursPerDay,
        })
      } else {
        return i18n.t('timesheetUI.Tooltips.Threshold.messagePercentage')
      }
    },
  },
}
</script>
