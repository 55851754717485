import { getTimesheets, getTimesheetsDuplicate } from '@Shared/filters'
import i18n from '@Shared/i18n'
import moment from 'moment'
import {
  filter,
  flow,
  identity,
  values,
  join,
  toLower,
  partialRight,
  includes,
} from 'lodash'
import store from '../store/store'

export const filterItemsByKeyword = (array, substr) => {
  return filter(
    array,
    flow(identity, values, join, toLower, partialRight(includes, substr))
  )
}

export const timesheetsForDay = (
  timesheetJob,
  timesheets,
  date,
  returnTimesheets = true
) => {
  return (timesheetJob.isClarizenAssignedProject &&
    timesheetJob.isClarizenTask) ||
    timesheetJob.selfAssignmentWithManageTasking
    ? getTimesheetsDuplicate(
        timesheets,
        timesheetJob.clarizenProjectId,
        date,
        returnTimesheets,
        timesheetJob.isClarizenTask,
        timesheetJob.clarizenTaskExternalId,
        timesheetJob.task,
        store.getters['storeTimesheets/currentDayJobFeatures'](
          timesheetJob,
          date
        ).isHistoricalJob
      )
    : getTimesheets(timesheets, timesheetJob.id, date)
}

export const durationValueValidation = (
  value,
  state,
  hoursTotalPerDay,
  newItem,
  dayIndex,
  fractionAllowed,
  isValidation = true,
  isPercentageAgency = false,
  previousValue = 0
) => {
  // prevent validation on zeroing out a value
  if (parseFloat(previousValue) > 0 && parseFloat(value) == 0) {
    return true
  }

  if (state.timesheetDetail) {
    if (state.timesheetDetail.disablePPMSelect) {
      if (state.timesheetDetail.ppmTaskGone && parseFloat(value) !== 0) {
        return i18n.t('timesheetUI.ValidationMessages.TimesheetPPMTaskGone')
      }
    }

    if (state.timesheetDetail?.restrictedPostEntry) {
      if (parseFloat(value) !== 0) {
        return i18n.t(
          'timesheetUI.ValidationMessages.TimesheetEntryRestrictionZeroOnly'
        )
      }
    }
  }

  const toSubtract = newItem ? 0 : previousValue

  if (
    state.timesheetDetailFeatures?.percentageOrDuration.data
      .isPercentageAgency ||
    isPercentageAgency
  ) {
    if (isNaN(value)) {
      return i18n.t(
        'timesheetUI.ValidationMessages.TimesheetNotCorrectIncrementQuantity'
      )
    }

    if (parseFloat(value) > 100) {
      return i18n.t('timesheetUI.ValidationMessages.TimesheetExceeded100')
    }

    const finalValue = isValidation
      ? hoursTotalPerDay[dayIndex].percent - toSubtract + parseFloat(value)
      : hoursTotalPerDay[dayIndex].percent - toSubtract + parseFloat(value)

    if (finalValue > 100) {
      return i18n.t('timesheetUI.ValidationMessages.TimesheetExceeded100')
    }

    if (state.isTimesheetBeingCorrected) {
      const oldDuration = state.timesheetDetailOld.durationPercentageValue
      const newDuration = state.timesheetDetail.durationPercentageValue

      if (
        hoursTotalPerDay[dayIndex].percent -
          parseFloat(oldDuration) +
          parseFloat(newDuration) >
        100
      )
        return i18n.t('timesheetUI.ValidationMessages.TimesheetExceeded100')
    }

    if ((parseFloat(value) * 100) % (1 * 100) !== 0) {
      return i18n.t(
        'timesheetUI.ValidationMessages.TimesheetNotCorrectIncrement',
        { step: 1 }
      )
    }

    if (parseFloat(value) < 0) {
      return i18n.t(
        'timesheetUI.ValidationMessages.TimesheetDurationMustBeZeroOrMore'
      )
    }
  } else {
    if (isNaN(value)) {
      return i18n.t('timesheetUI.ValidationMessages.TimesheetNotANumber')
    }

    if (parseFloat(value) > 24) {
      return i18n.t(
        'timesheetUI.ValidationMessages.DailyTotalDurationExceededMessage'
      )
    }

    if (parseFloat(value) < 0) {
      return i18n.t(
        'timesheetUI.ValidationMessages.TimesheetDurationMustBeZeroOrMore'
      )
    }

    const finalHourValue = isValidation
      ? hoursTotalPerDay[dayIndex].hours - toSubtract + parseFloat(value)
      : hoursTotalPerDay[dayIndex].hours - toSubtract + parseFloat(value)

    // hoursTotalPerDay[dayIndex].hours + (newItem ? parseFloat(value) : 0)
    if (finalHourValue > 24) {
      return i18n.t(
        'timesheetUI.ValidationMessages.DailyTotalDurationExceededMessage'
      )
    }

    if (state.isTimesheetBeingCorrected) {
      const oldDuration = state.timesheetDetailOld.durationValue
      const newDuration = state.timesheetDetail.durationValue

      if (
        hoursTotalPerDay[dayIndex].hours -
          parseFloat(oldDuration) +
          parseFloat(newDuration) >
        24
      )
        return i18n.t(
          'timesheetUI.ValidationMessages.DailyTotalDurationExceededMessage'
        )
    }

    const valueStandardised = (parseFloat(value) * 100).toFixed(2)
    const fractionStandardised = (fractionAllowed * 100).toFixed(2)

    if (
      parseFloat(valueStandardised) % parseFloat(fractionStandardised) !==
      0
    ) {
      return i18n.t(
        'timesheetUI.ValidationMessages.TimesheetNotCorrectIncrement',
        { step: fractionAllowed }
      )
    }

    if (isValidation && state.validateAll) {
      state.validateAll = false
      state.observer.validate()
    }
  }

  return true
}

export const getTimesheetRequiredFields = (job, date) => {
  if (!job.isJobVisible_V2) return {}
  const currentDayJob = job.availableFeaturesBillableIndicators?.find(
    (feat) =>
      moment(feat.date, 'DD/MM/YYYY').format('DD-MM-YYYY') ===
      moment(date).format('DD-MM-YYYY')
  )

  if (!currentDayJob) return false

  const features = currentDayJob.availablefeatures.features

  const featureKeys = Object.keys(features)

  let requiredFeatures = {}

  featureKeys.forEach((key) => {
    if (features[key].required && key !== 'percentageOrDuration') {
      requiredFeatures[key] = features[key].data ?? false
    }
    if (features[key].required && key == 'percentageOrDuration') {
      requiredFeatures.durationValue = features[key].data ?? false
    }
  })

  return requiredFeatures
}

export const isValidBasedOnRequiredFields = (payload) => {
  const requiredFields = getTimesheetRequiredFields(payload.job, payload.date)

  const keys = Object.keys(requiredFields)

  for (const key of keys) {
    if (key === 'activity' && !requiredFields[key]?.activities?.length) {
      return false
    }

    if (['activity'].includes(key)) {
      return false
    }

    if (
      ['task'].includes(key) &&
      payload.job.availableFeaturesBillableIndicators[payload.detail.dayIndex]
        .isHistoricalJob
    ) {
      return false
    }

    if (
      key === 'location' &&
      (!payload.detail?.location || !payload.detail?.locationCode)
    ) {
      return false
    }
  }

  if (
    payload.agencyData.isWFHActive &&
    [undefined, ''].includes(payload.detail?.['wfh'])
  ) {
    return false
  }
  return true
}

export const getLocationWfhSettings = (settings, date, locationList) => {
  const getUserSettingByDay = settings.find(
    (s) =>
      s?.date &&
      moment(s.date).format('dddd').toLowerCase() ===
        moment(date).format('dddd').toLowerCase()
  )

  const getUserSettingByWeek = settings.find(
    (s) => s.isLocationRestOfWeek || s.isWfhRestOfWeek
  )
  const selectedLocationByDayObj = locationList
    .filter((l) => !l?.header)
    .find((l) => l.value === getUserSettingByDay?.location)

  const selectedLocationFullweek = locationList
    .filter((l) => !l?.header)
    .find((l) => l.value === getUserSettingByWeek?.location)

  const tranformedWfhByDay =
    getUserSettingByDay?.wfh === 'Yes'
      ? true
      : getUserSettingByDay?.wfh === 'No'
      ? false
      : getUserSettingByDay?.wfh

  const tranformedWfhByWeek =
    getUserSettingByWeek?.wfh === 'Yes'
      ? true
      : getUserSettingByWeek?.wfh === 'No'
      ? false
      : getUserSettingByWeek?.wfh

  return {
    location:
      selectedLocationByDayObj?.label ?? selectedLocationFullweek?.label ?? '',
    locationCode:
      selectedLocationByDayObj?.value ?? selectedLocationFullweek?.value ?? '',
    wfh: tranformedWfhByDay ?? tranformedWfhByWeek ?? '',
  }
}

export const validForEntry = (timesheetExists, job, date) => {
  const currentDayJob = job.availableFeaturesBillableIndicators?.find(
    (feat) =>
      moment(feat.date, 'DD/MM/YYYY').format('DD-MM-YYYY') ===
      moment(date).format('DD-MM-YYYY')
  )

  if (timesheetExists) {
    if (
      currentDayJob?.availablefeatures?.features?.allowPTOWorkcode?.required
    ) {
      return true
    }
    return timesheetExists.every((a) => a.workCode || a.activity)
  }

  if (currentDayJob?.availablefeatures?.features) {
    if (currentDayJob.availablefeatures.features?.workcode?.required) {
      return currentDayJob.availablefeatures.features.workcode.data.workCodes
        .length
    }

    if (currentDayJob.availablefeatures.features.activity.required) {
      return currentDayJob.availablefeatures.features.activity.data?.activities
        ?.length
    }
  }

  return false
}

export const isActivityRequired = (job, date) => {
  const currentDayJob = job.availableFeaturesBillableIndicators?.find(
    (feat) =>
      moment(feat.date, 'DD/MM/YYYY').format('DD-MM-YYYY') ===
      moment(date).format('DD-MM-YYYY')
  )

  return currentDayJob?.availablefeatures?.features?.activity?.required
}

export const validateTotalDurationPerDay = (
  hoursPerDay,
  agencyData,
  dayIndex = null
) => {
  if (dayIndex === null) {
    const isPercentageAgency = agencyData?.isPercentageAgency
    if (isPercentageAgency) {
      return hoursPerDay.every((item) => item.percent <= 100)
        ? { isValid: true }
        : {
            isValid: false,
            message: i18n.t(
              'timesheetUI.ValidationMessages.TimesheetExtraPercentageDurationErrorMessage',
              window.CULTURE
            ),
          }
    } else {
      return hoursPerDay.every((item) => item.hours <= 24)
        ? { isValid: true }
        : {
            isValid: false,
            message: i18n.t(
              'timesheetUI.ValidationMessages.TimesheetExtraHoursDurationErrorMessage',
              window.CULTURE
            ),
          }
    }
  }

  if (dayIndex !== null) {
    const isPercentageAgency = agencyData?.isPercentageAgency
    if (isPercentageAgency) {
      return hoursPerDay[dayIndex].percent <= 100
        ? { isValid: true }
        : {
            isValid: false,
            message: i18n.t(
              'timesheetUI.ValidationMessages.TimesheetExtraPercentageDurationErrorMessage',
              window.CULTURE
            ),
          }
    } else {
      return hoursPerDay[dayIndex].hours <= 24
        ? { isValid: true }
        : {
            isValid: false,
            message: i18n.t(
              'timesheetUI.ValidationMessages.TimesheetExtraHoursDurationErrorMessage',
              window.CULTURE
            ),
          }
    }
  }
}
