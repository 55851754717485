<template>
  <VRow no-gutters class="root-row mx-n5">
    <div class="ml-5"></div>
    <VCol class="root-column">
      <template v-if="$slots.default">
        <slot></slot>
      </template>
      <GridRow v-else :no-borders="noBorders">
        <template v-for="(_, slotName) in $slots" #[slotName]>
          <slot :name="slotName"></slot>
        </template>
      </GridRow>
    </VCol>
    <div class="mr-5"></div>
  </VRow>
</template>

<script>
import GridRow from './GridRow.vue'

export default {
  name: 'GridRowWithPageOffset',
  props: {
    noBorders: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    GridRow,
  },
}
</script>

<style scoped lang="scss">
.root-row {
  border: 1px solid $border-color;
  border-radius: var(--grid-row-border-radius);

  .row {
    border: none;
  }
}
</style>
