var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "timesheet-status__icons" },
    [
      _c("timesheet-tooltip-v2", {
        attrs: {
          top: "",
          "nudge-top": 5,
          "live-region-text": _vm.tooltipText,
          openHover: true,
        },
        scopedSlots: _vm._u([
          {
            key: "trigger",
            fn: function () {
              return [
                _vm.isAnyTimesheetRejected.result ||
                _vm.isAnyTimesheetApproved.result ||
                _vm.isAnyTimesheetSubmitted.result
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "job-group__day--mulitiple-records",
                        attrs: {
                          text: "",
                          "aria-label":
                            _vm.humanFriendlyDate +
                            ", " +
                            "Status: " +
                            (_vm.isAnyTimesheetRejected.result
                              ? `${_vm.isAnyTimesheetRejected.count} ${
                                  _vm.isAnyTimesheetRejected.count > 1
                                    ? "timesheets"
                                    : "timesheet"
                                } rejected`
                              : "") +
                            (_vm.isAnyTimesheetApproved.result
                              ? `${_vm.isAnyTimesheetApproved.count} ${
                                  _vm.isAnyTimesheetApproved.count > 1
                                    ? "timesheets"
                                    : "timesheet"
                                } approved`
                              : "") +
                            (_vm.isAnyTimesheetSubmitted.result
                              ? `${_vm.isAnyTimesheetSubmitted.count} ${
                                  _vm.isAnyTimesheetSubmitted.count > 1
                                    ? "timesheets"
                                    : "timesheet"
                                } submitted`
                              : ""),
                          "min-width": "0px",
                          height: "34",
                          "x-small": "",
                          "retain-focus-on-click": "",
                        },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "status-btn-group" },
                          [
                            _vm.isAnyTimesheetRejected.result
                              ? _c("StatusIcon", {
                                  attrs: { status: "rejected", tabindex: -1 },
                                })
                              : _vm._e(),
                            _vm.isAnyTimesheetApproved.result
                              ? _c("StatusIcon", {
                                  attrs: { status: "approved", tabindex: -1 },
                                })
                              : _vm._e(),
                            _vm.isAnyTimesheetSubmitted.result
                              ? _c("StatusIcon", {
                                  attrs: { status: "submitted", tabindex: -1 },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [_vm._v(_vm._s(_vm.tooltipText))]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }