<template>
  <VRow no-gutters align="stretch" :class="{ 'no-borders': noBorders }">
    <VCol cols="3" class="first-col">
      <slot name="col1"></slot>
    </VCol>
    <VCol>
      <slot name="mon"> </slot>
    </VCol>
    <VCol>
      <slot name="tue"> </slot>
    </VCol>
    <VCol>
      <slot name="wed"> </slot>
    </VCol>
    <VCol>
      <slot name="thu"> </slot>
    </VCol>
    <VCol>
      <slot name="fri"> </slot>
    </VCol>
    <VCol>
      <slot name="sat"> </slot>
    </VCol>
    <VCol>
      <slot name="sun"> </slot>
    </VCol>
    <VCol class="last-col">
      <slot name="total"> </slot>
    </VCol>
  </VRow>
</template>

<script>
export default {
  name: 'GridRow',
  props: {
    noBorders: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped lang="scss">
.row {
  border: 1px solid $border-color;
  border-radius: var(--grid-row-border-radius);

  .col:not(:first-child) {
    border-left: 1px solid $border-color;
  }

  &.no-borders {
    .col {
      border: none;
    }
  }
}

.first-col {
  border-top-left-radius: var(--grid-cell-border-radius);
  border-bottom-left-radius: var(--grid-cell-border-radius);
}

.last-col {
  border-top-right-radius: var(--grid-cell-border-radius);
  border-bottom-right-radius: var(--grid-cell-border-radius);
}
</style>
