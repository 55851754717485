var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-icon",
    {
      staticClass: "material-symbols-outlined status-icon",
      attrs: {
        color: _vm.statusConfig[_vm.status].color ?? "#000",
        "aria-label": _vm.statusConfig[_vm.status].aria_label ?? "Status",
        tabindex: _vm.tabindex,
        size: _vm.size,
      },
    },
    [_vm._v(" " + _vm._s(_vm.statusConfig[_vm.status].icon) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }