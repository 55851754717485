var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-tooltip",
    {
      attrs: {
        top: _vm.top,
        left: _vm.left,
        right: _vm.right,
        bottom: _vm.bottom,
        "nudge-left": _vm.nudgeLeft,
        "nudge-right": _vm.nudgeRight,
        "nudge-top": _vm.nudgeTop,
        "nudge-bottom": _vm.nudgeBottom,
        absolute: _vm.absolute,
        "offset-x": _vm.offsetX,
        "offset-y": _vm.offsetY,
        origin: "top center",
        "open-on-hover": _vm.openHover,
        "open-on-click": "",
        disabled: _vm.disabled,
        "content-class": `timesheet-tooltip__v-tooltip ${_vm.tooltipTriangleClass}`,
      },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function ({ on, attrs }) {
              return [
                _c(
                  "div",
                  _vm._g(
                    _vm._b(
                      {
                        directives: [
                          {
                            name: "click-outside",
                            rawName: "v-click-outside",
                            value: _vm.hideToolTip,
                            expression: "hideToolTip",
                          },
                        ],
                        staticClass: "tooltip-trigger",
                        on: {
                          click: function ($event) {
                            _vm.show = !_vm.show
                          },
                        },
                      },
                      "div",
                      attrs,
                      false
                    ),
                    on
                  ),
                  [
                    _vm._t("trigger", null, {
                      show: _vm.show,
                      manualHandler: _vm.manualHandler,
                    }),
                    _c("span", {
                      ref: "liveRegion",
                      staticClass: "sr-only",
                      attrs: { role: "status" },
                    }),
                  ],
                  2
                ),
              ]
            },
          },
        ],
        null,
        true
      ),
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        {
          ref: "tooltipBody",
          staticClass: "timesheet-tooltip__body",
          attrs: { rounded: "xl" },
        },
        [_c("v-card-text", { staticClass: "px-3 py-2" }, [_vm._t("body")], 2)],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }