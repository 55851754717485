<template>
  <div class="timesheet-status__icons">
    <timesheet-tooltip-v2
      top
      :nudge-top="5"
      :live-region-text="tooltipText"
      :openHover="true"
    >
      <template v-slot:trigger>
        <v-btn
          class="job-group__day--mulitiple-records"
          text
          :aria-label="
            humanFriendlyDate +
            ', ' +
            'Status: ' +
            (isAnyTimesheetRejected.result
              ? `${isAnyTimesheetRejected.count} ${
                  isAnyTimesheetRejected.count > 1 ? 'timesheets' : 'timesheet'
                } rejected`
              : '') +
            (isAnyTimesheetApproved.result
              ? `${isAnyTimesheetApproved.count} ${
                  isAnyTimesheetApproved.count > 1 ? 'timesheets' : 'timesheet'
                } approved`
              : '') +
            (isAnyTimesheetSubmitted.result
              ? `${isAnyTimesheetSubmitted.count} ${
                  isAnyTimesheetSubmitted.count > 1 ? 'timesheets' : 'timesheet'
                } submitted`
              : '')
          "
          min-width="0px"
          height="34"
          x-small
          v-if="
            isAnyTimesheetRejected.result ||
            isAnyTimesheetApproved.result ||
            isAnyTimesheetSubmitted.result
          "
          retain-focus-on-click
        >
          <span class="status-btn-group">
            <StatusIcon
              v-if="isAnyTimesheetRejected.result"
              status="rejected"
              :tabindex="-1"
            />
            <StatusIcon
              v-if="isAnyTimesheetApproved.result"
              status="approved"
              :tabindex="-1"
            />
            <StatusIcon
              v-if="isAnyTimesheetSubmitted.result"
              status="submitted"
              :tabindex="-1"
            />
          </span>
        </v-btn>
      </template>
      <template v-slot:body>{{ tooltipText }}</template>
    </timesheet-tooltip-v2>
  </div>
</template>

<script>
import { formatDayCellTooltipText } from '@Shared/filters'
import { mapState } from 'vuex'
import StatusIcon from './StatusIcon'
// import TimesheetTooltip from './TimesheetTooltip.vue'
import TimesheetTooltipV2 from './TimesheetTooltipV2.vue'

import i18n from '@Shared/i18n'
import moment from 'moment'
import { timesheetsForDay } from '@/utils/timesheetHelper'

export default {
  name: 'TimesheetDayCellStatusIcons',
  props: ['day', 'timesheetJob'],
  components: {
    StatusIcon,
    // TimesheetTooltip,
    TimesheetTooltipV2,
  },
  computed: {
    ...mapState('storeTimesheets', ['timesheets']),
    isAnyTimesheetSubmitted() {
      const count = this.timesheetForDay().filter(
        (t) => t.timesheetStatus.toLowerCase() === 'submitted'
      ).length
      const result = count > 0

      return {
        result,
        count,
      }
    },
    isAnyTimesheetRejected() {
      const count = this.timesheetForDay().filter(
        (t) => t.timesheetStatus.toLowerCase() === 'rejected'
      ).length
      const result = count > 0

      return {
        result,
        count,
      }
    },
    isAnyTimesheetApproved() {
      const count = this.timesheetForDay().filter(
        (t) => t.timesheetStatus.toLowerCase() === 'approved'
      ).length
      const result = count > 0
      return {
        result,
        count,
      }
    },
    tooltipText() {
      const timesheetStatuses = []
      if (this.isAnyTimesheetRejected.result)
        timesheetStatuses.push(i18n.t('timesheetUI.Status.Rejected'))
      if (this.isAnyTimesheetApproved.result)
        timesheetStatuses.push(i18n.t('timesheetUI.Status.Approved'))
      if (this.isAnyTimesheetSubmitted.result)
        timesheetStatuses.push(i18n.t('timesheetUI.Status.Submitted'))

      return formatDayCellTooltipText(timesheetStatuses)
    },
    humanFriendlyDate() {
      return moment(this.day.date).format('dddd MMMM YYYY')
    },
  },
  methods: {
    isToday: function (day) {
      return moment().isSame(day.date, 'day')
    },
    timesheetForDay() {
      return timesheetsForDay(
        this.timesheetJob,
        this.timesheets,
        this.day.date,
        true
      )
    },
  },
}
</script>
