import {
  clientMicroServiceGet,
  clientMicroServicePost,
  clientMicroServiceDelete,
} from '@apis/clientService'

export const copyUserJobsFromPreviousWeek = (params) => {
  const response = clientMicroServicePost(
    `timesheet/Jobs/copy-user-jobs-from-previous-week`,
    params
  )

  return response
}

export const updateUserJobFavorite = (params) => {
  const response = clientMicroServicePost(
    `timesheet/Jobs/update-fav-userjob/${params.jobId}?IsFavJob=${params.isFavJob}`
  )
  return response
}

export const getPPMorAltairTasks = async (params) => {
  const response = await clientMicroServiceGet(
    `timesheet/Jobs/get-ppmtask-altairtask`,
    params
  )
  return response
}

export const updateAssignedJob = (params) => {
  let url = `timesheet/Jobs/update-assigned-project-task/${params.jobId}?isJobVisible=${params.isJobVisible}`

  if (params.isPPMTask && params.PPMTaskId) {
    url += `&IsPPMTask=${params.isPPMTask}&PPMTaskId=${params.PPMTaskId}`
  }

  if (params.isAltairTask && params.AltairTaskId) {
    url += `&IsAltairTask=${params.isAltairTask}&AltairTaskId=${params.AltairTaskId}`
  }

  const response = clientMicroServicePost(url)

  return response
}

export const deleteSelfAssignedJob = (params) => {
  const response = clientMicroServiceDelete(
    `timesheet/Jobs/delete-user-job/${params.jobId}`,
    params
  )

  return response
}

export const updateAssignedProjectTaskToTimesheet = (payload) => {
  const response = clientMicroServicePost(
    `timesheet/Timesheets/add-assign-project-task-to-timesheet`,
    payload
  )

  return response
}

export const getAvailableWorkcodesAndBillableIndicators = (params) => {
  const response = clientMicroServiceGet(
    `timesheet/Jobs/available-workcodes-billable-indicators`,
    params
  )

  return response
}
