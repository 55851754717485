<template>
  <div>
    <v-btn
      :disabled="loading"
      @click="openModal"
      outlined
      rounded
      class="font-size--14 font-weight--600 light-border"
      v-show="onBehalfOfMappings.length > 0"
    >
      <v-icon class="mr-2" v-show="!OBOUserName">mdi-plus</v-icon>
      <span> {{ $t('timesheetUI.HeaderOnBehalfOf.LabelOnBehalfOf') }} </span>
      <span class="accent--text"> &nbsp; {{ OBOUserName }}</span>
    </v-btn>

    <v-dialog
      v-model="showOnBehalfOfModal"
      @click:outside="closeModal"
      width="800"
    >
      <v-card>
        <v-card-title class="justify-space-between py-5">
          <h2 class="font-weight--600 text-h2">
            {{ $t('timesheetUI.HeaderOnBehalfOf.LabelOnBehalfOfPopupHeader') }}
          </h2>
          <v-btn
            icon
            @click="closeModal"
            aria-label="Close On Behalf of Dialog"
          >
            <v-icon color="primary">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="font-size--14 font-weight--600 py-5">
          <div class="mb-2">
            {{ $t('timesheetUI.HeaderOnBehalfOf.filterByUserName') }}
          </div>

          <v-text-field
            placeholder="Search by user name"
            v-model="searchTerm"
            class="rounded-lg mb-4 font-size--14 font-weight--400"
            outlined
            dense
            hide-details
            autofocus
          ></v-text-field>

          <v-divider class="mb-4"></v-divider>

          <div v-if="onBehalfOfFilterData.length < 1">
            {{ $t('timesheetUI.HeaderOnBehalfOf.dataNotFound') }} "{{
              searchTerm
            }}"
          </div>
          <v-simple-table
            v-if="onBehalfOfFilterData.length > 0"
            fixed-header
            height="300px"
            class="OBO-table"
          >
            <template v-slot:default>
              <thead class="OBO-table--header">
                <tr class="OBO-table--header-row">
                  <th
                    class="text-left font-size--12 font-weight--500 background"
                  >
                    {{ $t('General.Name') }}
                  </th>
                  <th
                    class="text-left font-size--12 font-weight--500 background"
                  >
                    {{ $t('General.Email') }}
                  </th>
                  <th class="text-left background"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="font-weight--500"
                  v-for="(item, index) in onBehalfOfFilterData"
                  :key="`${item.targetAltairCode}-${index}`"
                  @click="handleRowClick(item)"
                  @keypress.enter="handleRowClick(item)"
                  tabindex="0"
                  :aria-selected="`${
                    selectedUser.targetAltairCode === item.targetAltairCode
                  }`"
                  role="row"
                  :class="{
                    'cursor-pointer': true,
                    background:
                      index % 2 !== 0 &&
                      selectedUser.targetAltairCode !== item.targetAltairCode,
                    'accent-light':
                      selectedUser.targetAltairCode === item.targetAltairCode,
                  }"
                  :aria-label="`Select row for user ${item.targetUserName}`"
                >
                  <td role="cell">{{ item.targetUserName }}</td>
                  <td role="cell">{{ item.targetLionEmail }}</td>
                  <td role="cell">
                    <div class="w-5">
                      <v-icon
                        v-show="
                          selectedUser.targetAltairCode ===
                          item.targetAltairCode
                        "
                        class="checkbox-icon rounded-circle font-size--18 font-weight--600"
                        color="primary"
                        >mdi-check</v-icon
                      >
                    </div>
                  </td>
                </tr>
                <tr class="table-bottom-border"></tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="d-flex justify-space-between py-5 px-6">
          <v-btn
            outlined
            rounded
            @click="closeModal"
            class="font-weight--600"
            >{{ $t('General.Btns.Close') }}</v-btn
          >
          <v-btn
            class="font-weight--600"
            rounded
            color="primary"
            depressed
            @click="addUserOnBehalfOf"
            :disabled="!selectedUser.targetLionEmail"
          >
            {{ $t('General.Btns.Add') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  data() {
    return {
      showOnBehalfOfModal: false,
      selectedUser: {},
      searchTerm: '',
    }
  },
  methods: {
    ...mapMutations('storeTimesheets', [
      'setOnBehalfOfUser',
      'hideTimeEntryDrawer',
    ]),
    ...mapActions('storeTimesheets', ['loadData']),
    resetSelectedUser() {
      this.selectedUser =
        this.onBehalfOfFilterData.find(
          (item) => item.targetAltairCode === this.altairCodeOBO
        ) || {}
    },
    clearSelectedUser() {
      this.selectedUser = {}
    },
    openModal() {
      this.hideTimeEntryDrawer()
      this.showOnBehalfOfModal = true
      this.searchTerm = ''
    },
    closeModal() {
      this.showOnBehalfOfModal = false
      this.searchTerm = ''

      if (this.altairCodeOBO) {
        this.resetSelectedUser()
      } else {
        this.clearSelectedUser()
      }
    },
    handleRowClick(item) {
      if (item.targetLionEmail === this.selectedUser.targetLionEmail) {
        this.clearSelectedUser()
        return
      }

      if (item.targetLionEmail !== this.selectedUser.targetLionEmail) {
        this.selectedUser = item
        return
      }
    },
    addUserOnBehalfOf() {
      this.setOnBehalfOfUser({
        altairCodeOBO: this.selectedUser.targetAltairCode,
        OBOUserName: this.selectedUser.targetUserName,
      })
      this.loadData('initialLoad')
      this.closeModal()
    },
  },
  computed: {
    ...mapState('storeTimesheets', [
      'onBehalfOfMappings',
      'OBOUserName',
      'altairCodeOBO',
      'loading',
    ]),
    onBehalfOfFilterData() {
      if (this.searchTerm) {
        return this.onBehalfOfMappings.filter((data) =>
          data.targetUserName
            .toLowerCase()
            .includes(this.searchTerm.toLowerCase())
        )
      }
      return this.onBehalfOfMappings
    },
  },
  watch: {
    altairCodeOBO() {
      this.resetSelectedUser()
    },
  },
}
</script>

<style lang="scss" scoped>
.w-5 {
  width: 5px;
}
.checkbox-icon {
  border: 2px solid;
}
.OBO-table .OBO-table--header .OBO-table--header-row th {
  height: 40px;
}
</style>
