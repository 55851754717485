<template>
  <v-dialog v-model="dialogShow" width="665" v-if="dialogShow">
    <FocusLock returnFocus>
      <v-card
        role="alert"
        aria-label="Alert: There has been an error"
        aria-live="polite"
        tabindex="0"
      >
        <v-card-title
          :class="[
            'justify-space-between align-start py-5 px-6',
            `${dialogAlertType}--text`,
          ]"
        >
          <div class="flex-grow-1 d-flex flex-column">
            <div class="text-h2 mb-2 d-flex justify-space-between">
              <span v-if="dialogAlertTitle">{{ dialogAlertTitle }}</span>
              <span v-if="dialogAlertType && !dialogAlertTitle">
                <span v-if="dialogAlertType.toLowerCase() === 'error'">{{
                  $t('timesheetUI.ErrorDialog.ErrorTitle')
                }}</span>
                <span v-else-if="dialogAlertType.toLowerCase() === 'success'">{{
                  $t('timesheetUI.ErrorDialog.SuccessTitle')
                }}</span>
              </span>
              <v-btn icon @click="closeDialog" aria-label="Close modal">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <div class="h5 font-weight--400" v-if="!dialogAlertSubTitle">
              {{ $t('timesheetUI.ErrorDialog.DialogSubtitle') }}
            </div>
            <div
              class="h5 font-weight--400"
              v-else
              v-html="dialogAlertSubTitle"
            ></div>
          </div>
          <!-- <v-btn icon @click="closeDialog" aria-label="Close modal">
            <v-icon>mdi-close</v-icon>
          </v-btn> -->
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-5 px-6">
          <v-row>
            <v-col md="12">
              <div v-html="dialogAlertMessage"></div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-5 px-6">
          <v-btn rounded outlined @click="closeDialog" width="90" height="40">
            {{ $t('General.Btns.Close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </FocusLock>
  </v-dialog>
</template>

<script>
import { EventBus } from '@Shared/EventBus'
import FocusLock from 'vue-focus-lock'

export default {
  props: ['left'],
  components: {
    FocusLock,
  },
  data() {
    return {
      dialogShow: false,
      dialogAlertMessage: '',
      dialogAlertTitle: '',
      dialogAlertType: '',
      dialogCallback: null,
      dialogAlertSubTitle: '',
    }
  },
  watch: {
    dialogShow(newVal, oldVal) {
      EventBus.$emit('dialogAlertChange', this.dialogShow)
      if (oldVal === true && newVal === false) {
        this.returnFocusToSaveButton()
      }
    },
  },
  methods: {
    closeDialog() {
      this.dialogShow = false

      if (this.dialogCallback) {
        this.dialogCallback()
      }
    },
    returnFocusToSaveButton() {
      const saveButton = document.getElementById('timesheet-entry-save-btn')
      if (saveButton) {
        setTimeout(() => {
          saveButton.focus()
        }, 900)
      }
    },
  },
  created() {
    EventBus.$on('dialogAlertShow', (dialogAlert) => {
      this.dialogShow = true
      this.dialogAlertType = dialogAlert?.dialogAlertType
      this.dialogAlertTitle = dialogAlert?.dialogAlertTitle
      this.dialogAlertSubTitle = dialogAlert?.dialogAlertSubTitle
      this.dialogAlertMessage = dialogAlert.dialogAlertMessage

      if (dialogAlert.dialogCallback) {
        this.dialogCallback = dialogAlert.dialogCallback
      }
    })

    EventBus.$on('dialogAlertHide', () => {
      this.dialogShow = false
    })
  },
}
</script>
