<template>
  <v-dialog v-if="true" v-model="dialog" max-width="660px">
    <FocusLock returnFocus>
      <v-card role="alert" aria-live="polite" append-icon="mdi-close">
        <div class="py-5 px-6">
          <v-card-title class="d-flex justify-space-between pa-0">
            <span
              class="text-h2 error--text"
              :aria-label="`Alert: ${$t(
                'timesheetUI.ValidationMessages.DeleteErrorTitle'
              )} - ${$t('timesheetUI.ValidationMessages.DeleteErrorMessage')}`"
              >{{ $t('timesheetUI.ValidationMessages.DeleteErrorTitle') }}</span
            >
            <v-btn icon @click="dialog = false" aria-label="Close modal">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-subtitle class="pa-0 mt-2">
            {{ $t('timesheetUI.ValidationMessages.DeleteErrorMessage') }}
          </v-card-subtitle>
        </div>

        <v-divider></v-divider>

        <v-card-text class="py-5 px-6">
          <div class="d-flex gap-6">
            <div class="flex-grow-1">
              <v-label>{{
                $t('timesheetUI.EntryFormLabels.TimesheetEntryJobName')
              }}</v-label>
              <v-text-field
                outlined
                dense
                :value="deletingData ? deletingData.jobName : ''"
                disabled
                hide-details
              ></v-text-field>
            </div>
            <div class="flex-grow-1">
              <v-label>{{
                $t('timesheetUI.EntryFormLabels.TimesheetEntryJobID')
              }}</v-label>
              <v-text-field
                outlined
                dense
                :value="deletingData ? deletingData.job_ID : ''"
                disabled
                hide-details
              ></v-text-field>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="py-5 px-6">
          <v-btn
            color="primary"
            depressed
            min-width="88px"
            height="40px"
            rounded
            @click="dialog = false"
            outlined
            class="mr-auto"
          >
            {{ $t('General.Btns.Close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </FocusLock>
  </v-dialog>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import FocusLock from 'vue-focus-lock'

export default {
  name: 'TimesheetDialogDeleteError',
  components: {
    FocusLock,
  },
  data() {
    return {
      dialog: null,
      timesheetJobId: null,
    }
  },
  computed: {
    ...mapState('storeTimesheets', ['showDeleteError', 'deletingData']),
  },
  watch: {
    showDeleteError(newVal, oldVal) {
      this.dialog = this.showDeleteError
      this.timesheetJobId = this.deletingData?.id ?? this.timesheetJobId
      if (oldVal === true && newVal === false) {
        this.returnFocusToRemoveJobButton()
      }
    },
    dialog() {
      if (this.dialog === false) {
        this.closeDialog()
      }
    },
  },
  methods: {
    ...mapMutations('storeTimesheets', ['setTimesheetDeleteError']),
    closeDialog() {
      this.setTimesheetDeleteError({
        dialog: false,
        data: null,
      })
    },
    returnFocusToRemoveJobButton() {
      setTimeout(() => {
        document.getElementById(`job-${this.timesheetJobId}`).focus()
      }, 500)
    },
  },
}
</script>
