<template>
  <div class="search-container grow">
    <v-text-field
      v-model="search"
      :placeholder="$t('timesheetUI.HeaderSearchTimesheet.Placeholder')"
      :aria-label="$t('timesheetUI.HeaderSearchTimesheet.Placeholder')"
      class="timesheet-header__search"
      outlined
      hide-details
      dense
      clearable
      tabindex="0"
      @input="handleSearchInput"
    >
      <template v-slot:prepend-inner>
        <search-filter-chips />
      </template>
      <template v-slot:append>
        <search-filter-popover />
        <v-icon color="primary"> mdi-magnify </v-icon>
      </template>
    </v-text-field>
    <div
      class="search-feedback"
      v-if="searchFeedback && search && search.length"
      aria-live="polite"
      v-html="searchFeedback"
    ></div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import SearchFilterPopover from './SearchFilterPopover.vue'
import SearchFilterChips from './SearchFilterChips.vue'

import { debounce } from 'lodash'

export default {
  name: 'TimesheetNewHeader',
  components: { SearchFilterPopover, SearchFilterChips },
  data: () => {
    return {
      search: '',
      searchFeedback: '',
    }
  },
  computed: {
    ...mapState('storeTimesheets', [
      'timesheetJobsOg',
      'globalSearchSelectedFilters',
      'globalFilterOptions',
    ]),
    ...mapGetters('storeTimesheets', ['timesheetJobs']),
  },
  watch: {
    search() {
      this.$nextTick(() => {
        this.debouncedFeedback()
      })
    },
  },
  methods: {
    ...mapMutations('storeTimesheets', [
      'setSearchTimesheetJobs',
      'setTimesheetJobs',
      'setPerformRemoveButtonFocus',
      'setFilterOpen',
    ]),
    ...mapActions('storeTimesheets', ['setFocusOnRemoveBtn']),
    handleSearchInput($event) {
      this.setAriaLabel()
      this.setSearchTimesheetJobs($event)
    },
    setAriaLabel() {
      this.$nextTick(() => {
        const clearIcon = document.querySelector(
          '.v-input__icon--clear button.v-icon--link'
        )
        if (clearIcon) {
          clearIcon.setAttribute('aria-label', 'Clear search')
          clearIcon.setAttribute('tabindex', '0')
        }
      })
    },
    debouncedFeedback: debounce(function () {
      this.updateSearchFeedback()
      if (this.search && this.search.length > 0) {
        this.setPerformRemoveButtonFocus(false)
        this.setFocusOnRemoveBtn(this.timesheetJobs)
      }
    }, 1000),
    updateSearchFeedback() {
      if (this.timesheetJobs.length > 0) {
        this.searchFeedback = `${this.timesheetJobs.length} results matching "<span class='font-weight--600'>${this.search}<span>"`
      } else {
        this.searchFeedback = `No results matching "<span class='font-weight--600'>${this.search}</span>"`
      }
    },
  },
}
</script>
<style scoped>
.search-feedback {
  position: absolute;
}
</style>
