var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex gap-2 flex-wrap" }, [
    _c(
      "div",
      { staticClass: "old-values pa-3 rounded-lg flex-grow-1" },
      [
        _c("div", { staticClass: "mb-2 font-weight--600 font-size--16" }, [
          _vm._v(" " + _vm._s(_vm.$t("General.OldValues")) + " "),
        ]),
        _vm._l(_vm.timesheetDetailOldCopy, function (value, key) {
          return _c("div", { key: key }, [
            _vm.checkFieldForComparison(key)
              ? _c(
                  "div",
                  [
                    _c("v-label", [
                      _vm._v(
                        _vm._s(_vm.$t(`timesheetUI.ComparisonLabels.${key}`))
                      ),
                    ]),
                    value || key === "wfh"
                      ? _c("p", { staticClass: "default" }, [
                          _vm._v(
                            " " + _vm._s(_vm.valuehandler(value, key)) + " "
                          ),
                        ])
                      : _c("p", [_vm._v("-")]),
                  ],
                  1
                )
              : _vm._e(),
          ])
        }),
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "new-values pa-3 rounded-lg flex-grow-1" },
      [
        _c("div", { staticClass: "mb-2 font-weight--600 font-size--16" }, [
          _vm._v(" " + _vm._s(_vm.$t("General.Newvalues")) + " "),
        ]),
        _vm._l(_vm.timesheetDetailCopy, function (value, key) {
          return _c("div", { key: key }, [
            _vm.checkFieldForComparison(key)
              ? _c(
                  "div",
                  [
                    _c("v-label", [
                      _vm._v(
                        _vm._s(_vm.$t(`timesheetUI.ComparisonLabels.${key}`))
                      ),
                    ]),
                    _vm.hasValueChanged(
                      value,
                      _vm.timesheetDetailOldCopy[key],
                      key
                    )
                      ? _c(
                          "v-icon",
                          {
                            staticClass: "ml-1",
                            attrs: { size: "13", color: "primary" },
                          },
                          [_vm._v("mdi-autorenew")]
                        )
                      : _vm._e(),
                    _c(
                      "p",
                      {
                        class: {
                          default: _vm.hasValueChanged(
                            value,
                            _vm.timesheetDetailOldCopy[key],
                            key
                          ),
                        },
                      },
                      [
                        _vm.hasValueChanged(
                          value,
                          _vm.timesheetDetailOldCopy[key],
                          key
                        )
                          ? _c("span", [
                              _vm._v(
                                " " + _vm._s(_vm.valuehandler(value, key)) + " "
                              ),
                            ])
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("timesheetUI.InfoPanel.NoChanges")
                                )
                              ),
                            ]),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }