<template>
  <v-dialog v-if="dialog" v-model="dialog" max-width="660px">
    <v-card role="alert" aria-live="polite">
      <v-card-title class="d-flex justify-space-between align-start pa-4">
        <div
          :aria-label="`Alert: ${$t(
            'timesheetUI.ValidationMessages.EditHiddenJobErrorTitle'
          )} - ${$t(
            'timesheetUI.ValidationMessages.EditHiddenJobErrorMessage'
          )}`"
        >
          <div class="h1 mb-1 error--text font-weight-bold">
            {{ $t('timesheetUI.ValidationMessages.EditHiddenJobErrorTitle') }}
          </div>
          <div class="h5 font-color--error">
            {{ $t('timesheetUI.ValidationMessages.EditHiddenJobErrorMessage') }}
          </div>
        </div>
        <v-btn icon @click="dialog = false" aria-label="Close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="px-4">
        <v-row>
          <v-col md="6" class="pb-0">
            <v-label class="mb-1">{{
              $t('timesheetUI.EntryFormLabels.TimesheetEntryJobName')
            }}</v-label>
            <v-text-field
              class="mt-2"
              outlined
              dense
              :value="editHiddenJobData.jobName"
              disabled
              hide-details
            ></v-text-field>
          </v-col>
          <v-col md="6" class="pb-0">
            <v-label>{{
              $t('timesheetUI.EntryFormLabels.TimesheetEntryJobID')
            }}</v-label>
            <v-text-field
              class="mt-2"
              outlined
              dense
              :value="editHiddenJobData.job_ID"
              disabled
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end pa-4">
        <v-btn
          color="primary"
          depressed
          min-width="160px"
          @click="dialog = false"
          class="mr-auto"
          outlined
        >
          {{ $t('General.Btns.Close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
export default {
  name: 'TimesheetDialogHideJobError',
  data() {
    return {
      dialog: null,
    }
  },
  computed: {
    ...mapState('storeTimesheets', [
      'showEditHiddenJobError',
      'editHiddenJobData',
    ]),
  },
  watch: {
    showEditHiddenJobError() {
      this.dialog = this.showEditHiddenJobError
    },
    dialog() {
      if (this.dialog === false) {
        this.closeDialog()
      }
    },
  },
  methods: {
    ...mapMutations('storeTimesheets', ['setTimesheetEditJobError']),
    closeDialog() {
      this.setTimesheetEditJobError({
        dialog: false,
        data: null,
      })
    },
  },
}
</script>
