<template>
  <v-dialog v-model="dialogShow" width="630" v-if="dialogShow" persistent>
    <FocusLock returnFocus>
      <v-card
        role="alert"
        aria-live="polite"
        tabindex="0"
        aria-label="Alert: There has been an error"
        class="dialog-alert--confirm-action"
      >
        <v-card-title
          :class="[
            'd-flex justify-space-between flex-wrap-inherit align-start pa-4',
            `headline--${dialogAlertType}`,
          ]"
        >
          <div class="h1 mb-1 font-weight--600">
            <span>{{ dialogAlertTitle }}</span>
            <span v-if="dialogAlertType && !dialogAlertTitle">
              <span v-if="dialogAlertType.toLowerCase() === 'error'">{{
                $t('timesheetUI.ErrorDialog.ErrorTitle')
              }}</span>
              <span v-else-if="dialogAlertType.toLowerCase() === 'success'">{{
                $t('timesheetUI.ErrorDialog.SuccessTitle')
              }}</span>
            </span>
          </div>
        </v-card-title>

        <v-card-text class="pa-4">
          <v-row>
            <v-col md="12">
              <div v-html="dialogAlertMessage"></div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end pa-4">
          <v-btn
            outlined
            rounded
            large
            color="primary"
            width="100"
            class="mt-3 pa-4"
            @click="dialogShow = false"
            >{{ dialogCancelLabel }}</v-btn
          >
          <v-btn
            @click="callback"
            color="primary"
            depressed
            rounded
            large
            width="100"
            class="mt-3 pa-4"
            >{{ dialogConfirmLabel }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </FocusLock>
  </v-dialog>
</template>

<script>
import { EventBus } from '@Shared/EventBus'
import FocusLock from 'vue-focus-lock'

export default {
  components: {
    FocusLock,
  },
  data() {
    return {
      dialogShow: false,
      dialogAlertMessage: '',
      dialogAlertTitle: '',
      dialogAlertType: '',
      dialogConfirmLabel: '',
      dialogCancelLabel: '',
      dialogCallback: null,
    }
  },
  watch: {
    dialogShow() {
      EventBus.$emit('dialogAlertChange', this.dialogShow)
    },
  },
  methods: {
    callback() {
      this.dialogShow = false
      this.dialogCallback()
    },
  },
  created() {
    EventBus.$on('dialogAlertConfirmShow', (dialogAlert) => {
      this.dialogShow = true
      this.dialogAlertType = dialogAlert.dialogAlertType
      this.dialogAlertTitle = dialogAlert.dialogAlertTitle
      this.dialogAlertMessage = dialogAlert.dialogAlertMessage
      this.dialogCallback = dialogAlert.dialogCallback
      this.dialogConfirmLabel = dialogAlert.dialogConfirmLabel
      this.dialogCancelLabel = dialogAlert.dialogCancelLabel
    })

    EventBus.$on('dialogAlertConfirmHide', () => {
      this.dialogShow = false
    })
  },
}
</script>

<style lang="scss" scoped>
.headline {
  padding: 0 !important;

  &--error {
    color: #b00c5b;
  }

  &--success {
    color: $primary;
  }

  // &--warning {
  //   color: $warning;
  // }

  .v-icon {
    font-size: 25px;
    padding: 13px 12px 12px;
  }
}

.v-dialog .v-card {
  .v-card__text {
    padding-top: 32px;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 43px;
  }

  p {
    line-height: 1.5;
    font-weight: 500;
  }
}
</style>

<style lang="scss">
.alert-title {
  font-weight: 500;
  letter-spacing: 0.77px;
  font-size: 24px;
  text-align: center;
  line-height: 1.4;

  &--error {
    color: $error;
  }

  &--success {
    color: $approved;
  }

  // &--warning {
  //   color: $warning;
  // }

  b {
    font-weight: 700 !important;
  }
}

h3.alert-title {
  font-size: 21px;
  line-height: 1.4;
}

.v-dialog .v-card ol {
  padding-left: 0;

  li {
    padding-bottom: 8px;
    margin: 0;
    list-style-type: none;
    counter-increment: item;
    display: flex;

    &:before {
      display: inline-block;
      padding-right: 10px;
      font-weight: 900;
      text-align: right;
      content: counter(item) '.';
    }
  }
}
</style>
