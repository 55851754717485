var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VRow",
    {
      class: { "no-borders": _vm.noBorders },
      attrs: { "no-gutters": "", align: "stretch" },
    },
    [
      _c(
        "VCol",
        { staticClass: "first-col", attrs: { cols: "3" } },
        [_vm._t("col1")],
        2
      ),
      _c("VCol", [_vm._t("mon")], 2),
      _c("VCol", [_vm._t("tue")], 2),
      _c("VCol", [_vm._t("wed")], 2),
      _c("VCol", [_vm._t("thu")], 2),
      _c("VCol", [_vm._t("fri")], 2),
      _c("VCol", [_vm._t("sat")], 2),
      _c("VCol", [_vm._t("sun")], 2),
      _c("VCol", { staticClass: "last-col" }, [_vm._t("total")], 2),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }