export const announceErrorHandler = (error, container) => {
  let announceElement = document.getElementById('announceError')
  if (announceElement) {
    announceElement.remove()
  }

  announceElement = document.createElement('div')
  announceElement.setAttribute('id', 'announceError')
  announceElement.setAttribute('aria-live', 'off')
  announceElement.setAttribute('aria-atomic', 'true')
  announceElement.setAttribute('aria-relevant', 'all')
  announceElement.classList.add('sr-only')

  container.appendChild(announceElement)

  if (error && error.length > 0) {
    setTimeout(() => {
      announceElement.setAttribute('aria-live', 'assertive')
      announceElement.textContent = `error: ${error}`
    }, 100)
  }
}
