import ErrorMessagesFr from './errors-fr.js'

export const General = {
  AltairCodeNotFound: "Ce code dossier n'existe pas",
  LoadError: "Erreur système, veuillez contacter l'administrateur du système.",
  NoResultsFound: 'Aucun résultat trouvé',
  ElipsisMore: ' Afficher plus',
  NA: 'Non accessible',
  Enabled: 'Activé',
  Disabled: 'Désactivé',
  RowsPerPage: 'Lignes par page',
  Required: 'champs requis *',
  selectAll: 'Tout sélectionner',
  unselectAll: 'Tout désélectionner',
  for: 'pour',
  is: 'est',
  Btns: {
    download: 'Download',
    upload: 'Upload',
    downloading: 'Downloading',
    Save: 'Sauvegarder',
    AddNew: 'Ajouter',
    Cancel: 'Annuler',
    SaveChanges: 'Sauvegarder les modifications',
    Continue: 'Continuer',
    Confirm: 'Confirmer',
    ClearFilter: 'Supprimer filtres',
    Delete: 'Supprimer',
    Close: 'Fermer',
    LogTime: 'Sauvegarder',
    NewEntry: 'Nouvelle entrée',
    Back: 'Dos',
    CorrectTimesheet: 'Corriger la feuille de temps',
    ReviewCorrection: 'Correction de révision',
    RemoveCorrection: 'Remove Correction',
    //TODO:
    Stay: 'Stay',
    Leave: 'Leave',
    Add: 'Ajouter',
  },
  Warnings: {
    CantBeUndoneAlert: 'Cette action ne peut être annulée.',
    AreYouSure: 'Etes-vous sûr de vouloir faire cela?',
    SaveError: 'Les champs obligatoires doivent être renseignés.',
  },
  Alerts: {
    UploadSuccessful: 'Upload Successful',
    SaveMessage: 'Etes-vous sûr de vouloir sauvegarder ?',
    SaveSuccessful: 'Sauvegarde effectuée',
    DeleteSuccessful: 'Suppression des données effectuée.',
    //TODO:
    UnSavedChanges: 'You have unsaved changes!',
    UnSavedChangesMessage: 'Are you sure you want to leave ?',
  },
  Name: 'Nom',
  Email: 'e-mail',
  Nodataavailable: 'Aucune donnée disponible',
  Addnewrow: 'Ajouter de nouvelles valeurs',
  OldValues: 'Anciennes valeurs',
  Newvalues: 'Nouvelles valeurs',
  and: 'et',
}

export const timesheetUI = {
  PanelTitle: 'Feuille de temps',
  JobGroupPrefix: 'Type de dossier: ',
  Btns: {
    BtnCopyJobsFromPreviousWeekTooltip:
      'Cliquez pour copier les tâches ajoutées la semaine précédente',
    BtnCopyJobsFromPreviousWeek: 'Copier de la semaine précédente',
    BtnAddNewEntry: 'Ajouter une nouvelle entrée',
    BtnSubmit: 'Soumettre',
    BtnSubmitSmaller: 'Soumettre',
    BtnSubmitTimesheet: 'Soumettre la feuille de temps',
    BtnSave: 'Sauvegarder',
    BtnDuplicateRow: 'Dupliquer ligne',
    BtnDeleteRow: 'Supprimer ligne',
    BtnCancel: 'Annuler',
    BtnCopyJobsFromPreviousWeekMobile: 'Copier',
    BtnAddNewEntryMobile: 'Ajouter une nouvelle',
    BtnUnsubmitted: 'Non soumis',
    BtnSubmitted: 'Soumise',
    BtnApproved: 'Approuvée',
    BtnPartiallyApproved: 'Approuvée partiellement',
    BtnPartiallyApprovedSmaller: 'AP',
    BtnRejected: 'Rejetée',
    BtnSubmitAll: 'Soumettre tous',
    BtnClose: 'Fermer',
    BtnPrevWeek: 'Semaine précédente',
    BtnPrevWeekSmaller: 'Précédente',
    BtnNextWeek: 'Semaine suivante',
    BtnNextWeekSmaller: 'Suivante',
    BtnMoreInfo: "Plus d'informations",
    BtnShowDetail: 'Voir détails',
    BtnsSaving: 'Sauvegarde',
    BtnJobList: 'Ajouter dossier / tâche',
    BtnRemoveRow: 'Supprimer ligne',
    BtnBookAbsence: 'Planifier une absence',
    BtnBookAbsenceModify: 'Modifier',
    BtnBookAbsenceCancel: "Annuler l'absence",
    BtnBookAbsenceCancelConfirm: "Confirmer l'annulation",
    BtnCloseAbsenceCalendar: 'Ouvrir les feuilles de temps',
  },
  MobileBtns: {
    BtnSubmit: 'S',
    BtnSubmitted: 'S',
    BtnApproved: 'A',
    BtnPartiallyApproved: 'PA',
    BtnRejected: 'R',
    BtnSubmitAll: 'SA',
  },
  HeaderSearchTimesheet: {
    Placeholder: 'Rechercher dans la feuille de temps en cours',
    NoResultsTitle: 'Aucun résultat pour: <strong>${query}</strong>',
    NoEntriesFound: 'Aucune entrée correspondante',
    BtnSearch: 'Rechercher',
    BtnCancel: 'Annuler',
    BtnApplyFilters: 'Appliquer des filtres',
    FilterLabel: 'Filtrer la feuille de temps',
    FilterPopupLabel: 'Filtres et catégories',
    ClearFilters: 'Effacer tous les filtres',
    FilterOptions: {
      self_assigned: 'Auto-attribution',
      my_agency: 'Mon agence',
      other_agencies: 'Autres agences',
      assigned_job: 'Job assigné',
      favourites: 'Favoris',
    },
    noResultsLabel: 'Aucun résultat trouvé',
    noResultsSubText:
      'Aucune de vos feuilles de temps ne correspond au mot-clé recherché ou au filtre appliqué.',
  },
  HeaderOnBehalfOf: {
    LabelOnBehalfOf: 'Pour le compte de',
    LabelOnBehalfOfPopupHeader: 'Au nom de',
    Placeholder: 'Rechercher utilisateurs',
    selectedOnBehalfOfText: 'Bienvenue, vous enregistrez du temps au nom de',
    gotomytimesheet: 'Aller à ma feuille de temps',
    filterByUserName: "Filtrer par nom d'utilisateur",
    dataNotFound: "Nous n'avons trouvé aucun résultat correspondant à",
  },
  HeaderDatePicker: {
    BtnCurrentWeek: 'Semaine en cours',
    BtnLastWeek: 'Semaine précédente',
  },
  HeaderCalendar: {
    LabelHoliday: 'Férié',
    LabelTotal: 'TOTAL',
    LabelProject: 'PROJECT',
    LabelPercentage: 'POURCENTAGE',
    LabelHours: 'HEURES',
    LabelTotal2: 'Total',
    AllocationPeriod: "Période d'allocation:",
  },
  SearchJobs: {
    ShowSelfAssignmentError:
      'Veuillez contacter votre chef de projet pour lui demander de vous ajouter à ce projet dans rOar afin que vous puissiez ajouter votre temps.',
    NoStudioActivities:
      "L'activité studio ou le workcode doivent être ajoutés au job. Veuillez contacter l'équipe finance locale.",
    NoJobs:
      "Votre workcode doit être ajouté au dossier. Veuillez contacter l'équipe finance locale.",
    NotEligible:
      "Vous n'êtes pas autorisé à utiliser ce code d'absence. Veuillez contacter votre responsable RH.",
    multipleContractsAgencyLabel:
      "En tant qu'utilisateur non-studio, pour l'agence, $(0) avec le workcode $(1)",
    multipleContractsStudioLabel:
      "En tant qu'utilisateur studio, pour l'agence, $(0) avec le workcode $(1)",
    HasChangedContractTitle: `Vous avez changé d'affectation cette semaine. Vous ne pouvez pas utiliser ce dossier.`,
    SearchJobsBackLabel: 'Feuilles de temps',
    SearchJobsPanelTitle: 'Rechercher un dossier',
    SearchBoxPlaceholder:
      'Rechercher par nom/nr du dossier, client, produit, agence et plus …',
    AgencyFilterLabel: 'Filtre Agence',
    ResultTitle: 'Vos résultats de recherche pour',
    ResultsFor: 'dossiers trouvés. ${pagNumber} of ${totalResults}',
    ResultsFoundLabel: 'Résultats Trouvés',
    ShowingResultsLabel: 'Affiche ${pagNumber} résultats sur ${totalResults}',
    CrossCompanyLable: 'Inter Société',
    DetailPanelTitle: 'Détail de la feuille de temps',
    WorkCodeTitle: 'Workcode',
    ActivityTitle: 'Activité',
    TaskTitle: 'Tâche',
    BillableIndicatorTitle: 'Indicateur de facturation',
    AddedTitle: 'Ajouté à votre feuille de temps',
    BtnAddToTimesheet: 'Ajouter à la feuille de temps', //TODO: confirm the french translation
    BtnContinueSearch: 'Continuer la recherche',
    BtnGoBackToTimesheets: 'Feuille de temps',
    NoResultsTitle: 'Pas de résultat trouvé correspondant à "${searchQuery}"',
    //TODO: add french equivalent word
    NoJobsFound: 'Aucun jobs trouvé',
    NoResultsTipsTitle: 'Astuces de recherche',
    NoResultsTips: `Vérifiez l'orthographe et réessayez.<br />
            Essayez de rechercher avec un ou deux mots.<br />
            Essayez de rechercher avec des descriptions plus courtes<br />
            Cherchez avec l'identifiant du Dossier, exemple B1200-009127-00<br />
            Cherchez par nom de dossier, nom de produit, nom de client ou nom d'agence.<br />
                        `,
    AgencyFilters: [
      { title: 'Job assigné', value: 3, key: 'assignedJobs' },
      { title: 'Mon agence', value: 1, key: 'myAgency' },
      { title: 'Autres agences', value: 2, key: 'otherAgencies' },
      { title: 'Tout afficher', value: 0, key: 'showAll' },
    ],
    HybridTimesheetWarning: 'Etes-vous sûr de vouloir faire cela ?',
    DuplicateAssigned:
      'Un dossier avec le même identifiant a déjà été sélectionné, veuillez confirmer votre sélection',
    DuplicateSelfAssigned: 'Une tâche portant le même id existe déjà',
    CantBeAddToTimesheet: 'ne peut être ajouté(e)',
    toAddAJob:
      "Pour ajouter un Job à votre feuille de temps, vous devez d'abord sélectionner le nom de la tâche.",
    ConfirmNewRow: 'Confirmer une nouvelle ligne',
  },
  ValidationMessages: {
    GenericErrorTitle: 'Oups!',
    CantSubmitTimesheetsYetMessage:
      'Un minimum de {0} heures ou 100% est obligatoire de Lun à Ven afin de soumettre la semaine',
    TimesheetSubmittedSuccessMessageTitle: 'Merci !',
    TimesheetSubmittedSuccessMessage: `<p class="font-size--16 mb-2">Votre feuille de temps a été soumise pour approbation.</p><p><strong>Note: Vous pouvez modifier & resoumettre votre feuille de temps avant son approbation</strong></p>`,
    AlreadySubmittedTimesheetsMessage:
      'Vous avez déjà soumis votre feuille de temps',
    DailyTotalDurationExceededMessage:
      'Mais votre total par jour ne doit pas dépasser 24 heures ou 100%',
    CantDeleteTimesheetWithDurationMessageTitle:
      '<b>Saisir votre temps est important 😃</b>',
    CantDeleteTimesheetWithDurationMessage:
      'Pour supprimer cette feuille de temps, toutes les heures entrées doivent être mises à zéro.',
    TimesheetDurationMustBeZeroOrMore:
      'Doit être égal ou supérieur à zéro (0) ',
    TimesheetEntryAlreadyApprovedTitle: 'Déjà approuvée',
    TimesheetEntryAlreadyApprovedMessage:
      'Vous ne pouvez pas modifier du temps déjà approuvé.',
    TimesheetEntryFutureNotAllowed: 'Veuillez patienter',
    TimesheetEntryFutureNotAllowedMessage:
      'Vous ne pouvez pas saisir de temps dans le futur pour ce dossier',
    TimesheetNotCorrectIncrement:
      "Veuillez saisir vos temps en respectant l'incrément de ${0}",
    TimesheetNotCorrectIncrementQuantity:
      "Veuillez n'utiliser que des nombres entiers, par ex 1, 2, 3",
    TimesheetQuanitytZero:
      'Saisissez la quantité de produit pour sauvegarder votre feuille de temps',
    TimesheetNotANumber:
      'Veuiller saisir des nombres entiers ou avec décimales - ex: 3 ou 3,5',
    TimesheetValueChangeMustSave:
      'Vous avez modifié cette entrée, veuillez sauvegarder ou annuler',
    TimesheetReferenceRequired:
      'Le produit de référence est requis si une quantité de produit est ajoutée',
    ErrorForTimesheetingForNonStudioJobWithStudioUserTitle: 'Oups!',
    MissingRequiredFields:
      'Mandatory field is missing. Double-click or press enter on cell to see details',
    ErrorForTimesheetingForNonStudioJobWithStudioUser: `Vous avez sélectionné un Job Agence, mais votre contrat indique qu'à cette date vous êtes un utilisateur Studio. <br>Veuillez saisir votre temps en sélectionnant une activité Studio. Si vous constatez un problème, veuillez contacter l'équipe RH ou Finance.`,
    StartLimitationTitle: '<b>Désolé 😟,</b>',
    StartLimitationMessage:
      "Votre agence n'est pas encore active dans rOar à cette date",
    EndLimitationTitle: '<b>Désolé 😟,</b>',
    EndLimitationMessage:
      "Vous ne pouvez pas saisir de temps pour cette date. Si besoin, veuillez contacter l'équipe support locale.",
    TimesheetWholeNumbers:
      'Saisissez des nombres entiers (10, 33, 82…), sans décimales',
    CommentLengthExceeded: 'La limite maximale de caractères est de 125',
    TimesheetExceeded100: 'Vous ne pouvez saisir plus de 100% par jour',
    NoBillableIndicators: `Oups, nous ne pouvons déterminer l'indicateur de facturation pour ce workcode. Merci de contacter votre équipe finance/Altair interne`,
    LocationMessage:
      "Important : assurez-vous que l'emplacement sélectionné est à jour et reflète l'endroit où vous avez travaillé ce jour-là.",
    DeleteErrorTitle: `Vous ne pouvez pas supprimer ce dossier`,
    DeleteErrorMessage: `Pour supprimer ce dossier, toutes les heures saisies doivent être mises à zéro`,
    SubmitConfirmTitle: 'Votre feuille de temps a été soumise',
    SubmitConfirmMessage: `Votre feuille de temps a été soumise pour approbation. Vous pouvez modifier et soumettre à nouveau votre temps avant qu'il ne soit approuvé.`,
    SubmitConfirmMeta: `Total hours: {hours} • Soumis le {date}`,
    SubmitConfirmMetaPercent: `Total: {hours}% • Soumis le {date}`,
    EnterTime: 'Veuillez entrer votre temps',
    SelectWorkcode: 'Veuillez sélectionner votre workcode',
    SelectAltairTask: 'Veuillez sélectionner votre tâche Altair',
    DefaultRequired: 'Veuillez entrer votre {fieldName}',
    LocationMandatorySubmit:
      'Veuillez indiquer votre emplacement, vous pouvez le modifier avant de soumettre votre feuille de temps=',
    LocationWarningSubmit:
      "Important : assurez-vous que l'emplacement sélectionné est à jour et reflète l'endroit où vous avez travaillé ou voyagé ce jour-là",
    TimesheetPPMTaskGone:
      "La tâche PPM n'est plus disponible pour la saisie de temps à cette date, seule une valeur zéro est autorisée",
    TimesheetPPMTaskZeroOnly:
      "La valeur de la durée ne peut être modifiée qu'en zéro après avoir enregistré la tâche PPM",
    TimesheetEntryRestriction:
      "Saisie de temps en dehors de la période d'allocation",
    TimesheetEntryRestrictionMessage:
      "Veuillez noter que votre plage horaire allouée pour ce projet va du {startDate} au {endDate}.<br/>Veuillez ajuster votre saisie de temps pour qu'elle se situe dans cette période.",
    TimesheetEntryPostRestriction: 'Vos détails de votre staffing ont changé',
    TimesheetEntryPostRestrictionMessage:
      "Votre plage horaire allouée pour ce projet va du {startDate} au {endDate}.<br/>Veuillez ajuster votre saisie de temps pour qu'elle se situe dans cette période.",
    TimesheetEntryRestrictionZeroOnly:
      "Vos détails de staffing ont changé, la valeur de la durée ne peut être modifiée qu'en zéro",
    TimesheetEntryPPMTaskAllocationError:
      'La date de la tâche est en dehors de la période de dotation',
    WorkFromHome: "Merci d'indiquer votre statut de télétravail",
    HideJobErrorTitle: `Vous ne pouvez pas cacher ce job`,
    HideJobErrorMessage: `Pour cacher ce job, toutes les heures saisies doivent être mises à zéro.`,
    EditHiddenJobErrorTitle: `Vous ne pouvez pas modifier ce job`,
    EditHiddenJobErrorMessage: `Pour modifier ce job, vous devez d'abord le réafficher.`,
    TimesheetExtraHoursDurationErrorMessage: `Le temps total saisi pour une seule journée ne doit pas dépasser 24 heures.`,
    TimesheetExtraPercentageDurationErrorMessage: `Le temps enregistré pour une seule journée ne doit pas dépasser 100%.`,
  },
  JobGroupHighlight: {
    AgencyCode: "Code d'agence",
    JobID: 'Numéro de dossier',
    Week: 'Semaine',
    Year: 'Année',
    JobGroup: 'Type de dossier',
    Workcode: 'Workcode',
    WorkcodeName: 'Nom du Workcode',
    BillableIndicator: 'Type de facturation',
    Activity: 'Activité',
    ActivityName: 'Nom du Activité',
    Task: 'Tâche',
    ID: 'Numéro de dossier',
    CrossCompanyRuleId: 'Id Règle Inter Société',
    IsStudioJob: 'est un dossier studio',
    ClarizenJobTitleForBilling: 'Intitulé pour facturation',
    ClarizenJobTitleForBillingName: "Nom de l'intitulé pour facturation",
  },
  EntryFormLabels: {
    //TODO: change it to fr
    TimesheetEntryLocationSettingsLabel: 'Définir cet emplacement pour cette',
    TimesheetEntryWfhSettingsLabel:
      'Définir le statut de travail à domicile pour le',
    TimesheetWeeklySettings: [
      'Entrée seulement',
      'Journée entière',
      'Semaine entière',
    ],
    TimesheetEntryFullDayLabel: 'Full day',
    TimesheetEntryRestOfTheWeekLabel: 'reste de la semaine',
    TimesheetEntryLocationsLabel: 'Localisation',
    TimesheetEntryProductReferenceLabel: 'Produit de Référence',
    TimesheetEntryQuantityLabel: 'Quantité Produit',
    TimesheetEntryQuantityLabelSidenote: 'Pièce',
    TimesheetEntryHoursLabel:
      'Durée <span class="font-color--light">(i.e. 7, 7.5)</span>',
    TimesheetEntryDuration: 'Durée',
    TimesheetEntryPercentLabel: 'Durée (%)',
    TimesheetEntryHoursLabelSidenote: 'Heures',
    TimesheetEntryPercentLabelSidenote: '%',
    TimesheetEntryHoursLabelComments: 'Commentaire',
    TimesheetEntryLabelLocations: 'Localisation',
    TimesheetEntryLabelClarizenTaskName: 'Tâche PPM',
    BtnCancel: 'Annuler',
    BtnSubmit: 'Sauvegarder',
    TimesheetEntryLabelWorkcode: 'Workcode',
    TimesheetEntryLabelBI: 'Indicateur de facturation',
    TimesheetStudioActivity: 'Activité Studio',
    TimesheetEntryLabelTask: 'Tâche',
    TimesheetEntryHoursLabelCommentsPlaceholder: 'Entrez vos commentaires ici:',
    TimesheetEntryJobName: 'Nom du Dossier',
    TimesheetEntryJobID: 'ID du Dossier',
    timesheetStatusDescriptions: {
      unsubmitted: `La feuille de temps n'a pas encore été soumise. Vous pouvez toujours en modifier les informations avant de la soumettre.`,
      submitted: `La feuille de temps a été soumise pour approbation. Vous pouvez la modifier et la resoumettre avant son approbation.`,
      approved: `La feuille de temps a été approuvée, elle ne peut plus être modifiée.`,
      rejected:
        'La feuille de temps a été rejetée. Vous trouverez plus de détails et  commentaires ci-dessous.',
      approvedEnabledSelfCorrection:
        'La feuille de temps a été approuvée. Vous pourrez la corriger pendant ${day} jour(s).',
      canNotCorrect: 'Cette feuille de temps ne peut pas être corrigée',
      noConfigureSelfCorrection:
        "L'auto-correction des feuilles de temps n'est pas configurée pour votre statut FLSA et votre agence. Veuillez contacter votre administrateur",
      rejectedSelfCorrection:
        'To self-correct your timesheet, please ensure that any rejected self-corrected entries are removed before resubmitting',
    },
    WIPCorrectedMessage:
      "Ces temps ont été modifiés par l'équipe Finances. Aucune action n'est attendue de votre part.",
    TimesheetStatus: 'Statut de la feuille de temps',
    TimesheetEntryPsTask: 'Tâche PPM',
    TimesheetEntryPsTaskPlaceholder: 'Sélectionner le nom de la tâche',
    TimesheetEntryWfhYes: 'Oui',
    TimesheetEntryWfhNo: 'Non',
    TimesheetEntryWfhLabel: 'Travail à domicile',
    TimesheetEntryAdditionalInformation: 'Informations supplémentaires',
  },
  Empty: {
    TimesheetEmptyHasContract: 'Votre recherche est infructueuse ?',
    TimesheetEmptyNoContract:
      'La saisie de temps dans cette agence ne peut précéder votre date de transfert/embauche',
    Title: 'Nouvelle semaine, nouvelles opportunités',
    SubTitle:
      'Commencez à enregistrer vos temps quotidiens et copier les Jobs des semaines précédentes en utilisant les boutons ci-dessous.',
    Message:
      'Vous pouvez ici entrer vos temps et copier les dossiers de la semaine précédente. Utilisez les boutons ci-dessous pour commencer',
    MessageWithAbsence:
      'Vous pouvez ici entrer vos temps ou enregistrer vos absences, et copier les dossiers de la semaine précédente. Utilisez les boutons ci-dessous pour commencer',
    CopyJobFromPreviousWeek: 'Copier le dossier de la semaine précédente',
  },
  Rejected: {
    RejectedTitle: 'Raison du rejet ?',
  },
  Footer: {
    FooterTotalLabel: 'Total',
    actions: 'actions',
  },
  InfoPanel: {
    InfoPanelTitle: 'Détails de la feuille de temps',
    StatusTitle: 'Statut de la feuille de temps :',
    LabelUser: 'Utilisateur :',
    LabelLocation: 'Localisation :',
    LabelSubmittedBy: 'Soumis par :',
    LabelSubmittedOn: 'Soumis le :',
    LabelApprover: 'Approbateur :',
    LabelApprovedOn: 'Approuvé le :',
    LabelRejectedOn: 'Rejeté le :',
    LabelProdActivity: 'Activité de Production',
    LabelRefProd: 'Produit de référence',
    LabelProdQuantity: 'Qté Prod Réf',
    WIPTitle: 'Ajustement de la feuille de temps',
    WIPMessage:
      "Ces heures ont été modifiées par votre responsable Finance. Aucune action de votre part n'est nécéssaire",
    Comment: `Commentaire`,
    ReviewTitle: `Vous avez apporté les modifications suivantes`,
    NoChanges: 'Aucun changement',
    locationUpdate: {
      header:
        "Mise à jour de l'emplacement : Nous sommes passés d'une localisation basée sur la ville à une localisation basée sur l'État. Par exemple :",
      list1: 'Au lieu de "Gurgaon", veuillez rechercher "Haryana"',
      list2: 'Au lieu de "Boston", veuillez rechercher "Massachusetts"',
    },
  },
  TimesheetJobs: {
    ClarizenAssignTaskLabel: "Gestion des délégations d'approbation",
    ClarizenAssignProjectLabel: 'Projet assigné',
    SelfAssignmentProjectLabel: 'Auto assignation',
  },
  Tooltips: {
    InlineCellInfo: 'Double-click or press-enter on cell to view details',
    DailyStatus:
      "La feuille de temps pour ${day} est <span class='${dailyStatus}-text'>${dailyStatusName}</span>.",
    Status: {
      approved: 'Approuvée',
      partiallyapproved: 'Approuvée partiellement',
      submitted: 'Soumise',
      rejected: 'rejetée',
      submitting: 'Soumise',
      unsubmitted: "n'est pas Soumise",
    },
    Threshold: {
      title: "N'oubliez pas de totaliser vos temps.",
      message:
        "Le minimum d'heures par jour pour votre agence est de <b>{totalPerDay}.</b>",
      messageWeek:
        "Le nombre minimum d'heures par semaine pour votre agence est de <b> {totalPerWeek}. </b>",
      messageMixed:
        "Votre agence requiert que vous saisissiez <b>100% par jour</b> ou <b>{totalPerDay} d'heures par jour</b> avant de soumettre",
      messagePercentage:
        'Votre Agence nécessite que vous saisissiez 100% chaque journée avant de soumettre',
      alertHoursSpanishNormal: `Votre agence demande une saisie minimale de 8 heures par jour du lundi au jeudi et de 5,5 heures le vendredi avant de soumettre votre feuille de temps.`,
      alertHoursSpanishSummer: `Nous sommes entrés dans la période estivale, Votre agence demande désormais une saisie minimale de 7 heures par jour pour soumettre votre feuille de temps.`,
      alertHoursSpanishSummerEntering: `Nous sommes entrés dans la période estivale. Pour cette semaine, votre agence demande une saisie minimale de {timeSchedule} pour soumettre votre feuille de temps.`,
      alertHoursSpanishSummerLeaving: `La période estivale touche à sa fin. Pour cette semaine, votre agence demande une saisie minimale de {timeSchedule} pour soumettre votre feuille de temps.`,
      alertHours: 'Ajouter <b>{hours} heures de plus</b>',
      alertOvertime: 'Add <b>{hours} more hours regular time.</b> ',
      alertPercent: 'Ajouter <b>{hours} % plus</b>',
      alertDW1: 'pour soumettre les temps de cette journée.',
      alertDW2: 'pour soumettre la feuille de temps.',
    },
    Comment: {
      said: 'a dit',
    },
  },
  TableColumns: {
    BillableIndicator: 'Indicateur de facturation',
    Duration: 'Durée',
    Status: 'Statut',
    WorkCode: 'Workcode',
    Details: 'Détails',
  },
  Absence: {
    AbsenceCreateTitle: "Planification d'absence:",
    AbsenceType: "Type d'absence",
    AbsenceDuration: 'Durée',
    AbsenceSelectedHours: 'Heures sélectionnées',
    AbsenceSelectedDate: 'Date sélectionnée',
    AbsenceComments: 'Ajouter une note (facultatif)',
    AbsenceDetailShow: 'Afficher les détails',
    AbsenceDetailHide: 'Masquer les détails',
    AbsenceCollapsedTitle: 'Cette semaine :',
    AbsenceModifyTitle: "Gérer l'absence",
    AbsenceModifyTitleOngoing: "Confirmer l'annulation d'une absence en cours",
    AbsenceModifyTitleCancel: "Confirmer l'annulation",
    AbsenceModifyOngoingAlert:
      "Attention, vous essayez d'annuler une absence en cours. Votre demande d'annulation sera envoyée pour approbation.",
    AbsenceModifyFutureAlert:
      "Attention, vous essayez d'annuler une absence future. Votre demande d'annulation sera automatiquement approuvée.",
    AbsenceModifyPastAlert:
      "Attention, vous essayez d'annuler une absence passée. Votre demande d'annulation sera envoyée pour approbation.",
    AbsenceSummaryTitle: 'Résumé des absences',
    AbsenceThisYearTitle: '{start} année (01/01/{year} - 31/12/{year})',
    AbsenceBreakdownMessage:
      "Un récapitulatif de toutes les congés que vous avez pris jusqu'à présent dans l'année commerciale, réparti par type.",
    SendingRequest: 'envoi de la demande',
    ModifyAbsence: "modifier l'absence",
  },
  Status: {
    Unsubmitted: 'Non soumise',
    Submitted: 'Soumise',
    Approved: 'Approuvée',
    PartiallyApproved: 'Approuvée partiellement',
    Rejected: 'Rejetée',
  },
  ErrorDialog: {
    ErrorTitle: 'Désolé, nous avons rencontré une erreur',
    SuccessTitle: 'Succès',
    DialogSubtitle: `Voir le message ci-dessous pour plus d'informations.`,
  },
  ComparisonLabels: {
    location: 'Localisation',
    productReference: 'Produit de Référence',
    productQuantity: 'Quantité Produit',
    durationValue: 'Durée',
    durationPercentageValue: 'Durée (%)',
    comment: 'Commentaire',
    clarizenTaskName: 'Tâche PPM',
    workCode: 'Workcode',
    billableIndicator: 'Indicateur de facturation',
    activity: 'Activité Studio',
    task: 'Tâche',
    clarizenCustomTask: 'Tâche PPM',
    wfh: 'Travail à domicile',
    selfAssignmentWithManageTasking: 'Tâche PPM',
  },
  SelfConsent: {
    HeadingText: `Ce sont les emplacements que vous avez connectés`,
    Locations: 'Localisations',
    Days: 'Jours',
    TotalHours: 'Total Heures',
    ConsentText: `J'atteste et confirme que j'ai examiné ma carte de pointage et qu'elle reflète fidèlement toutes les heures que j'ai travaillées et le lieu où le travail a été effectué pendant la semaine de travail`,
  },
  ManagePreference: {
    Heading: "Gérer vos préférences pour l'emplacement",
    WFHHeading: 'et le travail à domicile',
    FullWeekLocationLabel: 'Emplacement pour toute la semaine',
    FullWeekWFHLabel: 'Travail à domicile pour toute la semaine',
    LocationLabel: 'Emplacement',
    WFHLabel: 'Travail à domicile',
    SelectedLocation: 'Emplacement sélectionné',
    ManageLocationPreferenceText: 'Localisation ',
    ManageWFHPreferenceText: '',
    ManagePreferenceText: 'Emplacement et travail à domicile',
    MultipleLocations: 'Localisations multiples',
  },
  SearchJobValidationMessage: {
    taskAlreadyExists:
      'La tâche avec le même nom est déjà dans votre liste, veuillez confirmer votre sélection',
    PPMAndAltairTaskAlreadyExists:
      'Un Job avec la même tâche PPM et tâche Altair est déjà dans votre liste, veuillez confirmer votre sélection',
    AltairTaskAlreadyExists:
      'Un emploi avec la même tâche Altair est déjà dans votre liste, veuillez confirmer votre sélection',
  },
  AssignedJobsBanner: {
    StartText: 'Vous avez',
    AssignTaskLabel: 'Tâche attribuée',
    And: 'et',
    AssignProjectLabel: 'Projet assigné',
    AssignedJobsBannerInYourList:
      'dans votre liste. Vous pouvez les ajouter à partir de',
    TheJobList: 'la liste des job.',
  },
  TimesheetsForLabel: 'feuilles de temps pour',
  TimesheetsSkipBar: "Passer à Ajouter un emploi dans la barre d'action",
  Sort: {
    BIAscName: 'BI',
    BIAsc: 'Billable en premier et Non-Billable en dernier',
    BIDescName: 'BI',
    BIDesc: 'Non-Billable en premier et Billable en dernier',
    NameAscName: 'Task Name',
    NameAsc: 'A-Z',
    NameDesc: 'Z-A',
  },
  JobOptions: {
    RemoveJob: 'Supprimer le Job',
    FavoriteJob: 'Job préféré',
    UnFavoriteJob: 'Retirer un Job des favoris',
  },
  LearnMore: {
    btnText: 'En savoir plus',
    Heading: 'Préférences de localisation',
    qustion1: {
      qustion:
        "Q : Qu'est-ce que les préférences de localisation et comment cela fonctionne-t-il ?",
      answer: {
        answer:
          "R :La préférence de localisation permet aux utilisateurs de sélectionner un lieu pour un jour spécifique ou une semaine. Une fois qu'une préférence de localisation est définie, elle s'applique aux nouvelles entrées créées après la configuration des préférences. Les préférences de localisation peuvent être définies à partir de deux endroits :",
        list1:
          "Le lien Localisation et Télétravail sur votre page de feuille de temps. Ce sera le lien Localisation, dans le cas où le télétravail n'est pas applicable à l'agence.",
        list2:
          'Le champ "Définir cette localisation pour" dans le formulaire d\'entrée de temps.',
      },
    },
    qustion2: {
      qustion:
        "Q : Qu'est-ce qu'une préférence de localisation pour une semaine complète ?",
      answer: {
        answer:
          'R : Une préférence de localisation pour une semaine complète définit un lieu unique pour toute la semaine.',
      },
    },
    qustion3: {
      qustion:
        "Q : Qu'est-ce qu'une préférence de localisation pour une journée complète ?",
      answer: {
        answer:
          "R : Une préférence de localisation pour une journée complète définit un lieu unique pour une journée entière. La préférence pour une journée complète a toujours la priorité sur la préférence pour une semaine complète. Par exemple, si vous sélectionnez l'Alaska pour la semaine complète et que vous sélectionnez ensuite l'Alabama pour le mardi, le système considérera l'Alaska pour toute la semaine sauf le mardi, qui sera l'Alabama. De plus, le système mettra à jour la valeur de la journée complète dans l'écran des préférences pour le mardi.",
      },
    },
    qustion4: {
      qustion:
        'Q : Pouvez-vous fournir un exemple de fonctionnement des préférences de localisation ?',
      answer: {
        answer: 'R : Bien sûr !',
        list1:
          "Tout d'abord, vous sélectionnez la préférence de localisation pour le mardi comme étant l'Alaska pour une journée complète. Lorsque vous saisissez le temps pour le mardi, le système pré-sélectionnera automatiquement l'Alaska comme lieu.",
        list2:
          "Ensuite, vous sélectionnez la préférence de localisation pour la semaine complète comme étant l'Alabama. Le système pré-sélectionnera alors l'Alabama pour la semaine complète et l'Alaska pour le mardi.",
        list3:
          "Que vous appliquiez d'abord la préférence pour la semaine complète ou la préférence pour la journée complète, la préférence pour la journée complète aura toujours la priorité sur la préférence pour la semaine complète.",
      },
    },
    qustion5: {
      qustion: 'Q : Que signifie "cette entrée seulement" ?',
      answer: {
        answer:
          'R : "Cette entrée seulement" s\'applique à une seule entrée de temps et n\'affecte pas votre lieu préféré défini pour une journée complète ou une semaine complète.',
      },
    },
  },
  copyRestOfWeek: {
    header: 'Copy rest of week',
    subHeader:
      'The copy feature will copy the time entry across all working days (usually Mon to Fri) within the selected week.',
    copyRestOfWeekList: {
      list1:
        'The copy feature will update from the day selected to the end of the week. (For example, if you select Wednesday, it will copy the entry to Thursday and Friday',
      list2: 'The copy feature will update one job (row) at a time',
      list3:
        'The copy feature will not update absences including public holidays',
      list4: 'The copy feature will not update Saturday or Sunday',
      list5:
        'If you already have time logged on a given day, a new entry will be created instead of overwriting the existing entry',
    },
  },
}

export const ErrorMessages = ErrorMessagesFr
