import moment from 'moment'
import i18n from '@Shared/i18n'

export const getTitle = (date) => {
  if (!date) {
    return ''
  }
  const locale = window.CULTURE === 'fr-FR' ? 'fr' : 'en'
  return moment(date.split('T')[0]).locale(locale).format('dddd DD MMM')
}

export const getSubTitle = (job) => {
  let subTitle = `${
    job?.isClarizenTask ? job?.clarizenProjectName : job.jobName
  } • ${job.job_ID}`
  if (job?.selfAssignmentWithManageTasking) {
    subTitle =
      subTitle +
      `<span class="d-inline-flex flex-wrap headline--error ml-1">
        • <span class="ml-1 d-flex"><span> ${i18n.t(
          'timesheetUI.TimesheetJobs.SelfAssignmentProjectLabel'
        )} 
        </span><span color="primary" small class="clarizen-task__label mdi mdi-check"></span></span>
        </span>`
  } else if (job?.isClarizenAssignedProject && job?.isClarizenTask) {
    subTitle =
      subTitle +
      `<span class="d-inline-flex flex-wrap headline--error ml-1">
        • <span class="ml-1 d-flex"><span> ${i18n.t(
          'timesheetUI.TimesheetJobs.ClarizenAssignProjectLabel'
        )} 
        </span><span color="primary" small class="clarizen-task__label mdi mdi-check"></span></span>
        </span>`
  } else if (job?.isClarizenTask) {
    subTitle =
      subTitle +
      `• ${job?.clarizenTaskName} • <span class="d-inline-flex ml-1 flex-wrap">
        <span class="headline--error">${i18n.t(
          'timesheetUI.TimesheetJobs.ClarizenAssignTaskLabel'
        )}</span>
        </span>`
  }

  if (job?.crossCompanyRuleTypeName) {
    subTitle =
      subTitle +
      `<span class="headline--error job-dialog__meta"><span class="font-color--body"> • </span> <span class="d-inline-flex ml-1 flex-wrap">${job?.crossCompanyRuleTypeName} </span> <span class=" d-inline-flex"> <span class="cross-company-rule__label mdi mdi-star"></span></span></span>`
  }

  return subTitle
}
