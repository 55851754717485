<template>
  <VCard outlined class="pa-12 mt-4 light-border">
    <h2 class="text-h2">
      {{ $t('timesheetUI.Empty.Title') }}
    </h2>

    <div class="mb-7 font-size--16">
      {{ $t('timesheetUI.Empty.SubTitle') }}
    </div>

    <SearchJob />

    <CopyJobsFromPreviousWeek color="primary" class="ml-5" />
  </VCard>
</template>

<script>
import SearchJob from './SearchJob.vue'
import CopyJobsFromPreviousWeek from './copy-jobs-from-previous-week/index.vue'

export default {
  name: 'TimesheetEmpty',
  components: {
    SearchJob,
    CopyJobsFromPreviousWeek,
  },
}
</script>
