<template>
  <VBanner
    v-if="
      unFilteredshelvedAssignedTasksCount ||
      unFilteredInactiveAssignedProjectsCount
    "
    single-line
    color="background"
    class="pa-0 font-size--12"
    v-model="showBanner"
  >
    <v-btn
      text
      class="font-weight-medium font-size--12 cursor-pointer"
      @click="setShowJobListPopupFromBannerLink(true)"
    >
      {{ $t('timesheetUI.AssignedJobsBanner.StartText') }}
      <span
        v-if="unFilteredshelvedAssignedTasksCount"
        class="px-1 font-weight--600 accent-dark--text"
      >
        {{ unFilteredshelvedAssignedTasksCount }}
        {{ $t('timesheetUI.AssignedJobsBanner.AssignTaskLabel')
        }}{{ unFilteredshelvedAssignedTasksCount > 1 ? 's' : '' }}
      </span>
      <span
        class="px-1"
        v-if="
          unFilteredshelvedAssignedTasksCount &&
          unFilteredInactiveAssignedProjectsCount
        "
        >{{ $t('timesheetUI.AssignedJobsBanner.And') }}</span
      >
      <span
        v-if="unFilteredInactiveAssignedProjectsCount"
        class="px-1 font-weight--600 accent-dark--text"
      >
        {{ unFilteredInactiveAssignedProjectsCount }}
        {{ $t('timesheetUI.AssignedJobsBanner.AssignProjectLabel')
        }}{{ unFilteredInactiveAssignedProjectsCount > 1 ? 's' : '' }}
      </span>
      {{ $t('timesheetUI.AssignedJobsBanner.AssignedJobsBannerInYourList') }}
      <span class="px-1 font-weight-medium text-decoration-underline">
        {{ $t('timesheetUI.AssignedJobsBanner.TheJobList') }}
      </span>
    </v-btn>
    <template v-slot:actions="{ dismiss }">
      <VBtn
        min-width="20"
        height="20"
        class="pa-0 rounded-lg"
        x-small
        text
        aria-label="Close Assigned Job"
        color="primary"
        @click="dismiss"
      >
        <VIcon class="font-size--14">mdi-window-close</VIcon>
      </VBtn>
    </template>
  </VBanner>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'AssignedJobsBanner',
  computed: {
    ...mapGetters('storeTimesheets', [
      'unFilteredshelvedAssignedTasksCount',
      'unFilteredInactiveAssignedProjectsCount',
    ]),

    ...mapState('storeTimesheets', ['showAssignedJobsCountInfoBanner']),

    showBanner: {
      get() {
        return this.showAssignedJobsCountInfoBanner
      },
      set(status) {
        console.log({ status })
        this.setShowAssignedJobsCountInfoBanner(status)
      },
    },
  },

  methods: {
    ...mapMutations('storeTimesheets', [
      'setShowJobListPopupFromBannerLink',
      'setShowAssignedJobsCountInfoBanner',
    ]),
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .v-banner__wrapper {
    border: none !important;
  }
}
</style>
