<template>
  <v-dialog
    v-model="showSelfConsentDialog"
    :max-width="dialogWidth"
    scrollable
    @click:outside="setSelfConsentSubmitDialog(false)"
  >
    <FocusLock returnFocus>
      <v-card>
        <v-card-title class="d-flex justify-space-between align-start pa-4">
          <div class="timesheet-entry__header">
            <div class="h1 font-weight--900 mb-1">
              {{ dialogTitle }}
            </div>
            <div class="h5">
              {{ $t('timesheetUI.SelfConsent.HeadingText') }}
            </div>
          </div>
          <v-btn icon @click="setSelfConsentSubmitDialog(false)" tabindex="0">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-progress-linear
          indeterminate
          v-if="!selfConsentData.consentData"
          color="primary"
        ></v-progress-linear>
        <template v-if="selfConsentData.consentData">
          <v-card-text class="pa-4">
            <v-row>
              <v-col sm="5">
                {{ $t('timesheetUI.SelfConsent.Locations') }}
              </v-col>
              <v-col sm="5"> {{ $t('timesheetUI.SelfConsent.Days') }} </v-col>
              <v-col sm="2">
                {{ $t('timesheetUI.SelfConsent.TotalHours') }}
              </v-col>
            </v-row>
            <v-row v-for="(item, i) in selfConsentData.consentData" :key="i">
              <v-col sm="5" class="py-2">
                <div class="px-3 light-border rounded align-center d-flex">
                  {{ item.location }}
                </div>
              </v-col>
              <v-col sm="5" class="py-2">
                <div class="px-3 light-border rounded align-center d-flex">
                  {{ item.days.join(', ') }}
                </div>
              </v-col>
              <v-col sm="2" class="py-2">
                <div class="px-3 light-border rounded align-center d-flex">
                  {{ item.duration }}h
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="2" offset="10" class="py-2">
                <div class="px-3 light-border rounded align-center d-flex">
                  {{ selfConsentData.totalDuration }}h
                </div>
              </v-col>
            </v-row>
            <div class="d-flex my-3">
              <v-checkbox
                v-model="enableSubmit"
                hide-details
                color="primary"
                class="ma-0"
                tabindex="0"
                aria-labelledby="consent-label"
              ></v-checkbox>
              <div id="consent-label" class="font-color--primary">
                {{ $t('timesheetUI.SelfConsent.ConsentText') }}
              </div>
            </div>
          </v-card-text>
          <v-divider></v-divider>
        </template>
        <v-card-actions class="justify-space-between pa-4">
          <v-btn
            color="primary"
            depressed
            rounded
            @click="setSelfConsentSubmitDialog(false)"
            class="mr-auto"
            outlined
            tabindex="0"
          >
            {{ $t('timesheetUI.Btns.BtnClose') }}
          </v-btn>

          <v-btn
            color="primary"
            depressed
            rounded
            type="submit"
            :disabled="!enableSubmit || loading"
            @click="$emit('submitTimesheet')"
            tabindex="0"
          >
            {{ $t('timesheetUI.Btns.BtnSubmitTimesheet') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </FocusLock>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import FocusLock from 'vue-focus-lock'

export default {
  name: 'TimesheetDialogSelfConsent',
  props: ['dialogTitle'],
  components: {
    FocusLock,
  },
  data() {
    return {
      showSelfConsentDialog: false,
      enableSubmit: false,
    }
  },
  watch: {
    showSelfConsentSubmitDialog() {
      this.showSelfConsentDialog = this.showSelfConsentSubmitDialog
    },
    showSelfConsentDialog(newVal) {
      if (!newVal) {
        this.enableSubmit = false
        this.setSelfConsentData({})
      }
    },
  },
  computed: {
    ...mapState('storeTimesheets', [
      'showSelfConsentSubmitDialog',
      'dialogWidth',
      'loading',
      'selfConsentData',
    ]),
  },
  methods: {
    ...mapMutations('storeTimesheets', [
      'setSelfConsentSubmitDialog',
      'setSelfConsentData',
    ]),
  },
}
</script>
<style lang="scss" scoped>
.light-border {
  border: 1px solid $timeCellGrey;
  min-height: 40px;
  color: $lightGrey;
}
.timesheet-entry__header {
  flex: 1 0 0;
}
</style>
