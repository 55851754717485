<template>
  <v-dialog v-if="dialog" v-model="dialog" max-width="660px">
    <FocusLock returnFocus>
      <v-card>
        <v-card-title class="d-flex justify-space-between align-start pa-4">
          <div>
            <div class="h1 mb-1 font-color--primary">
              {{ $t('timesheetUI.ValidationMessages.SubmitConfirmTitle') }}
            </div>
            <div class="h5">
              <span v-if="submittedData.isPercentage">
                {{
                  $t(
                    'timesheetUI.ValidationMessages.SubmitConfirmMetaPercent',
                    {
                      hours: submittedData.totalHours,
                      date: submittedData.submittedDate,
                    }
                  )
                }}
              </span>
              <span v-else>
                {{
                  $t('timesheetUI.ValidationMessages.SubmitConfirmMeta', {
                    hours: submittedData.totalHours,
                    date: submittedData.submittedDate,
                  })
                }}
              </span>
            </div>
          </div>
          <v-btn icon @click="dialog = false" aria-label="Close modal">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider v-if="!isTemporaryExternalEmployee"></v-divider>
        <v-card-text v-if="!isTemporaryExternalEmployee" class="px-4">
          <v-row>
            <v-col>
              <div class="font-color--primary">
                {{ $t('timesheetUI.ValidationMessages.SubmitConfirmMessage') }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end pa-4">
          <v-btn
            color="primary"
            depressed
            rounded
            @click="dialog = false"
            class="mr-auto px-4"
            outlined
          >
            {{ $t('General.Btns.Close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </FocusLock>
  </v-dialog>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex'
import FocusLock from 'vue-focus-lock'

export default {
  name: 'TimesheetDialogSubmitConfirmation',
  components: {
    FocusLock,
  },
  data() {
    return {
      dialog: null,
    }
  },
  computed: {
    ...mapState('storeTimesheets', ['showSubmitConfirmation', 'submittedData']),
    ...mapGetters('storeTimesheets', ['isTemporaryExternalEmployee']),
  },
  watch: {
    showSubmitConfirmation() {
      this.dialog = this.showSubmitConfirmation
    },
    dialog() {
      if (this.dialog === false) {
        this.closeDialog()
      }
    },
  },
  methods: {
    ...mapMutations('storeTimesheets', ['setTimesheetSubmitConfirmation']),
    closeDialog() {
      this.setTimesheetSubmitConfirmation({
        dialog: false,
        data: null,
        isPercentage: null,
      })
    },
  },
}
</script>
