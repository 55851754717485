<template>
  <div v-if="!savingTimesheet">
    <v-data-table
      class="timesheet-entry-table"
      :items="timesheetExists"
      :headers="headers"
      hide-default-footer
      :footer-props="{
        'items-per-page-text': $t('General.RowsPerPage'),
        'items-per-page-options': [500],
      }"
      :item-class="getTimesheetStatusClass"
    >
      <template v-slot:[`item.durationValue`]="{ item }">
        <div
          v-if="
            item.timesheetStatus === 'Approved' ||
            item.isClarizenAssignedProject ||
            (item.isSelfCorrected && item.selfCorrectedHours < 0)
          "
          class="pl-2"
        >
          <span v-if="item.isPercentageEntry">
            {{ item.durationPercentageValue }}
          </span>

          <span v-else>
            {{ item.durationValue }}
          </span>
        </div>
        <div v-else>
          <div class="timesheet-entry-table__duration-input">
            <validation-provider
              v-slot="{ errors }"
              :rules="
                timesheetDetailFeatures ? 'required|durationValidation' : ''
              "
              :name="$t('timesheetUI.EntryFormLabels.TimesheetEntryDuration')"
              :vid="item.id"
            >
              <div class="d-flex align-center">
                <v-text-field
                  v-if="item.isPercentageEntry"
                  v-model="item.durationPercentageValue"
                  @input="setTableToSave(item)"
                  dense
                  outlined
                  full-width
                  :hide-details="errors.length === 0"
                  :error-messages="errors"
                  :disabled="savingTimesheet || item.restrictedPostEntry"
                  class="my-2 mini-input"
                  height="30px"
                ></v-text-field>

                <v-text-field
                  v-else
                  v-model="item.durationValue"
                  @input="setTableToSave(item)"
                  dense
                  outlined
                  full-width
                  :hide-details="errors.length === 0"
                  :error-messages="errors"
                  :disabled="savingTimesheet || item.restrictedPostEntry"
                  class="my-2 mini-input"
                  height="30px"
                ></v-text-field>

                <div class="ml-1">
                  <span v-if="item.isPercentageEntry">%</span>
                  <span v-else>h</span>
                </div>
              </div>
            </validation-provider>
          </div>
        </div>
      </template>

      <template v-slot:[`item.workCodeName`]="{ item }">
        <span>{{
          item.jobTitleForBilling
            ? `${item.jobTitleForBilling} - ${item.jobTitleForBillingName}`
            : `${item.workCode} - ${item.workCodeName}`
        }}</span>
      </template>

      <template v-slot:[`item.activityName`]="{ item }">
        <span>{{ `${item.activity} - ${item.activityName}` }}</span>
      </template>

      <template v-slot:[`item.productReference`]="{ item }">
        <span>{{
          `${item.productReference} - ${item.productReferenceName}`
        }}</span>
      </template>

      <template v-slot:[`item.timesheetStatus`]="{ item }">
        <span v-if="item.timesheetStatus === 'Unsubmitted'">{{
          $t('timesheetUI.Status.Unsubmitted')
        }}</span>
        <span v-if="item.timesheetStatus === 'Submitted'">{{
          $t('timesheetUI.Status.Submitted')
        }}</span>
        <span v-if="item.timesheetStatus === 'Approved'">{{
          $t('timesheetUI.Status.Approved')
        }}</span>
        <span v-if="item.timesheetStatus === 'Rejected'">{{
          $t('timesheetUI.Status.Rejected')
        }}</span>
      </template>

      <template v-slot:[`item.details`]="{ item }">
        <v-btn
          outlined
          small
          color="primary"
          @click="openTimesheetDetail(item)"
          :aria-label="`Show details for time entry ${findItemIndex(item)}`"
        >
          {{ $t('timesheetUI.Btns.BtnShowDetail') }}
        </v-btn>
      </template>
    </v-data-table>
    <div class="d-flex mt-5 mb-4 ml-5">
      <div>{{ timesheetTotals }}</div>
      <div>
        <span v-if="timesheetExists[0].isPercentageEntry">%</span>
        <span v-else>h</span>
      </div>
      <div class="ml-5">
        {{ $t('timesheetUI.HeaderCalendar.LabelTotal2') }}
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import i18n from '@Shared/i18n'

export default {
  name: 'TimsheetEntryTable',
  components: {
    ValidationProvider,
  },
  computed: {
    ...mapState('storeTimesheets', [
      'timesheetExists',
      'agencyData',
      'savingTimesheet',
      'firstAvailableContract',
      'timesheetJobsOg',
      'timesheetDetailFeatures',
    ]),
    ...mapGetters('storeTimesheets', [
      'isHideTimesheetWorkcode',
      'isTemporaryExternalEmployee',
    ]),
    headers() {
      let headers = [
        {
          text: i18n.t('timesheetUI.TableColumns.Duration'),
          value: 'durationValue',
          sortable: false,
          width: this.isHideTimesheetWorkcode ? '' : '90px',
        },
        {
          text: i18n.t('timesheetUI.TableColumns.WorkCode'),
          value: 'workCodeName',
          sortable: false,
          width: '220px',
        },
        {
          text: i18n.t('timesheetUI.TableColumns.BillableIndicator'),
          value: 'billableIndicator',
          sortable: false,
          width: this.isHideTimesheetWorkcode ? '' : '130px',
        },
        {
          text: i18n.t('timesheetUI.TableColumns.Status'),
          value: 'timesheetStatus',
          sortable: false,
        },
        {
          text: i18n.t('timesheetUI.TableColumns.Details'),
          value: 'details',
          sortable: false,
        },
      ]
      if (this.isHideTimesheetWorkcode) {
        headers = headers.filter((header) => {
          return header.value !== 'workCodeName'
        })
      }
      if (this.isTemporaryExternalEmployee) {
        headers = headers.filter((header) => {
          return header.value !== 'timesheetStatus'
        })
      }

      if (
        this.agencyData.isLocationActive &&
        this.timesheetExists.some((a) => a.location)
      ) {
        const i = headers.findIndex((a) => a.value === 'timesheetStatus')

        headers.splice(i, 0, {
          text: i18n.t(
            'timesheetUI.EntryFormLabels.TimesheetEntryLocationsLabel'
          ),
          value: 'location',
          sortable: false,
        })
      }

      if (this.timesheetExists.some((a) => a.task)) {
        headers.splice(2, 0, {
          text: i18n.t('timesheetUI.EntryFormLabels.TimesheetEntryLabelTask'),
          value: 'taskName',
        })
      }

      if (this.timesheetExists.some((a) => a.activity)) {
        if (this.timesheetExists.some((a) => !a.activity)) {
          headers.splice(2, 0, {
            text: i18n.t('timesheetUI.EntryFormLabels.TimesheetStudioActivity'),
            value: 'activityName',
            width: '220px',
            sortable: false,
          })
        } else {
          headers.splice(1, 1, {
            text: i18n.t('timesheetUI.EntryFormLabels.TimesheetStudioActivity'),
            value: 'activityName',
            width: '220px',
            sortable: false,
          })
        }

        if (this.timesheetExists.some((a) => a.productReference)) {
          headers.splice(3, 0, {
            text: i18n.t(
              'timesheetUI.EntryFormLabels.TimesheetEntryProductReferenceLabel'
            ),
            value: 'productReference',
            width: '100px',
            sortable: false,
          })
        }
      }

      if (
        this.timesheetExists.some(
          (a) =>
            a.isClarizenTask &&
            this.timesheetJobsOg.find(
              (b) =>
                b.clarizenProjectId === a.clarizenProjectId &&
                (b.isClarizenAssignedProject ||
                  b.selfAssignmentWithManageTasking)
            )
        )
      ) {
        headers.splice(3, 0, {
          text: i18n.t(
            'timesheetUI.EntryFormLabels.TimesheetEntryLabelClarizenTaskName'
          ),
          value: 'clarizenTaskName',
        })
      }

      let width = '720px'

      switch (headers.length) {
        case 6:
          width = '1000px'
          break

        case 7:
          width = '1036px'
          break

        case 8:
          width = '1200px'
          break

        default:
          width = '720px'
          break
      }

      this.setDialogWidth(width)

      return headers
    },
    timesheetTotals() {
      return this.timesheetExists
        .map((a) =>
          parseFloat(
            a.isPercentageEntry
              ? a.durationPercentageValue
              : a.durationValue || 0
          )
        )
        .reduce((a, b) => a + b, 0)
        .toFixed(2)
    },
  },
  methods: {
    ...mapMutations('storeTimesheets', [
      'setTimesheetDetail',
      'setTableToSave',
      'resetTableToSave',
      'setDialogWidth',
    ]),
    ...mapActions('storeTimesheets', [
      'loadValidationLogic',
      'loadJobFeatures',
    ]),
    findItemIndex(item) {
      return this.timesheetExists.indexOf(item) + 1
    },
    openTimesheetDetail(item) {
      this.resetTableToSave()
      this.setTimesheetDetail({ data: item, dialog: true })
    },
    getTimesheetStatusClass(job) {
      if (job.timesheetStatus) {
        if (
          job.timesheetStatus.toLowerCase() === 'approved' ||
          job.timesheetStatus.toLowerCase() === 'rejected'
        ) {
          return 'timesheet-status--' + job.timesheetStatus.toLowerCase()
        } else {
          return ''
        }
      }
    },
  },
  mounted() {
    this.loadValidationLogic()
    this.loadJobFeatures()
  },
}
</script>

<style lang="scss" scoped>
.timesheet-entry-table__duration-input {
  width: 65px;
}
</style>

<style lang="scss">
.timesheet-entry-table {
  .error--text {
    position: static;
    padding-top: 15px;

    .v-text-field__details {
      padding-left: 0;
    }

    .v-messages.error--text {
      position: absolute;
      width: 100%;
      padding-top: 4px;
    }
  }

  th,
  td {
    border-width: 0 !important;
    font-weight: 500 !important;
    font-weight: $font-size-root !important;
  }

  td {
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    max-width: 220px !important;

    span {
      white-space: nowrap;
    }
  }

  .mini-input .v-input__slot {
    min-height: 30px !important;
    padding: 5px !important;
  }

  .v-data-table__wrapper {
    overflow: hidden !important;
  }
}
</style>
