<template>
  <div class="d-flex">
    <div
      class="d-flex align-center flex-wrap"
      style="flex-basis: max(calc(0.6 * 100vw), 800px)"
    >
      <h1 class="text-h1 font-weight--600 mr-7">
        {{ $t('timesheetUI.PanelTitle') }}
      </h1>
      <GlobalSearch />
    </div>
    <div>
      <div class="ml-5"><SearchJob /></div>
    </div>
  </div>
</template>

<script>
import GlobalSearch from './GlobalSearch.vue'
import SearchJob from '../SearchJob.vue'

export default {
  name: 'TimesheetHeader',
  components: {
    GlobalSearch,
    SearchJob,
  },
}
</script>
