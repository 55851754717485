import clientService from './clientServiceConfig'

if (window.location.host.includes('localhost')) {
  window.ENV = 'local'
}

const oldServiceBaseUrls = {
  local: 'http://localhost:49400/',
  'dev-singleorg': 'https://dev-api.roar.pub/timesheetapi-singleorg/',
  'qa-singleorg': 'https://timesheet-qa-singleorg-api.roar.pub/',
  'sandbox-singleorg': 'https://timesheet-sandbox-singleorg-api.roar.pub/',
  'live-singleorg': 'https://timesheet-singleorg-api.roar.pub/',
}

const baseOptions = {
  withCredentials: true,
}

function getOptions(options) {
  return { ...baseOptions, ...options }
}

// OLD Architecture
export const clientServiceGet = (endpoint, params, options = {}) => {
  if (window.IS_MICROSERVICE) {
    options.baseURL = oldServiceBaseUrls[window.ENV]
  }

  const endpointWithParams = () => {
    let endpointParams = new URL(clientService.defaults.baseURL)
    Object.keys(params).forEach((key) =>
      endpointParams.searchParams.append(key, params[key])
    )
    return (
      endpoint +
      endpointParams.search +
      '&SessionId=' +
      (window.SESSION_ID ? window.SESSION_ID : '') +
      '&Culture=' +
      (window.CULTURE ? window.CULTURE : '')
    )
  }

  return clientService.get(endpointWithParams(), getOptions(options))
}

export const clientServicePost = (endpoint, params, options = {}) => {
  if (window.IS_MICROSERVICE) {
    options.baseURL = oldServiceBaseUrls[window.ENV]
  }

  params.SessionId = window.SESSION_ID ? window.SESSION_ID : ''
  params.Culture = window.CULTURE ? window.CULTURE : ''

  if (options && options.noMiddlerwareParams) {
    delete params.SessionId
    delete params.Culture
    delete clientService.defaults.headers.SessionId
    delete clientService.defaults.headers.Culture
    delete clientService.defaults.headers.ClarizenUserId
  }

  return clientService.post(endpoint, params, getOptions(options))
}

//NEW Architecture
export const clientMicroServiceGet = (endpoint, params = {}, options = {}) => {
  const microserviceEndpoint = new URL(clientService.defaults.baseURL)
  const endpointWithParams = () => {
    Object.keys(params).forEach((key) =>
      microserviceEndpoint.searchParams.append(key, params[key])
    )
    return endpoint + microserviceEndpoint.search
  }

  options.baseURL = microserviceEndpoint.origin + microserviceEndpoint.pathname
  return clientService.get(endpointWithParams(), getOptions(options))
}

export const clientMicroServicePost = (endpoint, params = {}, options = {}) => {
  const microserviceEndpoint = new URL(clientService.defaults.baseURL)
  params.sessionId = window.SESSION_ID ? window.SESSION_ID : ''
  params.culture = window.CULTURE ? window.CULTURE : ''

  options.baseURL = microserviceEndpoint.origin + microserviceEndpoint.pathname
  return clientService.post(endpoint, params, getOptions(options))
}

export const clientMicroServiceDelete = (endpoint, params, options = {}) => {
  const microserviceEndpoint = new URL(clientService.defaults.baseURL)

  const endpointWithParams = () => {
    Object.keys(params).forEach((key) =>
      microserviceEndpoint.searchParams.append(key, params[key])
    )
    return endpoint + microserviceEndpoint.search
  }
  // options.data = params
  options.baseURL = microserviceEndpoint.origin + microserviceEndpoint.pathname
  return clientService.delete(endpointWithParams(), getOptions(options))
}
