<template>
  <OnBehalfOfModal />
</template>

<script>
import OnBehalfOfModal from './OnBehalfOfModal.vue'

export default {
  name: 'OhBehalfOf',
  components: {
    OnBehalfOfModal,
  },
  data: () => {
    return {
      showOnBehalfOfModal: false,
    }
  },
}
</script>
