import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

const options = {
  transition: 'Vue-Toastification__fade',
  position: 'bottom-left',
  closeOnClick: true,
  maxToasts: 3,
}

export { Toast, options }
